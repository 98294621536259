// UploadMagazine _crud

import axios from "axios";

export const API_ADMIN_EBOOK_BY_IDX = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/ebook/';
export const API_ADMIN_EBOOK = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/ebook';
export const API_ADMIN_EBOOK_UPLOADIMG = "/api/" + process.env.REACT_APP_API_VERSION + "/admin/common/onlyFileUploads";
export const API_ADMIN_EBOOK_UPLOADEBOOK = "/api/" + process.env.REACT_APP_API_VERSION + "/admin/common/onlyEPubFileUploads";


export function getEbookByIdx(ebookIdx){
    return axios.get(API_ADMIN_EBOOK_BY_IDX+ebookIdx);
}

export function createEbook(params){
    return axios.post(API_ADMIN_EBOOK,params);
}

export function updateEbook(ebookIdx, params) {
    return axios.put(API_ADMIN_EBOOK_BY_IDX + ebookIdx, params);
}

export function uploadEbookImg(params){
    return axios.post(API_ADMIN_EBOOK_UPLOADIMG,params, {headers : {'Content-Type' : 'multipart/form-data'} })
}

export function uploadEbookFile(params){
    return axios.post(API_ADMIN_EBOOK_UPLOADEBOOK,params, {headers : {'Content-Type' : 'multipart/form-data'} })
}

export function deleteEbookByIdx(ebookIdx){
    return axios.delete(API_ADMIN_EBOOK_BY_IDX+ebookIdx);
}