import React, { useEffect, useState,useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col, InputGroup, FormControl, Card, Badge, Button, Tabs, Tab, Modal} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import LanguageTabs from "../../../common/components/LanguageTabs";
import {getMediaclStaff,updateMedicalStaff,createMedicalStaff,uplodaMedicalStaffImg,deleteMedicalStaff} from "./crud";
import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import swal from 'sweetalert'

const UplodaMedicalStaff= ()=>{

    const {page,idx} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    // 내용이 채워졌을 때 업로드 활성화
    
    // 모달 (업로드)
    const [uploadShow, setShow_upload] = useState(false);
    const handleClose_upload = () => setShow_upload(false);
    const handleShow_upload = () => setShow_upload(true);

    const [deleteShow, setShow_delete] = useState(false);
    const handleClose_delete = () => setShow_delete(false);
    const handleShow_delete = () => setShow_delete(true);

    const [picPathFileName,setPicPathFilename] = useState('');
    const [picPathImg,setPicPathImg] = useState([]);

    const [medicalStaffIdx ,setMedicalStaffIdx] = useState('');
    const [name , setName] = useState('');
    const [langCode , setLangCode] = useState('');
    const [picPath , setPicPath] = useState('');

    const nameFocus = useRef(null);

    const onChangeName = (e) => {setName(e)}
    const onChangeLanguage = (e) => {setLangCode(e)} 
    const onChangePicPathinputimg =(e)=>{setPicPathImg(e); setPicPathFilename(e[0].name); };



    const picimgDelete= ()=>{
        setPicPath();
        setPicPathFilename('');
    }

     //파일 업로드 버튼 클릭 이벤트
     const onClickImageUpload = (e)=>{
        window.document.getElementById('picfile').click();
    }

    const getPicPathFileName =(e)=>{
        if(e){
        let fileName = e;
        let filenameArr = fileName.split('/');
        setPicPathFilename(filenameArr[2]);
        }
    }

    const getDetail = () => {
        const rs = getMediaclStaff(idx);
        rs.then(({ data : {success, message, code, data} }) => {
            if(data != null) {
                setMedicalStaffIdx(data.idx);
                setName(data.name);
                setLangCode(data.langCode);
                setPicPath(data.medicalStaffPicPath)
                getPicPathFileName(data.medicalStaffPicPath);
                console.log('data',data.picPath)
            } else {
                // swal('잘못 된 접근입니다.\r\n다시 해주세요!').then(()=>{history.push(`/mainScreenManagement/${page}/message`)});
            }
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        getDetail();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    const medicalStaffDelete = ()=>{
        handleClose_delete();
        const rs = deleteMedicalStaff(medicalStaffIdx);
        rs.then(({data : {success,message,code,data} })=>{
            if(success=true && message ==='success') {
                swal({
                    title :"알림",
                    text : "정상적으로 삭제 되었습니다."
                }).then((isConfirm)=>{
                    if(isConfirm){
                        history.push(`/medicalStaffManagement/${page}`);
                    }
                })
            }else{
                swal({
                    title: "알림",
                    text : message
                }).then((isConfirm)=>{
                    if(isConfirm){
                        return false;
                    }
                })
            }
        }).catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        medicalStaffDelete();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    const onSubmit = (picPaths) => {
        let medicalStaff = {
            name : name,
            langCode : langCode ? langCode :'en' ,
            medicalStaffPicPath : picPaths != '' ? picPaths : picPath 
        } 
        if(medicalStaffIdx != '') {
            const rs = updateMedicalStaff(medicalStaffIdx ,medicalStaff);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== null) {
                    swal({
                        title: "알림",
                        text: "정상적으로 수정 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push(`/medicalStaffManagement/${page}`);
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                // console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit(picPath);
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        } else {
            const rs = createMedicalStaff(medicalStaff);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== null) {
                    swal({
                        title: "알림",
                        text: "정상적으로 등록 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push('/medicalStaffManagement/1');}
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                // console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit(picPath);
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        }
    }


    const medicalStaffFileUpload =()=>{
        handleClose_upload();
        if(!name) return swal('알림','성명을 입력해주세요.').then(() => {nameFocus.current.focus();});
        let picPath = '';
        var formData = new FormData;
        if(picPathImg){
            formData.append('files',picPathImg[0]);
            formData.append('path','medicalStaffPicImg');
            const rs = uplodaMedicalStaffImg(formData);
            rs.then(({data})=>{
                let dataArray =new Array();
                
                for(let key in data){ 
                    let realName = data[key].realName;
                    let filePath = data[key].filePath;
                    let pushData = filePath + realName;
                    dataArray.push(pushData);
                }
                let picPath = dataArray.join();
                onSubmit(picPath);
            })
        }else{
            onSubmit(picPath);
        }

    }

    useMemo(()=>{
        getDetail();
    },[])

    return (
        <>
            <Container fluid className="UploadMediaclConatiner">

                {/* 버튼영역 */}
                <Row className="mb-4 no-gutters">
                    <Col className="d-flex justify-content-between align-items-center">
                        <div>
                        <Button variant="outline-primary mr-2" onClick={()=>history.push(`/medicalStaffManagement/${page}`)}>목록으로</Button>
                        </div>
                        {/* 정보입력시 활성화 ↑ */}
                        {/* <Button variant="dark" disabled>업로드</Button> */}
                        <div>
                        {medicalStaffIdx !=='' &&
                        <Button variant="outline-danger mr-2" onClick={handleShow_delete}>삭제</Button>
                        }
                        <Button variant="primary" onClick={handleShow_upload}>{medicalStaffIdx !== '' ? '의료진 수정' : '의료진 등록'}</Button>
                        </div>
                    </Col>
                </Row>
                {/* 모달 (업로드) */}
                <Modal show={uploadShow} onHide={handleClose_upload} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>{medicalStaffIdx !== '' ? '의료진을 수정' : '의료진을 등록'} 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_upload}>취소</Button>
                        <Button variant="primary" onClick={medicalStaffFileUpload}>확인</Button>
                    </Modal.Footer>
                </Modal>
                {/*모달 삭제 */}
                <Modal show={deleteShow} onHide={handleClose_delete} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_delete}>취소</Button>
                        <Button variant="primary" onClick={medicalStaffDelete}>확인</Button>
                    </Modal.Footer>
                </Modal>
                {/* 정보 입력영역 */}
                <Row>
                    <Col>
                        <Card>
                            <Card.Header className="cardTitle text-primary">의료진 정보</Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable">
                                    <Tbody>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>언어선택</Td>
                                            <Td><LanguageTabs 
                                            onLanSelected={onChangeLanguage}
                                            langCode={langCode}
                                            /></Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>성명</Td>
                                            <Td>
                                                {/* <Form.Control type="text" placeholder="이달의 시스템 메세지 제목을 입력하세요." onChange={(e)=>onChangeName(e.target.value)} value={title} /> */}
                                                <input className="form-control" type="text" placeholder="의료진 성명을 입력해 주세요." onChange={(e)=>onChangeName(e.target.value)} value={name} ref={nameFocus}/>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>사진 등록</Td>
                                            <Td>
                                                <div className="d-flex justify-content-between align-items-center">
                                                <form encType="multipart/form-data">
                                                <input type='file' key='image'accept="image/*" className='inputFiles' id='picfile'  hidden onChange={(e)=>onChangePicPathinputimg(e.target.files) } ></input>
                                                </form>
                                                    <Button variant="outline-primary" className="col-2 mr-2" onClick={(e)=>onClickImageUpload(e)}>파일선택</Button>
                                                    {picPathFileName&&
                                                    <Button variant="outline-primary" className="col-2 mr-2" onClick={()=>picimgDelete('')}>파일삭제</Button>
                                                    }
                                                    <Form.Control type="text" placeholder={picPathFileName ?picPathFileName : "파일을 선택해주세요."} disabled />
                                                </div>
                                                <Row className="no-gutters"  style={{marginTop:'10px'}}>
                                                    <Col><span>권장 이미지 크기 : 90 X 120, 180 X 240, 270 X 360</span></Col>
                                                </Row>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container >
        </>
    );

};

export default UplodaMedicalStaff;