import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col, InputGroup, FormControl, Card, Badge, Button, Pagination, Modal } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import CustomPagination from '../../../common/components/CustomPagination'
import { getAudioList,deleteAudioList,updateUseFlags } from "./crud";
import {makeNotEmptyObj} from '../../../../src/_metronic/utils/utils'

import Checkbox from '@material-ui/core/Checkbox';
import testImg from '../../../assets/img/testimg.jpg';
import SearchCard from "../../../common/components/SearchCard"
import swal from 'sweetalert';
import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import CustomSearchCardv2 from "../../../common/components/CustomSearchCardv2"

// import Pagination from '../../../common/components/Pagination';
// import { getUsers } from './crud';

// pagination
// let active = 2;
// let items = [];
// for (let number = 1; number <= 5; number++) {
//     items.push(
//       <Pagination.Item key={number} active={number === active}>
//         {number}
//       </Pagination.Item>,
//     );
// }

const AudiobookManagement = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { page } = useParams();
    // useMemo(() => {
    //     swal({
    //         title: "알림",
    //         text: "준비중입니다."
    //     }).then((isConfirm) => {
    //         if(isConfirm) {
    //             history.goBack();
    //         }
    //     })
        
    // },[])
    // return (<> </>)
    
    // 모달 (비공개 전환)
    const [show_private, setShow_private] = useState(false);
    const handleClose_private = () => setShow_private(false);
    const handleShow_private = () => setShow_private(true);

    // 모달 (공개 전환)
    const [show_public, setShow_public] = useState(false);
    const handleClose_public = () => setShow_public(false);
    const handleShow_public = () => setShow_public(true);
    
    // 모달 (오디오북 삭제)
    const [show_delete, setShow_delete] = useState(false);
    const handleClose_delete = () => setShow_delete(false);
    const handleShow_delete = () => {if(checkList.length<1){swal('알림','선택하신 오디오북이 없습니다.').then((isConfirm)=>{if(isConfirm)return false});}else{setShow_delete(true)}};

    const [langCode ,setLangCode] =useState((history.location.state && history.location.state.langCode) || '');
    const [sortColumn,setSortColumn] = useState((history.location.state && history.location.state.sortColumn) || 'createAt');
    const sortColumnItems = [
        {
            text : '등록일순',
            value : 'createAt',
        },
        {
            text : '오픈일수',
            value: 'useDateAt',
        },
        {
            text : '순서',
            value : 'num',
        },
    ];
    const [sortType, setSortType] = useState((history.location.state && history.location.state.sortType) || 'DESC')
    const sortTypeItems = [
        {
            text : '내림차순',
            value : 'DESC',
        },
        {
            text : '오름차순',
            value : 'ASC',
        },
    ];
    const [searchType, setSearchType] = useState((history.location.state && history.location.state.searchType) || 'title');
    const [search, setSearch] = useState((history.location.state && history.location.state.search) || '');
    const searchItems = [
        {
            text : '제목',
            value : 'title',
        },
        
    ];
    const [audioBookList, setAudioBookList] = useState([]);
    const [checkList , setCheckList] =useState([]);
    const [audioBookPage,setAudioBookPage] = useState([]) //페이지 


     //검색 onchange event
     const onChangeSortColumn = (e)=> setSortColumn(e.target.value);
     const onChangeSortType = (e) => setSortType(e.target.value);
     const onChangeSearchType = (e) => setSearchType(e.target.value);
     const onChangeSearch = (e) => setSearch(e.target.value);
     const onSelectLanguage = (e) =>{
         setLangCode(e.target.value);
     }

     //오디오북 리스트 가져오기
     const getAudioBookList = (page)=>{
        let params = {
            page : page,
            size : 25,
            type : "AUDIO"
        }   
        const chkParams = {
            langCode : langCode,      
            sortColumn : sortColumn,
            sortType : sortType,
        }
        if(searchType == 'title') { chkParams.title = search; }
        params = makeNotEmptyObj(params, chkParams);
        const rs = getAudioList(params);
        rs.then(({data :{content,totalElements,totalPages,last,first,numberOfElements}}) =>{
            setAudioBookList(content);
            const pageObj ={
                totalElements : totalElements,
                totalPages : totalPages,
                last : last,
                first : first,
                numberOfElements :numberOfElements
            }
            setAudioBookPage(pageObj);
        })
        .catch(({response : data})=>{
            if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;

                        localStorage.setItem('persist:auth', JSON.stringify(auth));

                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        movePage(page);
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                }).catch(({response : data})=>{
                    if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                });
            }
        });;
    }

    const openFlagChange = (e) =>{
        const rs =updateUseFlags(checkList,e.target.id);
        rs.then(({ data : {success, message, code, data} }) => {
          if(success === true && message === 'success') {
              swal({
                  title: "알림",
                  text: "정상적으로 수정 되었습니다."
              }).then((isConfirm) => {
                  if(isConfirm) {
                      movePage(page);
                  }
              })
          } else {
              swal({
                  title: "알림",
                  text: message
              }).then((isConfirm) => {
                  if(isConfirm) {
                      return false;
                  }
              })
          }
      })
      .catch(({response : data})=>{
          if(data.status == 401 && data.data && data?.data?.code == 1101){
              const auth = JSON.parse(localStorage.getItem('persist:auth'));
              const rsRf= refresh(auth.refreshToken);
              
              rsRf.then(({ data : {success, message, code, data} }) => {
                  if(data != null) {
                      let accessToken = data.accessToken;
                      let refreshToken = data.refreshToken;
                      auth.authToken = accessToken;
                      auth.refreshToken = refreshToken;
              
                      localStorage.setItem('persist:auth', JSON.stringify(auth));
  
                      dispatch(authAction.actions.login(accessToken, refreshToken));
                      openFlagChange(e);
                  } else {
                      swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
              })
              .catch(({response : data})=>{
                if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                  swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                }
              });
          }
      });
          if(e.target.id=='true'){
              setShow_public(false)
          }else{
              setShow_private(false)
          }
          setCheckList([]);
      }

    //오디오북 삭제
    const deleteAudioBookList = () =>{
        setShow_delete(false);
        const rs = deleteAudioList(checkList);
        rs.then(({ data : {success, message, code, data} }) => {
            if(success === true && message === 'success') {
                swal({
                    title: "알림",
                    text: "정상적으로 삭제 되었습니다."
                }).then((isConfirm) => {
                    if(isConfirm) {
                        if(audioBookPage.numberOfElements === checkList.length&& page>1){
                           // history.push(`/noticeManagement/${page-1}`);
                           movePage(page-1);
                        }else{
                            movePage(page);
                        }
                        
                    }
                })
            } else {
                swal({
                    title: "알림",
                    text: message
                }).then((isConfirm) => {
                    if(isConfirm) {
                        return false;
                    }
                })
            }
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        deleteAudioBookList();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    //체크 박스 전체 선택 유무
    const allCheck = (checked) =>{
        if(checked){
            const ckbox = [];
            audioBookList.map((v,i)=>{
            ckbox.push(v.idx);
            setCheckList(ckbox);
            })
        }else if(!checked){
            setCheckList([]);
        }
    }

    // 체크 박스 단일 선택
    const oneCheck = (checked,ckidx) =>{
        if(checked){
            setCheckList([...checkList, ckidx]);
        }else if(!checked){
            setCheckList(checkList.filter(idx => idx !== ckidx));
        }
    }
    
    const movePage = (page) => {   
        const params = {      
             langCode ,
            searchType,
            search,
            sortColumn,
            sortType,
        }
        
        history.push({
            pathname : "/audiobookManagement/"+page,
            state : params
        });
        setCheckList([]);
        getAudioBookList(page);
    }

    /* 디테일 페이지로 이동 */
    const handleClick = (idx) => {
        const params = {      
            langCode ,
            searchType,
            search,
            sortColumn,
            sortType,
        }
        
        const url = {
          pathname : `/audiobookManagement_upload/${page}/${idx}`,
          state : params,
        }
        history.push(url);
      }

    //검색
    const onSearch = (e) => {
        e.preventDefault();
        movePage(1);

    }
    
    useMemo(() => {
        movePage(page);        
    },[])


    return (
        <>
            <Container fluid className="EbookConatiner">

                {/* 검색영역 */}
                <CustomSearchCardv2 
                        isLanguage = {true}
                        onSelectLanguage={onSelectLanguage}
                        langValue = {langCode}

                        isSortColumn={true}
                        onChangeSortColumn ={onChangeSortColumn}
                        sortColumnItems={sortColumnItems}
                        sortColumn={sortColumn}

                        isSortType={true}
                        onChangeSortType={onChangeSortType}
                        sortTypeItems={sortTypeItems}
                        sortType={sortType}

                        isSearch={true}
                        onChangeSearch={onChangeSearch}
                        onSearchEvent={onSearch}
                        searchItems={searchItems}
                        onChangeSearchType={onChangeSearchType}
                        search={search}   
                        />

                {/* 오디오북리스트 */}
                <Row>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h4 className="cardTitleBig">오디오북 &nbsp;&nbsp;<span style={{fontSize : '14px', fontWeight: 400}}>[ 전체 : {audioBookPage.totalElements ? audioBookPage.totalElements : '0'} ]</span></h4>
                                <Button onClick={() => { history.push('/audiobookManagement_upload') }} variant="primary">오디오북 업로드</Button>
                            </Card.Header>
                            <Card.Body className="d-flex no-gutters p-0">
                                <Table className="commonTable borderTable bb-1">
                                        <Thead>
                                            <Tr>
                                                <Th style={{width:50}}>
                                                <Checkbox 
                                                checked = {
                                                    checkList.length === audioBookPage.numberOfElements && audioBookPage.numberOfElements>0
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) =>allCheck(e.target.checked)}/> 
                                                </Th>
                                                <Th style={{width:80}}>번호</Th>
                                                <Th style={{width:80}}>언어</Th>
                                                <Th>제목</Th>
                                                <Th style={{width:100}}>조회수</Th>
                                                <Th style={{width:80}}>순서</Th>
                                                <Th style={{width:120}}>설정상태</Th>
                                                <Th style={{width:160}}>오픈일</Th>
                                                <Th style={{width:160}}>등록(수정)일</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {audioBookList.length > 0 ?
                                                audioBookList.map((v,i)=>{
                                                    return(
                                                    <Tr >
                                                        <Td><Checkbox 
                                                            checked={
                                                                checkList.includes(v.idx) ? true : false
                                                            }
                                                         onChange={(e)=>oneCheck(e.target.checked,v.idx)} />
                                                         </Td>
                                                        <Td>{v.idx}</Td>
                                                         <Td>{v.langCodeName}</Td>
                                                        <Td className="text-left" onClick={() => {handleClick(v.idx)}}>
                                                            <div class="thumbWrap">
                                                            {v.thumbnailPath ?
                                                                <div className="thumbImgBox">
                                                                    <img src={'/fileUpload/'+v.thumbnailPath} className="thumbImg" />
                                                                    <span className="thumbSpan">{v.playtime ? v.playtime : '00:00' }</span>
                                                                </div>
                                                            :
                                                            <div className="thumbImgBox">
                                                                <img src={'/media/empty/default-video.jpg'} className="thumbImg" />
                                                                <span className="thumbSpan">{v.playtime ? v.playtime : '00:00'}</span>
                                                            </div>
                                                            }
                                                                <h6 className="thumbText" >{v.title}</h6>
                                                             </div>
                                                        </Td>
                                                        <Td>{v.viewCnt}</Td>
                                                        <Td>{v.num}</Td>
                                                        { v.useFlag == true ? 
                                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                                        : 
                                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비공개</div></Td>
                                                        }
                                                        <Td>{v.useDateAt}</Td>
                                                        <Td>{v.createAt}</Td>
                                                    </Tr> 
                                                    )
                                                })
                                                :
                                                    <Tr>
                                                        <Td colSpan="8">관련 게시물이 없습니다</Td>
                                                    </Tr>
                                            }
                                            {/* 
                                            <Tr>
                                                <Td><Checkbox /></Td>
                                                <Td>[번호 DATA]</Td>
                                                <Td className="text-left">
                                                    <div class="thumbWrap">
                                                        <div className="thumbImgBox">
                                                            <img src=[오디오북썸네일 URL DATA] className="thumbImg" />
                                                            <span className="thumbSpan">[오디오북 페이지수 DATA]</span>
                                                        </div>
                                                        <h6 className="thumbText">[오디오북 제목 DATA]</h6>
                                                    </div>
                                                </Td>
                                                <Td>[조회수 DATA]</Td>
                                                { 설정상태 == 공개 ? badge-success, 공개
                                                    <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                                : 비공개 badge-gray, 비공개
                                                    <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비공개</div></Td>
                                                }
                                                <Td>[날짜 시간 DATA]</Td>
                                            </Tr> 
                                            */}
                                            {/* <Tr>
                                                <Td><Checkbox /></Td>
                                                <Td>57</Td>
                                                <Td className="text-left">
                                                    <div class="thumbWrap">
                                                        <div className="thumbImgBox">
                                                            <img src={testImg} className="thumbImg" />
                                                            <span className="thumbSpan">21 page</span>
                                                        </div>
                                                        <h6 className="thumbText">오디오북 제목은 두줄로 하고 두 줄이 넘어가면 ellipsis, 한줄이면 간격 상관없이 상하 가운데정렬 - 이 내용은 앱 적용 내용입니다. 어드민에서는 내용 전체 노출됩니다. (메인배너, 동영상, E-BOOK)</h6>
                                                    </div>
                                                </Td>
                                                <Td>2,557</Td>
                                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                                <Td>2020.10.24 00:00</Td>
                                            </Tr>
                                            <Tr>
                                                <Td><Checkbox /></Td>
                                                <Td>56</Td>
                                                <Td className="text-left">
                                                    <div class="thumbWrap">
                                                        <div className="thumbImgBox">
                                                            <img src={testImg} className="thumbImg" />
                                                            <span className="thumbSpan">21 page</span>
                                                        </div>
                                                        <h6 className="thumbText">오디오북 제목은 두줄로 하고 두 줄이 넘어가면 ellipsis, 한줄이면 간격 상관없이 상하 가운데정렬</h6>
                                                    </div>
                                                </Td>
                                                <Td>2,557</Td>
                                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                                <Td>2020.10.24 00:00</Td>
                                            </Tr>
                                            <Tr>
                                                <Td><Checkbox /></Td>
                                                <Td>55</Td>
                                                <Td className="text-left">
                                                    <div class="thumbWrap">
                                                        <div className="thumbImgBox">
                                                            <img src={testImg} className="thumbImg" />
                                                            <span className="thumbSpan">21 page</span>
                                                        </div>
                                                        <h6 className="thumbText">오디오북 제목은 두줄로 하고 두 줄이 넘어가면 ellipsis, 한줄이면 간격 상관없이 상하 가운데정렬</h6>
                                                    </div>
                                                </Td>
                                                <Td>2,557</Td>
                                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비공개</div></Td>
                                                <Td>2020.10.24 00:00</Td>
                                            </Tr> */}
                                        </Tbody>
                                    </Table>
                            </Card.Body>
                            <Card.Footer className="d-flex flex-row justify-content-between align-items-center paginationFooter">
                                <div>
                                    <CustomPagination
                                        itemsCount={10}
                                        pageData={audioBookPage}
                                        page={page}
                                        onClickEvent={movePage}
                                    >
                                    </CustomPagination>
                                </div>
                                <div>
                                    <Button variant="outline-gray" onClick={handleShow_private}>비공개 전환</Button>
                                    <Button variant="outline-success ml-2" onClick={handleShow_public}>공개 전환</Button>
                                    <Button variant="outline-danger ml-2" onClick={handleShow_delete}>오디오북 삭제</Button>
                                </div>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>

                {/* 모달 (비공개 전환) */}
                <Modal show={show_private} onHide={handleClose_private} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>비공개로 전환하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_private}>취소</Button>
                        <Button variant="primary" id={'false'}onClick={(e)=>openFlagChange(e)}>확인</Button>
                    </Modal.Footer>
                </Modal>

                {/* 모달 (공개 전환) */}
                <Modal show={show_public} onHide={handleClose_public} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>공개로 전환하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_public}>취소</Button>
                        <Button variant="success" id={'true'}onClick={(e)=>openFlagChange(e)}>확인</Button>
                    </Modal.Footer>
                </Modal>
                
                {/* 모달 (오디오북 삭제) */}
                <Modal show={show_delete} onHide={handleClose_delete} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_delete}>취소</Button>
                        <Button variant="danger" onClick={deleteAudioBookList}>확인</Button>
                    </Modal.Footer>
                </Modal>

            </Container >
        </>
    );
};

export default AudiobookManagement;
