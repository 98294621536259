import React, { useEffect, useState,useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col, InputGroup, FormControl, Card, Badge, Button, Tabs, Tab, Modal} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import LanguageTabs from "../../../common/components/LanguageTabs";
import UploadImage from "../../../common/components/UploadImage";
import {getSystemMessageByIdx,updateSystemMessage,createSystemMessage,uploadSystemMessageFileupload,deleteSystemMessage} from "./crud";
import CustomDropZoneCom from '../../../common/components/CustomDropZoneCom'

import DeleteIcon from "@material-ui/icons/Close";



import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import swal from 'sweetalert'
// import { getUsers } from './crud';

const UploadMessage = () => {
    const {page,idx} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    // 내용이 채워졌을 때 업로드 활성화
    
    // 모달 (업로드)
    const [uploadShow, setShow_upload] = useState(false);
    const handleClose_upload = () => setShow_upload(false);
    const handleShow_upload = () => setShow_upload(true);

    const [deleteShow, setShow_delete] = useState(false);
    const handleClose_delete = () => setShow_delete(false);
    const handleShow_delete = () => setShow_delete(true);

    //멀티 이미지 등록
    let addInitialArray = [];
    let delInitialArray = [];
    let [addImgs, setAddImgs] = useState(addInitialArray);
    let [removeImgs, setRemoveImgs] = useState(delInitialArray);


    const [title , settitle] = useState(''); //시스템메세지 제목
    const [langCode , setlangCode] = useState(''); // 공지 사항 언어
    const [systemMessageIdx ,setsystemMessageIdx] = useState('');//공지사항 idx
    const [thumPath,setthumPath] = useState([]);
    const [picPath,setpicPath] = useState('');
   
    const [picPathFileName,setpicPathFilename] = useState('');
    const [picPathImg,setpicPathImg] = useState([]);
    const onChangetitle = (e) => {settitle(e)}
    const onChangeLanguage = (e) => {setlangCode(e)} 
    const onChangepicPathinputimg =(e)=>{setpicPathImg(e); setpicPathFilename(e[0].name); };

    const titleFocus = useRef(false);
    
    const picimgDelete= ()=>{
        setpicPath();
        setpicPathFilename('');
    }
    const getPicPathFileName =(e)=>{
        // console.log('e::', e)
        if(e){
        let fileName = e;
        let filenameArr = fileName.split('/');
        // console.log('e::',e);
        setpicPathFilename(filenameArr[2]);
        }
    }
    const deleteMessage = ()=>{
        handleClose_delete();
        const rs = deleteSystemMessage(systemMessageIdx);
        rs.then(({data : {success,message,code,data} })=>{
            if(success=true && message ==='success') {
                swal({
                    title :"알림",
                    text : "정상적으로 삭제 되었습니다."
                }).then((isConfirm)=>{
                    if(isConfirm){
                        history.push(`/mainScreenManagement/${page}/message`);
                    }
                })
            }else{
                swal({
                    title: "알림",
                    text : message
                }).then((isConfirm)=>{
                    if(isConfirm){
                        return false;
                    }
                })
            }
        }).catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        deleteMessage();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }
    const getDetail = () => {
        const rs = getSystemMessageByIdx(idx);
        // console.log('idx',idx);
        rs.then(({ data : {success, message, code, data} }) => {
            if(data != null) {
                // console.log('data ::',data);
                setsystemMessageIdx(data.idx);
                settitle(data.title);
                setlangCode(data.langCode);
                setthumPath(data.thumPath);
                setpicPath(data.picPath)
                getPicPathFileName(data.picPath);
                // console.log('pic 파일 이름',data.picPath)
            } else {
                // swal('잘못 된 접근입니다.\r\n다시 해주세요!').then(()=>{history.push(`/mainScreenManagement/${page}/message`)});
            }
        })
        .catch(({response : data})=>{
            // console.log('catch :: ', data)
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        getDetail();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }
    //삭제할 파일 배열에 넣기 
    const deleteOption = (file) => {
        setRemoveImgs(prev => [...prev, file]);
    }
    //추가할 파일 배열에 추가 
    const updateOption = (file) => {
        setAddImgs(prev => [...prev, file]);        
    }



    //pic 파일 업로드
    const systemMessageFileUpload =()=>{
        handleClose_upload();
        let picPath = '';
        var formData = new FormData;
        if(picPathImg){
            // console.log('파일',picPathImg[0])
            formData.append('files',picPathImg[0]);
        
            formData.append('path','systemMessagePicfile');
            const rs = uploadSystemMessageFileupload(formData);
            rs.then(({data})=>{
                let dataArray =new Array();
                
                for(let key in data){ 
                    let realName = data[key].realName;
                    let filePath = data[key].filePath;
                    let pushData = filePath + realName;
                    dataArray.push(pushData);
                }
                let picPath = dataArray.join();
                thumPathImpFillupload(picPath);
            })
        }else{
            thumPathImpFillupload(picPath);
        }

        }
    
        
   //썸네일 이미지 유무에 따른 공지사항 등록
    const thumPathImpFillupload = (picPath) =>{
        let inputImg = addImgs.filter(item => removeImgs.filter(i => item.upload.uuid == i.upload.uuid).length == 0);
        var formData = new FormData;
        // console.log(inputImg.length)
        if(inputImg.length > 0) {
            for(let i = 0; i < inputImg.length; i++) {
                formData.append('files', inputImg[i]);   
            }
        
        formData.append('path', 'systmeMessagethumImg')
        const rs = uploadSystemMessageFileupload(formData);
        rs.then(({data}) => {
            let dataArray = new Array();
            for(let key in data) {
                let realName = data[key].realName;
                let filePath = data[key].filePath;
                let pushData = filePath + realName;
                dataArray.push(pushData)
            }
            let paths = dataArray.join();
            onSubmit(paths,picPath);
        });
        
    }else{
        let paths = '';
        onSubmit(paths,picPath);
    }
    }
    //미리보기
    const galleryDel = (path) => {
        let arr = thumPath.split(',').filter(v => { return  v !== path });
        setthumPath(arr.join(','))
    }

    

    //수정 및 추가
    const onSubmit = (paths,picPaths) => {
        if(!title) return swal('알림','제목을 입력해주세요.').then(() => {titleFocus.current.focus();});

        let systemMessage = {
            title : title,
            //paths :  picPath.length ? picPath :  ''
            langCode : langCode ? langCode :'en' ,
            thumPath :  paths != '' ? paths : thumPath.length ?thumPath : '',
            picPath : picPaths != '' ? picPaths : picPath 
        } 
        // console.log(systemMessageIdx)
        if(systemMessageIdx != '') {
            const rs = updateSystemMessage(systemMessageIdx ,systemMessage);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== null) {
                    swal({
                        title: "알림",
                        text: "정상적으로 수정 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push(`/mainScreenManagement/${page}/message`);
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                // console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit(paths != '' ? paths : thumPath,picPath);
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        } else {
            const rs = createSystemMessage(systemMessage);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== null) {
                    swal({
                        title: "알림",
                        text: "정상적으로 등록 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push('/mainScreenManagement/1/message');}
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                // console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit(thumPath,picPath);
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        }
    }
     //파일 업로드 버튼 클릭 이벤트
     const onClickImageUpload = (e)=>{
        window.document.getElementById('picfile').click();
    }
    useMemo(()=>{
        getDetail();
        
    },[])
    return (
        <>
            <Container fluid className="UploadMessageConatiner">

                {/* 버튼영역 */}
                <Row className="mb-4 no-gutters">
                    <Col className="d-flex justify-content-between align-items-center">
                        <Button variant="outline-primary" onClick={()=>history.push(`/mainScreenManagement/${page}/message`)}>목록으로</Button>
                        {/* 정보입력시 활성화 ↑ */}
                        {/* <Button variant="dark" disabled>업로드</Button> */}
                        <div>
                        {systemMessageIdx !=='' &&
                        <Button variant="outline-danger mr-2" onClick={handleShow_delete}>삭제</Button>
                        }
                        <Button variant="primary" onClick={handleShow_upload}>{systemMessageIdx !== '' ? '수정' : '업로드'}</Button>
                        </div>
                    </Col>
                </Row>

                {/* 모달 (업로드) */}
                <Modal show={uploadShow} onHide={handleClose_upload} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>{systemMessageIdx !== '' ? '수정' : '업로드'} 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_upload}>취소</Button>
                        <Button variant="primary" onClick={systemMessageFileUpload}>확인</Button>
                    </Modal.Footer>
                </Modal>
                {/*모달 삭제 */}
                <Modal show={deleteShow} onHide={handleClose_delete} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_delete}>취소</Button>
                        <Button variant="primary" onClick={deleteMessage}>확인</Button>
                    </Modal.Footer>
                </Modal>
                {/* 정보 입력영역 */}
                <Row>
                    <Col>
                        <Card>
                            <Card.Header className="cardTitle text-primary">이달의 시스템 메세지</Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable">
                                    <Tbody>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>언어선택</Td>
                                            <Td><LanguageTabs 
                                            onLanSelected={onChangeLanguage}
                                            langCode={langCode}
                                            /></Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>사진등록</Td>
                                            <Td>
                                            <CustomDropZoneCom 
                                                    onUpdate={updateOption}
                                                    onDelete={deleteOption}
                                                    acceptedFiles={'image/*'}
                                                    iconFiletypes={['.jpg', '.png', '.gif', '.jpeg']}
                                                    maxFiles={10}
                                                    fileLimit={10}
                                                    title={'SIZE 375 X 584'}
                                                    description={'사진파일을 끌어서 여기에 놓거나<br />여기를 클릭하여 사진파일을 업로드합니다'}
                                                />
                                            </Td>
                                        </Tr>
                                        {thumPath != '' && thumPath.split(',').length > 0 &&
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100, fintSize: '13px'}}>기존이미지</Td>
                                            <Td>
                                                <div class="modifyCustomDropzone modifyImgContainer">
                                                <div class="gallery-grid">
                                                    {thumPath.split(',').map((v, i) => {
                                                        return(
                                                            <div class="gallery-frame">
                                                                {/* <img class="gallery-img" alt={v} src={'/fileUpload/' + v} />   */}
                                                                <img class="gallery-img" alt={v} src={'/fileUpload/' + v} />  
                                                                <button onClick={() => galleryDel(v)}><DeleteIcon /></button>
                                                            </div>  
                                                        )
                                                    })}
                                                    </div>
                                                </div>
                                            </Td>
                                        </Tr>
                                        }
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>제목</Td>
                                            <Td>
                                                {/* <Form.Control type="text" placeholder="이달의 시스템 메세지 제목을 입력하세요." onChange={(e)=>onChangetitle(e.target.value)} value={title} /> */}
                                                <input className="form-control" type="text" placeholder="이달의 시스템 메세지 제목을 입력하세요." onChange={(e)=>onChangetitle(e.target.value)} value={title} ref={titleFocus}/>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>썸네일</Td>
                                            <Td>
                                                <div className="d-flex justify-content-between align-items-center">
                                                <form encType="multipart/form-data">
                                                <input type='file' key='image'accept="image/*" className='inputFiles' id='picfile'  hidden onChange={(e)=>onChangepicPathinputimg(e.target.files) } ></input>
                                                </form>
                                                    <Button variant="outline-primary" className="col-2 mr-2" onClick={(e)=>onClickImageUpload(e)}>파일선택</Button>
                                                    {picPathFileName&&
                                                    <Button variant="outline-primary" className="col-2 mr-2" onClick={()=>picimgDelete('')}>파일삭제</Button>
                                                    }
                                                    <Form.Control type="text" placeholder={picPathFileName ?picPathFileName : "파일을 선택해주세요."} disabled />
                                                
                                                </div>
                                                <Row className="no-gutters"  style={{marginTop:'10px'}}>
                                                    <Col><span style={{color : 'red'}}>* 375 X 200 크기의 파일을 등록해주세요.</span></Col>
                                                </Row>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container >
        </>
    );
};

export default UploadMessage;
