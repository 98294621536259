import axios from "axios"
import { param } from "jquery";


export const ADMIN_QUESTION = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/question';
export const ADMIN_QUESTION_BY_IDX = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/question/';

export function getQuestionByIdx(questionIdx){
    return axios.get(ADMIN_QUESTION_BY_IDX+questionIdx)
}

export function updateQuestion(questionIdx,params){
    return axios.put(ADMIN_QUESTION_BY_IDX+questionIdx,params);
}

export function createQuestion(params){
    return axios.post(ADMIN_QUESTION,params);
}

export function deleteQuestion(questionIdx){
    return axios.delete(ADMIN_QUESTION_BY_IDX+questionIdx);
}