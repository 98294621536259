import axios from "axios";
import store from "../app/store/store";
import React from "react";
//토큰 재발행
export const REISSUE_TOKEN_URL = "seller/auth/accessToken";
const instance = axios.create();
instance.interceptors.request.use(
    config => {
        config.baseURL = process.env.REACT_APP_API_URL+"/api/v1/";
        const {
            auth: { refreshToken }
        } = store.getState();

        if (refreshToken) {
            config.headers.Authorization = `Bearer ${refreshToken}`;
        }

        return config;
    },
    err => Promise.reject(err)
);
export function reissueToken(refreshToken) {
    return instance.post(REISSUE_TOKEN_URL, {});
}

// 숫자와 컴마가 입력가능.
export const validateNumber = (value) => {
    // console.log(value);
    const numberRegexp = /^[0-9\\,]*$/;
    return numberRegexp.test(value)
}

// 콤마(,)표기 원화
export const currency = (val, cipher) => {
    let number = String(val).replace(/,/g, ""), strSymbol = '', result = '';
    if (number.indexOf('.') !== -1) number = number.split('.')[0];
    if (Number(number) < 0) {
        strSymbol = '-';
        number = String(Math.abs(number));
    }
    cipher = (cipher) ? cipher : 3;
    let len = number.length, nIndex = len % cipher, nMax = len - cipher + 1;
    nIndex = (nIndex === 0) ? cipher : nIndex;
    if (len <= cipher || cipher < 1) return String(val).replace(/,/g, "");
    result = number.substring(0, nIndex);
    while (nIndex <= nMax) {
        result += ',' + number.substring(nIndex, nIndex + 3);
        nIndex += cipher;
    }
    if (String(val).indexOf('.') !== -1) result += ('.' + String(val).split('.')[1]);
    return strSymbol + result;
}

// 콤마(,) 제거
export const removeComma = (value) => {
    value = value + '';
    value = value.split(',').join("");
    return value * 1;
}

//전화번호 폼 만들기
export const phoneFormat = (value) => {
    let phone;
    const valPhone = String(value);

    if (value.length == 11) {
        phone = valPhone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        if (phone.replace(' ', '').length == 10 && phone.substr(0, 2) == '01') {
            phone = valPhone.replace(' ', '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        }
    } else if (value.length == 10) {
        if (valPhone.indexOf('02') == 0) phone = valPhone.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
        else phone = valPhone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
    else {
        phone = valPhone.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
    };
    return phone;
}

//전화번호 '-'제거
export const phoneFormatReverse = (value) => {
    let arr = value.split('-');
    let phone = '';
    for (let key in arr) phone += arr[key];
    return phone;
}

export function formatDateNoTime(date, type) {
    let d = new Date(date);
    if (typeof type !== "undefined" && type) {
        d = date;
    }

    let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

//유저상태 db값 text로 변경
export const changeUserStatusValueToText = (value) => {
    let result = "";
    if (value === 'DEFAULT') {
        result = <span className="badge badge-success">가입</span>;
    } else if (value === 'BLOCKED') {
        result = <span className="badge badge-red">차단</span>;
    } else if (value === 'LEAVE_REQUEST') {
        result = <span className="badge badge-gray">탈퇴 요청</span>;
    } else if (value === 'LEAVE') {
        result = <span className="badge badge-dark">탈퇴</span>;
    }else if(value==='APPROVED'){
        result = <span className="badge badge-success">승인</span>;
    }else if(value==='PENDING'){
        result = <span className="badge badge-primary">요청</span>;
    }else if(value==='STOPPED'){
        result = <span className="badge badge-danger">정지</span>;
    }else if(value==='REJECTED'){
        result = <span className="badge badge-warning">거절</span>;
    }else if (value === 'CANCEL_COMPLETE') {
        result = <span className="badge badge-success">주문 취소</span>;
    }else if (value === 'MODIFYING') {
        result = <span className="badge badge-secondary">수정중</span>;
    }
    return result;
}
//상품상태 db값 text로 변경
export const changeOrderStatusValueToText = (value, route) => {
    let result = "";
    if (value === 'READY') {
        result = '결제대기';
    } else if (value === 'PAID') {
        result = '결제완료';
    } else if (value === 'PREPARING') {
        result = '배송준비중';
    } else if (value === 'DELIVERING') {
        result = '배송중';
    } else if (value === 'DELIVERED') {
        result = '배송완료';
    } else if (value === 'CANCEL_COMPLETE') {
        result = '취소완료';
    } else if (value === 'REFUND_COMPLETE') {
        result = '환불완료';
    } else if (value === 'PURCHASE_COMPLETE') {
        result = '구매확정';
    } else if (value === 'SUBSCRIPTION') {
        result = '구독중';
    } else if (value === 'TICKETING') {
        result = '발권완료';
    }

    if (route === 'delivery') {
        if (value === 'READY') {
            result = '발송예정';
        } else if (value === 'IN_PROGRESS') {
            result = '진행중';
        }
    }
    if (route === 'subscription' || route === 'general' || route === 'event') {
        if (value === 'PENDING') {
            result = '승인요청';
        } else if (value === 'APPROVED') {
            result = '판매중';
        } else if (value === 'REJECTED') {
            result = '승인거부';
        } else if (value === 'STOPPED') {
            result = '판매중단';
        } else if (value === 'HIDDEN') {
            result = '숨김';
        } else if (value === 'MODIFYING') {
            result = '수정중';
        }
    }
    return result;
}

//상품상태 color
export const changeProductStatusColor = (value, route) => {
    let result = "";
    // primary secondary success danger warning info dark
    if (value === 'PREPARING' || value === 'DELIVERING' || value === 'IN_PROGRESS' || value === 'REJECTED') {
        result = 'badge-primary';
    } else if (value === 'STOPPED') {
        result = 'badge-danger prduceDeleteBadge ';
    } else if (value === 'PAID' || value === 'DELIVERED' || value === 'CANCEL_COMPLETE' || value === 'REFUND_COMPLETE' || value === 'APPROVED') {
        result = 'badge-success';
    } else if (value === 'PURCHASE_COMPLETE' || value === 'HIDDEN') {
        result = 'badge-danger';
    } else if (value === 'READY' || value === 'PENDING') {
        result = 'badge-warning';
    } else if (value === 'MODIFYING') {
        result = 'badge-info';
    } else if (value === 'SUBSCRIPTION') {
        result = 'badge-dark';
    }
}

//상품타입 db값 text 변경
export const changeProductTypeToText = (type) => {
    let result = "";
    if(type === 'SUBSCRIPTION'){
        result = "구독상품";
    }else if(type === 'TICKET'){
        result = "티켓상품";
    }else if(type === 'GENERAL'){
        result = "일반상품";
    }else if (type === 'PRODUCT') {
        result = '상품옵션';
    }else if (type === 'TIMES') {
        result = '구독횟수';
    }else if (type === 'CYCLE') {
        result = '구독주기';
    }else if (type === 'EVENT_DATE') {
        result = '행사날짜';
    }else if (type === 'TICKET_TYPE') {
        result = '티켓명';
    }
    return result;
}

//날짜배열 문자열 만들기
export function dateArrayToString(array, division) {
    let result = ''
    Array.isArray(array) && array.length > 0 && array.map((v, i) => {
        result += (array.length != i + 1 ? v + division : v);
    })
    return result;
}

// 날짜 월, 일로 변경
export const changeDateFormToText = (date) => {
    const yyyymmdd = date.split(' ')[0];
    const arr = yyyymmdd.split('-');
    return arr[1] + '월 ' + arr[2] + '일'
}

//구독주기 구하기
export function makeSubscribeCycle(data) {
    let result = '';
    if (data == undefined) {
        return result;
    }
    let value = data.value;
    let unit = data.unit;
    if (value == '1' && unit == '주') {
        result = '매주';
    } else if (value == '2' && unit == '주') {
        result = '격주';
    } else if (value == '1' && unit == '월') {
        result = '매월';
    } else if (value == '2' && unit == '월') {
        result = '격월';
    }

    return result
}
//URL 파람 가져오기
export function getParam(sname) {
    var params = window.location.search.substr(window.location.search.indexOf("?") + 1);
    var sval = "";
    params = params.split("&");
    for (var i = 0; i < params.length; i++) {
        let temp = params[i].split("=");
        if ([temp[0]] == sname) { sval = temp[1]; }
    }
    return sval;
}

export function isEmpty(obj) {
    for(var prop in obj) {
      if(obj.hasOwnProperty(prop)) {
        return false;
      }
    }
  
    return JSON.stringify(obj) === JSON.stringify({});
  }
  
// 초를 분으로 변경
export function convertSecondsToMinutes(seconds) {
    if(seconds > 0) {
        let hour = new Date(seconds).getUTCHours();
        let min = new Date(seconds).getUTCMinutes();
        let sec = new Date(seconds).getUTCSeconds();
        if(hour > 0) {
            return addZero(hour) + ':' + addZero(min) + ':' + addZero(sec);
        }
        if(min > 0) {
            return addZero(min) + ':' + addZero(sec);
        }
        if(sec > 0) {
            return '00:' + addZero(sec);
        }
    }
    return "00:00";
}

function addZero(num) {
    if(num < 10) {
        return '0' + num;
    }
    return num;
}
    