// UploadMessage _crud
import axios from "axios";

export const API_ADMIN_SYSTEMMESSAGE = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/systemMessage';
export const API_ADMIN_SYSTEMMESSAGE_BY_IDX = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/systemMessage/';

export const API_ADMIN_SYSTEMMESSAGE_FILEUPLOAD ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/common/onlyFileUploads'

export function getSystemMessageByIdx(systemmessageIdx) {
    // GET : /api/v1/admin/notice/{noticeIdx}
    return axios.get(API_ADMIN_SYSTEMMESSAGE_BY_IDX + systemmessageIdx);
}

export function updateSystemMessage(stemMessageIdx, params) {
    // PUT : /api/v1/admin/notice/{noticeIdx}
    return axios.put(API_ADMIN_SYSTEMMESSAGE_BY_IDX + stemMessageIdx, params);
}

export function createSystemMessage(params) {
    return axios.post(API_ADMIN_SYSTEMMESSAGE, params);
}

export function deleteSystemMessage(systemMessageIdx) {
    return axios.delete(API_ADMIN_SYSTEMMESSAGE_BY_IDX+systemMessageIdx)
}

export function uploadSystemMessageFileupload(params){
    return axios.post(API_ADMIN_SYSTEMMESSAGE_FILEUPLOAD,params,{headers : {'Content-Type' : 'multipart/form-data'}})
}