import React, { useEffect, useState,useMemo } from 'react';
import { Col, Card, Button, Pagination } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Checkbox from '@material-ui/core/Checkbox';
import CustomPagination from '../../../common/components/CustomPagination'

const TabList = ({title, history,page,checkList,oneCheck,categoryIdx,allCheck,questionList,movePage,questionPage,searchParams,handleShow_delete}) => {

    
 const handleClick = (idx, e) => {
     if(e.target.type === 'checkbox') return false;
     const params = {   
         langCode : searchParams[1],          
         searchType : searchParams[3],   
         sortColumn : searchParams[0],
         search:searchParams[4],
         categoryIdx : categoryIdx
     }
     const url = {
       pathname : `/questionManagement_upload/${page}/${idx}/${searchParams[1]}`,
       state : params,
     }
     history.push(url);
   }
   const uploadhandleClick = () => {
       
     const params = {      
         categoryIdx : categoryIdx,
         langCode : searchParams[1],          
     }
     const url = {
       pathname : `/questionManagement_upload/1/0/${searchParams[1]}`,
       state : params,
     }
     history.push(url);
   }
   
     return(
         <Card className="tabBodyCard">
             <Card.Header className="d-flex justify-content-between align-items-center">
                 <h4 className="cardTitleBig">{title} &nbsp;&nbsp;<span style={{fontSize : '14px', fontWeight: 400}}>[ 전체 : {questionPage.totalElements} ]</span></h4>
                 <Button onClick={uploadhandleClick} variant="primary">질문 업로드</Button>
             </Card.Header>
             <Card.Body className="d-flex no-gutters p-0">
                 <Table className="commonTable borderTable bb-1">
                         <Thead>
                             <Tr>
                                 <Th style={{width:50}}>
                                 <Checkbox checked = {
                                     checkList.length === questionPage.numberOfElements && questionPage.numberOfElements>0
                                         ? true
                                         : false
                                     }
                                     onChange={(e) =>allCheck(e.target.checked)}/>
                                 </Th>
                                 <Th style={{width:80}}>번호</Th>
                                 <Th>질문</Th>
                                 <Th style={{width:80}}>답변수</Th>
                                 <Th style={{width:80}}>총점</Th>
                                 <Th style={{width:160}}>등록(수정)일</Th>
                             </Tr>
                         </Thead>
                         <Tbody>
                                 {questionList.length > 0 
 
                                 ? 
                                 questionList.map((v,i)=>{
                                     return(
                                         <Tr onClick={(e)=>handleClick(v.questionIdx, e)}>
                                         <Td>
                                             <Checkbox 
                                             checked={
                                                 checkList.includes(v.questionIdx) ? true : false
                                             }
                                             onChange={(e)=>oneCheck(e.target.checked,v.questionIdx)} />
                                         </Td>
                                         <Td>{v.questionIdx}</Td>
                                         <Td>{v.title}</Td>
                                         <Td>{v.answerCount}</Td>
                                         <Td>총점</Td>
                                         <Td>{v.questionCreateAt}</Td>
                                         </Tr>
                                     )
                                     
                                 })
                                
                                 :
                                 <Tr >
                                     <Td colSpan="6">관련 게시물이 없습니다</Td>
                                 </Tr>
                                 }
                         </Tbody>
                     </Table>
             </Card.Body>
 
             <Card.Footer className="d-flex flex-row justify-content-between align-items-center paginationFooter">
                 <div>
                     <CustomPagination
                         itemsCount={10}
                         pageData={questionPage}
                         page={page}
                         selectctgidx={categoryIdx}
                         onClickEvent={movePage}
                     >
                     </CustomPagination>
                 </div>
                 <div>
                    <Button variant="outline-danger ml-2" onClick={handleShow_delete}>질문 삭제</Button>
                </div>
             </Card.Footer>
 
         </Card>
     )
 }
 
 export default TabList;