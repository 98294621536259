
import React, { useState } from 'react';
import _ from 'lodash';
import { Pagination } from "react-bootstrap";

const CustomPagination = ({itemsCount, pageData, page, selectctgidx,onClickEvent}) => {
    const pageCount = (itemsCount / pageData ? pageData.totalElements : 0);
    if(pageCount === 1) return null;

    const movePage = (page) => {
        if(!selectctgidx){
        onClickEvent(page)
        }else{
            onClickEvent(page,selectctgidx)
        }
    }

    return (
        <Pagination>
            {pageData?.first === false &&  <Pagination.Prev onClick={() => movePage((Number(page) - 1))}/>}
            {[...Array(pageData.totalPages)].map((v, i) => {
                if ( i < 5 ) {
                    let _page;
                    if (page == 1) _page = Number(page) + i;
                    else if (page == 2) _page = Number(page) + i - 1;
                    else if (page == 3) _page = Number(page) + i - 2;
                    else if (page == pageData.totalPages -1)  _page = Number(page) + i - 3;
                    else if (page == pageData.totalPages)  _page = Number(page) + i - 4;
                    else _page = Number(page) + i - 2;
                    let active = page == _page;
                    if (pageData.totalElements / itemsCount % 10 != 0 && _page <= pageData.totalElements / itemsCount + 1)
                        return (
                            <Pagination.Item key={_page} value={_page} active={active} onClick={() => movePage(_page)}>
                                {_page}
                            </Pagination.Item>
                        )
                    else if (pageData.totalElements / itemsCount % 10 == 0 && _page <= pageData.totalElements / itemsCount)
                        return (
                            <Pagination.Item key={_page} value={_page} active={active} onClick={() => movePage(_page)}>
                                {_page}
                            </Pagination.Item>
                        )
                }
            })
            }
            {pageData?.last === false && <Pagination.Next onClick={() => movePage((Number(page) + 1))} />}
        </Pagination>
    )
}

export default CustomPagination;


