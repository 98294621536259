import React, { useEffect, useState,useMemo,useRef,createRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col, InputGroup, Card, Badge, Button, Tabs, Tab, Modal} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";


import {getParsonByIdx,getParsonLevel,uploadParsonFileupload,deleteParson,createParson,updateParson} from "./crud";

import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import LanguagesTabs from "../../../common/components/LanguageTabs/LanguagesTabs"
import UploadImage from "../../../common/components/UploadImage";
import CustomDropZoneCom from '../../../common/components/CustomDropZoneCom'
import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import swal from 'sweetalert'
import userTableMock from '../../../_metronic/__mocks__/userTableMock';

import DeleteIcon from "@material-ui/icons/Close";

// import swal from 'sweetalert';
// import * as authAction from "../../../app/store/ducks/auth.duck";
// import { reissueToken, currency } from '../../../common/up_utils';
// import { TOKEN_EXPIRED_CODE } from '../../../common/dickey';
// import Pagination from '../../../common/components/Pagination';

// import { getUsers } from './crud';

// Radio Checked Style
const useStyles = makeStyles(theme => ({
    radio: {
        '&$checked': {
          color: '#343a40'
        }
    },
    checked: {}
}));

const countryList = [
    'USA','KOREA','JAPAN','TAIWAN','INDONESIA','THAILAND'
]


const UploadHonorary = () => {
    //nullCheck 포커스
    const storyListFocus0 =useRef()
    const storyListFocus1 =useRef()
    const storyListFocus2 =useRef()
    const storyListFocus3 =useRef()
    const storyListFocus4 =useRef()
    const storyListFocus5 =useRef()
    
    const stroyListHistory0 = useRef()
    const stroyListHistory1 = useRef()
    const stroyListHistory2 = useRef()
    const stroyListHistory3 = useRef()
    const stroyListHistory4 = useRef()
    const stroyListHistory5 = useRef()

    const picPathFocus = useRef()
    const countryFocus = useRef()
    const nameFocus = useRef()
    const nameEngFocus = useRef()
    const numFocus = useRef();
    const historyFocus = [stroyListHistory0,stroyListHistory1,stroyListHistory2,stroyListHistory3,stroyListHistory4,stroyListHistory5]
    const interviewFocus = [storyListFocus0,storyListFocus1,storyListFocus2,storyListFocus3,storyListFocus4,storyListFocus5]
    // 레가시 트리플 더블 만 추가 내용 있음
    const classes = useStyles();
    const history = useHistory();
    const { page, idx } = useParams();
    const dispatch = useDispatch();
    // Radio_Category
    // 추가 내용 있는 등급

    const Level =['LEGACY','TRIPLE','DOUBLE','PRESIDENTIAL']

    // 내용이 채워졌을 때 업로드 활성화

    // 모달 (업로드)
    const [uploadShow, setShow_upload] = useState(false);
    const handleClose_upload = () => setShow_upload(false);
    const handleShow_upload = () => setShow_upload(true);
    //삭제
    const [deleteShow, setShow_delete] = useState(false);
    const handleClose_delete = () => setShow_delete(false);
    const handleShow_delete = () => setShow_delete(true);
    //thumPath 파일 삭제 추가
    let addInitialArray = [];
    let delInitialArray = [];
    let [addImgs, setAddImgs] = useState(addInitialArray);
    let [removeImgs, setRemoveImgs] = useState(delInitialArray);

    const deleteOption = (file) => {
        setRemoveImgs(prev => [...prev, file]);
    }
    const updateOption = (file) => {
        setAddImgs(prev => [...prev, file]);        
    }
    const [type, setType] = useState('LEGACY'); //카테고리 등급
    const [parsonIdx,setParsonIdx] = useState(''); //parsonIdx
    const [name,setName] = useState('');//성명
    const [country,setCountry] =useState('USA');//국가
    //const [type,setType] =useState('LEGACY'); // 등급
    const [picPath,setPicPath] = useState([]); //프로필 사진
    const [picPathImg,setPicPathImg] = useState([]); //input 프로필 사진
    const [picPathFileName,setPicPathFileName] = useState(''); //프로필 파일 이름
    const [nameEng,setNameEng] = useState(''); // 영문명
    const [thumPath,setThumPath] = useState([]); // thumPath
    const [num, setNum] = useState(0);
    const [storyIdx,setStoryIdx]= useState([]); 
    const [storyLangCode,setStoryLangCode]= useState(['en']); // 선택한 언어 리스트
    //const [storyhistory,setstoryhistory]= useState(['']);
    //const [storyinterview,setstoryinterview] =useState([''])
    const [langIdx,setLangIdx] = useState();
    //storyList
    const [storyList,setStoryList] = useState([{
       langCode : 'en',
       history : '',
       interview : ''
   }]);
  //const [langIdx,setLangIdx] = useState()
  //등급 리스트
   const [levelList,setLevelList] = useState([{
    levelType: [],
    description :[]
   }]);
   //기본 정보 onChange 
   const onChangeName = (e)=>{setName(e.target.value)}
   const onChangeCountry = (e)=>{setCountry(e)}
   const onchangeNameEng = (e)=>{setNameEng(e.target.value)}
   const onChangeType = (e) =>{setType(e.target.value);}
   const onChangeNum = (e) =>{setNum(e.target.value)}
    const onChangePicPathinputimg =(e)=>{setPicPathImg(e.target.files); setPicPathFileName(e.target.files[0].name); };
   //story onChange 
    const onChangeStoryLanguage = (e,i) => {        
        storyList[i].langCode = e;
        setLangIdx(e)
        storyLangCode.splice(i,1,e); //console.log('asd',storyLangCode)        
    } 
   const onChangestoryHistory = (e,i) => {storyList[i].history=e.target.value;}
   const onChangestoryInterview = (e,i) => {storyList[i].interview=e.target.value;}
   

   //파일 pic 업로드 
    const personPicFileUpload =()=>{
        let picPath = '';
        var formData = new FormData;
        if(picPathImg.length>0){
            // console.log('파일',picPathImg[0])
            formData.append('files',picPathImg[0]);
            
            formData.append('path','honoraryPicFile');
            const rs = uploadParsonFileupload(formData);
            rs.then(({data})=>{
                let dataArray =new Array();
                
                for(let key in data){ 
                    let realName = data[key].realName;
                    let filePath = data[key].filePath;
                    let pushData = filePath + realName;
                    dataArray.push(pushData);
                }
                let picPath = dataArray.join();
                thumPathImpFillupload(picPath);
            })
        }else{
            thumPathImpFillupload(picPath);
        }
        }
    //프로필 사진 삭제
    const picImgDelete= ()=>{
        setPicPath([]);
        setPicPathFileName('');
    }
    //프로필 사진 이름
    const getPicPathFileName =(e)=>{
        if(e){
        let fileName = e;
        let filenameArr = fileName.split('/');
        setPicPathFileName(filenameArr[2]);
        }
    }
    // const LangcodeTabs = ()=>{
    //     return storyLangCode
    //     //setStoryLangCode([]);
    //     //setStoryLangCode(storyList.langCode)
    // }
   //썸네일 업로드
    const thumPathImpFillupload = (picPath) =>{
        let inputImg = addImgs.filter(item => removeImgs.filter(i => item.upload.uuid == i.upload.uuid).length == 0);
        
        var formData = new FormData;
        // //console.log(inputImg.length)
        if(inputImg.length > 0) {
            for(let i = 0; i < inputImg.length; i++) {
                formData.append('files', inputImg[i]);   
            }
        formData.append('path', 'honoraryThumFile')
        const rs = uploadParsonFileupload(formData);
        rs.then(({data}) => {
            let dataArray = new Array();
            for(let key in data) {
                let realName = data[key].realName;
                let filePath = data[key].filePath;
                let pushData = filePath + realName;
                dataArray.push(pushData)
            }
            let paths = dataArray.join();
            onSubmit(paths,picPath);
        });
        
        }else{
        let paths = '';
        onSubmit(paths,picPath);
        }
        }
        //미리보기
        const galleryDel = (path) => {
            let arr = thumPath.split(',').filter(v => { return  v !== path });
            setThumPath(arr.join(','))
    }
    //storyList null 체크
    const nullCheck=()=>{
        handleClose_upload();
        
        if(!country) return swal("알림", "국가명을 입력하지 않았습니다.").then(() => countryFocus.current.focus());
        if(!name) return swal("알림", "성명을 입력하지 않았습니다.").then(() => nameFocus.current.focus());
        if(!nameEng) return swal("알림", "영문명을 입력하지 않았습니다.").then(() => nameEngFocus.current.focus());
        if(!picPathFileName) return swal("알림", "프로필 사진은 필수 입니다.").then(() => picPathFocus.current.focus());
        // if(!num) return swal("알림", "순서를 입력하지 않았습니다.").then(() => numFocus.current.focus());
        
        if(Level.includes(type)){
            for(let i =0; i<storyLangCode.length; i++ ){
                //if(!storyList[i].langCode||!storyList[i].history||!storyList[i].interview){
                if(!storyList[i].langCode){
                    // console.log(storyList[i]);
                return swal({
                        title: "알림",
                        text: '선택하지 않은 언어 작성하지 않은 내용이 있습니다.'
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            //handleClose_upload();
                            if(!storyList[i].interview){
                                interviewFocus[i].current.focus();
                            }else if(!storyList[i].history||!storyList[i].langCode){
                                historyFocus[i].current.focus();
                            }
                        }
                    })
                }else return personPicFileUpload();
            }
        }else if(Level.includes(type)==false){
            personPicFileUpload();
        }
    }

   //등급 가져오기
    const getLevel =()=>{
       const rs = getParsonLevel();
       rs.then(({data})=>{
           //console.log('data',data)
           setLevelList(data);
       }).catch(({response : data})=>{
        if(data.status == 401 && data.data && data?.data?.code == 1101){
            const auth = JSON.parse(localStorage.getItem('persist:auth'));
            const rsRf= refresh(auth.refreshToken);
            
            rsRf.then(({ data : {success, message, code, data} }) => {
                if(data != null) {
                    let accessToken = data.accessToken;
                    let refreshToken = data.refreshToken;
                    auth.authToken = accessToken;
                    auth.refreshToken = refreshToken;
            
                    localStorage.setItem('persist:auth', JSON.stringify(auth));
    
                    dispatch(authAction.actions.login(accessToken, refreshToken));
                    getLevel();
                } else {
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                }
            })
            .catch(({response : data})=>{
              if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
              }
            });
        }
    });
    }

    //storyList 에 삽입
    // const inputStoryList = () =>{
    //     let listStory = [];
    //     personPicFileUpload(listStory);
    // }

   //StoryList 추가
    const addStory = () =>{
        const e = ['en','ko','ja','zh','id','th'];
        var item = {
            langCode : 'en',
            history : '',
            interview : ''
        }
        let tmp = e.filter((item)=>{return storyLangCode.filter(itm => item === itm).length === 0});
        // console.log(tmp)
        if(tmp.length > 0){
            setStoryLangCode([...storyLangCode, tmp[0]])
            item.langCode = tmp[0];
        }else{
            setStoryLangCode([...storyLangCode,''])
        }
        setStoryList([...storyList, item ]);
    }
   //StoryList 삭제
    const deleteStory = (e,i)=>{
        let tmpArr3 = [...storyList];
        let tmpArr4 = [...storyLangCode];
        tmpArr3.splice(i,1,'')
        tmpArr4.splice(i,1,'')
        tmpArr3.splice(i,1)
        tmpArr4.splice(i,1)
        setStoryList(tmpArr3);
        setStoryLangCode(tmpArr4);

    }
   //디테일
    const getDetail = () => {
        const rs = getParsonByIdx(idx);
        rs.then(({ data : {success, message, code, data} }) => {
            if(data != null) {
                setName(data.name);
                //console.log('idx',data.idx)
                setParsonIdx(data.idx);
                setCountry(data.country);
                setType(data.type);
                setPicPath(data.picPath);
                setNameEng(data.nameEng);
                setThumPath(data.thumPath);
                // setType(data.type);
                setStoryList(data.storyList);
                getPicPathFileName(data.picPath)
                setNum(data.level);
                // console.log('data.picPath',data.picPath);
                let langObj=[]
                for(let i=0;i<data.storyList.length; i++){
                    langObj.push(data.storyList[i].langCode)
                }
                // console.log('langObj',langObj)
                setStoryLangCode(langObj)

            } else {
                // swal('잘못 된 접근입니다.\r\n다시 해주세요!').then(()=>{history.push(`/noticeManagement/${page}`)});
            }
        })
        .catch(({response : data})=>{
            // //console.log('catch :: ', data)
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        getDetail();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                }
                });
            }
        });
}

    //추가 및 수정
    const onSubmit = (paths,picPaths) => {
        let levelCk = true 
        if(Level.includes(type)==false){
            levelCk=false
        }
        let person = {
        country : country,
        name : name,
        nameEng :nameEng,
        thumPath : levelCk?  paths != '' ? paths : thumPath.length ?thumPath : '' :'',
        picPath : picPaths != '' ? picPaths : picPath ,
        type : type,
        level : num !== '' ? num : 0,
        storyList : levelCk ? storyList : [{}]
        } 
        // //console.log(systemMessageIdx)
        if(parsonIdx != '') {
            const rs = updateParson(parsonIdx ,person);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== null) {
                    swal({
                        title: "알림",
                        text: "정상적으로 수정 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push(`/honoraryManagement/${page}`);
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                // //console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit(paths != '' ? paths : thumPath,picPath);
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                    if(data.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                    });
                }
            });
        } else {
            const rs = createParson(person);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== null) {
                    swal({
                        title: "알림",
                        text: "정상적으로 등록 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push('/honoraryManagement/1');
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                // //console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit(thumPath,picPath);
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                    if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                    });
                }
            });
        }
    }

    // 삭제
    const deleteonParson = ()=>{
        handleClose_delete(false);
        const rs = deleteParson(parsonIdx);
        rs.then(({data : {success,message,code,data} })=>{
            if(success=true && message ==='success') {
                swal({
                    title :"알림",
                    text : "정상적으로 삭제 되었습니다."
                }).then((isConfirm)=>{
                    if(isConfirm){
                        history.push(`/honoraryManagement/${page}`);
                    }
                })
            }else{
                swal({
                    title: "알림",
                    text : message
                }).then((isConfirm)=>{
                    if(isConfirm){
                        return false;
                    }
                })
            }
        }).catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        deleteonParson();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                }
                });
            }
        });
    }

const onClickImageUpload = (e)=>{
    window.document.getElementById('picfile').click();
}

useMemo(()=>{
    getDetail()
    getLevel()
},[])
// useEffect(()=>{
//     console.log('storyLangCode',storyLangCode);
// },[storyLangCode])

    return (
        <>
            <Container fluid className="UploadHonoraryContainer">

                {/* 버튼영역 */}
                <Row className="mb-4 no-gutters">
                    <Col className="d-flex justify-content-between align-items-center">
                        <Button variant="outline-primary" onClick={()=>history.push(`/honoraryManagement/${page}`)}>목록으로</Button>
                        {/* 정보입력시 활성화 ↑ */}
                        <div>
                        {parsonIdx !=='' &&
                        <Button variant="outline-danger mr-2" onClick={handleShow_delete}>삭제</Button>
                        }
                        <Button variant="primary" onClick={handleShow_upload}>{parsonIdx !== '' ? '수정' : '업로드'}</Button>
                        </div>
                    </Col>
                </Row>

                {/* 모달 (업로드) */}
                <Modal show={uploadShow} onHide={handleClose_upload} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>{parsonIdx !== '' ? '수정' : '업로드'} 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_upload}>취소</Button>
                        <Button variant="primary" onClick={nullCheck}>확인</Button>
                    </Modal.Footer>
                </Modal>
                {/*모달 삭제 */}
                <Modal show={deleteShow} onHide={handleClose_delete} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_delete}>취소</Button>
                        <Button variant="primary" onClick={deleteonParson}>확인</Button>
                    </Modal.Footer>
                </Modal>

                {/* 정보 입력영역 */}
                <Row className="mb-4">
                    <Col>
                        <Card>
                            <Card.Header  className="cardTitle text-primary">기본정보</Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable">
                                    <Tbody>
                                       
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0"  style={{width:100}}>등급 선택</Td>
                                            <Td >
                                                <FormControl component="fieldset" style={{'display':'flex'}}>
                                                    <RadioGroup
                                                        aria-label="Category"
                                                        name="category"
                                                        value={type}
                                                        onChange={onChangeType}
                                                        row
                                                    >
                                                    {levelList.map((v,i)=>{
                                                        return(
                                                        <FormControlLabel
                                                        control={
                                                            <Radio 
                                                                classes={{
                                                                    root: classes.radio,
                                                                    checked: classes.checked
                                                                }}
                                                            />
                                                        }
                                                        value={v.levelType}
                                                        label={v.description}
                                                    />
                                                        )
                                                    })}
                                            
                                                     </RadioGroup>
                                                </FormControl>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>국 가 명</Td>
                                            <Td  className="countryListRadio">
                                                <Tabs activeKey={country} transition={false}  className="languageTabs mb-0" onSelect={(e)=>onChangeCountry(e)}>
                                                    {
                                                        countryList.map((v,i)=>{
                                                            return (
                                                                <Tab  disabled={country == v} eventKey={v} title={v} key={'tab_data_'+i}></Tab>         
                                                            )
                                                        })
                                                    }
                                                   
                                                </Tabs>                                              
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0"  style={{width:100}}>프 로 필</Td>
                                            <Td>
                                                <div className="d-flex justify-content-between align-items-center">
                                                <form encType="multipart/form-data">
                                                <input type='file' key='image'accept="image/*" className='inputFiles' id='picfile'  hidden onChange={(e)=>onChangePicPathinputimg(e) } ></input>
                                                </form>
                                                    <Button variant="outline-primary" className="col-2 mr-2" onClick={(e)=>onClickImageUpload(e)}>파일 선택</Button>
                                                    {picPathFileName&&
                                                    <Button variant="outline-primary" className="col-2 mr-2" onClick={()=>picImgDelete('')}>파일 삭제</Button>
                                                    }
                                                    <Form.Control type="text" ref={picPathFocus&&picPathFocus} placeholder={picPathFileName ?picPathFileName : "파일을 선택해주세요."} disabled />
                                                
                                                </div>
                                                <Row className="no-gutters"  style={{marginTop:'10px'}}>
                                                    <Col><span style={{color : 'red'}}>* 250 X 300 크기의 파일로 등록해주세요</span></Col>
                                                </Row>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td colspan="2">
                                                <Row className="no-gutters">
                                                    <Col className="d-flex justify-content-between align-items-center mr-3 no-gutters">
                                                        <label className="font-weight-bold text-dark text-left" style={{width:100}}>성 명</label>
                                                        <Form.Control type="text" placeholder="성명을 입력하세요." ref={nameFocus&&nameFocus}  value={name} onChange={(e)=>onChangeName(e)} />
                                                    </Col>
                                                    <Col className="d-flex justify-content-between align-items-center ml-3 no-gutters">
                                                        <label className="font-weight-bold text-dark text-left" style={{width:100}}>영 문 명</label>
                                                        <Form.Control type="text" style={{imeMode :'disabled'}} ref={nameEngFocus&&nameEngFocus}placeholder="영문명을 입력하세요." value={nameEng} onChange={(e)=>onchangeNameEng(e)}/>
                                                    </Col>
                                                </Row>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td colspan="2">
                                                <Row className="no-gutters">
                                                    <Col className="d-flex justify-content-between align-items-center mr-3 no-gutters">
                                                        <label className="font-weight-bold text-dark text-left" style={{width:100}}>순서</label>
                                                        <Form.Control type="number" placeholder="순서를 입력하세요." ref={numFocus&&numFocus}  value={num} onChange={(e)=>onChangeNum(e)} />
                                                        {/* <input className="form-control" style={{width : '30px'}} type="number" placeholder="순서를 입력해 주세요" onChange={(e)=>onChangeNum(e)} defaultValue={num} ref={numFocus}/> */}
                                                    </Col>
                                                </Row>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            {Level.includes(type) &&
                <Row className="mb-4">
                    <Col>
                        <Card>
                            {/* <Card.Header className="cardTitle text-primary">추가정보(프레지덴셜 등급 이상)</Card.Header> */}
                            <Card.Header className="cardTitle text-primary">추가정보(모든언어)</Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable">
                                    <Tbody>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>사진등록</Td>
                                            <Td>
                                            <CustomDropZoneCom 
                                                    onUpdate={updateOption}
                                                    onDelete={deleteOption}
                                                    acceptedFiles={'image/*'}
                                                    iconFiletypes={['.jpg', '.png', '.gif', '.jpeg']}
                                                    maxFiles={3}
                                                    fileLimit={3}
                                                    title={'SIZE 375 X 375'}
                                                    description={'사진파일을 끌어서 여기에 놓거나<br />여기를 클릭하여 사진파일을 업로드합니다'}
                                                />
                                            </Td>
                                        </Tr>
                                        {thumPath != '' && thumPath.split(',').length > 0 &&
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100, fintSize: '13px'}}>기존이미지</Td>
                                            <Td>
                                                <div class="modifyCustomDropzone modifyImgContainer">
                                                <div class="gallery-grid">
                                                    {thumPath.split(',').map((v, i) => {
                                                        return(
                                                            <div class="gallery-frame">
                                                                {/* <img class="gallery-img" alt={v} src={'/fileUpload/' + v} />   */}
                                                                <img class="gallery-img" alt={v} src={'/fileUpload/' + v} />  
                                                                <button onClick={() => galleryDel(v)}><DeleteIcon /></button>
                                                            </div>  
                                                        )
                                                    })}
                                                    </div>
                                                </div>
                                            </Td>
                                        </Tr>
                                        }
                                        </Tbody>
                                </Table>
                                </Card.Body>
                        </Card>
                    </Col>
                </Row>
    }

{Level.includes(type) &&
<>
    {Array.isArray(storyList) && storyList.map((v,i)=>{    
    return(
        <>
                <Row className="mb-4">
                    <Col>
                        <Card>
                            <Card.Body className="p-0">
                                <Table className="commonTable">
                                    <Tbody>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>언어선택</Td>
                                            <Td>
                                  
                                            <LanguagesTabs
                                                i={i}
                                                onLanSelected={(e,i)=>{onChangeStoryLanguage(e,i)}}
                                                langCode={storyList[i].langCode}
                                                idx={parsonIdx}
                                                selectLangCode={storyLangCode}
                                            />
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>연혁내용</Td>
                                            <Td>
                                                <Form.Control as="textarea" rows="8" ref={historyFocus[i]&&historyFocus[i]}placeholder="해당 인물의 연혁을 입력하세요."  defaultValue={v.history} onChange={(e)=>onChangestoryHistory(e,i)} />
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>인터뷰</Td>
                                            <Td>
                                                <Form.Control as="textarea" ref={interviewFocus[i]&&interviewFocus[i]} rows="8" placeholder="해당 인물의 인터뷰 내용을 입력하세요."  defaultValue={v.interview} onChange={(e)=>onChangestoryInterview(e,i)}/>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Card.Body>
                            <Card.Footer>
                                {storyList.length >1 &&
                            <Button variant="primary" onClick={(e)=>deleteStory(e,i)}>삭제</Button>
                                }
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
                </>
            )
    })}
             {storyList.length !== 6 && 
                <div>
                    <Button variant="primary" onClick={()=>{addStory()}}>언어별 내용 추가</Button>
                </div>
            }  
            </>
}
            </Container >
        </>
    );
};

export default UploadHonorary;
