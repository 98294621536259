// MainScreenManagement _crud
import axios from 'axios';
export const SYSTEMMESSAGEGETLIST ="/api/" + process.env.REACT_APP_API_VERSION + "/admin/systemMessages";
export const SYSTEMMESSAGEGETDETAIL ="/api/" + process.env.REACT_APP_API_VERSION + "/admin/systemMessages/"
export const CATEGORY_NUM_CHANGE = "/api/"+ process.env.REACT_APP_API_VERSION + "/admin/category/"

export const MAINCARTEGORT = "/api/" + process.env.REACT_APP_API_VERSION + "/admin/category"

export const MAIN_TOP_SCREEN = "/api/"+ process.env.REACT_APP_API_VERSION + "/admin/mainTop"
export const MAIN_TOP_SCREEN_List = "/api/"+ process.env.REACT_APP_API_VERSION + "/admin/MainTop"

export const MAIN_TOP_SCREEN_BY_IDX = "/api/"+ process.env.REACT_APP_API_VERSION + "/admin/mainTop/"
export const MAIN_TOP_SCREEN_OPENFLAG = "/api/" + process.env.REACT_APP_API_VERSION + "/admin/updateMainTopOpenFlag/"

export const MAIN_TOP_SCREEN_FILE ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/common/onlyFileUploads'



export function getSystemMessageList(params){
    return axios.get(SYSTEMMESSAGEGETLIST,{params});
}
export function deleteSystemMessage(checkList){
    return axios.delete(SYSTEMMESSAGEGETDETAIL,{data :{idxs : checkList}})
}

export function getCategoryList(params){
    return axios.get(MAINCARTEGORT,{params});
}

export function updateCategoryNum(idx,num){
    return axios.put(CATEGORY_NUM_CHANGE+idx+'/'+num);
}




export function getMainTopScreenList(params){
    return axios.get(MAIN_TOP_SCREEN_List,{params})
}

export function deleteMainTopScreenList(checkList){
    return axios.delete(MAIN_TOP_SCREEN,{data :{idxs : checkList}})

}

export function getMainTopScreen(idx){
    return axios.get(MAIN_TOP_SCREEN_BY_IDX+idx)
}
export function deleteMainTopScreen(mainTopScreenIdx) {
    return axios.delete(MAIN_TOP_SCREEN_BY_IDX+mainTopScreenIdx)
}
export function createMainTopScreen(params) {
    return axios.post(MAIN_TOP_SCREEN, params);
}
export function updateMainTopScreenOpenflag(mainTopScreenIdx,openFlag ) {
    return axios.put(MAIN_TOP_SCREEN_OPENFLAG+mainTopScreenIdx+'/?openFlag='+openFlag);
}
export function updateMainTopScreen(mainTopScreenIdx, params) {
    // PUT : /api/v1/admin/notice/{noticeIdx}
    return axios.put(MAIN_TOP_SCREEN_BY_IDX + mainTopScreenIdx, params);
}
export function uploadMainTopScreenFileupload(params){
    return axios.post(MAIN_TOP_SCREEN_FILE,params,{headers : {'Content-Type' : 'multipart/form-data'}})
}
//export function 