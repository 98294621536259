import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Container, Col, Card, Button, Modal } from "react-bootstrap";
import CustomPagination from '../../../common/components/CustomPagination'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import SearchCard from "../../../common/components/SearchCard"
import { getNotices, deleteNotices } from "./crud";
import swal from 'sweetalert'
import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import {makeNotEmptyObj} from '../../../../src/_metronic/utils/utils'
import CustomSearchCardv2 from "../../../common/components/CustomSearchCardv2"

const NoticeManagement = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { page } = useParams();
    //삭제 모달
    const [show_delete, setShow_delete] = useState(false);
    const handleShow_delete = () => {if(checkList.length<1){swal('알림','선택하신 공지사항이 없습니다.').then((isConfirm)=>{if(isConfirm)return false});}else{setShow_delete(true)}};
    const handleClose_delete = () => setShow_delete(false);

    /*페이징 관련 변수 */
    const [noticePage , setNoticePage] = useState([]);  //시작 페이지, 마지막 페이지    
    //공지사항 목록을 넣을 변수
    const [noticeList , setNoticeList] = useState([{
        idx: [],
        title : [],
        langCode : [],
        content : [],
        author : [],
        viewCnt : [],
        createAt : []
    }]);
    //체크박스
    const [checkList , setCheckList] =useState([]);
    //검색 관련 변수
    const [langCode ,setLangCode] =useState((history.location.state && history.location.state.langCode) || '');
    const [sortColumn,setSortColumn] = useState((history.location.state && history.location.state.sortColumn) || 'createAt');
    const sortColumnItems = [
        {
            text : '등록일순',
            value : 'createAt',
        },
    ];
    const [sortType, setSortType] = useState((history.location.state && history.location.state.sortType) || 'DESC')
    const sortTypeItems = [
        {
            text : '오름차순',
            value : 'ASC',
        },
        {
            text : '내림차순',
            value : 'DESC',
        },
    ];
    const [searchType, setSearchType] = useState((history.location.state && history.location.state.searchType) || 'titleOrContent');
    const [search, setSearch] = useState((history.location.state && history.location.state.search) || '');
    const searchItems = [
        {
            text : '제목+내용',
            value : 'titleOrContent',
        },
    ];
    //검색 onchange event
    const onChangeSortColumn = (e)=> setSortColumn(e.target.value);
    const onChangeSortType = (e) => setSortType(e.target.value);
    const onChangeSearchType = (e) => setSearchType(e.target.value);
    const onChangeSearch = (e) => setSearch(e.target.value);
    const onSelectLanguage = (e) =>{
        setLangCode(e.target.value);
    }
    //공지사항 삭제
    const noticeDelete = () =>{
        setShow_delete(false);
        const rs = deleteNotices(checkList);
        rs.then(({ data : {success, message, code, data} }) => {
            if(success === true && message === 'success') {
                swal({
                    title: "알림",
                    text: "정상적으로 삭제 되었습니다."
                }).then((isConfirm) => {
                    if(isConfirm) {
                        if(noticePage.numberOfElements === checkList.length&& page>1){
                           // history.push(`/noticeManagement/${page-1}`);
                           movePage(page-1);
                        }else{
                            movePage(page);
                        }
                        
                    }
                })
            } else {
                swal({
                    title: "알림",
                    text: message
                }).then((isConfirm) => {
                    if(isConfirm) {
                        return false;
                    }
                })
            }
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        noticeDelete();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    //호출한 공지사항 목록 리스트에 넣기
    const getNoticeList = (page)=>{
        let params = {
            page : page,
            size : 25,
        }   
        const chkParams = {
            langCode : langCode,      
            sortColumn : sortColumn,
            sortType : sortType,
        }
        if(searchType == 'titleOrContent') { chkParams.titleOrContent = search; }
        params = makeNotEmptyObj(params, chkParams);
        const rs = getNotices(params);
        rs.then(({data :{content,totalElements,totalPages,last,first,numberOfElements}}) =>{
            setNoticeList(content);
            const pageObj ={
                totalElements : totalElements,
                totalPages : totalPages,
                last : last,
                first : first,
                numberOfElements :numberOfElements
            }
            setNoticePage(pageObj);
        })
        .catch(({response : data})=>{
            if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;

                        localStorage.setItem('persist:auth', JSON.stringify(auth));

                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        movePage(page);
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                }).catch(({response : data})=>{
                    if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                });
            }
        });;
    }

    //체크 박스 전체 선택 유무
    const allCheck = (checked) =>{
        if(checked){
            const ckbox = [];
            noticeList.map((v,i)=>{
            ckbox.push(v.idx);
            setCheckList(ckbox);
            })
        }else if(!checked){
            setCheckList([]);
        }
    }

    // 체크 박스 단일 선택
    const oneCheck = (checked,ckidx) =>{
        if(checked){
            setCheckList([...checkList, ckidx]);
        }else if(!checked){
            setCheckList(checkList.filter(idx => idx !== ckidx));
        }
    }
    
    const movePage = (page) => {   
        const params = {      
             langCode ,
            searchType,
            search,
            sortColumn,
            sortType,
        }
        
        history.push({
            pathname : "/noticeManagement/"+page,
            state : params
        });
        setCheckList([]);
        getNoticeList(page);
    }

    /* 디테일 페이지로 이동 */
  const handleClick = (idx) => {
    const params = {      
        langCode ,
        searchType,
        search,
        sortColumn,
        sortType,
    }
    
    const url = {
      pathname : `/noticeManagement_upload/${page}/${idx}`,
      state : params,
    }
    history.push(url);
  }
   //검색
    const onSearch = (e) => {
        e.preventDefault();
        movePage(1);

    }
    
    useMemo(() => {
        movePage(page);        
    },[])


    return (
        <>
            <Container fluid className="noticeContainer">
                
                {/* 검색영역 */}
                {/* <SearchCard 
                onChangesortColumn ={onChangesortColumn}
                onSelectLanguage = {onSelectLanguage}
                onChangeTitleOrContent={onChangeTitleOrContent}
                onSearchEvent={onSearch}
                sortValue={sortColumn}
                langValue={langCode}
                value={titleOrContent}
                /> */}
                     <CustomSearchCardv2 
                        isLanguage = {true}
                        onSelectLanguage={onSelectLanguage}
                        langValue = {langCode}

                        isSortColumn={true}
                        onChangeSortColumn ={onChangeSortColumn}
                        sortColumnItems={sortColumnItems}
                        sortColumn={sortColumn}

                        isSortType={false}
                        onChangeSortType={onChangeSortType}
                        sortTypeItems={sortTypeItems}
                        sortType={sortType}

                        isSearch={true}
                        onChangeSearch={onChangeSearch}
                        onSearchEvent={onSearch}
                        searchItems={searchItems}
                        onChangeSearchType={onChangeSearchType}
                        search={search}   
                        />

                {/* 공지사항 리스트 */}
                <Row>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h4 className="cardTitleBig">공지사항 &nbsp;&nbsp;<span style={{fontSize : '14px', fontWeight: 400}}>[ 전체 : {noticePage.totalElements} ]</span></h4>
                                <Button onClick={() => { history.push('/noticeManagement_upload/1/0') }} variant="primary">공지사항 업로드</Button>
                            </Card.Header>
                            <Card.Body className="d-flex no-gutters p-0">
                                <Table className="commonTable borderTable bb-1 clickable">
                                        <Thead>
                                            <Tr>
                                                <Th style={{width:50}}>
                                                <Checkbox 
                                                checked = {
                                                    checkList.length === noticePage.numberOfElements && noticePage.numberOfElements>0
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) =>allCheck(e.target.checked)}/> 
                                                </Th>
                                                <Th style={{width:80}}>번호</Th>
                                                <Th style={{width:150}}>언어</Th>
                                                <Th>제목</Th>
                                                <Th style={{width:140}}>작성자</Th>
                                                <Th style={{width:100}}>조회수</Th>
                                                <Th style={{width:160}}>등록(수정)일</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {/*
                                            <Tr>
                                                <Td><Checkbox /></Td>
                                                <Td>[번호 DATA]</Td>
                                                <Td className="ellipsis">[언어 DATA]</Td>
                                                <Td className="text-left fw-500 ellipsis">[제목 DATA]</Td>
                                                <Td>[작성자 DATA]</Td>
                                                <Td>[조회수 DATA]</Td>
                                                <Td>[날짜 시간 DATA]</Td>
                                            </Tr>
                                            */}
                                             {
                                             noticeList.length > 0 ? noticeList.map((v,i) =>{
                                                 return (
                                                     <Tr>
                                                         <Td><Checkbox 
                                                            checked={
                                                                checkList.includes(v.idx) ? true : false
                                                            }
                                                         onChange={(e)=>oneCheck(e.target.checked,v.idx)} />
                                                         </Td>
                                                         <Td onClick={() => {handleClick(v.idx)}}>{v.idx}</Td>
                                                         <Td onClick={() => {handleClick(v.idx)}}>{v.langCodeName}</Td>
                                                         <Td style={{textAlign:'left',padding:'1rem'}} onClick={() => {handleClick(v.idx)}}>{v.title}</Td>
                                                         <Td onClick={() => {handleClick(v.idx)}}>{v.author}</Td>
                                                         <Td onClick={() => {handleClick(v.idx)}}>{v.viewCnt}</Td>
                                                         <Td onClick={() => {handleClick(v.idx)}}>{v.createAt}</Td>
                                                     </Tr>
                                                 )
                                                 })
                                                 :
                                                 <Tr>
                                                    <Td className="font-weight-bold" colSpan={7} >데이터가 없습니다.</Td>
                                                </Tr>
                                                 }
                                        </Tbody>
                                    </Table>
                            </Card.Body>
                            <Card.Footer className="d-flex flex-row justify-content-between align-items-center paginationFooter">
                                <div>
                                    
                                    <CustomPagination
                                        itemsCount={10}
                                        pageData={noticePage}
                                        page={page}
                                        onClickEvent={movePage}
                                    >
                                    </CustomPagination>
                                </div>
                                <div>
                                    <Button variant="outline-danger ml-2" onClick={handleShow_delete}>공지사항 삭제</Button>
                                </div>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>

                {/* 모달 (공지사항 삭제) */}
                <Modal show={show_delete} onHide={()=>handleClose_delete('revoke')} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"  onClick={handleClose_delete}>취소</Button>
                        <Button variant="danger" onClick={noticeDelete}>확인</Button>
                    </Modal.Footer>
                </Modal>

            </Container >
        </>
    );
};

export default NoticeManagement;
