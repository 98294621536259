import axios from "axios";
import store from "../../../app/store/store";

export const LOGIN_URL = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/auth/signin';
export const REFRESH_URL = '/api/' + process.env.REACT_APP_API_VERSION +'/admin/auth/accessToken';
export const ME_URL = "/api/" + process.env.REACT_APP_API_VERSION +"/admin/auth/me";

export function login(email, password) {
    return axios.post(LOGIN_URL, { email, password } );
}

// export function login(accessToken, authPlatform) {
//     // return axios.post(LOGIN_URL, { accessToken, authPlatform });
//     return axios.post(LOGIN_URL, {authPlatform}, {headers :{ authorization : `Bearer `+accessToken}});
// }

const instance = axios.create();


instance.interceptors.request.use(
  config => {
    const {
      auth: { refreshToken }
    } = store.getState();

    if (refreshToken) {
      config.headers.Authorization = `Bearer ${refreshToken}`;
    }

    return config;
  },
  err => Promise.reject(err)
);

export function refresh(refreshToken) {
    return instance.post(REFRESH_URL, {  });
  }

  export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    return axios.get(ME_URL);
  }