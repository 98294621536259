
import React, { useState } from 'react';
import styled, {css} from 'styled-components';

const SummaryCard = ({ title, data, color }) => {

    const SummaryCardConatainer = styled.div`
        padding:2rem 1rem;
        .bar{
            display:block;
            width:4px;
            height:60%;
            border-radius:10px;
            position:absolute;
            top:20%;
            left:10px;
            background:${color};
        }
    `;

    return (
        <SummaryCardConatainer className="flex-row justify-content-between align-items-center position-relative card">
            <div>
                <span className="bar"></span>
                <h5 className="font-weight-bold pl-3" style={{color:color}}>{title}</h5>
            </div>
            <div className="d-inline-block w-auto m-0 badge badge-dark px-2 text-wrap font-weight-normal" style={{background:color}}>{data}</div>
        </SummaryCardConatainer>
    )
}

export default SummaryCard;


