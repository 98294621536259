import React, { useEffect, useState,useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col, InputGroup, FormControl, Card, Badge, Button, Tabs, Tab, Pagination, Modal } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import ReactDragList from 'react-drag-list';
import LanguageTabs from "../../../common/components/LanguageTabs";

import { useHistory, useParams } from "react-router-dom";

import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import Checkbox from '@material-ui/core/Checkbox';
import testImg from '../../../assets/img/testimg.jpg';
import CustomPagination from '../../../common/components/CustomPagination';

import {makeNotEmptyObj} from '../../../../src/_metronic/utils/utils'
import swal from 'sweetalert'
import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import CustomSearchCardv2 from "../../../common/components/CustomSearchCardv2"

import { getSystemMessageList,deleteSystemMessage,getCategoryList,updateCategoryNum,getMainTopScreenList,deleteMainTopScreenList } from "./crud";

import SearchCard from "../../../common/components/SearchCard"
import { TableBody } from '@material-ui/core';

// import Pagination from '../../../common/components/Pagination';
// import { getUsers } from './crud';


let List = [];
const MainScreenManagement = () => {
    const history = useHistory();
    const { page,tabacv } = useParams();
    const dispatch = useDispatch();

    // 모달 (메인화면 상단사진 - 사진 업로드)
    const [show_uploadBanner, setShow_uploadBanner] = useState(false);
    const handleClose_uploadBanner = () => setShow_uploadBanner(false);
    const handleShow_uploadBanner = () => setShow_uploadBanner(true);

    // 모달 (메세지 삭제)
    const [show_deleteMsg, setShow_deleteMsg] = useState(false);
    const handleClose_deleteMsg = () => setShow_deleteMsg(false);
    const handleShow_deleteMsg = () => {if(checkList.length<1){return swal('알림','선택하신 메세지가 없습니다.')}else{setShow_deleteMsg(true)}}

    // 모달 (사진 삭제)
    const [show_deleteBanner, setShow_deleteBanner] = useState(false);
    const handleClose_deleteBanner = () => setShow_deleteBanner(false);
    const handleShow_deleteBanner = () => {if(checkList.length<1){return swal('알림','선택하신 상단사진이 없습니다.')}else{setShow_deleteBanner(true)}}
    //검색 변수
    const [langCode ,setLangCode] =useState((history.location.state && history.location.state.langCode) || '');
    //검색 변수
        const [sortColumn,setSortColumn] = useState((history.location.state && history.location.state.sortColumn) || 'createAt');
        const sortColumnItems = [
            {
                text : '등록일순',
                value : 'createAt',
            },
        ];
        const [sortType, setSortType] = useState((history.location.state && history.location.state.sortType) || 'DESC')
        const sortTypeItems = [
            {
                text : '오름차순',
                value : 'ASC',
            },
            {
                text : '내림차순',
                value : 'DESC',
            },
        ];
        const [searchType, setSearchType] = useState((history.location.state && history.location.state.searchType) || 'title');
        const [search, setSearch] = useState((history.location.state && history.location.state.search) || '');
        const searchItems = [
            {
                text : '제목',
                value : 'title',
            },
        ];
    const [tabMenu,setTabMenu] =useState(tabacv)
    //메인상단 사진
    const [mainTopScreenList,setMainTopScreenList] = useState([]);
    const [mainTopScreenPage,setMainTopScreenPage] = useState([]);
    //카테고리
    const [mainCategoryPage,setMainCategoryPage] = useState([]);
    //const [oldmaincartegoryList,setoldmaincartegoryList]=useState([]);
    const [mainCategoryList,setMainCategoryList] =useState([])   
    //페이지 관련 변수
    const [systemMessagePage,setSystemMessagePage] = useState([]);
    //체크박스 리스트
    const [checkList,setCheckList] = useState([]);
    //시스템 메세지 목록
    const [systemMessageList,setSystemMessageList] = useState([{
        idx: [],
        title : [],
        langCodeName : [],
        viewCnt : [],
        createAt : [],
        author : []
    }]);
    //검색 onChange
    const onChangeSortColumn = (e)=> setSortColumn(e.target.value);
    const onChangeSortType = (e) => setSortType(e.target.value);
    const onChangeSearchType = (e) => setSearchType(e.target.value);
    const onChangeSearch = (e) => setSearch(e.target.value);
    const onSelectLanguage = (e) =>{
        setLangCode(e.target.value);
    }

    const onChangetabMenu = (e)=>{
        const oct = true;
        setTabMenu(e);
        movePage(1,e,oct);
    }
    //검색 클릭 이벤트
    const onSearch = (e) => {
        e.preventDefault();
        movePage(1,tabMenu);

    }
    //시스템 메세지 가져오기
    const readSystemMessageList =(page)=>{
        let params = {
            page :page,
            size : 25,
        }
        const chkParams = {
            langCode : langCode,      
            sortColumn : sortColumn,
            sortType : sortType,
        }
        if(searchType == 'title') { chkParams.title = search; }

        params = makeNotEmptyObj(params,chkParams);
        const rs = getSystemMessageList(params);
        rs.then(({data :{content,totalElements,totalPages,last,first,numberOfElements}}) =>{
            setSystemMessageList(content);
            const pageObj ={
                totalElements : totalElements,
                totalPages : totalPages,
                last : last,
                first : first,
                numberOfElements : numberOfElements
            }
            setSystemMessagePage(pageObj);
        }).catch(({response : data})=>{
            if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;

                        localStorage.setItem('persist:auth', JSON.stringify(auth));

                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        movePage(page,tabacv);
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                }).catch(({response : data})=>{
                    if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                });
            }
        });;
    }


    //메인 카테고리 가져오기
    const readMaincategoryList = () =>{
        let params = {
            page :page,
            size : 25,
            sortType : "ASC",
            sortColumn : "num",
        }
        /*const chKParams = {
            langCode : langCode,      
            title : titleOrContent,   
            sortColumn : sortColumn,
        }*/
        //params = makeNotEmptyObj(params,chKParams);
        const rs = getCategoryList(params);
         rs.then(({data :{content,totalElements,totalPages,last,first,numberOfElements}}) =>{
            setMainCategoryList(content);
            List = [...content];
            // console.log(List)
            const pageObj ={
                totalElements : totalElements,
                totalPages : totalPages,
                last : last,
                first : first,
                numberOfElements : numberOfElements
            }
            setMainCategoryPage(pageObj);
        }).catch(({response : data})=>{
            if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;

                        localStorage.setItem('persist:auth', JSON.stringify(auth));

                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        movePage(page,tabacv);
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                }).catch(({response : data})=>{
                    if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                });
            }
        });;
    }
   

    // const LangcodeTabs = ()=>{
    //     return mainCategoryList;
    //  }


    // //카테고리 순서 변경
    // const categoryChange=(oldIdx,newIdx)=>{
    //     setCartegoryoldIdx(oldIdx);
    //     setCartegorynewIdx(newIdx);
    // }
    const categoryChange = (oldIdx,newIdx,val)=>{
        setMainCategoryList([]);    
        for(let i=0; i<val.length; i++){
            val[i].num = i+1;
        }    
        setMainCategoryList(val);
        //  console.log('List',List[newIdx].idx);
        //  console.log('newIdx',newIdx);
        //  console.log('List',List);
        // console.log(List, newIdx);
        let Idx =  List[oldIdx].idx;
        // console.log('Idx :: ', Idx)
        
        const rs = updateCategoryNum(Idx,newIdx + 1);
        rs.then(({ data}) => {
            // console.log(data);
        })

    }
    

    //체크 박스 전체 선택 유무
    
    const allCheck = (checked) =>{

        if(checked){
            const ckbox = [];
            if(tabMenu=='message'){
            systemMessageList.map((v,i)=>{
            ckbox.push(v.idx);
            setCheckList(ckbox);
            })
        }else if(tabMenu=='banner'){
            mainTopScreenList.map((v,i)=>{
                ckbox.push(v.idx);
                setCheckList(ckbox);
                })
        }
        }else if(!checked){
            setCheckList([]);
        }
    }

    // 체크 박스 단일 선택
    const oneCheck = (checked,ckidx) =>{
        if(checked){
            setCheckList([...checkList, ckidx]);
        }else if(!checked){
            setCheckList(checkList.filter(idx => idx !== ckidx));
        }
    }
    //삭제
    const systemMessageDelete = () =>{
        setShow_deleteMsg(false);
        const rs = deleteSystemMessage(checkList);
        rs.then(({ data : {success, message, code, data} }) => {
            if(success === true && message === 'success') {
                swal({
                    title: "알림",
                    text: "정상적으로 삭제 되었습니다."
                }).then((isConfirm) => {
                    if(isConfirm) {
                        if(systemMessagePage.numberOfElements === checkList.length&& page>1){
                            // history.push(`/noticeManagement/${page-1}`);
                            movePage(page-1,tabacv);
                         }else{
                             movePage(page,tabacv);
                         }
                    }
                })
            } else {
                swal({
                    title: "알림",
                    text: message
                }).then((isConfirm) => {
                    if(isConfirm) {
                        return false;
                    }
                })
            }
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        systemMessageDelete();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    
    //메인상단 사진 삭제
    const mainTopScreenDelete = () =>{
        handleClose_deleteBanner();
        const rs = deleteMainTopScreenList(checkList);
        rs.then(({ data : {success, message, code, data} }) => {
            if(success === true && message === 'success') {
                swal({
                    title: "알림",
                    text: "정상적으로 삭제 되었습니다."
                }).then((isConfirm) => {
                    if(isConfirm) {
                        if(systemMessagePage.numberOfElements === checkList.length&& page>1){
                            // history.push(`/noticeManagement/${page-1}`);
                            movePage(page-1,tabacv);
                         }else{
                             movePage(page,tabacv);
                         }
                    }
                })
            } else {
                swal({
                    title: "알림",
                    text: message
                }).then((isConfirm) => {
                    if(isConfirm) {
                        return false;
                    }
                })
            }
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        systemMessageDelete();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    //메인상단 사진
    const readMainTopScreen = (page)=>{
        let params = {
            page :page,
            size : 25,
        }
        const chkParams = {
            langCode : langCode,      
            sortColumn : sortColumn,
            sortType : sortType,
        }
        if(searchType == 'title') { chkParams.title = search; }
        params = makeNotEmptyObj(params,chkParams);
        const rs = getMainTopScreenList(params);
         rs.then(({data :{content,totalElements,totalPages,last,first,numberOfElements}}) =>{
            setMainTopScreenList(content);
            const pageObj ={
                totalElements : totalElements,
                totalPages : totalPages,
                last : last,
                first : first,
                numberOfElements : numberOfElements
            }
            setMainTopScreenPage(pageObj);
        }).catch(({response : data})=>{
            if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;

                        localStorage.setItem('persist:auth', JSON.stringify(auth));

                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        movePage(page,tabacv);
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                }).catch(({response : data})=>{
                    if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                });
            }
        });;
    }


    //디테일 페이지로 이동 
    const handleClick = (idx) => {
        // console.log('idx',idx);
        const params = {      
            langCode ,
            searchType,
            search,
            sortColumn,
            sortType,
            }
        const url = {
          pathname : `/mainScreenManagement_uploadMessage/${page}/${idx}`,
          state : params,
        }
        history.push(url);
      }

    const topHandleClick = (idx) =>{
        const params = {      
            langCode ,
            searchType,
            search,
            sortColumn,
            sortType,
            }
        const url = {
            pathname : `/mainScreenManagement_UploadmainTopScreen/${page}/${idx}`,
            state : params,
        }
     history.push(url);
    }
      

    //페이지 이동에 따른 시스템메세지 출력
    const movePage =(page,Tabs,oct) =>{
        const params = {      
            langCode ,
            searchType,
            search,
            sortColumn,
            sortType,
        }
        
        const params2={}
        
        history.push({
            pathname : "/mainScreenManagement/"+page+"/"+Tabs,
            state : oct ? params2 : params
        });
        if(Tabs=='message'){
        readSystemMessageList(page);
        }
        if(Tabs=='banner'){
        readMainTopScreen(page);
        }
        if(Tabs=='category'){
        readMaincategoryList();
        }
        setCheckList([]);
    }




    useMemo(()=>{
        movePage(page,tabMenu?tabMenu:'message');
    },[])
    
    useEffect(()=>{
        // console.log('mainCategoryList effect ',mainCategoryList);
    },[])

    return (
        <>
            <Container fluid className="MainConatiner">

                {/* 검색영역 */}
                {/* <SearchCard 
                onChangesortColumn ={onChangesortColumn}
                onSelectLanguage = {onSelectLanguage}
                onChangeTitleOrContent={onChangeTitleOrContent}
                onSearchEvent={onSearch}
                sortValue={sortColumn}
                langValue={langCode}
                value={titleOrContent}
                /> */}
                 <CustomSearchCardv2 
                        isLanguage = {true}
                        onSelectLanguage={onSelectLanguage}
                        langValue = {langCode}

                        isSortColumn={true}
                        onChangeSortColumn ={onChangeSortColumn}
                        sortColumnItems={sortColumnItems}
                        sortColumn={sortColumn}

                        isSortType={false}
                        onChangeSortType={onChangeSortType}
                        sortTypeItems={sortTypeItems}
                        sortType={sortType}

                        isSearch={true}
                        onChangeSearch={onChangeSearch}
                        onSearchEvent={onSearch}
                        searchItems={searchItems}
                        onChangeSearchType={onChangeSearchType}
                        search={search}   
                        />

                {/* 탭영역 */}
                <Row>
                    <Col>
                        <Tabs defaultActiveKey={tabacv} transition={false} className="upTabs m-0" onSelect={(e)=>onChangetabMenu(e)}>

                            {/* 이달의 시스템 메세지 */}
                            <Tab eventKey="message" title="이달의 시스템 메세지">
                                <Card className="tabBodyCard">
                                    <Card.Header className="d-flex justify-content-between align-items-center">
                                        <h4 className="cardTitleBig">이달의 시스템 메세지 &nbsp;&nbsp;<span style={{fontSize : '14px', fontWeight: 400}}>[ 전체 : {systemMessagePage.totalElements} ]</span></h4>
                                        <Button onClick={() => { history.push('/mainScreenManagement_uploadMessage/1/0') }} variant="primary"><span>이달의 시스템 메세지 업로드</span></Button>
                                    </Card.Header>
                                    <Card.Body className="d-flex justify-content-between align-items-center no-gutters p-0">
                                        <Table className="commonTable borderTable bb-1 clickable">
                                            <Thead>
                                                <Tr>
                                                    <Th style={{width:50}}>
                                                    <Checkbox 
                                                        checked = {
                                                            checkList.length === systemMessagePage.numberOfElements && systemMessagePage.numberOfElements>0
                                                            ? true
                                                            : false
                                                        }
                                                        onChange={(e) =>allCheck(e.target.checked)}/>
                                                    </Th>
                                                    <Th style={{width:80}}>번호</Th>
                                                    <Th style={{width:150}}>언어</Th>
                                                    <Th>제목</Th>
                                                    <Th style={{width:140}}>작성자</Th>
                                                    <Th style={{width:100}}>조회수</Th>
                                                    <Th style={{width:160}}>등록(수정)일</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {/* 
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>[번호 DATA]</Td>
                                                    <Td>[언어 DATA]</Td>
                                                    <Td className="text-left fw-500 ellipsis">[제목 DATA]</Td>
                                                    <Td>[조회수 DATA]</Td>
                                                    <Td>[날짜 시간 DATA]</Td>
                                                </Tr>
                                                */}
                                                {/* 
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>57</Td>
                                                    <Td>KOREAN</Td>
                                                    <Td className="text-left fw-500 ellipsis">2020년 10월 이달의 시스템 메세지</Td>
                                                    <Td>2,828</Td>
                                                    <Td>2020.10.24 00:00</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>56</Td>
                                                    <Td>ENGLISH</Td>
                                                    <Td className="text-left fw-500 ellipsis">System Message of Oct.2020.</Td>
                                                    <Td>2,828</Td>
                                                    <Td>2020.10.24 00:00</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>55</Td>
                                                    <Td>KOREAN</Td>
                                                    <Td className="text-left fw-500 ellipsis">2020년 10월 이달의 시스템 메세지 2020년 10월 이달의 시스템 메세지 2020년 10월 이달의 시스템 메세지 2020년 10월 이달의 시스템 메세지</Td>
                                                    <Td>2,828</Td>
                                                    <Td>2020.10.24 00:00</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>54</Td>
                                                    <Td>KOREAN</Td>
                                                    <Td className="text-left fw-500 ellipsis">2020년 10월 이달의 시스템 메세지</Td>
                                                    <Td>2,828</Td>
                                                    <Td>2020.10.24 00:00</Td>
                                                </Tr>*/}
                                                {
                                                systemMessageList.length > 0 ? systemMessageList.map((v,i)=>{

                                                return(
                                                    <Tr>
                                                    <Td><Checkbox 
                                                        checked={
                                                            checkList.includes(v.idx) ? true : false
                                                        }
                                                        onChange={(e)=>oneCheck(e.target.checked,v.idx)} />
                                                    </Td>
                                                    <Td onClick={() => {handleClick(v.idx)}} >{v.idx}</Td>
                                                    <Td onClick={() => {handleClick(v.idx)}}>{v.langCodeName}</Td>
                                                    <Td  style={{textAlign:'left',padding:'1rem'}} onClick={() => {handleClick(v.idx)}}>{v.title}</Td>
                                                    <Td onClick={() => {handleClick(v.idx)}}>{v.author}</Td>
                                                    <Td onClick={() => {handleClick(v.idx)}}>{v.viewCnt}</Td>
                                                    <Td onClick={() => {handleClick(v.idx)}}>{v.createAt}</Td>
                                                    </Tr>
                                                )
                                                })
                                                :
                                                <Tr>
                                                    <Td className="font-weight-bold" colSpan={7} >데이터가 없습니다.</Td>
                                                </Tr>
                                                }   
                                            </Tbody>
                                        </Table>
                                    </Card.Body>
                                    <Card.Footer className="d-flex flex-row justify-content-between align-items-center paginationFooter">
                                        <div>
                                        <CustomPagination
                                        selectctgidx={tabacv}
                                        itemsCount={10}
                                        pageData={systemMessagePage}
                                        page={page}
                                        onClickEvent={movePage}
                                    >
                                    </CustomPagination>
                                        </div>
                                        <div>
                                            <Button variant="outline-danger" onClick={handleShow_deleteMsg}>메세지 삭제</Button>
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </Tab>

                            {/* 메인화면 상단사진 */}
                            <Tab eventKey="banner" title="메인화면 상단사진">
                            <Card className="tabBodyCard">
                                    <Card.Header className="d-flex justify-content-between align-items-center">
                                        <h4 className="cardTitleBig">메인화면 상단사진 &nbsp;&nbsp;<span style={{fontSize : '14px', fontWeight: 400}}>[ 전체 : {mainTopScreenPage.totalElements} ]</span></h4>
                                        <Button onClick={() => { history.push('/mainScreenManagement_UploadmainTopScreen/1/0') }} variant="primary"><span>사진 업로드</span></Button>
                                    </Card.Header>
                                    <Card.Body className="d-flex justify-content-between align-items-center no-gutters p-0">
                                        <Table className="commonTable borderTable bb-1">
                                            <Thead>
                                                <Tr>
                                                <Th style={{width:50}}>
                                                    <Checkbox 
                                                        checked = {
                                                            checkList.length === mainTopScreenPage.numberOfElements && mainTopScreenPage.numberOfElements>0
                                                            ? true
                                                            : false
                                                        }
                                                        onChange={(e) =>allCheck(e.target.checked)}/>
                                                    </Th>
                                                    <Th style={{width:80}}>번호</Th>
                                                    <Th style={{width:150}}>언어</Th>
                                                    <Th>파일명</Th>
                                                    <Th style={{width:180}}>설정상태</Th>
                                                    <Th style={{width:160}}>등록(수정)일</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {/* 
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>[번호 DATA]</Td>
                                                    <Td>[언어 DATA]</Td>
                                                    <Td className="text-left">
                                                        <div class="thumbWrap">
                                                            <div className="thumbImgBox"><img src="[메인이미지 URL DATA]" className="thumbImg" /></div>
                                                            <h6 className="thumbText">[메인이미지명.확장자 DATA]</h6>
                                                        </div>
                                                    </Td>
                                                    <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">[설정상태 DATA]</div></Td>
                                                    <Td>[날짜 시간 DATA]</Td>
                                                </Tr>
                                                */}
                                                {mainTopScreenList.length >0
                                                ?
                                                mainTopScreenList.map((v,i)=>{
                                                    return(
                                                        <Tr>
                                                        <Td><Checkbox 
                                                            checked={
                                                                checkList.includes(v.idx) ? true : false 
                                                            }
                                                        onChange={(e)=>oneCheck(e.target.checked,v.idx)} />
                                                        </Td>
                                                        <Td>{v.idx}</Td>
                                                        <Td>{v.langCodeName}</Td>
                                                        <Td className="text-left">
                                                            <div class="thumbWrap">
                                                                {v.picPath ? 
                                                                    <div className="thumbImgBox">
                                                                        <img src={"/fileUpload/"+v.picPath} className="thumbImg" onClick={() => {topHandleClick(v.idx)}} />
                                                                    </div>
                                                                :
                                                                <div className="thumbImgBox">
                                                                    <img src={'/media/empty/default-video.jpg'} className="thumbImg" onClick={() => {topHandleClick(v.idx)}} />
                                                                </div>
                                                                }  
                                                                <h6 className="thumbText" onClick={() => {topHandleClick(v.idx)}}>{v.title}</h6>
                                                            </div>
                                                        </Td>
                                                        { v.openFlag
                                                        ?
                                                    <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">활성</div></Td>
                                                    :  
                                                    <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비활성</div></Td>
                                                        }
                                                        <Td>{v.createAt}</Td>
                                                </Tr> 
                                                    )
                                                })
                                            :
                                                <Tr>
                                                <Td className="font-weight-bold" colSpan={6} >관련 게시글이 없습니다.</Td>
                                                </Tr>
                                            }
                                            {/*                                                     
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>57</Td>
                                                    <Td>KOREAN</Td>
                                                    <Td className="text-left">
                                                        <div class="thumbWrap">
                                                            <div className="thumbImgBox"><img src={testImg} className="thumbImg" /></div>
                                                            <h6 className="thumbText">mainImage_december_12127846_fooxcnfsdlweifgbdsdckxxoso_123123452341.jpg</h6>
                                                        </div>
                                                    </Td>
                                                    <Td><div className="d-inline-block w-75 m-0 badge badge-success text-wrap font-weight-normal stateBadge">KOREAN</div></Td>
                                                    <Td>2020.10.24 00:00</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>57</Td>
                                                    <Td>KOREAN</Td>
                                                    <Td className="text-left">
                                                        <div class="thumbWrap">
                                                            <div className="thumbImgBox"><img src={testImg} className="thumbImg" /></div>
                                                            <h6 className="thumbText">mainImage.jpg</h6>
                                                        </div>
                                                    </Td>
                                                    <Td><div className="d-inline-block w-75 m-0 badge badge-success text-wrap font-weight-normal stateBadge">KOREAN</div></Td>
                                                    <Td>2020.10.24 00:00</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>57</Td>
                                                    <Td>KOREAN</Td>
                                                    <Td className="text-left">
                                                        <div class="thumbWrap">
                                                            <div className="thumbImgBox"><img src={testImg} className="thumbImg" /></div>
                                                            <h6 className="thumbText">mainImage.jpg</h6>
                                                        </div>
                                                    </Td>
                                                    <Td><div className="d-inline-block w-75 m-0 badge badge-success text-wrap font-weight-normal stateBadge">KOREAN</div></Td>
                                                    <Td>2020.10.24 00:00</Td>
                                                </Tr> */}

                                            </Tbody>
                                        </Table>
                                    </Card.Body>
                                    <Card.Footer className="d-flex flex-row justify-content-between align-items-center paginationFooter">
                                        <div>
                                        <CustomPagination
                                        selectctgidx={Tabs}
                                        itemsCount={10}
                                        pageData={mainTopScreenPage}
                                        page={page}
                                        onClickEvent={movePage}
                                    >
                                    </CustomPagination>
                                        </div>
                                        <div>
                                            {/* <Button variant="outline-success">메인사진 설정</Button> */}
                                            <Button variant="outline-danger" className="ml-2" onClick={handleShow_deleteBanner}>사진 삭제</Button>
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </Tab>

                            {/* 메인화면 카테고리 순서 */}
                            <Tab eventKey="category" title="메인화면 카테고리 순서">
                            <Card className="tabBodyCard">
                                    <Card.Header className="d-flex justify-content-between align-items-center">
                                        <h4 className="cardTitleBig">카테고리 &nbsp;&nbsp;<span style={{fontSize : '14px', fontWeight: 400}}>[ 전체 : {mainCategoryPage.totalElements} ]</span></h4>
                                    </Card.Header>
                                    <Card.Body className="d-flex justify-content-between align-items-center no-gutters p-0">
                                        <Table className="commonTable borderTable">
                                            <Thead>
                                                <Tr>
                                                    <Th style={{width:80}}>순서</Th>
                                                    <Th>카테고리명</Th>
                                                </Tr>
                                            </Thead>
                                                
                                                {/* 
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>[순서 DATA]</Td>
                                                    <Td className="text-left ellipsis">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h6 className="fw-500">[카테고리명 DATA]</h6>
                                                            <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Menu.svg')}/></span>
                                                        </div>
                                                    </Td>
                                                </Tr> 
                                                */}
                                                {/* {mainCategoryList.map((v,i)=>{
                                                    return(
                                                        <Tr>
                                                        <Td><Checkbox /></Td>
                                                        <Td>{v.num}</Td>
                                                        <Td className="text-left ellipsis">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <h6 className="fw-500">{v.titleKo}</h6>
                                                                <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Menu.svg')}/></span>
                                                            </div>
                                                        </Td>
                                                    </Tr> 
                                                    )
                                                })} */}
                                                <Tbody>                                                    
                                                    <Tr>
                                                        <Td colSpan="2">                                                             
                                                            <ReactDragList
                                                                dataSource={mainCategoryList}
                                                                row={(e,i)=>
                                                                    <Table className="commonTable borderTable">
                                                                        <Tr>
                                                                            <Td style={{width:80}}>{e.num}</Td>
                                                                            <Td className="text-left ellipsis">
                                                                                <div className="d-flex justify-content-between align-items-center">
                                                                                    <h6 className="fw-500">{e.titleKo}</h6>
                                                                                    <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Menu.svg')}/></span>
                                                                                </div>
                                                                            </Td>
                                                                        </Tr>
                                                                    </Table>
                                                                }
                                                                onUpdate = {(e,v)=>{categoryChange(e.oldIndex,e.newIndex,v)}}                                                                
                                                                handles = {false}
                                                                />                                                                  
                                                    </Td>
                                                    </Tr>
                                                </Tbody>
                                                {/* <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>11</Td>
                                                    <Td className="text-left ellipsis">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h6 className="fw-500">명예의전당</h6>
                                                            <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Menu.svg')}/></span>
                                                        </div>
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>10</Td>
                                                    <Td className="text-left ellipsis">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h6 className="fw-500">NSP 시너지</h6>
                                                            <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Menu.svg')}/></span>
                                                        </div>
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>9</Td>
                                                    <Td className="text-left ellipsis">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h6 className="fw-500">메가마스터</h6>
                                                            <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Menu.svg')}/></span>
                                                        </div>
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>8</Td>
                                                    <Td className="text-left ellipsis">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h6 className="fw-500">헬스&amp;뷰티</h6>
                                                            <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Menu.svg')}/></span>
                                                        </div>
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>7</Td>
                                                    <Td className="text-left ellipsis">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h6 className="fw-500">리더쉽</h6>
                                                            <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Menu.svg')}/></span>
                                                        </div>
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>6</Td>
                                                    <Td className="text-left ellipsis">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h6 className="fw-500">뉴 핀 스피치</h6>
                                                            <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Menu.svg')}/></span>
                                                        </div>
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>5</Td>
                                                    <Td className="text-left ellipsis">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h6 className="fw-500">사업진행사례</h6>
                                                            <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Menu.svg')}/></span>
                                                        </div>
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>4</Td>
                                                    <Td className="text-left ellipsis">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h6 className="fw-500">마이스토리</h6>
                                                            <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Menu.svg')}/></span>
                                                        </div>
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>3</Td>
                                                    <Td className="text-left ellipsis">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h6 className="fw-500">HOW TO</h6>
                                                            <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Menu.svg')}/></span>
                                                        </div>
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>2</Td>
                                                    <Td className="text-left ellipsis">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h6 className="fw-500">이글스시스템</h6>
                                                            <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Menu.svg')}/></span>
                                                        </div>
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><Checkbox /></Td>
                                                    <Td>1</Td>
                                                    <Td className="text-left ellipsis">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h6 className="fw-500">이달의 시스템 메세지</h6>
                                                            <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Menu.svg')}/></span>
                                                        </div>
                                                    </Td>
                                                </Tr>
                                                */}
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Tab>
                        
                        </Tabs>
                    </Col>
                </Row>

                {/* 모달 (메인화면 상단사진 - 사진 업로드) */}
                {/* <Modal show={show_uploadBanner} onHide={handleClose_uploadBanner} centered>
                    <Modal.Header closeButton><Modal.Title>사진 업로드</Modal.Title></Modal.Header>
                    <Modal.Body className="haveTable">
                        <Table className="commonTable">
                            <Tbody>
                            <Tr>
                                <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>언어선택</Td>
                                <Td><LanguageTabs 
                         
                                /></Td>
                            </Tr>
                                <Tr><Th>사진선택</Th><Td><div className="d-flex justify-content-start align-items-center"><Form.Control type="text" placeholder="선택된 파일 이름.asd" /><Button variant="outline-primary" className="ml-2">파일선택</Button></div></Td></Tr>
                            </Tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_uploadBanner}>취소</Button>
                        <Button variant="primary" onClick={handleClose_uploadBanner}>확인</Button>
                    </Modal.Footer>
                </Modal> */}

                {/* 모달 (메세지 삭제) */}
                <Modal show={show_deleteMsg} onHide={handleClose_deleteMsg} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_deleteMsg}>취소</Button>
                        <Button variant="danger" onClick={systemMessageDelete}>확인</Button>
                    </Modal.Footer>
                </Modal>

                {/* 모달 (사진 삭제) */}
                <Modal show={show_deleteBanner} onHide={handleClose_deleteBanner} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_deleteBanner}>취소</Button>
                        <Button variant="danger" onClick={mainTopScreenDelete}>확인</Button>
                    </Modal.Footer>
                </Modal>

            </Container >
        </>
    );
};

export default MainScreenManagement;
