import React, { useState, useEffect,useMemo } from 'react';
// import { toAbsoluteUrl } from "../../utils/utils";
import { toAbsoluteUrl } from "../../_metronic";

const styles = {
    body : {
      height : window.screen.height, 
      backgroundColor : '#fff', 
    },
    container : {
      height : '100%', 
    },
    section1 : {
    //   padding : '100px 0'
    },
    imgBox : {
    //   backgroundColor : '#fff', 
    //   borderRadius : 35, 
    //   textAlign : 'center', 
      width : '100%', 
    //   height : 250, 
    //   padding : '15px 0', 
    //   margin : 'auto'
    },
    logoImg : {
      width : '100%'
    },
    btnBox : {
      textAlign: 'center', 
      margin : '10px 0'
    },
    btnBox1 : {
        textAlign: 'center', 
        margin : '10px 5px',
        textAlign: 'right',
    },
    androidBtn : {
      backgroundColor : '#a4c639',
      padding : 15,
      margin : 15,
      borderRadius : 5,
    },
    iosBtn : {
      backgroundColor : '#999',
      padding : 15,
      margin : 15,
      borderRadius : 5,
    },
    adminBtn : {
        // padding : 15,
        margin : 15,
        borderRadius : 5,
    },
    androidLogoImg : {
      width : 30,
    },
    iosLogoImg : {
      width : 30,
    },
    downText : {
      fontSize : '1.5em', 
      color : '#fff',
      marginLeft : 15,
      verticalAlign : 'middle'
    },
    downText2 : {
      fontSize : '1.5em', 
      color : '#fff',
      fontWeight : 400,
      verticalAlign : 'middle'
    },
    footer : {
      backgroundColor : '#fff',
      position : 'absolute',
      left : 0,
      bottom : 0,
      width : '100%'
    },

  }

const Intro = () => {
    const openGooglePlay = e => window.location.href = "https://play.google.com/store/apps/details?id=com.synergy.client";
  // const openAppStore = e => window.location.href = "https://apps.apple.com/kr/app/%EC%95%84%ED%8C%8C%ED%8A%B8%EB%A6%AC-%EB%AA%A8%EB%B0%94%EC%9D%BC-%EA%B4%80%EB%A6%AC%EC%82%AC%EB%AC%B4%EC%86%8C/id1510705927";
  const openAppStore = e => window.location.href = "https://apps.apple.com/us/app/eaglessystem/id1544702950";

    // const [policy ,setPolicy] = useState('');
    
    // useEffect(()=>{
    //     const rs = axios.get('/api/v1/app/common/terms?langCode=Ko');
    //     rs.then(({data})=>{    
    //         let obj = data?.filter(item=>item.type == 'PRIVACY_POLICY')[0];        
    //         setPolicy(obj.item.content.replace(/\n/g, '<br/>'));
    //     });
    // },[])

    return (
        <div style={styles.body}>
            <div style={styles.container}>
                <div style={styles.section1}>
                    <div style={styles.imgBox}>
                        <img src={toAbsoluteUrl("/media/intro/intro1.jpg")} 
                            alt="Aptree logo"
                            style={styles.logoImg} />
                    </div>
                    <div style={styles.imgBox}>
                        <img src={toAbsoluteUrl("/media/intro/intro2.jpg")} 
                            alt="Aptree logo"
                            style={styles.logoImg} />
                    </div>
                </div>

                <div style={styles.btnBox}>
                    <div style={styles.adminBtn}>
                        <a className="btn btn-info" style={{width: '100%', padding:'15px 0', fontSize: '1.5em', fontWeight:'bold'}} href="/auth/login">관리자 페이지로 이동</a>
                    </div>
                    <div style={styles.androidBtn} onClick={openGooglePlay}>
                        <img src={toAbsoluteUrl("/media/logo/android-logo.png")}
                        alt="Google play logo"
                        style={styles.androidLogoImg}
                        />
                        <b style={styles.downText}>안드로이드 앱 </b><span style={styles.downText2}>다운로드</span>
                    </div>
                    <div style={styles.iosBtn}
                        onClick={openAppStore}
                        // onClick={()=> alert('준비 중 입니다.')}
                    >
                        <img src={toAbsoluteUrl("/media/logo/apple-logo.png")}
                        alt="App store logo"
                        style={styles.iosLogoImg}
                        />
                        <b style={styles.downText}>IOS 앱 </b><span style={styles.downText2}>다운로드</span>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Intro;
