// HonoraryManagement _crud

import axios from'axios';

export const ADMIN_PERSON ="/api/" + process.env.REACT_APP_API_VERSION + "/admin/person";
export const ADMIN_PERSON_DELETE ="/api/" + process.env.REACT_APP_API_VERSION + "/admin/persons";


export function getPersonList(params){
    return axios.get(ADMIN_PERSON,{params});
}

export function deletePerson(CheckList){
    return axios.delete(ADMIN_PERSON_DELETE,{data :{idxs : CheckList}});
}