import React, { useEffect, useState,useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col, InputGroup, FormControl, Card, Badge, Button, Tabs, Tab, Modal} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import {Uploadnoticeimg,fileupload,createNotice,deleteNotice,updateNotice, getNoticeByIdx} from "./crud";
import UploadImage from "../../../common/components/UploadImage";
import LanguageTabs from "../../../common/components/LanguageTabs"

import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import swal from 'sweetalert'
import CustomDropZone from '../../../common/components/CustomDropZone'
import CustomDropZoneCom from '../../../common/components/CustomDropZoneCom'

import DeleteIcon from "@material-ui/icons/Close";




import _ from "lodash";
// import swal from 'sweetalert';
// import * as authAction from "../../../app/store/ducks/auth.duck";
// import { reissueToken, currency } from '../../../common/up_utils';
// import { TOKEN_EXPIRED_CODE } from '../../../common/dickey';
// import Pagination from '../../../common/components/Pagination';

// import { getUsers } from './crud';

const UploadNotice = (props) => {
    const { page, idx } = useParams();
    // let fileDatas = new FormData();
    let addInitialArray = [];
    let delInitialArray = [];
    let [addImgs, setAddImgs] = useState(addInitialArray);
    let [removeImgs, setRemoveImgs] = useState(delInitialArray);

    const history = useHistory();
    const dispatch = useDispatch();

    //const defaultLangCode = history.location.langCodedata && history.location.langCodedata ? history.location.langCodedata : ''; 
    //console.log('언어',defaultLangCode)
    // 모달 (업로드)
    const [show, setShow_upload] = useState(false);
    const handleShow_upload = () => setShow_upload(true);
    const handleClose_upload = () =>  setShow_upload(false);
    // 모달 (삭제)
    const [deleteShow, setShow_delete] = useState(false);
    const handleClose_delete = () => setShow_delete(false);
    const handleShow_delete = () => setShow_delete(true);
    //공지사항
    // const [inputImg , setinputImg] = useState([]); //공지사항 이미지
    const [title , setTitle] = useState(''); //공지사항 제목
    const [content , setContent] = useState(''); //공지 사항 내용
    const [langCode , setLangCode] = useState('en'); // 공지 사항 언어
    const [picPath , setPicPath] = useState([]); //공지 사항 picPath

    const [noticeIdx ,setNoticeIdx] = useState('');//공지사항 idx
    //focus 
    const titleFocus = useRef(null);
    const contentFocus = useRef(null);
    //onChange event
    const onChangeTitle = (e) => {setTitle(e.target.value)}
    const onChangeContent = (e) => {setContent(e.target.value)}
    const onChangeLanguage = (e) => {setLangCode(e)}
    //공지사항 저장
    const savenotice = () =>{
        handleClose_upload();
        noticeImpFillupload();
        // view();
    }
    //수정 할때 기존에 있던 이미지 파일 가공
    // const modpreview = () =>{
    //     let tmpArr = [];
    //     let picPath = [];
    //     if(picPaths){
    //         picPaths.split(',').map((v,i)=>{
    //             let imt ={
    //                 name : "/fileUpload/",
    //                 path : v
    //             }
    //         tmpArr.push(imt);
    //         picPath.push(v);
    //     })
    //     // setpreview(tmpArr);
    //     setPicPath(picPath);
    //     }
    //     }

    // const view=()=>{
    //     setNoticeIdx('')
    //     // setTitle('')
    //     // setContent('')
    //     setLangCode('en')
    // }
    
    //input된 이미지 삭제하기
    // const removeImageInput = (k) =>{
    //     let tmpArr3 = [...picPath];
    //     tmpArr3.splice(k,1)
    //     setPicPath(tmpArr3);
    // }

    const deleteOption = (file) => {
        setRemoveImgs(prev => [...prev, file]);
    }
    const updateOption = (file) => {
        setAddImgs(prev => [...prev, file]);        
    }
    
   //파일 유무에 따른 공지사항 등록
    const noticeImpFillupload = () =>{
        if(!title) return swal('알림','제목을 입력해주세요.').then(() => {titleFocus.current.focus();});
        if(!content) return swal('알림','내용을 입력해주세요.').then(() => {contentFocus.current.focus();});
        let inputImg = addImgs.filter(item => removeImgs.filter(i => item.upload.uuid == i.upload.uuid).length == 0);
        var formData = new FormData;
        // console.log(inputImg.length)
        if(inputImg.length > 0) {
            for(let i = 0; i < inputImg.length; i++) {
                formData.append('files', inputImg[i]);   
            }
        
        formData.append('path', 'notice')
        const rs = Uploadnoticeimg(formData);
        rs.then(({data}) => {
            let dataArray = new Array();
            for(let key in data) {
                let realName = data[key].realName;
                let filePath = data[key].filePath;
                let pushData = filePath + realName;
                dataArray.push(pushData)
            }
            let paths = dataArray.join();
            onSubmit(paths);
        });
    }else{
        let paths ='';
            onSubmit(paths);
    }
    }

    // const noticeUpload = (picPath) =>{
    //     // console.log('최종picPath',picPath);
    //     let noticeObj = {
    //         title : title,
    //         content : content,
    //         langCode : langCode,
    //         picPath : picPath
    //     } 

    //     if(noticeIdx ){
    //         const rs = updateNotice(noticeIdx ,noticeObj);
    //         rs.then(({ data : {success, message, code, data} }) => {
    //                 if(data !== null) {
    //                     swal({
    //                         title: "알림",
    //                         text: "정상적으로 수정 되었습니다."
    //                     }).then((isConfirm) => {
    //                         if(isConfirm) {
    //                             history.push('/noticeManagement/1');
    //                         }
    //                     })
    //                 } else {
    //                     swal({
    //                         title: "알림",
    //                         text: message
    //                     }).then((isConfirm) => {
    //                         if(isConfirm) {
    //                             return false;
    //                         }
    //                     })
    //                 }
    //         })
    //         .catch(({response : data})=>{
    //             // console.log('catch :: ', data)
    //             if(data.status == 401 && data.data && data?.data?.code == 1101){
    //                 const auth = JSON.parse(localStorage.getItem('persist:auth'));
    //                 const rsRf= refresh(auth.refreshToken);
                    
    //                 rsRf.then(({ data : {success, message, code, data} }) => {
    //                     if(data != null) {
    //                         let accessToken = data.accessToken;
    //                         let refreshToken = data.refreshToken;
    //                         auth.authToken = accessToken;
    //                         auth.refreshToken = refreshToken;
                    
    //                         localStorage.setItem('persist:auth', JSON.stringify(auth));
            
    //                         dispatch(authAction.actions.login(accessToken, refreshToken));
    //                         getDetail();
    //                     } else {
    //                         swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
    //                     }
    //                 })
    //                 .catch(({response : data})=>{
    //                   if(data?.status == 401 && data?.data && data?.data?.code == 1101){
    //                     swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
    //                   }
    //                 });
    //             }
    //         });

            
    //     } else if(!noticeIdx ){
    //         createNotice(noticeObj);
    //         swal({
    //             title: "알림",
    //             text: "정상적으로 등록 되었습니다."
    //         }).then((isConfirm) => {
    //             if(isConfirm) {
    //                 history.push('/noticeManagement/1');
    //             }
    //         })
    //     }
    // }
    //저장
    const onSubmit = (paths) => {
        let noticeObj = {
            title : title,
            content : content,
            langCode : langCode,
            picPath : paths != '' ? paths :  picPath.length ? picPath :  ''
        } 
        // console.log(noticeIdx)
        if(noticeIdx != '') {
            const rs = updateNotice(noticeIdx ,noticeObj);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== null) {
                    swal({
                        title: "알림",
                        text: "정상적으로 수정 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push(`/noticeManagement/${page}`);
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    })
                    .then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                // console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit(paths != '' ? paths : picPath);
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        } else {
            const rs = createNotice(noticeObj);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== null) {
                    swal({
                        title: "알림",
                        text: "정상적으로 등록 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push('/noticeManagement/1');
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    })
                    .then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                // console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit(picPath);
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        }
    }
    //디테일
     const  getDetail =  () => {
        const rs = getNoticeByIdx(idx);
        rs.then(({ data : {success, message, code, data} }) => {
            if(data != null) {
                setNoticeIdx(data.idx);
                setTitle(data.title);
                setLangCode(data.langCode);
                setContent(data.content);
                setPicPath(data.picPath)
            } else {
                // swal('잘못 된 접근입니다.\r\n다시 해주세요!').then(()=>{history.push(`/noticeManagement/${page}`)});
            }
        })
        .catch(({response : data})=>{
            // console.log('catch :: ', data)
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        getDetail();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    //삭제
    const deleteNotice = ()=>{
        handleClose_delete(false);
        const rs = deleteNotice(noticeIdx);
        rs.then(({data : {success,message,code,data} })=>{
            if(success=true && message ==='success') {
                swal({
                    title :"알림",
                    text : "정상적으로 삭제 되었습니다."
                }).then((isConfirm)=>{
                    if(isConfirm){
                        history.push(`/noticeManagement/${page}`);
                    }
                })
            }else{
                swal({
                    title: "알림",
                    text : message
                }).then((isConfirm)=>{
                    if(isConfirm){
                        return false;
                    }
                })
            }
        }).catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        deleteNotice();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    const galleryDel = (path) => {
        let arr = picPath.split(',').filter(v => { return  v !== path });
        setPicPath(arr.join(','))
    }

    useMemo(()=>{
        getDetail();
        // if(picPaths){
            // modpreview();
        // }
    },[])

    // console.log('picPath :: ', picPath)
    return (
        <>
            <Container fluid className="UploadNoticeContainer">

                {/* 버튼영역 */}
                <Row className="mb-4 no-gutters">
                    <Col  className="d-flex justify-content-between align-items-center">
                        <Button variant="outline-primary" onClick={()=>history.push(`/noticeManagement/${page}`)}>목록으로</Button>
                        
                       <div>
                        {/* 정보입력시 활성화 ↑ */}
                        {noticeIdx !=='' &&
                        <Button  variant="outline-danger mr-2" onClick={handleShow_delete}>삭제</Button>
                        }
                        {/* <Button variant="dark" disabled>업로드</Button> */}
                        <Button  variant="primary" onClick={handleShow_upload} >{noticeIdx !== '' ? '수정' : '업로드'}</Button>
                        </div>
                    </Col>
                </Row>

                {/* 모달 (업로드) */}
                <Modal show={show} onHide={handleClose_upload} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>{noticeIdx != '' ? '수정' : '업로드'} 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_upload}>취소</Button>
                        <Button variant="primary" id='upload'onClick={savenotice}>확인</Button>
                    </Modal.Footer>
                </Modal>
                {/*모달 삭제 */}
                <Modal show={deleteShow} onHide={handleClose_delete} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_delete}>취소</Button>
                        <Button variant="primary" onClick={deleteNotice}>확인</Button>
                    </Modal.Footer>
                </Modal>

                {/* 정보 입력영역 */}
                <Row className="mb-4">
                    <Col>
                        <Card>
                            <Card.Header className="cardTitle text-primary">공지사항</Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable">
                                    <Tbody>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>언어선택</Td>
                                            <Td>
                                                 <LanguageTabs
                                                    onLanSelected={onChangeLanguage}
                                                    langCode={langCode}
                                                />  
{/*                                                 
                                                {langCode&&
                                                    <Tabs  activeKey={langCode} transition={false} className="languageTabs mb-0" onSelect={(e)=>{setLangCode(e)}}>
                                                        <Tab eventKey="en" title="ENGLISH"></Tab>
                                                        <Tab eventKey="ko" title="KOREAN"></Tab>
                                                        <Tab eventKey="ja" title="JAPANESE"></Tab>
                                                        <Tab eventKey="zh" title="TAIWANESE"></Tab>
                                                        <Tab eventKey="id" title="INDONESIAN"></Tab>
                                                        <Tab eventKey="th" title="THAI"></Tab>
                                                    </Tabs>
                                                } */}
                                            </Td>                                           
                                        </Tr>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>공지제목</Td>
                                            <Td>
                                                {/* <Form.Control type="text" placeholder="공지사항 제목을 입력하세요." onChange={(e)=>onChangeTitle(e.target.value)} value={title}/> */}
                                                <input className="form-control" type="text" placeholder="공지사항 제목을 입력하세요." onChange={(e)=>onChangeTitle(e)} value={title} ref={titleFocus}/>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>{noticeIdx !== '' ? '교체이미지' : '사진등록'}</Td>
                                            <Td>
                                                <CustomDropZoneCom 
                                                    onUpdate={updateOption}
                                                    onDelete={deleteOption}
                                                    acceptedFiles={'image/*'}
                                                    iconFiletypes={['.jpg', '.png', '.gif', '.jpeg']}
                                                    maxFiles={10}
                                                    fileLimit={10}
                                                    title={'SIZE 375 X 584'}
                                                    description={'사진파일을 끌어서 여기에 놓거나<br />여기를 클릭하여 사진파일을 업로드합니다'}
                                                />
                                                {/* <CustomDropZone onUpdate={onChangeEvent} data={picPath} /> */}
                                                {/* <UploadImage
                                                    title="SIZE 375 X 584"
                                                    description={'사진파일을 끌어서 여기에 놓거나<br />여기를 클릭하여 사진파일을 업로드합니다'} 
                                                    disabled ={preview.length}
                                                    onChangeEvent ={onChangeEvent}
                                                    multiple = {true}
                                                    removeImageInput = {removeImageInput}
                                                    preview ={preview}
                                                /> */}
                                            </Td>
                                        </Tr>
                                        {picPath != '' && picPath.split(',').length > 0 &&
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100, fintSize: '13px'}}>기존이미지</Td>
                                            <Td>
                                                <div class="modifyCustomDropzone modifyImgContainer">
                                                <div class="gallery-grid">
                                                    {picPath.split(',').map((v, i) => {
                                                        return(
                                                            <div class="gallery-frame">
                                                                {/* <img class="gallery-img" alt={v} src={'/fileUpload/' + v} />   */}
                                                                <img class="gallery-img" alt={v} src={'/fileUpload/' + v} />  
                                                                <button onClick={() => galleryDel(v)}><DeleteIcon /></button>
                                                            </div>  
                                                        )
                                                    })}
                                                    </div>
                                                </div>
                                            </Td>
                                        </Tr>
                                        }
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>공지내용</Td>
                                            <Td>
                                                {/* <Form.Control as="textarea" rows="8" placeholder="공지사항 내용을 입력하세요."  value={content} onChange={(e)=>onChangeContent(e.target.value)} /> */}
                                                <textarea className="form-control" rows="8" placeholder="공지사항 내용을 입력하세요."  value={content} onChange={(e)=>onChangeContent(e)} ref={contentFocus}></textarea>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container >
        </>
    );
};

export default UploadNotice;
