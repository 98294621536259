import axios from "axios"

export const ADMIN_GOODS = "/api/"+ process.env.REACT_APP_API_VERSION +"/admin/goods"
export const ADMIN_GOODS_BY_IDX = "/api/"+ process.env.REACT_APP_API_VERSION +"/admin/goods/"


export function getGoodsByIdx(goodsIdx){
    return axios.get(ADMIN_GOODS_BY_IDX+goodsIdx);
}

export function updateGoods(goodsIdx,params){
    return axios.put(ADMIN_GOODS_BY_IDX +goodsIdx,params);
}

export function createGoods(params){
    return axios.post(ADMIN_GOODS,params);
}

export function deleteGoods(goodsIdx){
    return axios.delete(ADMIN_GOODS_BY_IDX+goodsIdx);
}