import React, { useEffect, useState ,useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col,FormControl, Card, Badge, Button, Modal } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import {getPushTempByIdx,updatePushTemp,createPushTemp} from "./crud"
import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import swal from 'sweetalert'
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
const useStyles = makeStyles(theme => ({
    radio: {
        size :40,
        '&$checked': {
          color: '#343a40',
        }
    },
    checked: {}
}));
const UploadPush = () =>{
    
    const {page,idx} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    // 내용이 채워졌을 때 업로드 활성화

    // 모달 (업로드)
    const [show, setShow_upload] = useState(false);
    const handleClose_upload = () => setShow_upload(false);
    const handleShow_upload = () => setShow_upload(true);

    const [checkList , setCheckList] =useState([]);

    const [pushTempIdx,setPushTempIdx] = useState('');

    const [textKo ,setTextKo] = useState('');
    const [textEn ,setTextEn] = useState('');
    const [textId ,setTextId] = useState('');
    const [textTh ,setTextTh] = useState('');
    const [textZh ,setTextZh] = useState('');
    const [textJa ,setTextJa] = useState('');

    const [isCheckEn ,setIsCheckEn] = useState(false);
    const [isCheckKo ,setIsCheckKo] = useState(false);
    const [isCheckId ,setIsCheckId] = useState(false);
    const [isCheckZh ,setIsCheckZh] = useState(false);
    const [isCheckTh ,setIsCheckTh] = useState(false);
    const [isCheckJa ,setIsCheckJa] = useState(false);

    const textKoFocus = useRef(null);
    const textEnFocus = useRef(null);
    const textJaFocus = useRef(null);
    const textIdFocus = useRef(null);
    const textThFocus = useRef(null);
    const textZhFocus = useRef(null);

    const [author ,setAuthor] = useState("");
    const [updateAt , setUpdateAt] = useState("");

    const onChangeTextKo = (e) =>{if(e.target.value.length <= 100)setTextKo(e.target.value)}
    const onChangeTextEn = (e) =>{if(e.target.value.length <= 100)setTextEn(e.target.value)}
    const onChangeTextJa = (e) =>{if(e.target.value.length <= 100)setTextJa(e.target.value)}
    const onChangeTextId = (e) =>{if(e.target.value.length <= 100)setTextId(e.target.value)}
    const onChangeTextTh = (e) =>{if(e.target.value.length <= 100)setTextTh(e.target.value)}
    const onChangeTextZh = (e) =>{if(e.target.value.length <= 100)setTextZh(e.target.value)}

    const getDetail = () =>{
        const rs =getPushTempByIdx(idx);
        rs.then(({ data : {success, message, code, data} }) => {
            if(data != null) {
                setPushTempIdx(data.idx);

                setTextEn(data.textEn);
                setTextKo(data.textKo);
                setTextTh(data.textTh);
                setTextZh(data.textZh);
                setTextId(data.textId);
                setTextJa(data.textJa);

                setIsCheckId(data.checkId);
                setIsCheckEn(data.checkEn);
                setIsCheckKo(data.checkKo);
                setIsCheckTh(data.checkTh);
                setIsCheckZh(data.checkZh);
                setIsCheckJa(data.checkJa);

                setAuthor(data.author);
                setUpdateAt(data.updateAt);
            } else {
                // swal('잘못 된 접근입니다.\r\n다시 해주세요!').then(()=>{history.push(`/categoryManagement/${page}`)});
            }
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != '') {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        getDetail();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    const onSubmit=()=>{
        handleClose_upload();
        if(textEn == "" && textKo == "" && textTh  == "" && textZh == "" && textJa == "" && textId == ""){
            return swal('알림','하나 이상의 푸쉬 알림을 입력 하셔야합니다.');
        }

        if(isCheckKo && textKo == ""){
            return swal('알림','체크하신 언어에 알림 내용이 없습니다.').then(()=> textKoFocus.current.focus());
        }

        if(isCheckEn && textEn == ""){
            return swal('알림','체크하신 언어에 알림 내용이 없습니다.').then(()=> textEnFocus.current.focus());
        }

        if(isCheckJa && textJa == ""){
            return swal('알림','체크하신 언어에 알림 내용이 없습니다.').then(()=> textJaFocus.current.focus());
        }

        if(isCheckTh && textTh == ""){
            return swal('알림','체크하신 언어에 알림 내용이 없습니다.').then(()=> textThFocus.current.focus());
        }

        if(isCheckZh && textZh == ""){
            return swal('알림','체크하신 언어에 알림 내용이 없습니다.').then(()=> textZhFocus.current.focus());
        }

        if(isCheckId && textId == ""){
            return swal('알림','체크하신 언어에 알림 내용이 없습니다.').then(()=> textIdFocus.current.focus());
        }

        let pushTempObj = {
            textEn : textEn,
            textKo : textKo,
            textTh : textTh,
            textZh : textZh,
            textJa : textJa,
            textId : textId,

            checkZh : isCheckZh,
            checkKo : isCheckKo,
            checkEn : isCheckEn,
            checkTh : isCheckTh,
            checkJa : isCheckJa,
            checkId : isCheckId,
        }

        // console.log('Obj',pushTempObj)
        if(pushTempIdx !=''){
            const rs = updatePushTemp(pushTempIdx,pushTempObj);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== '') {
                    swal({
                        title: "알림",
                        text: "정상적으로 수정 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push(`/pushManagement/${page}`);
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != '') {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit();
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        } else{
            const rs = createPushTemp(pushTempObj);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== '') {
                    swal({
                        title: "알림",
                        text: "정상적으로 등록 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push(`/pushManagement/1`);
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != '') {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit();
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        }
    }

    useMemo(()=>{
        // if(idx !==0)
        getDetail();
    },[])
   
    return (
        <>
            <Container fluid className="UploadGoodsContainer">
           
                {/* 버튼영역 */}
                <Row className="mb-4 no-gutters">
                    <Col className="d-flex justify-content-between align-items-center">
                        <Button  onClick={()=>history.push(`/pushManagement/${page}`)} variant="outline-primary">목록으로</Button>
                        {/* 정보입력시 활성화 ↑ */}
                        {/* <Button variant="dark" disabled>업로드</Button> */}
                        <div >
                        <Button  variant="primary" onClick={handleShow_upload} >전송</Button>
                        </div>
                    </Col>
                </Row>

                {/* 모달 (업로드) */}
                <Modal show={show} onHide={handleClose_upload} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>전송 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_upload}>취소</Button>
                        <Button variant="primary" onClick={onSubmit}>확인</Button>
                    </Modal.Footer>
                </Modal>
                 

                {/* 정보 입력영역 */}
                <Row className="mb-4">
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h4 className="cardTitleBig text-primary">PUSH 알림 메세지</h4>
                                {
                                pushTempIdx != ""
                                ?
                                <h9>{`전송인 : ${author} | 전송일 : ${updateAt}`}</h9>
                                :
                                <h9>선택한 언어별로 입력한 메세지가 각 언어별 사용자에게 PUSH 알림이 전송되며 미선택한 언어의 경우 알림이 가지 않습니다.</h9>
                                }
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable">
                                    <Tbody>
                                        <Tr>
                                            <Td colspan="3" style={{border: 0}}>
                                                <Row className="no-gutters">
                                                    <Col className="d-flex justify-content-between align-items-center ml-3 no-gutters">
                                                        <div className="check-div">
                                                            <input type="checkBox" id="check-id" className="check-input" checked={isCheckKo}></input>
                                                            <span className="custom-check-box" htmlFor="check-id" onClick={()=>{setIsCheckKo(!isCheckKo)}}></span>
                                                        </div>
                                                        {/* <Radio classes = {{root: classes.radio,checked: classes.checked}} checked={isCheckKo} onClick={()=>{setIsCheckKo(!isCheckKo)}}/> */}
                                                        <label className="font-weight-bold text-dark text-left" style={{width:140,fontSize:20,paddingLeft : 10}}>KOREAN</label>
                                                        <Form.Control as="textarea"  rows="4" ref={textKoFocus&&textKoFocus} placeholder="PUSH 알림으로 사용자에게 보낼 메세지를 100자 내로 입략해 주세요." value={textKo} onChange={(e)=>onChangeTextKo(e)}/>
                                                    </Col>
                                                </Row>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td colspan="3" style={{border: 0}}>
                                                <Row className="no-gutters">
                                                    <Col className="d-flex justify-content-between align-items-center ml-3 no-gutters" >
                                                        <div className="check-div">
                                                            <input type="checkBox" id="check-id" className="check-input" checked={isCheckEn}></input>
                                                            <span className="custom-check-box" htmlFor="check-id" onClick={()=>{setIsCheckEn(!isCheckEn)}}></span>
                                                        </div>
                                                        {/* <Radio classes = {{root: classes.radio,checked: classes.checked}} checked={isCheckEn} onClick={()=>{setIsCheckEn(!isCheckEn)}}/> */}
                                                        <label className="font-weight-bold text-dark text-left" style={{width:140,fontSize:20,paddingLeft : 10}}>ENGLISH</label>
                                                        <Form.Control as="textarea"  rows="4" ref={textEnFocus&&textEnFocus} placeholder="PUSH 알림으로 사용자에게 보낼 메세지를 100자 내로 입략해 주세요." value={textEn} onChange={(e)=>onChangeTextEn(e)}/>
                                                    </Col>
                                                </Row>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td colspan="3" style={{border: 0}}>
                                                <Row className="no-gutters">
                                                    <Col className="d-flex justify-content-between align-items-center ml-3 no-gutters" >
                                                        <div className="check-div">
                                                            <input type="checkBox" id="check-id" className="check-input" checked={isCheckJa}></input>
                                                            <span className="custom-check-box" htmlFor="check-id" onClick={()=>{setIsCheckJa(!isCheckJa)}}></span>
                                                        </div>
                                                        {/* <Radio classes = {{root: classes.radio,checked: classes.checked}} checked={isCheckJa} onClick={()=>{setIsCheckJa(!isCheckJa)}}/> */}
                                                        <label className="font-weight-bold text-dark text-left" style={{width:140,fontSize:20,paddingLeft : 10}}>JAPENESE</label>
                                                        <Form.Control as="textarea"  rows="4" ref={textJaFocus&&textJaFocus} placeholder="PUSH 알림으로 사용자에게 보낼 메세지를 100자 내로 입략해 주세요." value={textJa} onChange={(e)=>onChangeTextJa(e)}/>
                                                    </Col>
                                                </Row>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td colspan="3" style={{border: 0}}>
                                                <Row className="no-gutters">
                                                    <Col className="d-flex justify-content-between align-items-center ml-3 no-gutters" >
                                                        <div className="check-div">
                                                            <input type="checkBox" id="check-id" className="check-input" checked={isCheckTh}></input>
                                                            <span className="custom-check-box" htmlFor="check-id" onClick={()=>{setIsCheckTh(!isCheckTh)}}></span>
                                                        </div>
                                                        {/* <Radio classes = {{root: classes.radio,checked: classes.checked}} checked={isCheckTh} onClick={()=>{setIsCheckTh(!isCheckTh)}}/> */}
                                                        <label className="font-weight-bold text-dark text-left" style={{width:140,fontSize:20,paddingLeft : 10}}>TAILLAND</label>
                                                        <Form.Control as="textarea"  rows="4" ref={textThFocus&&textThFocus} placeholder="PUSH 알림으로 사용자에게 보낼 메세지를 100자 내로 입략해 주세요." value={textTh} onChange={(e)=>onChangeTextTh(e)}/>
                                                    </Col>
                                                </Row>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td colspan="3" style={{border: 0}}>
                                                <Row className="no-gutters">
                                                    <Col className="d-flex justify-content-between align-items-center ml-3 no-gutters" >
                                                        <div className="check-div">
                                                            <input type="checkBox" id="check-id" className="check-input" checked={isCheckZh}></input>
                                                            <span className="custom-check-box" htmlFor="check-id" onClick={()=>{setIsCheckZh(!isCheckZh)}}></span>
                                                        </div>
                                                        {/* <Radio classes = {{root: classes.radio,checked: classes.checked}} checked={isCheckZh} onClick={()=>{setIsCheckZh(!isCheckZh)}}/> */}
                                                        <label className="font-weight-bold text-dark text-left" style={{width:140,fontSize:20,paddingLeft : 10}}>CHINENES</label>
                                                        <Form.Control as="textarea"  rows="4" ref={textZhFocus&&textZhFocus} placeholder="PUSH 알림으로 사용자에게 보낼 메세지를 100자 내로 입략해 주세요." value={textZh} onChange={(e)=>onChangeTextZh(e)}/>
                                                    </Col>
                                                </Row>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td colspan="3" style={{border: 0}}>
                                                <Row className="no-gutters">
                                                    <Col className="d-flex justify-content-between align-items-center ml-3 no-gutters" >
                                                        <div className="check-div">
                                                            <input type="checkBox" id="check-id" className="check-input" checked={isCheckId}></input>
                                                            <span className="custom-check-box" htmlFor="check-id" onClick={()=>{setIsCheckId(!isCheckId)}}></span>
                                                        </div>
                                                        {/* <Radio classes = {{root: classes.radio,checked: classes.checked}} checked={isCheckId} onClick={()=>{setIsCheckId(!isCheckId)}}/> */}
                                                        <label className="font-weight-bold text-dark text-left" style={{width:140,fontSize:20,paddingLeft : 10}}>INDONESIAN</label>
                                                        <Form.Control as="textarea"  rows="4" ref={textIdFocus&&textIdFocus} placeholder="PUSH 알림으로 사용자에게 보낼 메세지를 100자 내로 입략해 주세요." value={textId} onChange={(e)=>onChangeTextId(e)}/>
                                                    </Col>
                                                </Row>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container >
        </>
    );

}

export default UploadPush;