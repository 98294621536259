import React ,{useState,useMemo} from 'react'
import { useDispatch } from 'react-redux';
import CustomPagination from '../../../common/components/CustomPagination'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Form, Row, Container, Col, Button, Tabs, Tab, Modal,Card } from "react-bootstrap";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import SearchCard from "../../../common/components/SearchCard"
import { useHistory, useParams } from "react-router-dom";
import {getPushTemp} from './crud'
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert'
import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import {makeNotEmptyObj} from '../../../../src/_metronic/utils/utils'
import CustomSearchCardv2 from "../../../common/components/CustomSearchCardv2"


const PushManagement = () =>{

    const history = useHistory();
    const dispatch = useDispatch();
    const { page } = useParams();

    //페이징 관련 변수
    const [pushPage , setPushPage] = useState([]);
    //제품 목록
    const [pushList, setPushList] = useState([])
    const [sortColumn,setSortColumn] = useState((history.location.state && history.location.state.sortColumn) || 'createAt');
    const sortColumnItems = [
        {
            text : '등록일순',
            value : 'createAt',
        },
    ];
    
    const [sortType, setSortType] = useState((history.location.state && history.location.state.sortType) || 'DESC')
    const sortTypeItems = [];
    const [searchType, setSearchType] = useState((history.location.state && history.location.state.searchType) || 'text');
    const [search, setSearch] = useState((history.location.state && history.location.state.search) || '');
    const searchItems = [
        {
            text : '내용',
            value : 'text',
        },
    ]; 


    //검색 onChange event
    const onChangeSortColumn = (e)=> setSortColumn(e.target.value);
    const onChangeSortType = (e) => setSortType(e.target.value);
    const onChangeSearchType = (e) => setSearchType(e.target.value);
    const onChangeSearch = (e) => setSearch(e.target.value);

    
    


    const getPushTempList = (page)=>{
        let params = {
            page : page,
            size : 25,
        }
        const chkParams = {
            sortColumn : sortColumn,
            sortType : sortType,
        }
        if(searchType =="text") {chkParams.text = search};
        params = makeNotEmptyObj(params, chkParams);
        const rs = getPushTemp(params);
        rs.then(({data :{content,totalElements,totalPages,last,first,numberOfElements}}) =>{
            setPushList(content);
            const pageObj ={
                totalElements : totalElements,
                totalPages : totalPages,
                last : last,
                first : first,
                numberOfElements :numberOfElements
            }
            setPushPage(pageObj);
        })
        .catch(({response : data})=>{
            if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;

                        localStorage.setItem('persist:auth', JSON.stringify(auth));

                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        movePage(page);
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                }).catch(({response : data})=>{
                    if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                });
            }
        });;

    }


    const handleClick = (idx) => {
        const params = {
            // langCode,      
            searchType,
            search,
            sortColumn,
            sortType
        }
        
        const url = {
        pathname : `/pushManagement_upload/${page}/${idx}`,
        state : params,
        }
        history.push(url);
    }

    const movePage = (page) => {   
        const params = {   
            // langCode,   
            searchType,
            search,
            sortColumn,
            sortType
        }
        
        history.push({
            pathname : "/pushManagement/"+page,
            state : params
        });
        getPushTempList(page);
    }

    //검색
    const onSearch = (e) => {
        e.preventDefault();
        movePage(1);

    }
    
    useMemo(() => {
        movePage(page);        
    },[])
    

    return (
        <>
        <Container fluid className="medicalStaffManagement">
            
                 <CustomSearchCardv2 
                    isLanguage = {false}
                    // onSelectLanguage={onSelectLanguage}
                    // langValue = {langCode}

                    isSortColumn={true}
                    onChangeSortColumn ={onChangeSortColumn}
                    sortColumnItems={sortColumnItems}
                    sortColumn={sortColumn}

                    isSortType={false}
                    onChangeSortType={onChangeSortType}
                    sortTypeItems={sortTypeItems}
                    sortType={sortType}

                    isSearch={true}
                    onChangeSearch={onChangeSearch}
                    onSearchEvent={onSearch}
                    searchItems={searchItems}
                    onChangeSearchType={onChangeSearchType}
                    search={search}   
                    />

            {/* 공지사항 리스트 */}
            <Row>
                <Col>
                    <Card>
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <h4 className="cardTitleBig">PUSH 알림 내역 &nbsp;&nbsp;<span style={{fontSize : '14px', fontWeight: 400}}>[ 전체 : {pushPage.totalElements} ]</span></h4>
                            <Button onClick={() => { history.push('/pushManagement_upload/1/0') }} variant="primary">PUSH 알림 전송</Button>
                        </Card.Header>
                        <Card.Body className="d-flex no-gutters p-0">
                            <Table className="commonTable borderTable bb-1 clickable">
                                    <Thead>
                                        <Tr>
                                            <Th style={{width:80}}>번호</Th>
                                            <Th>내용</Th>
                                            <Th style={{width:120}}>작성자</Th>
                                            <Th style={{width:160}}>등록(수정)일</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                         {
                                         pushList.length > 0 ? pushList.map((v,i) =>{
                                             return (
                                                 <Tr>
                                                     <Td onClick={() => {handleClick(v.idx)}}>{v.idx}</Td>
                                                     <Td onClick={() => {handleClick(v.idx)}}>{
                                                         v.textKo != '' ? v.textKo : v.textEn != '' ? v.textEn : v.textZh ? v.textZh : v.textJa ? v.textJa : v.textId ? v.textId : v.textTh ? v.textTh : ''
                                                     }</Td>
                                                     <Td style={{textAlign:'left',padding:'1rem'}} onClick={() => {handleClick(v.idx)}}>{v.author}</Td>
                                                     <Td onClick={() => {handleClick(v.idx)}}>{v.updateAt}</Td>
                                                 </Tr>
                                             )
                                             })
                                             :
                                             <Tr>
                                                <Td className="font-weight-bold" colSpan={4} >데이터가 없습니다.</Td>
                                            </Tr>
                                             }
                                    </Tbody>
                                </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            

        </Container >
    </>
    )
}

export default PushManagement;