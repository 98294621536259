import React, { useEffect, useState,useMemo,useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col, Button, Tabs, Tab, Modal } from "react-bootstrap";
import TabList from './TabList';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import CustomPagination from '../../../common/components/CustomPagination'
import CustomSearchCardv2 from "../../../common/components/CustomSearchCardv2"
import {getCategoryList,getQuestion,deleteQuestion} from "./crud"
import QuestionSearchCard from "../../../common/components/QuestionSearchCard"
import swal from 'sweetalert'
import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import {makeNotEmptyObj} from '../../../../src/_metronic/utils/utils'

const QuestionManagement =()=>{
    const history = useHistory();
    const dispatch = useDispatch();
    const { page,categoryIdx,langCode } = useParams();

    // 모달 (메세지 삭제)
    const [show_delete, setShow_delete] = useState(false);
    const handleClose_delete = () => setShow_delete(false);
    const handleShow_delete = () => {if(checkList.length<1){return swal('알림','선택하신 질문이 없습니다.')}else{setShow_delete(true)}};

    //검색영역
    const [selectLangCode ,setSelectLangCode] =useState(langCode);
    //검색 변수
    const [sortColumn,setSortColumn] = useState((history.location.state && history.location.state.sortColumn) || 'createAt');
    const sortColumnItems = [
        {
            text : '등록일순',
            value : 'createAt',
        },
    ];
    const [sortType, setSortType] = useState((history.location.state && history.location.state.sortType) || 'DESC')
    const sortTypeItems = [
        {
            text : '오름차순',
            value : 'ASC',
        },
        {
            text : '내림차순',
            value : 'DESC',
        },
    ];
    const [searchType, setSearchType] = useState((history.location.state && history.location.state.searchType) || 'title');
    const [search, setSearch] = useState((history.location.state && history.location.state.search) || '');
    const searchItems = [
        {
            text : '제목',
            value : 'title',
        },
    ];

    const [searchParams,setSearchParams] =useState([sortColumn,selectLangCode,searchType,search]);
    const [selectCtgIdx,setSelectCtgIdx] =useState(categoryIdx)
    const [Page,setPage] =useState(page);
    const [questionPage,setQuestionPage] =useState([]);
    const [checkList,setCheckList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [questionList,setQuestionList] = useState([]);

    const onChangeSortColumn = (e)=> setSortColumn(e.target.value);
    const onChangeSortType = (e) => setSortType(e.target.value);
    const onChangeSearchType = (e) => setSearchType(e.target.value);
    const onChangeSearch = (e) => setSearch(e.target.value);
    const onSelectLanguage = (e) =>{
        setSelectLangCode(e.target.value);
        // movePage(1,selectCtgIdx);
    }

    //검색 클릭 이벤트
    const onSearch = (e) => {
        e.preventDefault();
        movePage(1,selectCtgIdx);
    }

    const onChangecategory=(e)=>{
        const categort = true
        setSelectCtgIdx(e);
        movePage(1,e,categort);
    }

    //체크 박스 전체 선택 유무
    const allCheck = (checked) =>{
        if(checked){
            const ckbox = [];
            questionList.map((v,i)=>{
            ckbox.push(v.questionIdx);
            setCheckList(ckbox);
            })
        }else if(!checked){
            setCheckList([]);
        }
    }

    // 체크 박스 단일 선택
    const oneCheck = (checked,ckidx) =>{
        if(checked){
            setCheckList([...checkList, ckidx]);
        }else if(!checked){
            setCheckList(checkList.filter(idx => idx !== ckidx));
        }
    }

    const getQuestionList=(page,selectCtgIdx)=>{
        let params = {
            page : page,
            size : 25,
            langCode:selectLangCode==="all" ? "" :selectLangCode,
            questionCategoryIdx: selectCtgIdx
        }
        const chkParams = {
            sortColumn : sortColumn,
            sortType : sortType,
        }
        if(searchType == 'title') { chkParams.title = search;}
        params = makeNotEmptyObj(params,chkParams);
        const rs = getQuestion(params);
        rs.then(({data : {content,totalElements,totalPages,last,first,numberOfElements}})=>{
            setQuestionList(content);

            const pageObj = {
                totalElements : totalElements,
                totalPages : totalPages,
                last : last,
                first : first,
                numberOfElements : numberOfElements
            }
            setQuestionPage(pageObj);
        }).catch(({response : data})=>{
            if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;

                        localStorage.setItem('persist:auth', JSON.stringify(auth));

                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        movePage(page,selectCtgIdx);
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                }).catch(({response : data})=>{
                    if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                });
            }
        });;
    
    }

    const questionDelete =()=>{
        setShow_delete(false);
        const rs = deleteQuestion(checkList);
        rs.then(({ data : {success, message, code, data} }) => {
            if(success === true && message === 'success') {
                swal({
                    title: "알림",
                    text: "정상적으로 삭제 되었습니다."
                }).then((isConfirm) => {
                    if(isConfirm) {
                        if(questionPage.numberOfElements === checkList.length&& Page>1){
                            movePage(Page-1,categoryIdx);
                         }else{
                             movePage(Page,categoryIdx);
                         }
                    }
                })
            } else {
                swal({
                    title: "알림",
                    text: message
                }).then((isConfirm) => {
                    if(isConfirm) {
                        return false;
                    }
                })
            }
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        questionDelete();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }



    const readCategoryList = ()=>{
      const rs = getCategoryList();
        rs.then(({data})=>{
            setCategoryList(data);
            if(categoryIdx ==='0'){
                movePage(1,data[0].idx)
            }
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        readCategoryList();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    const movePage = (page,ctgIdx,categort) => {  
        
        const params = {      
            langCode ,
            searchType,
            search,
            sortColumn,
            sortType,
        }
        const params2 = {
            searchType,
            search,
            sortColumn,
            sortType,
            langCode ,
        }
        history.push({
            pathname : "/questionManagement/"+page+"/"+ctgIdx+"/"+selectLangCode,
            state : categort? params2 : params
        });
            getQuestionList(page,ctgIdx);
            setCheckList([]);
    }
        

    useMemo(()=>{
        readCategoryList()
        movePage(page,selectCtgIdx);
    },[selectLangCode])

    return (
        <>
            <Container fluid className="questionConatainer">
                    <QuestionSearchCard 
                        isLanguage = {true}
                        onSelectLanguage={onSelectLanguage}
                        langValue = {selectLangCode}

                        isSortColumn={true}
                        onChangeSortColumn ={onChangeSortColumn}
                        sortColumnItems={sortColumnItems}
                        sortColumn={sortColumn}

                        isSortType={false}
                        onChangeSortType={onChangeSortType}
                        sortTypeItems={sortTypeItems}
                        sortType={sortType}

                        isSearch={true}
                        onChangeSearch={onChangeSearch}
                        onSearchEvent={onSearch}
                        searchItems={searchItems}
                        onChangeSearchType={onChangeSearchType}
                        search={search}   
                        />
                <Row>
                    <Col>
                    <Tabs defaultActiveKey={categoryIdx} ActiveKey={selectCtgIdx} transition={false} className="upTabs m-0" onSelect={(e)=>onChangecategory(e)} > 
                        {categoryList.map((item, index)=> {
                            return (
                                <Tab eventKey={item.idx} title={item.name}>
                                    <TabList
                                        searchParams ={searchParams}
                                        title={item.name}
                                        history={history}
                                        handleShow_delete={handleShow_delete}
                                        questionPage = {questionPage}
                                        movePage={movePage}
                                        page={Page}
                                        questionList={questionList}
                                        categoryIdx={selectCtgIdx}
                                        allCheck ={allCheck}
                                        oneCheck = {oneCheck}
                                        checkList={checkList}
                                        handleShow_delete = {handleShow_delete}
                                    />
                                </Tab>
                            )
                        })}
                    </Tabs>
                    </Col>
                </Row>

                
                {/* 모달 (동영상 삭제) */}
                <Modal show={show_delete} onHide={handleClose_delete} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_delete}>취소</Button>
                        <Button variant="danger" onClick={questionDelete}>확인</Button>
                    </Modal.Footer>
                </Modal>

            </Container >
        </>
    );


};

export default QuestionManagement;