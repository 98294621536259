import axios from 'axios';

export const ADMIN_CATEGORY ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/common/category';

export const ADMIN_BANNER_BY_IDX = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/banner/';

export const API_ADMIN_BANNER_FILE_UPLOAD ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/common/onlyFileUploads'

export const API_ADMIN_BANNER = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/banner';

export const API_ADMIN_BANNER_BY_IDX = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/banner/';


export function getCategoryList(params){
    return axios.get(ADMIN_CATEGORY,{params});
}

export function getBannerByIdx(idx){
    return axios.get(ADMIN_BANNER_BY_IDX+idx);
}

export function uploadBannerFileUpload(params){
    return axios.post(API_ADMIN_BANNER_FILE_UPLOAD,params,{headers : {'Content-Type' : 'multipart/form-data'}})
}

export function createBanner(params) {
    return axios.post(API_ADMIN_BANNER, params);
}

export function updateBanner(bannerIdx, params) {
    return axios.put(API_ADMIN_BANNER_BY_IDX + bannerIdx, params);
}

export function deleteBanner(bannerIdx) {
    return axios.delete(API_ADMIN_BANNER_BY_IDX + bannerIdx)
}