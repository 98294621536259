// VideoManagement _crud

import axios from "axios";
 
export const ADMIN_CATEGORY ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/common/category';
export const ADMIN_VIDEO ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/media';
export const ADMIN_VIDEO_DELETE ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/medias';

export const ADMIN_VIDEO_BY_IDX = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/media/';
export const ADMIN_OPENFLAG = '/api/'+process.env.REACT_APP_API_VERSION+'/admin/updateMediaOpenFlag'
export function getCategoryList(params){
    return axios.get(ADMIN_CATEGORY,{params});
}

export function getVideoList(params){
    // console.log('params',params);
    return axios.get(ADMIN_VIDEO,{params});
}

export function deleteVideo(CheckList){
    return axios.delete(ADMIN_VIDEO_DELETE,{data :{idxs : CheckList}});
}

export function updataOpenFlag(CheckList,openFlag){
    let opflag = false;
    if(openFlag == 'true'){
        opflag = true;
    }else{
        opflag = false;
    }
    return axios.put(ADMIN_OPENFLAG,{idxs: CheckList ,openFlag : opflag})
}