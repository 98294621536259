// CategoryManagement _crud

import axios from "axios";
 
export const ADMIN_CATEGORY ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/category';
export const ADMIN_CATEGORY_BY_IDX ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/category/';
export const ADMIN_CATEGORY_DELETE  ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/categories';



export function getCategoryList(params){
    return axios.get(ADMIN_CATEGORY,{params});
}

export function deleteCategoryList(CheckList){
    return axios.delete(ADMIN_CATEGORY_DELETE,{data :{idxs : CheckList}});
}

