//https://d3c6f7vmlailvy.cloudfront.net/mov_1606810212867.m3u8

import React, { useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import videoJsContribQualityLevels from 'videojs-contrib-quality-levels';
import videojsHlsQualitySelector from 'videojs-hls-quality-selector';
import {getAppVideoByIdx} from './crud';
import {getParam} from '../../../common/up_utils'
videojs.registerPlugin('qualityLevel', videoJsContribQualityLevels);
videojs.registerPlugin('hlsQualitySelector', videojsHlsQualitySelector);

const options = {
    fill: true,
    fluid: true,
    responsive: true,
    preload: 'auto',
    controls: true,
    html5: {
        hls: {
            enableLowInitialPlaylist: true,
            smoothQualityChange: true,
            overrideNative: true,
        },
    },
    plugins: {
        qualityLevel: {}, hlsQualitySelector: { displayCurrentQuality: true }, 
    },
    playbackRates: [0.5, 1, 1.5, 2],
    sourceOrder: true,
};

// eslint-disable-next-line import/prefer-default-export
const usePlayer = ({ controls, autoplay }) => {
    const mediaIdx = getParam('mediaIdx');
    // const [thumbnailPath, setThumbnailPath] = useState('');
    // const [mediaType, setMediaType] = useState('');
    let mov_url = 'https://d3c6f7vmlailvy.cloudfront.net/';
    const videoRef = useRef(null);

    const getDetail = () => {
        const rs = getAppVideoByIdx(mediaIdx);
        rs.then(({ data : {success, message, code, data} }) => {
            if(data != null) {
                // console.log('data:: ', data)
                // setThumbnailPath(data.thumbnailPath);
                // setMediaType(data.mediaType);
                // videoPath = data.videoPath;
                let sourcesArr = [
                    {
                        src : mov_url + data.videoPath + '.m3u8',
                        type : 'application/x-mpegURL',
                    },
                    {
                        src : mov_url + data.videoPath + '480p.m3u8',
                        type : 'application/x-mpegURL',
                    },
                    {
                        src : mov_url + data.videoPath + '720p.m3u8',
                        type : 'application/x-mpegURL',
                    },
                    {
                        src : mov_url + data.videoPath + '1200p.m3u8',
                        type : 'application/x-mpegURL',
                    },
                ]

                const vjsPlayer = videojs(videoRef.current, {
                    ...options,
                    controls,
                    autoplay,
                    sources: sourcesArr,
                });

                let player = vjsPlayer;
        
                if (player !== null) {
                    player.src(sourcesArr);
                }

                return () => {
                    if(player !== null) {
                        player.dispose();
                    }
                };
            } else {
                // swal('잘못 된 접근입니다.\r\n다시 해주세요!').then(()=>{history.push(`/categoryManagement/${page}`)});
            }
        });
    }
    
    useEffect(() => {
        if(mediaIdx != '') {
            getDetail();
        }
    }, []);

    return videoRef;
};

const Players = ({ controls, autoplay }) => {
    const playerRef = usePlayer({ controls, autoplay });
    
    return (
        <div data-vjs-player>
            <video ref={playerRef} className="video-js" />
        </div>
    )
};

Players.propTypes = {
//   src: PropTypes.string.isRequired,
  controls: PropTypes.bool,
  autoplay: PropTypes.bool,
};

Players.defaultProps = {
  controls: true,
  autoplay: false,
};

export default Players;
