import axios from "axios"

export const ADMIN_PUSH = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/pushTemp'
export const ADMIN_PUSH_BY_IDX = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/pushTemp/'

export function getPushTempByIdx(pushTempIdx){
    return axios.get(ADMIN_PUSH_BY_IDX+pushTempIdx);
}

export function updatePushTemp(pushTempIdx,params){
    return axios.put(ADMIN_PUSH_BY_IDX+pushTempIdx,params);
}

export function createPushTemp(params){
    return axios.post(ADMIN_PUSH,params);
}

