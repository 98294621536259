import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col, InputGroup, Card, Badge, Button, Tabs, Tab, Modal} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import {getCategoryList,getvideoByIdx,UploadVideoimg,updateVideo,createVideo,deleteOnvideo,insertMedia, mediaConvert, requestPush} from "./crud";

import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import LanguageTabs from "../../../common/components/LanguageTabs"
import UploadImage from "../../../common/components/UploadImage";


import CustomDropZoneCom from '../../../common/components/CustomDropZoneCom'
import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import swal from 'sweetalert'
import { isEmpty } from '../../../common/up_utils';

import { LayoutSplashScreen } from "../../../../src/_metronic/layout/LayoutContext";

import jQuery from 'jquery';

import DeleteIcon from "@material-ui/icons/Close";
import { constant } from 'lodash';

// import swal from 'sweetalert';
// import * as authAction from "../../../app/store/ducks/auth.duck";

// import { TOKEN_EXPIRED_CODE } from '../../../common/dickey';
// import Pagination from '../../../common/components/Pagination';

// import { getUsers } from './crud';


// Radio Checked Style
const useStyles = makeStyles(theme => ({
    radio: {
        '&$checked': {
          color: '#343a40'
        }
    },
    checked: {}
}));

const UploadVideo = () => {
    const { page, idx } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const titleFocus = useRef(null)
    const contentFocus = useRef(null)
    const numFocus = useRef(null);
    /** aws s3 upload [[ */
    const [isLoading, setIsLoading] = useState(false);
    var aws = require('aws-sdk'); 
    require('dotenv').config(); // Configure dotenv to load in the .env file
    // Configure aws with your accessKeyId and your secretAccessKey

    const fileRef = useRef();
    const [file, setFile] = useState({});
    const [videoPath, setVideoPath] = useState('');
    const [fileName, setFileName] = useState('');
    const [isImg ,setIstImg] = useState(false);
    // const region = 'ap-northeast-2';
    // const identityPoolId = 'ap-northeast-2:d5aac547-4440-4cb9-976b-c7edfe9e39f5';
    // const bucket = 'kr.synergy.dev.origin.video';
    const region = process.env.REACT_APP_AWS_REGION;
    const identityPoolId = process.env.REACT_APP_AWS_IDENTITY_POOL_ID;
    const bucket = process.env.REACT_APP_AWS_BUCKET;

    const handleChange = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name)
    }

    const handleUpload = (e) => {
        setIsLoading(true);

        let html = '<img src="/media/logos/eaglesLogo-big.png" alt="eagles admin logo" style="width:260px;">';
        html += '<p>동영상을 업로드 중입니다. 잠시만 기다려주세요.</p>';
        html += '<svg class="spinner" viewBox="0 0 50 50">';
        html += '<circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="3"></circle>';
        html += '</svg>';      
        jQuery('#splash-screen').html(html);

        handleClose_upload();
        var n = new Date().getTime();

        var fileKey = 'mov_'+n;
        // console.log(file.type);
        var params = {
            Key: fileKey,
            ContentType: file.type,
            Body: file,
            ContentDisposition: 'attachment',
        };

        aws.config.update({
            region: region, // Put your aws region here
            credentials: new aws.CognitoIdentityCredentials({
                IdentityPoolId: identityPoolId
            })
        });
        aws.config.httpOptions.timeout = 0;
    
        var s3 = new aws.S3({
            apiVersion: "2006-03-01",
            params: { Bucket: bucket }
        });

        // console.log(s3);

        s3.upload(params).on('httpUploadProgress', function(evt) {
            // 여기서 업로드 진행 상황 알려줌..
            var uploaded = Math.round(evt.loaded / evt.total * 100);
            console.log('File uploaded: '+uploaded+'%');
            // if(uploaded > 99) {
                
            // }
            // $('#loading_per').text(uploaded+'%');
            // $('.loading .bar2').animate({width : uploaded + '%'},100);
        }).send(function(err, data) {
            if (err){
                // console.log(err, err.stack);
                alert('File upload fail!');
                setIsLoading(false);
                return false;
            }
            // console.log('data :: ', data)
            // 업로드 완료 되면 호출되는 부분
            // ths.uploadVideoFileSrc = data.Location;
            // ths.uploadVideoKey = fileKey;
            // // fileKey  부분이 was 쪽에 전달이 되어야 한다.
            // console.log(fileKey)
            // console.log('File URL:', ths.uploadVideoFileSrc);
            // ths.uploadVideoFile = null
            var obj = {
                bucket: data.Bucket,
                eTag: data.ETag,
                key: data.Key,
                location: data.Location,
                fileType: file.type,
            }
            thumbnailImpFillupload(obj);
            // alert('File is uploaded successfully!');
        })
    }
    /** aws s3 upload ]] */

    const classes = useStyles();
    // Radio_Category
    const [beforeCategory ,setbeforeCategory] = useState((history.location.state && history.location.state.categoryIdx)||'')
   
    // Radio_Status
   

    let addInitialArray = [];
    let delInitialArray = [];
    let [addImgs, setAddImgs] = useState(addInitialArray);
    let [removeImgs, setRemoveImgs] = useState(delInitialArray);

    const deleteOption = (file) => {
        setIstImg(false);
        setRemoveImgs(prev => [...prev, file]);
    }
    const updateOption = (file) => {
        setIstImg(true);
        setAddImgs(prev => [...prev, file]);        
    }

    // 모달 (업로드)
    const [show, setShow_upload] = useState(false);
    const handleClose_upload = () => setShow_upload(false);
    const handleShow_upload = () => setShow_upload(true);

    const [deleteShow, setShow_delete] = useState(false);
    const handleClose_delete = () => setShow_delete(false);
    const handleShow_delete = () => setShow_delete(true);

    const [push, setShow_push] = useState(false);
    const handleClose_push = () => setShow_push(false);
    const handleShow_push = () => setShow_push(true);

    const [categoryList,setCategoryList] = useState([{
        idx:[],
        titleKo:[],
        thumbnailPath:[],
        useFlag:[],
        num:[],
        type:[]
    }]);
    const [videoIdx,setVideoIdx] =useState('');
    const [langCode,setLangCode] =useState('');
    const [title,setTitle] =useState('');
    const [content,setContent] =useState('');
    const [thumbnailPath,setThumbnailPath] =useState([]);
    const [openFlag,setOpenFlag] =useState(true);
    const [category, setCategory] = useState(beforeCategory);
    const [status, setStatus] = useState('false');
    const [categoryIdx,setCategoryIdx] =useState();
    const [num, setNum] = useState(0);
    const [downloadLink ,setDownloadLink] = useState('');
    
    //onChange
    const onChangeTitle = (e) => {if(e.target.value.length <=55)setTitle(e.target.value)}
    const onChangeContent = (e) => {setContent(e.target.value)}
    const onChangeLanguage = (e) => {setLangCode(e)} 
    const onChangeCategory=(e) => { setCategory(e.target.value);}
    const onChangeStatus=(e)=> {setStatus(e.target.value);}
    const onChangeNum = (e) =>{setNum(e.target.value)}
    const onChangeDownloadLink=(e)=>{setDownloadLink(e.target.value)}

    //카테고리 리스트
    const readCategoryList = ()=>{
        let params = {
            sortType : "ASC",
            sortColumn : "num",
            contentType : 'VIDEO'
        }
      const rs = getCategoryList(params);
        rs.then(({data :{content}})=>{
            setCategoryList(content);
            //console.log('content[0].idx',content[0].idx)
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        readCategoryList();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }
    //상세 정보
    const getDetail=()=>{
        // console.log('idx',idx)
        const rs = getvideoByIdx(idx);
        rs.then(({ data : {success, message, code, data} }) => {
            if(data != null) {
                setVideoIdx(data.idx);
                setTitle(data.title);
                setLangCode(data.langCode);
                setThumbnailPath(data.thumbnailPath);
                setOpenFlag(data.openFlag);
                setContent(data.content);
                setStatus(data.openFlag);
                setCategory(Number(data.categoryIdx));
                setVideoPath(data.videoPath);
                setNum(data.num);
                setDownloadLink(data.downloadLink);
            } else {
                // swal('잘못 된 접근입니다.\r\n다시 해주세요!').then(()=>{history.push(`/noticeManagement/${page}`)});
            }
        })
        .catch(({response : data})=>{
            // console.log('catch :: ', data)
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        getDetail();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }
    
    //null체크
    const nullCheck =()=>{
        handleClose_upload();
        if(!title) return swal("알림", '영상 제목을 입력하지 않았습니다.').then(() =>  titleFocus.current.focus())
        //if(!content) return swal("알림", '영상 내용을 입력하지 않았습니다.').then(() =>  contentFocus.current.focus())

        // if(!title){
            
        //     swal({
        //         title: "알림",
        //         text: '영상 제목을 입력하지 않았습니다.'
        //     }).then((isConfirm) => {
        //         if(isConfirm) {
        //             return false;
        //         }
        //     })
        // }contentFocus
    //    else if(!content){
    //         handleClose_upload();
    //         swal({
    //             title: "알림",
    //             text: '영상 내용을 입력하지 않았습니다.'
    //         }).then((isConfirm) => {
    //             if(isConfirm) {
    //                 return false;
    //             }
    //         })
    //     } else 
        if(file.type != undefined){
            handleUpload()
        }else{
            thumbnailImpFillupload({})
        }
    }
    

    //썸네일 이미지 업로드
    const thumbnailImpFillupload = (obj) =>{
        handleClose_upload();
        let inputImg = addImgs.filter(item => removeImgs.filter(i => item.upload.uuid == i.upload.uuid).length == 0);
        var formData = new FormData;
        // console.log(inputImg.length)
        if(inputImg.length > 0) {
            for(let i = 0; i < inputImg.length; i++) {
                formData.append('files', inputImg[i]);   
            }
        
        formData.append('path', 'videothumbnail')
        const rs = UploadVideoimg(formData);
        rs.then(({data}) => {
            let dataArray = new Array();
            for(let key in data) {
                let realName = data[key].realName;
                let filePath = data[key].filePath;
                let pushData = filePath + realName;
                dataArray.push(pushData)
            }
            let paths = dataArray.join();
            onSubmit(paths, obj);
        });
    }else{
        let paths ='';
            onSubmit(paths, obj);
    }
    }
    //썸네일 미리보기
    const galleryDel = (path) => {
        let arr = thumbnailPath.split(',').filter(v => { return  v !== path });
        setThumbnailPath(arr.join(','))
    }

    //수정 및 삭제 
    const onSubmit = (paths, obj) => {
        let videoObj = {
            title : title,
            content : content,
            langCode : langCode ?langCode  :'en',
            thumbnailPath : paths != '' ? paths :  thumbnailPath.length ? thumbnailPath :  '',
            openFlag : status,
            categoryIdx : category,
            playtime : 0,
            mediaType : obj.fileType,
            videoPath : obj.key,
            uploadedVideos :  JSON.parse(JSON.stringify(obj)),
            num : num !== '' ? num : 0,
            downloadLink : downloadLink
        } 
        if(isEmpty(obj)) {
            videoObj = {
                title : title,
                content : content,
                langCode : langCode ?langCode  :'en',
                thumbnailPath : paths != '' ? paths :  thumbnailPath.length ? thumbnailPath :  '',
                openFlag : status,
                categoryIdx : category,
                // playtime : 0,
                // mediaType : obj.fileType,
                videoPath : videoPath,
                num : num !== '' ? num : 0,
                downloadLink : downloadLink
                // uploadedVideos :  JSON.parse(JSON.stringify(obj)),
            } 
        } else {
            videoObj = {
                title : title,
                content : content,
                langCode : langCode ?langCode  :'en',
                thumbnailPath : paths != '' ? paths :  thumbnailPath.length ? thumbnailPath :  '',
                openFlag : status,
                categoryIdx : category,
                playtime : 0,
                mediaType : obj.fileType,
                videoPath : obj.key,
                uploadedVideos :  JSON.parse(JSON.stringify(obj)),
                num : num !== '' ? num : 0,
                downloadLink : downloadLink
            } 
        }
        // console.log(removeImgs)
        

        // console.log(videoIdx)
        if(videoIdx != '') {
            const rs = updateVideo(videoIdx ,videoObj);
            rs.then(({ data : {success, message, code, data} }) => {
                // TODO :: 여기
                let isSwal = false;
                if(!isEmpty(obj)) {
                    var convertParams = {
                        path : obj.key,
                        bucketRegion : region,
                        albumBucketName : bucket,
                        IdentityPoolId : identityPoolId,
                    }
        
                    const convertRs = mediaConvert(convertParams);
                    convertRs.then((convertRes) => {
                        setIsLoading(false);
                        // console.log('convertRes :: ', convertRes)
                        isSwal = false;
                        swal({
                            title: "알림",
                            text: "정상적으로 수정 되었습니다."
                        }).then((isConfirm) => {
                            if(isConfirm) {
                                history.push(`/videoManagement/1/`+category);
                                return false;
                            }
                        })
                    })
                } else {
                    isSwal = true;
                }

                if(isSwal) {
                    if(data !== null) {
                        swal({
                            title: "알림",
                            text: "정상적으로 수정 되었습니다."
                        }).then((isConfirm) => {
                            if(isConfirm) {
                                history.push(`/videoManagement/1/`+category);
                            }
                        })
                    } else {
                        swal({
                            title: "알림",
                            text: message
                        }).then((isConfirm) => {
                            if(isConfirm) {
                                return false;
                            }
                        })
                    }
                }
            })
            .catch(({response : data})=>{
                // console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit(paths != '' ? paths : thumbnailPath);
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        } else {
            const rs = createVideo(videoObj);
            rs.then(({ data : {success, message, code, data} }) => {
                let isSwal = false;
                if(!isEmpty(obj)) {
                    var convertParams = {
                        path : obj.key,
                        bucketRegion : region,
                        albumBucketName : bucket,
                        IdentityPoolId : identityPoolId,
                    }
        
                    const convertRs = mediaConvert(convertParams);
                    convertRs.then((convertRes) => {
                        isSwal = false;
                        swal({
                            title: "알림",
                            text: "정상적으로 등록 되었습니다."
                        }).then((isConfirm) => {
                            if(isConfirm) {
                                history.push('/videoManagement/1/'+category);
                                return false;
                            }
                        })
                    })
                } else {
                    isSwal = true;
                }

                if(isSwal) {
                    if(data !== null) {
                        swal({
                            title: "알림",
                            text: "정상적으로 등록 되었습니다."
                        }).then((isConfirm) => {
                            if(isConfirm) {
                                history.push('/videoManagement/1/'+category);
                            }
                        })
                    } else {
                        swal({
                            title: "알림",
                            text: message
                        }).then((isConfirm) => {
                            if(isConfirm) {
                                return false;
                            }
                        })
                    }
                }
            })
            .catch(({response : data})=>{
                // console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit(thumbnailPath);
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        }
    }
    //삭제
    const deletevideo = ()=>{
        handleClose_delete(false);
        const rs = deleteOnvideo(videoIdx);
        rs.then(({data : {success,message,code,data} })=>{
            if(success=true && message ==='success') {
                swal({
                    title :"알림",
                    text : "정상적으로 삭제 되었습니다."
                }).then((isConfirm)=>{
                    if(isConfirm){
                        history.push(`/videoManagement/${page}/`+beforeCategory);
                    }
                })
            }else{
                swal({
                    title: "알림",
                    text : message
                }).then((isConfirm)=>{
                    if(isConfirm){
                        return false;
                    }
                })
            }
        }).catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        deletevideo();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    const sendPush = () => {
        handleClose_push(false);
        let params = {
            idx: idx,
            linkType: "VIDEO",
            langCode: langCode,
        }
        const rs = requestPush(params);
        rs.then(({ data : { success, message }}) => {
            if ( success == true ) {
                swal({
                    title: '알림',
                    text: '푸쉬알림이 정상적으로 발송되었습니다.'
                })
            }
            else {
                swal({
                    title: '알림',
                    text: message
                })
            }
        })
    }

    useMemo(()=>{
        readCategoryList();
        getDetail();         
    },[])
    return (
        <>
            <Container fluid className="UploadVideoContainer">

                {/* 버튼영역 */}
                <Row className="mb-4 no-gutters">
                    <Col className="d-flex justify-content-between align-items-center">
                        <div>
                        <Button variant="outline-primary mr-2" onClick={()=>history.push(`/videoManagement/${page}/`+(beforeCategory !== '' ? beforeCategory : category))} >목록으로</Button>
                        {videoIdx !== '' && <Button onClick={handleShow_push}>푸쉬발송</Button>}
                        </div>
                        {/* 정보입력시 활성화 ↑ */}
                        <div>
                        {/* 정보입력시 활성화 ↑ */}
                        {videoIdx !=='' &&
                        <Button  variant="outline-danger mr-2" onClick={handleShow_delete}>삭제</Button>
                        }
                        {/* <Button variant="dark" disabled>업로드</Button> */}
                        <Button  variant="primary" onClick={handleShow_upload} >{videoIdx !== '' ? '수정' : '업로드'}</Button>
                        </div>
                    </Col>
                </Row>

                {/*모달 푸쉬 */}
                <Modal show={push} onHide={handleClose_push} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>푸쉬알림을 발송하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_push}>취소</Button>
                        <Button variant="primary" onClick={sendPush}>확인</Button>
                    </Modal.Footer>
                </Modal>

                {/* 모달 (업로드) */}
                <Modal show={show} onHide={handleClose_upload} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>{videoIdx != '' ? '수정' : '업로드'} 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_upload}>취소</Button>
                        <Button variant="primary" id='upload'onClick={nullCheck}>확인</Button>
                        {/* {file.type != undefined ?
                            <Button variant="primary" id='upload'onClick={handleUpload}>확인</Button>    
                        :
                            <Button variant="primary" id='upload'onClick={() => thumbnailImpFillupload({})}>확인</Button>
                        } */}
                        
                    </Modal.Footer>
                </Modal>
                {/*모달 삭제 */}
                <Modal show={deleteShow} onHide={handleClose_delete} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_delete}>취소</Button>
                        <Button variant="primary" onClick={deletevideo}>확인</Button>
                    </Modal.Footer>
                </Modal>

                {/* 정보 입력영역 */}
                <Row className="mb-4">
                    <Col>
                        <Card>
                            <Card.Header className="cardTitle text-primary">동영상 정보</Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable">
                                    <Tbody>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>언어선택</Td>
                                            <Td><LanguageTabs
                                                    onLanSelected={onChangeLanguage}
                                                    langCode={langCode}
                                                />  </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>카테고리</Td>
                                            <Td>
                                                <FormControl component="fieldset" style={{'display':'flex'}}>
                                                    <RadioGroup
                                                        aria-label="Category"
                                                        name="category"
                                                        //defaultValue={category}
                                                        value={Number(category)}
                                                        onChange={(e)=>onChangeCategory(e)}
                                                        row
                                                    >
                                                    {categoryList.map((v,i)=>{
                                                        return(
                                                            <FormControlLabel
                                                            control={
                                                                <Radio 
                                                                    classes={{
                                                                        root: classes.radio,
                                                                        checked: classes.checked
                                                                    }}
                                                                />
                                                            }
                                                            value={v.idx}
                                                            label={v.titleKo}
                                                            //onClick={(e)=>onChangeRadio(e.target.value)}
                                                        />
                                                        )

                                                    })}
                                            
                                                    </RadioGroup>
                                                </FormControl>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>파일선택</Td>
                                            <Td>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <form encType="multipart/form-data">
                                                        <input type='file' key='video'accept="video/*" className='inputFiles' id='file'  hidden onChange={handleChange} ref={fileRef} ></input>
                                                    </form>
                                                    <Button variant="outline-primary" className="col-2 mr-2" onClick={() => window.document.getElementById('file').click()}>파일선택</Button>
                                                    <Form.Control type="text" placeholder="*권장 해상도 및 화질 [16:9 1080p]" disabled value={videoPath ? videoPath : fileName} />
                                                </div>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                        <Td colspan="2">
                                                <Row className="no-gutters">
                                                    <Col className="d-flex justify-content-between align-items-center mr-3 no-gutters">
                                                        <label className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>영상 제목</label>
                                                        <Form.Control type="text" placeholder="동영상의 제목을 입력해 주세요.(최대 55자)"  value={title} ref={titleFocus&&titleFocus} onChange={(e)=>onChangeTitle(e)} />
                                                    </Col>
                                                    <Col className="d-flex justify-content-between align-items-center ml-3 no-gutters">
                                                        <label className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>다운로드 URL</label>
                                                        <Form.Control type="text" placeholder="해당 동영상이 다운로드가 가능한 링크(URL)를 입력해 주세요."  value={downloadLink}  onChange={(e)=>onChangeDownloadLink(e)} />
                                                    </Col>
                                                </Row>
                                         </Td>       
                                        </Tr>
                                        {/* <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>영상 제목</Td>
                                            <Td>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <Form.Control type="text" placeholder="동영상의 제목을 입력해 주세요.(최대 55자)"  value={title} ref={titleFocus&&titleFocus} onChange={(e)=>onChangeTitle(e)} />
                                                </div>
                                            </Td>
                                        </Tr> */}
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>순서</Td>
                                            <Td>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <Form.Control type="number" placeholder="순서를 입력하세요." ref={numFocus&&numFocus}  value={num} onChange={(e)=>onChangeNum(e)} />
                                                </div>
                                            </Td>
                                        </Tr>
                                        <Tr style={{display:'none'}}>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>영상 내용</Td>
                                            <Td>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <Form.Control type="text" placeholder="동영상의 내용을 입력해 주세요." value={content} ref={contentFocus&&contentFocus} onChange={(e)=>onChangeContent(e)} />
                                                </div>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            {/* <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>미리보기</Td> */}
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>{videoIdx !== '' ? '교체이미지' : isImg ? '미리보기':'사진등록'}</Td>            
                                            <Td>
                                                    <CustomDropZoneCom 
                                                    onUpdate={updateOption}
                                                    onDelete={deleteOption}
                                                    acceptedFiles={'image/*'}
                                                    iconFiletypes={['.jpg', '.png', '.gif', '.jpeg']}
                                                    maxFiles={1}
                                                    fileLimit={1}
                                                    title={'SIZE 400 x 225'}
                                                    description={'사진파일을 끌어서 여기에 놓거나<br />여기를 클릭하여 사진파일을 업로드합니다'}
                                                />
                                                    {/* 
                                                    <Col className="px-2">
                                                        <UploadImage
                                                            title="SIZE 345 X 200"
                                                            description="사진파일을 끌어서 여기에 놓거나<br />여기를 클릭하여 사진파일을 업로드합니다."
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col className="px-2">
                                                        <UploadImage
                                                            title="SIZE 345 X 200"
                                                            description="사진파일을 끌어서 여기에 놓거나<br />여기를 클릭하여 사진파일을 업로드합니다."
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col className="px-2">
                                                        <UploadImage
                                                            title="SIZE 345 X 200"
                                                            description="사진파일을 끌어서 여기에 놓거나<br />여기를 클릭하여 사진파일을 업로드합니다."
                                                            disabled
                                                        />
                                                    </Col>
                                                    */}
                                               
                                            </Td>
                                        </Tr>
                                        {thumbnailPath != '' && thumbnailPath.split(',').length > 0 &&
                                                        <Tr>
                                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100, fintSize: '13px'}}>기존이미지</Td>
                                                            <Td>
                                                                <div class="modifyCustomDropzone modifyImgContainer">
                                                                <div class="gallery-grid">
                                                                    {thumbnailPath.split(',').map((v, i) => {
                                                                        return(
                                                                            <div class="gallery-frame">
                                                                                {/* <img class="gallery-img" alt={v} src={'/fileUpload/' + v} />   */}
                                                                                <img class="gallery-img" alt={v} src={'/fileUpload/' + v} />  
                                                                                <button onClick={() => galleryDel(v)}><DeleteIcon /></button>
                                                                            </div>  
                                                                        )
                                                                    })}
                                                                    </div>
                                                                </div>
                                                            </Td>
                                                        </Tr>
                                        }
                                    </Tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-4 openCheck">
                    <Col>
                        <Card className="d-flex flex-row align-items-center p-4">
                            <Col className="d-flex col-9 justify-content-start align-items-center">
                                <h5 className="cardTitle text-primary">공개여부</h5>
                                <p className="m-0 ml-4 text-subgray">동영상 업로드시 공개여부를 선택해주세요. 공개 선택시 모바일에서 바로 영상 감상이 가능합니다.</p>
                            </Col>
                            <Col className="col-3">
                                <FormControl component="fieldset" style={{'display':'flex'}}>
                                    <RadioGroup
                                        aria-label="Status"
                                        name="status"
                                        value={String(status)}
                                        onChange={(e)=>onChangeStatus(e)}
                                        row
                                        className="statusRadio justify-content-end"
                                    >
                                        <FormControlLabel
                                            control={
                                                <Radio 
                                                    classes={{
                                                        root: classes.radio,
                                                        checked: classes.checked
                                                    }}
                                                />
                                            }
                                            value= 'false'
                                            label="비공개"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Radio 
                                                    classes={{
                                                        root: classes.radio,
                                                        checked: classes.checked
                                                    }}
                                                />
                                            }
                                            value='true'
                                            label="공개"
                                            className="ml-2"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Col>
                        </Card>
                    </Col>
                </Row>

            </Container >
            <LayoutSplashScreen visible={isLoading}/>
            {/* <CustonSpinner text={'업로드가 진행 중 입니다.'} /> */}
        </>
    );
};

export default UploadVideo;
