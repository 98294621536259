import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Registration from "../../../views/auth/Registration/index";
import ForgotPassword from "../../../views/auth/ForgotPassword/index";
import Login from "../../../views/auth/Login/index";
import eaglesLogo from '../../../assets/img/eaglesLogo-big.png';

export default function AuthPage() {
  return (
    <>
        <div className="kt-grid kt-grid--ver kt-grid--root" style={{ backgroundColor: '#fff' }}>
            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
                <div className="loginView">
                    <div className="flex-column justify-content-center">
                        <img src={eaglesLogo} className="loginLogo" />
                        <h5 className="loginLogoText">'이글스시스템 : 관리자페이지' 입니다.<br />로그인 후 이용해주세요.</h5>
                    </div>
                    <div>
                        <Switch>
                            <Redirect from="/auth" exact={true} to="/auth/login" />
                            <Route path="/auth/login" component={Login} />
                            {/* <Route path="/auth/registration" component={Registration} />
                            <Route
                                path="/auth/forgot-password"
                                component={ForgotPassword}
                            /> */}
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}
