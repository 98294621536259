import Checkbox from '@material-ui/core/Checkbox';
import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import CustomPagination from '../../../common/components/CustomPagination'

const TabList = ({checkList, bannerList, searchParams, history, bannerPage, page, allCheck, categoryIdx, movePage, handleShow_private, handleShow_public, handleShow_delete, onCheck}) => {

    const handleClick = (v, e) => {
        if(e.target.type === 'checkbox') return false;
        const params = {
            idx: v.idx,
            langCode: v.langCode,
            title: v.title,
            bannerPicPath: v.bannerPicPath,
            openFlag: v.openFlag,
            bannerLink: v.bannerLink,
            categoryIdx: v.categoryIdx
        }
        const url = {
          pathname : `/bannerManagement_upload/${page}/${v.idx}`,
          state : params,
        }
        history.push(url);
    }

    const onClickBannerUpload = () => {
        const params = {      
            categoryIdx : categoryIdx
        }
        const url = {
          pathname : `/bannerManagement_upload/1/0`,
          state : params,
        }
        history.push(url);
    }

    return (
        <>
        <Card className = "tabBodyCard">
            <Card.Header className = "d-flex justify-content-between align-items-center">
                <h4 className="cardTitleBig">{'배너'} &nbsp;&nbsp;<span style={{fontSize : '14px', fontWeight: 400}}>[ 전체 : {bannerPage.totalElements} ]</span></h4>
                <Button onClick = {onClickBannerUpload} variant="primary">배너 업로드</Button>
            </Card.Header>
            <Card.Body className="d-flex no-gutters p-0">
                <Table className = "commonTable borderTable bb-1">
                    <Thead>
                        <Tr>
                            <Th style = {{width: 70}}>
                                <Checkbox checked = {
                                    checkList.length === bannerPage.numberOfElements && bannerPage.numberOfElements > 0
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>allCheck(e.target.checked)}
                                />
                            </Th>
                            <Th style={{width:80}}>번호</Th>
                            <Th style={{width:120}}>언어</Th>
                            <Th>제목</Th>
                            <Th style={{width:120}}>작성자</Th>
                            <Th style={{width:120}}>공개상태</Th>
                            <Th style={{width:160}}>등록(수정)일</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {bannerList.length > 0 ?
                            bannerList.map((v, i) => {
                                return (
                                    <Tr onClick = {(e) => handleClick(v, e)}>
                                        <Td>
                                            <Checkbox
                                                checked={checkList.includes(v.idx) ? true : false}
                                                onChange={(e)=> onCheck(e.target.checked,v.idx)}
                                            />
                                        </Td>
                                        <Td>{v.idx}</Td>
                                        <Td>{v.langCodeName}</Td>
                                        <Td className="text-left">
                                            <div class="thumbWrap">
                                                {v.bannerPicPath ?
                                                <div className="thumbImgBox">
                                                    <img src={'/fileUpload/'+v.bannerPicPath} className="thumbImg" />
                                                </div>
                                                :
                                                <div className="thumbImgBox">
                                                    <img src={'/media/empty/default-video.jpg'} className="thumbImg" />
                                                </div>
                                                 }
                                                <h6 className="thumbText">{v.title}</h6>
                                            </div>
                                        </Td>
                                        <Td>{v.author}</Td>
                                        {v.openFlag
                                        ?<Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                        :<Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비공개</div></Td>
                                        }
                                        <Td>{v.updateAt}</Td>
                                    </Tr>
                                )
                            }) :
                            <Tr >
                                <Td colSpan="7">관련 게시물이 없습니다</Td>
                            </Tr>       
                        }
                    </Tbody>
                </Table>
            </Card.Body>
            
            <Card.Footer className="d-flex flex-row justify-content-between align-items-center paginationFooter">
                <div>
                    <CustomPagination
                        itemsCount={10}
                        pageData={bannerPage}
                        page={page}
                        selectctgidx={categoryIdx}
                        onClickEvent={movePage}
                    >
                    </CustomPagination>
                </div>
                <div>
                    <Button variant="outline-gray" onClick={handleShow_private}>비공개 전환</Button>
                    <Button variant="outline-success ml-2" onClick={handleShow_public}>공개 전환</Button>
                    <Button variant="outline-danger ml-2" onClick={handleShow_delete}>배너 삭제</Button>
                </div>
            </Card.Footer>
        </Card>
        </>
    )
}

export default TabList;