import axios from "axios";

export const ADMIN_CATEGORY ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/common/category';

export const ADMIN_BANNER ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/banner';

export const ADMIN_OPENFLAG = '/api/'+process.env.REACT_APP_API_VERSION+'/admin/updateBannerOpenFlag'

export const ADMIN_BANNER_DELETE ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/banners';

export function getCategoryList(params){
    return axios.get(ADMIN_CATEGORY,{params});
}

export function getBannerList(params){
    return axios.get(ADMIN_BANNER,{params});
}

export function deleteBanner(CheckList){
    return axios.delete(ADMIN_BANNER_DELETE,{data :{idxs : CheckList}});
}

export function updataOpenFlag(CheckList,openFlag){
    let opflag = false;
    if(openFlag == 'true'){
        opflag = true;
    }else{
        opflag = false;
    }
    console.log('vfvfv', CheckList)
    return axios.put(ADMIN_OPENFLAG,{idxs: CheckList ,openFlag : opflag})
}
