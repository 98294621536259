import React, { useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Container, Col, Button, Tabs, Tab, Modal } from "react-bootstrap";
import TabList from './TabList';
import CustomSearchCardv2 from "../../../common/components/CustomSearchCardv2"
import swal from 'sweetalert';
import { refresh } from '../../auth/Login/crud';
import { getCategoryList, getBannerList, updataOpenFlag, deleteBanner } from "./crud";
import { useDispatch } from 'react-redux';
import * as authAction from "../../../app/store/ducks/auth.duck";
import {makeNotEmptyObj} from '../../../../src/_metronic/utils/utils'

const BannerManagement = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { page, categoryIdx } = useParams();

    const sortColumnItems = [
        {
            text : '등록일순',
            value : 'createAt',
        },
    ];

    const searchItems = [
        {
            text : '제목',
            value : 'title',
        },
    ];

    const [Page,setPage] =useState(page)
    const [sortColumn,setSortColumn] = useState((history.location.state && history.location.state.sortColumn) || 'createAt');
    const [selectCtgIdx,setSelectCtgIdx] =useState(categoryIdx)
    const [searchType, setSearchType] = useState((history.location.state && history.location.state.searchType) || 'title');
    const [search, setSearch] = useState((history.location.state && history.location.state.search) || '');
    const [categoryList, setCategoryList] = useState([{
        idx:[],
        titleKo:[],
        thumbnailPath:[],
        useFlag:[],
        num:[],
        type:[]
    }])
    
    const [langCode ,setLangCode] =useState((history.location.state && history.location.state.langCode) || '');
    const [checkList,setCheckList] = useState([]);
    const [bannerList, setBannerList] = useState([{
        idx:[],
        langCode:[],
        title:[],
        bannerPicPath:[],
        openFlag:[],
        bannerLink:[],
        author:[],
        createAt:[],
        updateAt:[],
        categoryIdx:[],
        langCodeName:[]
    }])
    const [bannerPage, setBannerPage] = useState([]);
    // 모달 (비공개 전환)
    const [show_private, setShow_private] = useState(false);
    const handleClose_private = () => setShow_private(false);
    const handleShow_private = () => {if(checkList.length<1){return swal('알림','선택하신 배너가 없습니다.')}else{setShow_private(true)}};
    // 모달 (공개 전환)
    const [show_public, setShow_public] = useState(false);
    const handleClose_public = () => setShow_public(false);
    const handleShow_public = () => {if(checkList.length<1){return swal('알림','선택하신 배너가 없습니다.')}else{setShow_public(true)}};

    // 모달 (메세지 삭제)
    const [show_delete, setShow_delete] = useState(false);
    const handleClose_delete = () => setShow_delete(false);
    const handleShow_delete = () => {if(checkList.length<1){return swal('알림','선택하신 배너가 없습니다.')}else{setShow_delete(true)}};

    const onChangeSortColumn = (e)=> setSortColumn(e.target.value);
    const onChangeSearch = (e) => setSearch(e.target.value);
    const onChangeSearchType = (e) => setSearchType(e.target.value);
    const onSearch = (e) => {
        e.preventDefault();
        movePage(1, selectCtgIdx);
    }

    const movePage = (page,ctgIdx,category) => {  
        const params = {      
            langCode,
            searchType,
            search,
            sortColumn,
        }
        const params2 = {

        }
        
        history.push({
            pathname : "/bannerManagement/"+page+"/"+ctgIdx,
            state : category ? params2 : params
        });
        readBannerList(page, ctgIdx);
        setCheckList([]);
    }

    const onChangeCategory = (e) => {
        const category = true
        setSelectCtgIdx(e);
        movePage(1, e, category);
    }

    //카테고리 리스트
    const readCategoryList = ()=>{
        let params = {
            sortType : "ASC",
            sortColumn : "num",
            contentType : 'VIDEO'
        }
      const rs = getCategoryList(params);
        rs.then(({data :{content}})=>{
            setCategoryList(content);
            // console.log('content[0].idx',content[0].idx)
            if(categoryIdx ==='0'){
                movePage(1,content[0].idx)
            }
            //setfirstcategoryIdx(content[0].idx)
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        readCategoryList();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    //배너 리스트 
    const readBannerList=(page,selectCtgIdx)=>{
        let params = {
            page : page,
            size : 25,
            categoryIdx: selectCtgIdx
        }
        const chkParams = {
            langCode : langCode,      
            sortColumn : sortColumn,
        }
        if(searchType == 'title') { chkParams.title = search; }
        params = makeNotEmptyObj(params,chkParams);
        const rs = getBannerList(params);
        rs.then(({data : {content,totalElements,totalPages,last,first,numberOfElements}})=>{
            setBannerList(content);
            const pageObj = {
                totalElements : totalElements,
                totalPages : totalPages,
                last : last,
                first : first,
                numberOfElements : numberOfElements
            }
            setBannerPage(pageObj);
        }).catch(({response : data})=>{
            if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;

                        localStorage.setItem('persist:auth', JSON.stringify(auth));

                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        movePage(page,selectCtgIdx);//수정해야함
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                }).catch(({response : data})=>{
                    if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                });
            }
        });;
    }

    //체크 박스 전체 선택 유무
    const allCheck = (checked) =>{
        if(checked){
            const chkBox = [];
            bannerList.map((v,i)=>{
            chkBox.push(v.idx);
            setCheckList(chkBox);
            })
        }else if(!checked){
            setCheckList([]);
        }
    }

    // 체크 박스 단일 선택
    const onCheck = (checked, chkIdx) =>{
        if(checked){
            setCheckList([...checkList, chkIdx]);
        }else if(!checked){
            setCheckList(checkList.filter(idx => idx !== chkIdx));
        }
    }

    useMemo(()=>{
        readCategoryList();
        movePage(page, selectCtgIdx);
    },[])

    const updateOpenFlag =(e)=>{
        const rs = updataOpenFlag(checkList,e);
        rs.then(({ data : {success, message, code, data} }) => {
            if(success === true && message === 'success') {
                swal({
                    title: "알림",
                    text: "정상적으로 수정 되었습니다."
                }).then((isConfirm) => {
                    if(isConfirm) {
                        movePage(Page, categoryIdx);
                    }
                })
            } else {
                swal({
                    title: "알림",
                    text: message
                }).then((isConfirm) => {
                    if(isConfirm) {
                        return false;
                    }
                })
            }
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        updateOpenFlag(e);
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
        if(e=='true'){
            setShow_public(false)
        }else{
            setShow_private(false)
        }
        setCheckList([]);
    }

    //배너 삭제
    const bannerDelete =()=>{
        setShow_delete(false);
        const rs = deleteBanner(checkList);
        rs.then(({ data : {success, message, code, data} }) => {
            if(success === true && message === 'success') {
                swal({
                    title: "알림",
                    text: "정상적으로 삭제 되었습니다."
                }).then((isConfirm) => {
                    if(isConfirm) {
                        if(bannerPage.numberOfElements === checkList.length&& Page>1){
                            // history.push(`/noticeManagement/${page-1}`);
                            movePage(Page-1,categoryIdx);
                         }else{
                             movePage(Page,categoryIdx);
                         }
                    }
                })
            } else {
                swal({
                    title: "알림",
                    text: message
                }).then((isConfirm) => {
                    if(isConfirm) {
                        return false;
                    }
                })
            }
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        bannerDelete();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    return (
        <>
            <Container fluid className = "BannerContainer">
                <CustomSearchCardv2 
                    isSortColumn = {true}
                    onChangeSortColumn = {onChangeSortColumn}
                    sortColumnItems = {sortColumnItems}
                    sortColumn = {sortColumn}

                    isSearch = {true}
                    onChangeSearch = {onChangeSearch}
                    onSearchEvent = {onSearch}
                    searchItems = {searchItems}
                    onChangeSearchType = {onChangeSearchType}
                    search={search}
                />
                <Row>
                    <Col>
                        <Tabs defaultActiveKey = {categoryIdx} ActiveKey = {selectCtgIdx} transition = {false} className = "upTabs m-0" onSelect = {(e) => onChangeCategory(e)}>
                            {categoryList.map((item, index) => {
                                return (
                                    <Tab eventKey = {item.idx} title = {item.titleKo}>
                                        <TabList
                                            history = {history}
                                            checkList = {checkList}
                                            bannerList = {bannerList}
                                            bannerPage = {bannerPage}
                                            page = {page}
                                            categoryIdx = {categoryIdx}
                                            movePage = {movePage}
                                            onCheck = {onCheck}
                                            allCheck ={allCheck}
                                            handleShow_private={handleShow_private}
                                            handleShow_public={handleShow_public}
                                            handleShow_delete={handleShow_delete}
                                        />
                                    </Tab>
                                )
                            })}

                        </Tabs>
                    </Col>
                </Row>
                
                {/* 모달 (비공개 전환) */}
                <Modal show={show_private} onHide={handleClose_private} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>비공개로 전환하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_private}>취소</Button>
                        <Button variant="primary" id='false' onClick={(e)=>updateOpenFlag(e.target.id)}>확인</Button>
                    </Modal.Footer>
                </Modal>

                {/* 모달 (공개 전환) */}
                <Modal show={show_public} onHide={handleClose_public} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>공개로 전환하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_public}>취소</Button>
                        <Button variant="success" id='true' onClick={(e)=>updateOpenFlag(e.target.id)}>확인</Button>
                    </Modal.Footer>
                </Modal>
                
                {/* 모달 (동영상 삭제) */}
                <Modal show={show_delete} onHide={handleClose_delete} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_delete}>취소</Button>
                        <Button variant="danger" onClick={bannerDelete}>확인</Button>
                    </Modal.Footer>
                </Modal>
                
            </Container>
        </>
    );
};

export default BannerManagement;