import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col, InputGroup, FormControl, Card, Badge, Button, Pagination } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";

import Checkbox from '@material-ui/core/Checkbox';
import SearchCard from "../../../common/components/SearchCard"

// import swal from 'sweetalert';
// import * as authAction from "../../../app/store/ducks/auth.duck";
// import { reissueToken, currency } from '../../../common/up_utils';
// import { TOKEN_EXPIRED_CODE } from '../../../common/dickey';
// import Pagination from '../../../common/components/Pagination';
// import { getUsers } from './crud';

// pagination
let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>,
    );
}

const PremiumManagement = () => {
    const history = useHistory();

    return (
        <>
            <Container fluid className="premiumContainer">
                
                {/* 검색영역 */}
                <SearchCard noLanguage />

                {/* 프리미엄회원 리스트 */}
                <Row>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-start align-items-center">
                                <h4 className="cardTitleBig">프리미엄회원 리스트</h4>
                            </Card.Header>
                            <Card.Body className="d-flex no-gutters p-0">
                                <Table className="commonTable borderTable bb-1">
                                        <Thead>
                                            <Tr>
                                                <Th style={{width:50}}>
                                                    <Checkbox />
                                                </Th>
                                                <Th style={{width:80}}>번호</Th>
                                                <Th style={{width:150}}>국가</Th>
                                                <Th>걔정명</Th>
                                                <Th style={{width:100}}>상품명</Th>
                                                <Th style={{width:140}}>결제금액</Th>
                                                <Th style={{width:240}}>유효기간</Th>
                                                <Th style={{width:140}}>상태</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {/*
                                            <Tr>
                                                <Td><Checkbox /></Td>
                                                <Td>[번호 DATA]</Td>
                                                <Td className="ellipsis">[국가 DATA]</Td>
                                                <Td className="text-left ellipsis">[계정 DATA]</Td>
                                                <Td>[상품명 DATA]</Td>
                                                <Td>[결제금액 단위 수치 DATA]</Td>
                                                <Td>[유효기간 시작날짜 ~ 종료날짜 DATA]</Td>
                                                { 상태 == 사용중 ? badge-success, 사용중
                                                    <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">사용중</div></Td>
                                                : 기간만료 badge-danger, 기간만료
                                                    <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-danger">기간만료</div></Td>
                                                }
                                            </Tr>
                                            */}
                                            <Tr>
                                                <Td><Checkbox /></Td>
                                                <Td>10</Td>
                                                <Td className="ellipsis">SOUTH KOREA</Td>
                                                <Td className="text-left ellipsis">selee@upchain.kr</Td>
                                                <Td>6개월</Td>
                                                <Td>KRW 20,000</Td>
                                                <Td>2020.10.10 ~ 2021.04.09</Td>
                                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">사용중</div></Td>
                                            </Tr>
                                            <Tr>
                                                <Td><Checkbox /></Td>
                                                <Td>10</Td>
                                                <Td className="ellipsis">INDONESIA</Td>
                                                <Td className="text-left ellipsis">selee@upchain.kr</Td>
                                                <Td>12개월</Td>
                                                <Td>USD 9.69</Td>
                                                <Td>2019.10.10 ~ 2021.10.09</Td>
                                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-danger">기간만료</div></Td>
                                            </Tr>
                                            <Tr>
                                                <Td><Checkbox /></Td>
                                                <Td>10</Td>
                                                <Td className="ellipsis">SOUTH KOREA</Td>
                                                <Td className="text-left ellipsis">selee@upchain.kr</Td>
                                                <Td>6개월</Td>
                                                <Td>KRW 20,000</Td>
                                                <Td>2020.10.10 ~ 2021.04.09</Td>
                                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">사용중</div></Td>
                                            </Tr>
                                            <Tr>
                                                <Td><Checkbox /></Td>
                                                <Td>10</Td>
                                                <Td className="ellipsis">SOUTH KOREA</Td>
                                                <Td className="text-left ellipsis">selee@upchain.kr</Td>
                                                <Td>6개월</Td>
                                                <Td>KRW 20,000</Td>
                                                <Td>2020.10.10 ~ 2021.04.09</Td>
                                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">사용중</div></Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                            </Card.Body>
                            <Card.Footer className="d-flex flex-row justify-content-start align-items-center paginationFooter">
                                <div>
                                    <Pagination>{items}</Pagination>
                                </div>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>

            </Container >
        </>
    );
};

export default PremiumManagement;
