import React, { useEffect, useState,useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col, InputGroup, FormControl, Card, Badge, Button, Tabs, Tab, Modal} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import LanguageTabs from "../../../common/components/LanguageTabs";
import {getMainTopScreen,uploadMainTopScreenFileupload,deleteMainTopScreen,createMainTopScreen,updateMainTopScreen,updateMainTopScreenOpenflag} from "./crud";



import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import swal from 'sweetalert'
// import { getUsers } from './crud';

const UploadmainTopScreen = () => {
    const {page,idx} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    // 내용이 채워졌을 때 업로드 활성화
    
    // 모달 (업로드)
    const [uploadShow, setShow_upload] = useState(false);
    const handleClose_upload = () => setShow_upload(false);
    const handleShow_upload = () => setShow_upload(true);
    //삭제
    const [deleteShow, setShow_delete] = useState(false);
    const handleClose_delete = () => setShow_delete(false);
    const handleShow_delete = () => setShow_delete(true);
    
     // 모달 (활성 비활성 전환)
     const [show_public, setShow_public] = useState(false);
     const handleClose_public = () => setShow_public(false);
     const handleShow_public = () => setShow_public(true);

    const [title , setTitle] = useState('');
    const [langCode , setLangCode] = useState('en'); // 
    const [mainTopScreenIdx ,setMainTopScreenIdx] = useState('');//
    const [picPath,setPicPath] = useState('');
    const [picPathFileName,setPicPathFileName] = useState('');
    const [picPathImg,setpicPathImg] = useState([]);
    const [openFlag,setOpenFlag] = useState();
    //onChange event
    const onChangeLanguage = (e) => {setLangCode(e)} 
    const onChangepicPathinputimg =(e)=>{setpicPathImg(e.target.files); setPicPathFileName(e.target.files[0].name); };
    const onChangetitle = (e) => {setTitle(e.target.value)}

    const picImgDelete= ()=>{
        setPicPath();
        setPicPathFileName('');
    }
    const getPicPathFileName =(e)=>{
        if(e){
        let fileName = e;
        let filenameArr = fileName.split('/');
        setPicPathFileName(filenameArr[2]);
        }
    }
    const deleteTopScreen = ()=>{
        handleClose_delete(false);
        const rs = deleteMainTopScreen(mainTopScreenIdx);
        rs.then(({data : {success,message,code,data} })=>{
            if(success=true && message ==='success') {
                swal({
                    title :"알림",
                    text : "정상적으로 삭제 되었습니다."
                }).then((isConfirm)=>{
                    if(isConfirm){
                        history.push(`/mainScreenManagement/${page}/banner`);
                    }
                })
            }else{
                swal({
                    title: "알림",
                    text : message
                }).then((isConfirm)=>{
                    if(isConfirm){
                        return false;
                    }
                })
            }
        }).catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        deleteTopScreen();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }
    //활성 비활성   
    const updateOpenFlag = ()=>{
        handleClose_public();
    let opflag = openFlag ? false : true
        const rs= updateMainTopScreenOpenflag(mainTopScreenIdx,opflag);
        rs.then(({data : {success,message,code,data} })=>{
            if(success=true && message ==='success') {
                swal({
                    title :"알림",
                    text : "정상적으로 "+(opflag?'활성화':'비활성화')+ "되었습니다."
                }).then((isConfirm)=>{
                    if(isConfirm){
                       return false;
                    }
                })
            }else{
                swal({
                    title: "알림",
                    text : message
                }).then((isConfirm)=>{
                    if(isConfirm){
                        return false;
                    }
                })
            }
        }).catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        updateOpenFlag();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
        setOpenFlag(opflag);
    }
    //디테일 
    const getDetail = () => {
        const rs = getMainTopScreen(idx);
      
        rs.then(({ data : {success, message, code, data} }) => {
            if(data != null) {
                setMainTopScreenIdx(data.idx);
                setTitle(data.title);
                setOpenFlag(data.openFlag);
                setLangCode(data.langCode);
                setPicPath(data.picPath)
                getPicPathFileName(data.picPath);
            } else {
                // swal('잘못 된 접근입니다.\r\n다시 해주세요!').then(()=>{history.push(`/mainScreenManagement/${page}/message`)});
            }
        })
        .catch(({response : data})=>{
            // console.log('catch :: ', data)
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        getDetail();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    //pic 파일 업로드
    const mainTopScreenFileUpload =()=>{
        let picPath = '';
        var formData = new FormData;
        if(picPathImg){
            formData.append('files',picPathImg[0]);
        
            formData.append('path','mainTopScreenIdx');
            const rs = uploadMainTopScreenFileupload(formData);
            rs.then(({data})=>{
                let dataArray =new Array();
                
                for(let key in data){ 
                    let realName = data[key].realName;
                    let filePath = data[key].filePath;
                    let pushData = filePath + realName;
                    dataArray.push(pushData);
                }
                let picPath = dataArray.join();
                onSubmit(picPath);
            })
        }else{
            onSubmit(picPath);
        }

        }
    
    const nullCheck = ()=>{
      handleClose_upload();
         if(!langCode){
           return swal({
                title: "알림",
                text: '언어를 선택 하지 않았습니다'
            })
        }
        if(!title){
            return swal({
                title: "알림",
                text: '제목을 작성하지 않았습니다'
            })
        }
        if(!picPathFileName){
            return swal({
                title: "알림",
                text: '프로필 사진을 선택 하지 않았습니다.'
            })
        }
        mainTopScreenFileUpload();
    }
  

    

    //수정 및 추가
    const onSubmit = (picPaths) => {
        let mainTopScreen = {
            //paths :  picPath.length ? picPath :  ''
            title : title,
            langCode : langCode ? langCode :'en' ,
            picPath : picPaths != '' ? picPaths : picPath 
        } 
        // console.log(mainTopScreenIdx)
        if(mainTopScreenIdx != '') {
            const rs = updateMainTopScreen(mainTopScreenIdx ,mainTopScreen);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== null) {
                    swal({
                        title: "알림",
                        text: "정상적으로 수정 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push(`/mainScreenManagement/${page}/banner`);
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                // console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit(picPath);
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        } else {
            const rs = createMainTopScreen(mainTopScreen);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== null) {
                    swal({
                        title: "알림",
                        text: "정상적으로 등록 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push('/mainScreenManagement/1/banner');
                  }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                // console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit(picPath);
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        }
    }
     //파일 업로드 버튼 클릭 이벤트
     const onClickImageUpload = (e)=>{
        window.document.getElementById('picfile').click();
    }
    useMemo(()=>{
        getDetail();
        
    },[])
    return (
        <>
            <Container fluid className="UploadMessageConatiner">

                {/* 버튼영역 */}
                <Row className="mb-4 no-gutters">
                    <Col className="d-flex justify-content-between align-items-center">
                        <Button variant="outline-primary" onClick={()=>history.push(`/mainScreenManagement/${page}/banner`)}>목록으로</Button>
                        {/* 정보입력시 활성화 ↑ */}
                        {/* <Button variant="dark" disabled>업로드</Button> */}
                        <div>
                        {mainTopScreenIdx !=='' &&
                        <>
                         {openFlag ?
                            <Button variant="outline-gray mr-2" onClick={handleShow_public}>비활성화</Button>
                        :
                            <Button variant="outline-success mr-2" onClick={handleShow_public} >활성화</Button> 
                        }
                        <Button variant="outline-danger mr-2" onClick={handleShow_delete}>삭제</Button>
                        </>
                        }
                        <Button variant="primary" onClick={handleShow_upload}>{mainTopScreenIdx !== '' ? '수정' : '업로드'}</Button>
                        </div>
                    </Col>
                </Row>

                {/* 모달 (업로드) */}
                <Modal show={uploadShow} onHide={handleClose_upload} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>{mainTopScreenIdx !== '' ? '수정' : '업로드'} 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_upload}>취소</Button>
                        <Button variant="primary" onClick={nullCheck}>확인</Button>
                    </Modal.Footer>
                </Modal>
                {/*모달 삭제 */}
                <Modal show={deleteShow} onHide={handleClose_delete} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_delete}>취소</Button>
                        <Button variant="primary" onClick={deleteTopScreen}>확인</Button>
                    </Modal.Footer>
                </Modal>
                {/* 정보 입력영역 */}
                <Row>
                    <Col>
                        <Card>
                            <Card.Header className="cardTitle text-primary">메인화면 상단사진</Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable">
                                    <Tbody>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>언어선택</Td>
                                            <Td><LanguageTabs 
                                            onLanSelected={onChangeLanguage}
                                            langCode={langCode}
                                            /></Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>제목</Td>
                                            <Td>
                                                <Form.Control type="text" placeholder="메인화면 상단사진 제목을 입력하세요." onChange={(e)=>onChangetitle(e)} value={title} />
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>사진등록</Td>
                                            <Td>
                                                <div className="d-flex justify-content-between align-items-center">
                                                <form encType="multipart/form-data">
                                                <input type='file' key='image'accept="image/*" className='inputFiles' id='picfile'  hidden onChange={(e)=>onChangepicPathinputimg(e) } ></input>
                                                </form>
                                                    <Button variant="outline-primary" className="col-2 mr-2" onClick={(e)=>onClickImageUpload(e)}>파일선택</Button>
                                                    {picPathFileName&&
                                                    <Button variant="outline-primary" className="col-2 mr-2" onClick={()=>picImgDelete('')}>파일삭제</Button>
                                                    }
                                                    <Form.Control type="text" placeholder={picPathFileName ?picPathFileName : "파일을 선택해주세요."} disabled />                                                
                                                </div>
                                                <Row className="no-gutters"  style={{marginTop:'10px'}}>
                                                    <Col><span style={{color : 'red'}}>* 375 X 250 크기로 등록해주세요.</span></Col>
                                                </Row>
                                            </Td>
                                        </Tr>
                                        {mainTopScreenIdx&& 
                                        <>
                                        <Tr><Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}} >상태</Td>
                                        <Td>
                                        <Col style={{textAlign:'left'}}>
                                        {openFlag
                                        ?    
                                            <span className="font-weight-bold text-success">활성화</span>
                                        :
                                            <span className="font-weight-bold text-gray">비활성화</span>
                                        }
                                        </Col>
                                        </Td>
                                        </Tr>
                                        </>
                                         }
                                    </Tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                   {/* 모달 (활성 전환) */}
            <Modal show={show_public} onHide={handleClose_public} centered>
                <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                <Modal.Body>
                    메인상단사진을 {!openFlag ? '활성화' : '비활성화'}로 전환하시겠습니까?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose_public}>취소</Button>
                    <Button variant="success" onClick={updateOpenFlag}>확인</Button>
                </Modal.Footer>
            </Modal>
            </Container >
        </>
    );
};

export default UploadmainTopScreen;
