import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "../../../views/auth/Login/crud.js";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  refreshToken: undefined
};

export const reducer = persistReducer(
  { storage, key: "auth", whitelist: ["user", "authToken", "refreshToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, refreshToken } = action.payload;
        return { authToken,refreshToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken, refreshToken } = action.payload;
        return { authToken, refreshToken, user: undefined };
      }

      case actionTypes.Logout:
        return initialAuthState;

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken, refreshToken) => {return ({ type: actionTypes.Login, payload: { authToken, refreshToken }})},
  register: (authToken, refreshToken) => ({
    type: actionTypes.Register,
    payload: { authToken, refreshToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: (data) => ({ type: actionTypes.UserLoaded, payload: { user : data } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser());
  // });
  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data : {success, code, data} } = yield getUserByToken();
    if(success) {
      if(code == null) {
        yield put(actions.fulfillUser(data));
      }
    }
  });
}
