import React, { useEffect, useState,useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col, InputGroup, FormControl, Card, Badge, Button, Pagination, Modal } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Checkbox from '@material-ui/core/Checkbox';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import {getTerms,deleteTerms,updateOpenFlag} from "./crud"
import {makeNotEmptyObj} from '../../../../src/_metronic/utils/utils'
import SearchCard from "../../../common/components/SearchCard"
import CustomPagination from '../../../common/components/CustomPagination'
import { refresh } from '../../auth/Login/crud';
import swal from 'sweetalert'
import * as authAction from "../../../app/store/ducks/auth.duck";





const TermsManagement = ()=>{
    const history = useHistory();
    const dispatch = useDispatch();
    const { page } = useParams();

 

    // 모달 (약관 삭제)
    const [show_delete, setShow_delete] = useState(false);
    const handleClose_delete = () => setShow_delete(false);
    const handleShow_delete = () => {if(checkList.length<1){swal('알림','선택하신 약관이 없습니다.').then((isConfirm)=>{if(isConfirm)return false});}else{setShow_delete(true)}};
    //검색 변수
    const [sortColumn,setSortColumn] = useState((history.location.state && history.location.state.sortColumn) || 'createAt');
    const [type ,setType] =useState((history.location.state && history.location.state.type) || '');
    const [titleOrContent , setTitleOrContent] =useState((history.location.state && history.location.state.titleOrContent) || '');
    
    const [termsList,setTermsList] =useState([]);
    const [termsPage,setTermsPage] = useState([]);
    const [checkList,setCheckList] = useState([]);

    const onChangeSortColumn = (e)=>{
        setSortColumn(e.target.value);
    }
    const onSelectType = (e) =>{
        setType(e.target.value);
    }
    const onChangeTitleOrContent = (e)=>{
        setTitleOrContent(e.target.value);
    }

    

    //약관 리스트 가져오기
    const readTermsList =()=>{
        let params = {
            page :page,
            size :10,
        }

        const chkParams = {
            // type : type,
            // titleOrContent : titleOrContent,
            //sortColumn :sortColumn
        }
        params = makeNotEmptyObj(params,chkParams);
        const rs = getTerms(params);
        rs.then(({data :{content,totalElements,totalPages,last,first,numberOfElements}}) =>{
            setTermsList(content);
            const pageObj ={
                totalElements : totalElements,
                totalPages : totalPages,
                last : last,
                first : first,
                numberOfElements
            }
            setTermsPage(pageObj);
        })
        .catch(({response : data})=>{
            if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;

                        localStorage.setItem('persist:auth', JSON.stringify(auth));

                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        movePage(page);
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                }).catch(({response : data})=>{
                    if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                });
            }
        });;
    }

    //삭제
    const termsDelete =()=>{
        setShow_delete(false);
        const rs = deleteTerms(checkList);
        rs.then(({ data : {success, message, code, data} }) => {
            if(success === true && message === 'success') {
                swal({
                    title: "알림",
                    text: "정상적으로 삭제 되었습니다."
                }).then((isConfirm) => {
                    if(isConfirm) {
                        movePage(page);
                    }
                })
            } else {
                swal({
                    title: "알림",
                    text: message
                }).then((isConfirm) => {
                    if(isConfirm) {
                        return false;
                    }
                })
            }
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        termsDelete();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }
    //디테일 페이지이동
    const handleClick= (idx) =>{
        // const params ={
        //     type ,
        //     titleOrContent ,
        //     sortColumn
        //     }
        const url = {
            pathname : `/termsManagement_upload/${page}/${idx}`,
         //   state :params,
        } 
        history.push(url);
    }

    //페이지 이동
    const movePage = (page) =>{
        // const params = {      
        //     type : type,
        //     titleOrContent : titleOrContent,
        //     sortColumn : sortColumn,
        // }
        
        history.push({
            pathname : "/termsManagement/"+page,
           // state : params
        });
        setCheckList([]);
        readTermsList(page);
    }

    //검색
    const onSearch =(e) =>{
        e.preventDefault();
        movePage(1);
    }

    //체크박스
    const allCheck = (checked) =>{
        if(checked){
            const ckbox = [];
            termsList.map((v,i)=>{
            ckbox.push(v.idx);
            setCheckList(ckbox);
            })
        }else if(!checked){
            setCheckList([]);
        }
    }

    // 체크 박스 단일 선택
    const oneCheck = (checked,ckidx) =>{
        if(checked){
            setCheckList([...checkList, ckidx]);
        }else if(!checked){
            setCheckList(checkList.filter(idx => idx !== ckidx));
        }
    }

    useMemo(()=>{
        
        movePage(page)
    },[])
    return(
        <Container fluid className="termsManagement">
                
        {/* 검색영역 */}
        {/* <SearchCard 
            noLanguage
           onChangeSortColumn ={onChangeSortColumn}
           onSelectType = {onSelectType}
           onChangeTitleOrContent={onChangeTitleOrContent}
           onSearchEvent={onSearch}
           sortValue={sortColumn}
           typeValue={type}
           value={titleOrContent}
        /> */}

        {/* 관리자 리스트 */}
        <Row>
            <Col>
                <Card>
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <h4 className="cardTitleBig">약관 &nbsp;&nbsp;<span style={{fontSize : '14px', fontWeight: 400}}>[ 전체 : {termsPage.totalElements} ]</span></h4>
                        <Button id='add' variant="primary" onClick={()=>{history.push('/termsManagement_upload')}}>약관 추가</Button>
                    </Card.Header>
                    <Card.Body className="d-flex no-gutters p-0">
                        <Table className="commonTable borderTable bb-1">
                                <Thead>
                                        <Tr>
                                            <Th style={{width:50}}>
                                                <Checkbox 
                                                checked = {
                                                    checkList.length === termsPage.numberOfElements  && termsPage.numberOfElements>0
                                                    ? true
                                                    : false
                                                    }
                                                    onChange={(e) =>allCheck(e.target.checked)}
                                                    />
                                            </Th>
                                            <Th style={{width:120}}>버전</Th>
                                            <Th>약관 종류</Th>
                                            <Th style={{width:120}}>작성자</Th>
                                            <Th style={{width:140}}>고지상태</Th>
                                            <Th style={{width:140}}>고지날짜</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                        termsList.length > 0 ? termsList.map((v,i)=>{
                                            return(
                                                <Tr>
                                                    <Td><Checkbox
                                                    checked={
                                                        checkList.includes(v.idx) ? true : false
                                                    }
                                                    onChange={(e)=>oneCheck(e.target.checked,v.idx)}
                                                    /></Td>
                                                    <Td>{v.version}</Td>
                                                    <Td onClick={() => {handleClick(v.idx)}}>{v.typeDescription}</Td>
                                                    <Td>{v.author}</Td>
                                                    { v.openFlag
                                                    ?
                                                        <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">고지</div></Td>
                                                    : 
                                                        <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">고지중단</div></Td>
                                                    }
                                                    <Td>{v.createAt}</Td>
                                                </Tr>
                                            )
                                        })
                                        :
                                        <Tr>
                                            <Td className="font-weight-bold" colSpan={6} >데이터가 없습니다.</Td>
                                        </Tr>

                                        }
                                    {/* 
                                    <Tr>
                                        <Td><Checkbox /></Td>
                                        <Td>[번호 DATA]</Td>
                                        <Td className="text-left fw-500 ellipsis" onClick={handleShow_modifyandaddAdmin} style={{cursor:'pointer'}}>[계정 DATA]</Td>
                                        <Td>[성명 DATA]</Td>
                                        <Td>[권한 DATA]</Td>
                                        { 상태 == 사용중 ? badge-success, 활성
                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">활성</div></Td>
                                        : 기간만료 badge-gray, 비활성
                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비활성</div></Td>
                                        }
                                        <Td>[날짜 시간 DATA]</Td>
                                    </Tr> 
                                    */}
                                    </Tbody>
                            </Table>
                     </Card.Body>
                     <Card.Footer className="d-flex flex-row justify-content-between align-items-center paginationFooter">
                                <div>
                                    <CustomPagination
                                        itemsCount={5}
                                        pageData={termsPage}
                                        page={page}
                                        onClickEvent={movePage}
                                    >
                                    </CustomPagination> 
                                </div>
                                <div>
                                    <Button variant="outline-danger ml-2"onClick={handleShow_delete} >약관삭제</Button>
                                </div>
                     </Card.Footer>
                    </Card>
                </Col>
            </Row>
            

        

             {/* 모달 (약관 삭제) */}
             <Modal show={show_delete} onHide={handleClose_delete} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_delete}>취소</Button>
                        <Button variant="danger" onClick={termsDelete}>확인</Button>
                    </Modal.Footer>
                </Modal>

            </Container>
    )




};

export default TermsManagement;
