import React, { useEffect, useState,useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col, InputGroup, FormControl, Card, Badge, Button, Pagination, Modal } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import {getAdminList,modStatus,adminRoles,uploadUserprofile,userAddandModify,deleteUsers} from "./crud"
import Checkbox from '@material-ui/core/Checkbox';
import SearchCard from "../../../common/components/SearchCard"
import UploadImage from "../../../common/components/UploadImage";
import {makeNotEmptyObj} from '../../../../src/_metronic/utils/utils'
import CustomSearchCardv2 from "../../../common/components/CustomSearchCardv2"

import CustomPagination from '../../../common/components/CustomPagination'
import { refresh } from '../../auth/Login/crud';
import swal from 'sweetalert'
import * as authAction from "../../../app/store/ducks/auth.duck";
//import * as EmailValidator from 'email-validator';
//npm install email-validator 해야함


// import swal from 'sweetalert';
// import * as authAction from "../../../app/store/ducks/auth.duck";
// import { reissueToken, currency } from '../../../common/up_utils';
// import { TOKEN_EXPIRED_CODE } from '../../../common/dickey';
// import Pagination from '../../../common/components/Pagination';
// import { getUsers } from './crud';

// pagination


const AdminManagement = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { page } = useParams();
    const Level = ['ACTIVATION','DEACTIVATION','LEAVE'];
/*
    // 모달 (관리자 추가)
    const [show_addAdmin, setShow_addAdmin] = useState(false);
    const handleClose_addAdmin = () => setShow_addAdmin(false);
    const handleShow_addAdmin = () => setShow_addAdmin(true);
*/
    // 모달 (관리자 수정)
    const [show_modifyAdmin, setShow_modifyAdmin] = useState(false);
    const handleClose_modifyAdmin = () => {setShow_modifyAdmin(false); defaultSet(); }
    const handleShow_modifyandaddAdmin = () => setShow_modifyAdmin(true);

    // 모달 (비활성 전환)
    const [show_private, setShow_private] = useState(false);
    const handleClose_private = () => setShow_private(false);
    const handleShow_private = () => {if(checkList.length<1){swal('알림','선택하신 관리자가 없습니다.').then((isConfirm)=>{if(isConfirm)return false});}else{setShow_private(true)}}

    // 모달 (활성 전환)
    const [show_public, setShow_public] = useState(false);
    const handleClose_public = () => setShow_public(false);
    const handleShow_public = () => {if(checkList.length<1){swal('알림','선택하신 관리자가 없습니다.').then((isConfirm)=>{if(isConfirm)return false});}else{setShow_public(true)}}
    
    // 모달 (관리자 삭제)
    const [show_delete, setShow_delete] = useState(false);
    const handleClose_delete = () => setShow_delete(false);
    const handleShow_delete = () => {if(checkList.length<1){swal('알림','선택하신 관리자가 없습니다.').then((isConfirm)=>{if(isConfirm)return false});}else{setShow_delete(true)}}

    const [sortColumn,setSortColumn] = useState((history.location.state && history.location.state.sortColumn) || 'createAt');
    const sortColumnItems = [
        {
            text : '등록일순',
            value : 'createAt',
        },
    ];
    const [sortType, setSortType] = useState((history.location.state && history.location.state.sortType) || 'DESC')
    const sortTypeItems = [
        {
            text : '내림차순',
            value : 'DESC',
        },
        {
            text : '오름차순',
            value : 'ASC',
        },
    ];
    const [searchType, setSearchType] = useState((history.location.state && history.location.state.searchType) || 'name');
    const [search, setSearch] = useState((history.location.state && history.location.state.search) || '');
    const searchItems = [
        {
            text : '성명',
            value : 'name',
        },
        {
            text: 'email',
            value :'email'
        }
    ];
    const [menu,setMenu] =useState('');
    const [fileName , setFileName] =useState('');
    const [checkList , setCheckList] =useState([]); //체크 리스트
    const [modalData, setModalData] = useState(''); //사용자 페스워드
    const [adminIdx, setAdminIdx] = useState(''); //사용자 idx
    const [name, setName] = useState(''); //사용자 성명
    const [email, setEmail] = useState(''); //사용자 이메일
    const [password, setPassword] = useState(''); //사용자 페스워드
    const [status, setStatus] = useState(''); //사용자 상태
    const [admininputimg,setAdminInputImg] = useState(); //input이미지
    const [profilePath, setProfilePath] = useState('');//프로필 이미지 Path 
    const [userTypes, setUserTypes] = useState([]); //사용자 권한
    const [createAt, setCreateAt] = useState(''); //사용자 등록일
    const [preview , setPreview] =useState([]); //미리보기 Path
    const [adminDetailData,setAdminDetailData] = useState({});
    const [adminPage,setAdminPage] = useState([]) //페이지 
    const [emailisInvalid,setEmailIsInvalid] =useState(false);// 이메일 검사 t/f
    const [passwordisInvalidType,setPasswordIsInvalidType] =useState();//password 검사 타입
    const [passwordisInvalid , setPwIsInvalid] =useState(true); // password 검사 타입
    //const [ckbNullCheck,setCkbNullCheck] = useState(false);
    const [adminRolesList,setAdminRolesList] = useState([{ //권한 리스트
        description :[],
        userType :[]
    }]);
    const [adminList ,setAdminList] = useState([{ //어드민 리스트
        idx : [],
        name : [],
        email : [],
        password : [],
        status :[],
        profilePath : [],
        userTypes : [],
        createAt : []
    }]);
    
    const nameFocus = useRef(null);
    const passwordFocus = useRef(null);
    const emailFocus = useRef(null);

     //검색 onChange
    const onChangeSortColumn = (e)=> setSortColumn(e.target.value);
    const onChangeSortType = (e) => setSortType(e.target.value);
    const onChangeSearchType = (e) => setSearchType(e.target.value);
    const onChangeSearch = (e) => setSearch(e.target.value);
    //사용자 정보
    const onChangeName =(e)=>{setName(e.target.value)};
    const onChangeEmail =(e)=>{
        let email = e;
        // let regExp =/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
//         let regExp = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;

//         if(email.match(regExp)!=null){
// //            console.log('이메일 확인 t')
//             setEmailIsInvalid(true);
//         }else {
// //            console.log('이메일 확인 f')
//             setEmailIsInvalid(false);
//         }
        setEmailIsInvalid(true);
       setEmail(email);
    };
    const onChangePassword =(e)=>{
        let password =e.target.value;
//         let regExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*#?&]{8,16}$/;
//         let string = password.match(/^(?=.*[A-Za-z])/);
//         let num = password.match(/(?=.*\d)/);
//         let stal = password.match(/(?=.*[$@$!%*?&])/);
//         let long = password.match(/^[A-Za-z\d$@$!%*#?&]{8,16}$/);
//         let pwck = [string,num,stal,long]
//         for(let i = 0; i<pwck.length;i++){
//             if(pwck[i]==null){
//                 setPasswordIsInvalidType(i);
//             }
//         }
//         if(password.match(regExp)!=null){
// //           console.log('비밀번호 확인 t')
//         setPwIsInvalid(true);
//         }else {
//         setPwIsInvalid(false);
// //        console.log('비밀번호 확인 f')
//         }
        setPwIsInvalid(true);
        setPassword(password)
    };

    //const onChangeAdminInputImg =(e)=>{ setAdminInputImg(e.target.files);  modviewImg(e.target.files); setFileName(e.target.files[0].name); };
    const onChangeUserTypes =(e,v)=>{
        let checked =e.target.checked;
        let Roles = v.userType;
        if(checked){
            setUserTypes([...userTypes,Roles]);
        }else if(!checked){
            setUserTypes(userTypes.filter(userTypes => userTypes!==Roles));
        }
    }
    // //수정모달 미리보기
    // const modviewImg = (e)=>{
    //     let tmpArr = [];
    //     let itm ={
    //         name : "",
    //         path : URL.createObjectURL(e[0])
    //       }
    //       tmpArr.push(itm);
    //     setPreview(tmpArr);
        
    // }
//     //등록된 프로필 미리보기
//     const viewImg = (e)=>{
//         if(e){
// //            console.log('profilePath',e);
//             let tmpArr = [];
//             let imt ={
//                 name : "/fileUpload/",
//                 path : e
//             }
//             let fileName =e;
//             let filenameArr = fileName.split('/');
//             tmpArr.push(imt)
//             setPreview(tmpArr);
//             setFileName(filenameArr[2]);
//         }
//     }
    // //등록된 이미지 삭제
    // const removeImageInput = () =>{
    //     setAdminInputImg();
    //     setPreview([]);
    // }


    //권한 리스트 받아오기
    const rolesList =()=>{
        const rs = adminRoles();
        rs.then(({data}) =>{
         
            setAdminRolesList(data)
        }).catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        rolesList();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    
    }
    //검색
    const onSearch =(e) =>{
        e.preventDefault();
        movePage(1);
    }


    //adminList체크 박스 전체 선택 유무
    const allCheck = (checked) =>{
        if(checked){
            const ckbox = [];
            adminList.map((v,i)=>{
            ckbox.push(v.idx);
            setCheckList(ckbox);
            })
        }else if(!checked){
            setCheckList([]);
        }
    }
    
    // adminList체크 박스 단일 선택
    const oneCheck = (checked,ckidx) =>{
        if(checked){
//            console.log('한개 선택',checked);
            setCheckList([...checkList, ckidx]);
        }else if(!checked){
//            console.log('한개 선택 취소',checked);
            setCheckList(checkList.filter(idx => idx !== ckidx));
        }
    }
    //사용자 활성 & 비활성
    const statusChange = (e) =>{
      const rs =modStatus(checkList,e);
      rs.then(({ data : {success, message, code, data} }) => {
        if(success === true && message === 'success') {
            swal({
                title: "알림",
                text: "정상적으로 수정 되었습니다."
            }).then((isConfirm) => {
                if(isConfirm) {
                    movePage(page);
                }
            })
        } else {
            swal({
                title: "알림",
                text: message
            }).then((isConfirm) => {
                if(isConfirm) {
                    return false;
                }
            })
        }
    })
    .catch(({response : data})=>{
        if(data.status == 401 && data.data && data?.data?.code == 1101){
            const auth = JSON.parse(localStorage.getItem('persist:auth'));
            const rsRf= refresh(auth.refreshToken);
            
            rsRf.then(({ data : {success, message, code, data} }) => {
                if(data != null) {
                    let accessToken = data.accessToken;
                    let refreshToken = data.refreshToken;
                    auth.authToken = accessToken;
                    auth.refreshToken = refreshToken;
            
                    localStorage.setItem('persist:auth', JSON.stringify(auth));

                    dispatch(authAction.actions.login(accessToken, refreshToken));
                    statusChange(e);
                } else {
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                }
            })
            .catch(({response : data})=>{
              if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
              }
            });
        }
    });
        if(e=='ACTIVATION'){
            setShow_public(false)
        }else{
            setShow_private(false)
        }
        setCheckList([]);
    }
    //adminList 가져오기 
    const listAdmin =(page)=>{
//        console.log('리스트 페이지',page)
        let params = {
            page : page,
            size : 15,
        }   
       const chkParams = {
        //name : titleOrContent,
        sortType: sortType,
        sortColumn : sortColumn,
       }
       if(searchType =='name'){chkParams.name = search};
       if(searchType =='email'){chkParams.email = search};
    //    console.log('chkParams',chkParams);
       params = makeNotEmptyObj(params,chkParams)
        const rs = getAdminList(params);
        rs.then(({data : {content,totalElements,totalPages,last,first,numberOfElements}})=>{
            setAdminList(content);
            const pageObj = {
                totalElements : totalElements,
                totalPages : totalPages,
                last : last,
                first : first,
                numberOfElements : numberOfElements
            }
            setAdminPage(pageObj);
        }).catch(({response : data})=>{
            if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;

                        localStorage.setItem('persist:auth', JSON.stringify(auth));

                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        movePage(page);//수정해야함
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                }).catch(({response : data})=>{
                    if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                });
            }
        });;
    }
    // //체크확인
    // const checkBoxCheck =()=>{
    //     if(checkList.length<1){
    //         swal({
    //             title: "알림",
    //             text: "선택하신 관리자가 없습니다."
    //         }).then((isConfirm) => {
    //             if(isConfirm) {
    //                 setCkbNullCheck(false);
    //                 return false
    //             }
    //         })
    //     }else{
    //         setCkbNullCheck(true);
    //     }
    // }
    //상태값 초기화
    const defaultSet = ()=>{
        setAdminIdx('');
        setName('');
        setEmail('');
        setPassword('');
        setStatus('');
        //setProfilePath('');
        setUserTypes([]);
        setEmailIsInvalid(false);
        setPwIsInvalid(false);
        setCreateAt('');
        setPreview([]);
        setFileName('');
    }
    //수정 및 추가 onClick
    const onClickaddandmod =(e,v)=>{
        setMenu(e);
        if(e=='add'){
            defaultSet();
            setAdminDetailData({v});
        }else if(e=='mod'){
            setModalData(v);
            setAdminDetailData(v);
            setAdminIdx(v.idx)
            setName(v.name)
            setEmail(v.email)
            //setPassword(v.password);
            setStatus(v.status)
            //setProfilePath(v.profilePath)
            setUserTypes(v.userTypes)
            setCreateAt(v.createAt)
            //viewImg(v.profilePath);
            setPwIsInvalid(true);
            setEmailIsInvalid(true);
        }
        handleShow_modifyandaddAdmin();
    }
   //삭제
   const deleteAdminuser = ()=>{
    handleClose_delete();
    const rs = deleteUsers(checkList);
        rs.then(({ data : {success, message, code, data} }) => {
            if(success === true && message === 'success') {
                swal({
                    title: "알림",
                    text: "정상적으로 삭제 되었습니다."
                }).then((isConfirm) => {
                    if(isConfirm) {
                        movePage(page);
                    }
                })
            } else {
                swal({
                    title: "알림",
                    text: message
                }).then((isConfirm) => {
                    if(isConfirm) {
                        return false;
                    }
                })
            }
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        deleteUsers();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
   }     
    //수정 및 추가
    const onSubmit =() =>{
        let admindata = {
            name : name,
            email : email,
            password : password,
            status :status,
            profilePath : '', //picPath || modalData.profilePath,
            userTypes : userTypes
        }

       const rs = userAddandModify(adminIdx,admindata);
       rs.then(({ data : {success, message, code, data} }) => {
        if(data !== null) {
            swal({
                title: "알림",
                text: "정상적으로 "+(menu == 'add' ? '추가' : '수정')+"되었습니다."
            }).then((isConfirm) => {
                if(isConfirm) {
                    handleClose_modifyAdmin();
                    menu == 'add' ? movePage(1,menu) : movePage(page);
                }
            })
        } else {
            swal({
                title: "알림",
                text: message
            }).then((isConfirm) => {
                if(isConfirm) {
                    return false;
                }
            })
        }
    })
    .catch(({response : data})=>{
//        // console.log('catch :: ', data)
        if(data.status == 401 && data.data && data?.data?.code == 1101){
            const auth = JSON.parse(localStorage.getItem('persist:auth'));
            const rsRf= refresh(auth.refreshToken);
            
            rsRf.then(({ data : {success, message, code, data} }) => {
                if(data != null) {
                    let accessToken = data.accessToken;
                    let refreshToken = data.refreshToken;
                    auth.authToken = accessToken;
                    auth.refreshToken = refreshToken;
            
                    localStorage.setItem('persist:auth', JSON.stringify(auth));
    
                    dispatch(authAction.actions.login(accessToken, refreshToken));
                    onSubmit();
                } else {
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                }
            })
            .catch(({response : data})=>{
              if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
              }
            });
        }
    });
    }
    //이메일 체크
    const emailAndPassowrdCk=()=>{
        handleShow_modifyandaddAdmin();

        let type = passwordisInvalidType;
        let con = '올바르지 않은 email 형식입니다'
        let pwtype=['한개 이상의 문자가 포함되어야합니다','한개 이상의 숫자가 포함 되어야합니다','한개 이상의 특수문자가 포함되어야합니다','최소 8글자 이상 최대 16자 이하이여야합니다.']
        if(!name) return swal("알림", '성명을 입력 하지 않았습니다.').then(() => { nameFocus.current.focus(); })
        if(!emailisInvalid) return swal("알림", con).then(() => { emailFocus.current.focus(); });
        if(menu === 'add') {
            if(!password) return swal("알림", '페스워드를 입력하지 않았습니다').then(() => { passwordFocus.current.focus(); });
        }
        if(password !== '') {
            if(!passwordisInvalid) return swal("알림", pwtype[type]).then(() => { passwordFocus.current.focus(); });
        }
        onSubmit();
    }
    
//     //파일 업로드
//     const adminImpFillupload=()=>{
     
//         let picPath ='';
//         var formData = new FormData;
//             if(admininputimg){
// //                console.log('admininputimg ::' ,admininputimg);
//                 formData.append('files', admininputimg[0]);   
//                 formData.append('path', 'userprofile');
//                 const rs = uploadUserprofile(formData);
//                 rs.then(({data})=>{
// //                    console.log('filePath',data[0].filePath);
// //                    console.log('realName',data[0].realName);
//                     picPath +=data[0].filePath.concat(data[0].realName);
// //                    console.log('picPath',picPath);
//                     onSubmit(picPath);
//                 })  
               
//                 }else {
// //                    console.log('picPath',picPath);
//                     onSubmit(picPath);
//                 }

//     }
    // //파일 업로드 버튼 클릭 이벤트
    // const onClickImageUpload = ()=>{
    //     window.document.getElementById('file').click();
    // }
    const movePage=(page,menu)=>{
//        console.log('page 확인',page);

        const params = {           
            searchType,   
            search , 
            sortType, 
            sortColumn,
        }
        const addParams={

        }

        history.push({
            pathname : "/adminManagement/"+page,
            state :  menu!=='add' ? params :addParams 
        });
        listAdmin(page);
        setCheckList([]);
    }

    useMemo(() => {
        rolesList();
        movePage(page);
    },[])
    return (
        <>
            <Container fluid className="adminContainer">
                
                {/* 검색영역 */}
                <CustomSearchCardv2
                    isSortColumn={true}
                    onChangeSortColumn ={onChangeSortColumn}
                    sortColumnItems={sortColumnItems}
                    sortColumn={sortColumn}

                    isSortType={true}
                    onChangeSortType={onChangeSortType}
                    sortTypeItems={sortTypeItems}
                    sortType={sortType}

                    isSearch={true}
                    onChangeSearch={onChangeSearch}
                    onSearchEvent={onSearch}
                    searchItems={searchItems}
                    onChangeSearchType={onChangeSearchType}
                    search={search}
                />

                {/* 관리자 리스트 */}
                <Row>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h4 className="cardTitleBig">관리자 &nbsp;&nbsp;<span style={{fontSize : '14px', fontWeight: 400}}>[ 전체 : {adminPage.totalElements} ]</span></h4>
                                <Button id='add'onClick={(e)=>{onClickaddandmod(e.target.id)}} variant="primary">관리자 추가</Button>
                            </Card.Header>
                            <Card.Body className="d-flex no-gutters p-0">
                                <Table className="commonTable borderTable bb-1">
                                        <Thead>
                                            <Tr>
                                                <Th style={{width:50}}>
                                                    <Checkbox 
                                                    checked = {
                                                        checkList.length === adminPage.numberOfElements  && adminPage.numberOfElements>0
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(e) =>allCheck(e.target.checked)}
                                                    />
                                                </Th>
                                                <Th style={{width:80}}>번호</Th>
                                                <Th>E-MAIL</Th>
                                                <Th style={{width:120 }}>성명</Th>
                                                <Th style={{width:120, display:'none'}}>권한</Th>
                                                <Th style={{width:140}}>상태</Th>
                                                <Th style={{width:140}}>등록일</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {/* 
                                            <Tr>
                                                <Td><Checkbox /></Td>
                                                <Td>[번호 DATA]</Td>
                                                <Td className="text-left fw-500 ellipsis" onClick={handleShow_modifyandaddAdmin} style={{cursor:'pointer'}}>[계정 DATA]</Td>
                                                <Td>[성명 DATA]</Td>
                                                <Td>[권한 DATA]</Td>
                                                { 상태 == 사용중 ? badge-success, 활성
                                                    <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">활성</div></Td>
                                                : 기간만료 badge-gray, 비활성
                                                    <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비활성</div></Td>
                                                }
                                                <Td>[날짜 시간 DATA]</Td>
                                            </Tr> 
                                            */}
                                             {adminList.length>0 ?
                                             adminList.map((v,i)=>{
                                                return (
                                                    <Tr key={'list_tr_'+i}>
                                                        <Td><Checkbox 
                                                         checked={
                                                            checkList.includes(v.idx) ? true : false
                                                        }
                                                     onChange={(e)=>oneCheck(e.target.checked,v.idx)} />
                                                       
                                                        </Td>
                                                        <Td>{v.idx}</Td>
                                                        <Td className="text-left fw-500 ellipsis clickable" id='mod' onClick={(e)=>onClickaddandmod(e.target.id, v)} style={{cursor:'pointer'}}>{v.email}</Td>
                                                        <Td style={{textAlign:'left',padding :'1rem'}}>{v.name}</Td>
                                                        {/* {adminRolesList.map((v,i)=>{
                                                                return(
                                                                    <Tr>
                                                                    <Checkbox defaultChecked={userTypes.includes(v.userType) ? true : false} onChange={(e)=>onChangeUserTypes(e.target.checked,v.userType)}/>
                                                                    <Td>{v.description}</Td>
                                                                    </Tr>
                                                                )
                                                            })} */}
                                                        <Td style={{display:'none'}}>{v.userTypes ? v.userTypes.map((v,i)=>{
                                                            return (
                                                                adminRolesList.map((k,u)=>{
                                                                    return(
                                                                        v=== k.userType
                                                                        ?
                                                                        i===0  
                                                                        ?
                                                                        k.description 
                                                                        :
                                                                        ','.concat(k.description) 
                                                                        :null
                                                                    )
                                                                }) 
                                                            )
                                                        }) : '미정'}</Td>
                                                        { v.status === Level[0] &&
                                                    <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">활성</div></Td>
                                                        }
                                                        { v.status === Level[1] &&
                                                    <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비활성</div></Td>
                                                        }
                                                        { v.status === Level[2] &&
                                                    <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">탈퇴</div></Td>
                                                        }
                                                        <Td>{v.createAt}</Td>
                                                    </Tr>
                                                )
                                            })
                                            :
                                            <Tr >
                                            <Td colSpan="7">관련 게시물이 없습니다</Td>
                                        </Tr>
                                        }
                                        </Tbody>
                                    </Table>
                            </Card.Body>
                            <Card.Footer className="d-flex flex-row justify-content-between align-items-center paginationFooter">
                                <div>
                                    <CustomPagination
                                        itemsCount={5}
                                        pageData={adminPage}
                                        page={page}
                                        onClickEvent={movePage}
                                    >
                                    </CustomPagination>
                                </div>
                                <div>
                                    <Button variant="outline-gray" onClick={handleShow_private}>비활성 전환</Button>
                                    <Button variant="outline-success ml-2" onClick={handleShow_public}>활성 전환</Button>
                                    <Button variant="outline-danger ml-2" onClick={handleShow_delete}>관리자 삭제</Button>
                                </div>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            
                {/* 모달 (관리자 추가) */}
                {/* 
                <Modal show={show_addAdmin} onHide={handleClose_addAdmin} centered>
                    <Modal.Header closeButton><Modal.Title>관리자 추가</Modal.Title></Modal.Header>
                    <Modal.Body className="haveTable">
                        <Table className="commonTable">
                            <Tbody>
                                <Tr><Th>사진선택</Th><Td><div className="d-flex justify-content-start align-items-center"><Button variant="outline-primary" className="mr-2">파일선택</Button><h6 class="text-subgray">선택된 파일 이름.asd</h6></div></Td></Tr>
                                
                               
                                <Tr><Th>관리자명</Th><Td><Form.Control type="text" placeholder="관리자명을 입력하세요." /></Td></Tr>
                                <Tr>
                                    <Th>권한선택</Th>
                                    <Td>
                                        <Form.Control className="select-bold" as="select">
                                            {adminRolesList.map((v,i)=>{
                                                return(
                                                 <option value={v.userType}>{v.description}</option>
                                                )
                                            })}
                                           
                                        </Form.Control>
                                    </Td>
                                </Tr>
                                <Tr><Th>E-MAIL</Th><Td><Form.Control type="text" placeholder="이메일을 입력하세요." /></Td></Tr>
                                <Tr><Th>PASSWORD</Th><Td><Form.Control type="text" placeholder="비밀번호를 입력하세요." /></Td></Tr>
                            </Tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_addAdmin}>취소</Button>
                        <Button variant="primary" onClick={handleClose_addAdmin}>확인</Button>
                    </Modal.Footer>
                </Modal>
                                        */}                       
                {/* 모달 (관리자 수정) */}
                <Modal show={show_modifyAdmin} onHide={handleClose_modifyAdmin} centered>
                    <Modal.Header closeButton><Modal.Title>관리자 {menu == 'add' ? '추가' : '수정'}</Modal.Title></Modal.Header>
                    <Modal.Body className="haveTable">
                        <Table className="commonTable">
                            {/*  onChangeidx
                                onChangeName
                                onChangeEmail
                                onChangePassword
                                onChangestatus
                                onChangeAdminInputImg
                                onChangecreateAt */}
                            <Tbody>
                         
                             
                                 {/* <form encType="multipart/form-data">
                                 <input type='file' key='image'accept="image/*" className='inputFiles' id='file'  hidden onChange={(e)=>onChangeAdminInputImg(e) } ></input>
                                </form>
                            <Tr><Th>사진선택</Th><Td><div className="d-flex justify-content-start align-items-center"><Button variant="outline-primary" onClick={()=>onClickImageUpload()}>파일선택</Button><h6 class="text-dark ml-2"  >{fileName?fileName:'파일을 선택해 주세요'}</h6></div></Td></Tr> */}

                                {/* 
                                <Tr><Th>사진 선택</Th><Td> <UploadImage 
                                title="SIZE 375 X 584"
                                description={'사진파일을 끌어서 여기에 놓거나<br />여기를 클릭하여 사진파일을 업로드합니다'} 
                                multiple={false}
                                disabled ={preview.length}
                                onChangeEvent={onChangeAdminInputImg}
                                preview ={preview}
                                removeImageInput ={removeImageInput}
                                styles ={{width : '100px',height :'150px', paddingTop: '20px' }}
                                />
                                </Td></Tr>
                            */}
                                <Tr><Th>관리자명</Th><Td><input className="form-control" type="text" placeholder="관리자명을 입력하세요."  value={name} onChange={(e)=>onChangeName(e)} ref={nameFocus}/></Td></Tr>
                                <Tr style={{display:'none'}}>
                                    <Th>권한선택</Th>
                                    <Td>
                                                {adminRolesList.map((v,i)=>{
                                                    return(
                                                        <Tr key={'role_tr_'+i}>
                                                        <Checkbox defaultChecked={userTypes.includes(v.userType) ? true : false} onChange={(e)=>onChangeUserTypes(e,v)}/>
                                                        <Td className="text-left">{v.description}</Td>
                                                        </Tr>
                                                    )
                                                })}
                                    </Td>
                                </Tr>
                                {/* {adminIdx&&
                                <Tr><Th>상태</Th>
                                {status == 'ACTIVATION'
                                ?
                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">활성</div></Td>
                                :
                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비활성</div></Td>
                                }
                                </Tr>
                                } 
                                isInvalid={emailisInvalid}
                                */}

                                <Tr>
                                    <Th>E-MAIL</Th>
                                    <Td>
                                        <input className="form-control" type="text" disabled={adminIdx ? true : false}  validation={false} placeholder="이메일을 입력하세요. ex) test@test.kr"  defaultValue={email} onChange={(e)=>onChangeEmail(e.target.value)} ref={emailFocus}/>
                                        
                                    </Td>
                                </Tr>

                                <Tr>
                                    <Th>PASSWORD</Th>
                                    <Td>
                                        <input className="form-control" type="password" placeholder="비밀번호를 입력하세요."   onChange={(e)=>onChangePassword(e)} ref={passwordFocus}/>
                                    </Td>
                                </Tr>
                                <Tr style={{display:'none'}}>
                                    <Td colSpan={2}><small style={{color: 'gray'}}>*패스워드 최소 8글자 이상 최대 16자 이하 (특수문자,숫자 포함)</small></Td>
                                </Tr>

                            </Tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_modifyAdmin}>취소</Button>
                        <Button variant="primary" onClick={emailAndPassowrdCk}>확인</Button>
                    </Modal.Footer>
                </Modal>

                {/* 모달 (비활성 전환) */}
                <Modal show={show_private} onHide={handleClose_private} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>비활성으로 전환하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_private}>취소</Button>
                        <Button variant="primary" id='DEACTIVATION'onClick={(e)=>statusChange(e.target.id)}>확인</Button>
                    </Modal.Footer>
                </Modal>

                {/* 모달 (활성 전환) */}
                <Modal show={show_public} onHide={handleClose_public} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>활성으로 전환하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_public}>취소</Button>
                        <Button variant="success" id='ACTIVATION'onClick={(e)=>statusChange(e.target.id)}>확인</Button>
                    </Modal.Footer>
                </Modal>
                
                {/* 모달 (E-BOOK 삭제) */}
                <Modal show={show_delete} onHide={handleClose_delete} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_delete}>취소</Button>
                        <Button variant="danger" onClick={deleteAdminuser}>확인</Button>
                    </Modal.Footer>
                </Modal>

            </Container >
        </>
    );
};

export default AdminManagement;
