import axios from "axios";

export const ADMIN_MEDICALSTAFF = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/medicalStaff';
export const ADMIN_MEDICALSTAFF_DELETE = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/medicalStaffs';

export function getMedicalStaff(params){
    return axios.get(ADMIN_MEDICALSTAFF,{params});
}

export function deleteMedicalStaff(checkList){
    return axios.delete(ADMIN_MEDICALSTAFF_DELETE,{data : {idxs :checkList}});
}