// AdminManagement _crud


import axios from "axios";

export const ADMIN_USER = "/api/" + process.env.REACT_APP_API_VERSION + "/admin/user";
export const ADMIN_USER_MOD = "/api/" + process.env.REACT_APP_API_VERSION + "/admin/user/";
export const ADMIN_USER_STATUS_ACTIVATION = "/api/" + process.env.REACT_APP_API_VERSION + "/admin/activation/users";
export const ADMIN_USER_STATUS_DEACTIVATION = "/api/" + process.env.REACT_APP_API_VERSION + "/admin/deactivation/users";
export const ADMIN_ROLES ="/api/" + process.env.REACT_APP_API_VERSION + "/admin/auth/roles";
export const ADMIN_USERPROFILE_UPLOADIMG = "/api/" + process.env.REACT_APP_API_VERSION + "/admin/common/onlyFileUploads";
export const DELETE_ADMIN_USERS = "/api/" + process.env.REACT_APP_API_VERSION + "/admin/users";







export function getAdminList(params){
    
    return axios.get(ADMIN_USER,{params});
}

export function modStatus(useridxs,state){
   if(state=='DEACTIVATION'){
        return axios.put(ADMIN_USER_STATUS_DEACTIVATION,{idxs : useridxs})
   }else if(state =='ACTIVATION'){
    return axios.put(ADMIN_USER_STATUS_ACTIVATION,{idxs : useridxs})
   }
}

export function uploadUserprofile(param){
    return axios.post(ADMIN_USERPROFILE_UPLOADIMG,param, {headers : {'content-Type' : 'multipart/form-data'} })
}

export function userAddandModify (idx,param){
    if(idx){
    return axios.put(ADMIN_USER_MOD+idx,param); 
    }else{
    return axios.post(ADMIN_USER,param);
    }
}

export function deleteUsers(CheckList){
    return axios.delete(DELETE_ADMIN_USERS,{data :{idxs : CheckList}})
}

export function adminRoles (){
    return axios.get(ADMIN_ROLES);
}
