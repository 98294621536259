
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import { Button } from "react-bootstrap";

import * as auth from "../../../app/store/ducks/auth.duck";
import { NEED_ADMIN_AUTH } from '../../../common/dickey';
import { login } from "./crud";
import KaKaoLogin from 'react-kakao-login';
import swal from "sweetalert";

function Login(props) {
    const { intl } = props;
    const [loading, setLoading] = useState(false);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
        paddingRight: "2.5rem"
    });

    const enableLoading = () => {
        setLoading(true);
        setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
        setLoading(false);
        setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    const ClickKakaoLogin = (result) => {
        login(result.response.access_token, 'kakao')
            .then((data) => {
                if(data.data.success){
                    props.login(data.data.data.accessToken, data.data.data.refreshToken);
                    props.fulfillUser({ kakao: result, loginStatus: data.data });
                }else{
                    swal(data.data.message);
                }
            }).catch(({ response: data }) => {
                // console.log(data);
            })
    }
    
    return (
        <>
            <div className="kt-login__body kakaoButtonBody">
                <div className="kt-login__form">

                    <Formik
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        // }}
                        validate={values => {
                            // console.log(values)
                            const errors = {};
              
                            if (!values.email) {
                              errors.email = intl.formatMessage({
                                id: "MODAL_SWAL_INPUT_USER_ID"
                              });
                            } 
                            if (!values.password) {
                              errors.password = intl.formatMessage({
                                id: "MODAL_SWAL_INPUT_USER_PASSWORD"
                              });
                            }
              
                            return errors;
                          }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            enableLoading();
                            setTimeout(() => {
                                login(values.email, values.password)
                                .then(({ data : {success, message, code, data} }) => {
                                    // console.log(data)
                                    // var data = 
                                    if(data !== null) {
                                        const accessToken = data.accessToken;
                                        const refreshToken = data.refreshToken;
                                        disableLoading();
                                        props.login(accessToken, refreshToken);
                                    } else {
                                        swal({
                                            title: "알림",
                                            text: message
                                        }).then((isConfirm) => {
                                            if(isConfirm) {
                                                disableLoading();
                                                return false;
                                            }
                                        })
                                    }
                                    return false;
                                })
                            }, 1000);
                        }}
                    >
                        {({
                              values,
                              status,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting
                          }) => (
                            <form
                                noValidate={true}
                                autoComplete="off"
                                className="kt-form"
                                onSubmit={handleSubmit}
                            >

                                <div className="form-group">
                                    <TextField
                                        type="email"
                                        label="이메일"
                                        margin="normal"
                                        className="kt-width-full"
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        helperText={touched.email && errors.email}
                                        error={Boolean(touched.email && errors.email)}
                                    />
                                </div>

                                <div className="form-group">
                                    <TextField
                                        type="password"
                                        margin="normal"
                                        label="비밀번호"
                                        className="kt-width-full"
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.password}
                                        helperText={touched.password && errors.password}
                                        error={Boolean(touched.password && errors.password)}
                                    />
                                </div>

                                {/* <div className="mt-3 mb-5 loginState" >
                                    <input type="checkbox" />
                                    <label style={{
                                        marginLeft: 5,
                                        verticalAlign: 'middle'
                                    }}>로그인 상태 유지</label>
                                </div> */}

                                <Button type="submit" className="loginButton" variant="primary" style={{marginTop : '5px'}} size="lg">로그인</Button>


                                {/*<KaKaoLogin*/}
                                    {/*className="kakaoButton"*/}
                                    {/*jsKey={process.env.REACT_APP_KAKAO_APIKEY}*/}
                                    {/*buttonText=""*/}
                                    {/*onSuccess={(data) => ClickKakaoLogin(data)}*/}
                                    {/*onFailure={(e) => console.log(e)}*/}
                                    {/*getProfile={true}*/}
                                {/*/>*/}
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
}

export default injectIntl(
    connect(
        null,
        auth.actions
    )(Login)
);
