import axios from "axios"

export const ADMIN_QUESTION_CATEGORY='/api/' + process.env.REACT_APP_API_VERSION + '/admin/common/questionCategories';
export const ADMIN_QUESTION = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/question';
export const ADMIN_QUESTIONS = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/questions';
export const ADMIN_QUESTION_BY_IDX = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/question/';

export function getCategoryList(){
    return axios.get(ADMIN_QUESTION_CATEGORY);
}

export function getQuestion(params){
    return axios.get(ADMIN_QUESTIONS,{params});
}

export function deleteQuestion(checkList){
    return axios.delete(ADMIN_QUESTION,{data : {idxs :checkList}});
}