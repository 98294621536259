import React ,{useState,useMemo} from 'react'
import { useDispatch } from 'react-redux';
import CustomPagination from '../../../common/components/CustomPagination'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Form, Row, Container, Col, Button, Tabs, Tab, Modal } from "react-bootstrap";
import TabList from './TabList';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import SearchCard from "../../../common/components/SearchCard"
import { useHistory, useParams } from "react-router-dom";
import {getGoods,deleteGoods} from './crud'
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert'
import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import {makeNotEmptyObj} from '../../../../src/_metronic/utils/utils'
import CustomSearchCardv2 from "../../../common/components/CustomSearchCardv2"
const GoodsManagement = () =>{
    const history = useHistory();
    const dispatch = useDispatch();
    const { page,langCode } = useParams();

    //삭제 모달
    const [show_delete, setShow_delete] = useState(false);
    const handleShow_delete = () => {if(checkList.length<1){swal('알림','선택하신 제품이 없습니다.').then((isConfirm)=>{if(isConfirm)return false});}else{setShow_delete(true)}};
    const handleClose_delete = () => setShow_delete(false);
    //페이징 관련 변수
    const [goodsPage , setGoodsPage] = useState([]);
    //제품 목록
    const [goodsList, setGoodsList] = useState([])
    const [checkList , setCheckList] =useState([]);
    const [sortColumn,setSortColumn] = useState((history.location.state && history.location.state.sortColumn) || 'createAt');
    const sortColumnItems = [
        {
            text : '등록일순',
            value : 'createAt',
        },
    ];
    const [selectLangCode,setSelectLangCode ] = useState(langCode)
    const [sortType, setSortType] = useState((history.location.state && history.location.state.sortType) || 'DESC')
    const sortTypeItems = [];
    const [searchType, setSearchType] = useState((history.location.state && history.location.state.searchType) || 'title');
    const [search, setSearch] = useState((history.location.state && history.location.state.search) || '');
    const searchItems = [
        {
            text : '제품명',
            value : 'title',
        },
        {
            text : '품번',
            value : 'articleNumber'
        },

    ];
    
    const langCodeList = [
        {
        idx : 'en',
        title : 'ENGLISH'
        },
        {
        idx : 'ko',
        title : 'KOREAN'
        },
        {
        idx : 'ja',
        title : 'JAPANESE'
        },
        {
        idx : 'zh',
        title : 'TAIWAN'
        },
        {
        idx : 'id',
        title : 'INDONESIAN'
        },
        {
        idx : 'th',
        title : 'THAI'
        },

    ]
    //검색 onChange event
    const onChangeSortColumn = (e)=> setSortColumn(e.target.value);
    const onChangeSortType = (e) => setSortType(e.target.value);
    const onChangeSearchType = (e) => setSearchType(e.target.value);
    const onChangeSearch = (e) => setSearch(e.target.value);
    const onChangeLangCode = (e)=>{
        setSelectLangCode(e);
        movePage(1,e)
    }
    const [searchParams,setSearchParams] =useState([sortColumn,selectLangCode,searchType,search]);

   

    const goodsDelete = () =>{
        setShow_delete(false);
        const rs = deleteGoods(checkList);
        rs.then(({ data : {success, message, code, data} }) => {
            if(success === true && message === 'success') {
                swal({
                    title: "알림",
                    text: "정상적으로 삭제 되었습니다."
                }).then((isConfirm) => {
                    if(isConfirm) {
                        if(goodsPage.numberOfElements === checkList.length&& page>1){
                           movePage(page-1,selectLangCode);
                        }else{
                            movePage(page,selectLangCode);
                        }
                        
                    }
                })
            } else {
                swal({
                    title: "알림",
                    text: message
                }).then((isConfirm) => {
                    if(isConfirm) {
                        return false;
                    }
                })
            }
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        goodsDelete();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    const getGoodsList = (page,langCode)=>{
        let params = {
            langCode : langCode,
            page : page,
            size : 25,
        }
        const chkParams = {
            sortColumn : sortColumn,
            sortType : sortType,
        }
        if(searchType =="title") {chkParams.title = search};
        if(searchType =="articleNumber") {chkParams.articleNumber = search};
        params = makeNotEmptyObj(params, chkParams);
        const rs = getGoods(params);
        rs.then(({data :{content,totalElements,totalPages,last,first,numberOfElements}}) =>{
            setGoodsList(content);
            const pageObj ={
                totalElements : totalElements,
                totalPages : totalPages,
                last : last,
                first : first,
                numberOfElements :numberOfElements
            }
            setGoodsPage(pageObj);
        })
        .catch(({response : data})=>{
            if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;

                        localStorage.setItem('persist:auth', JSON.stringify(auth));

                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        movePage(page);
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                }).catch(({response : data})=>{
                    if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                });
            }
        });;

    }
    
    //체크 박스 전체 선택 유무
    const allCheck = (checked) =>{
        if(checked){
            const ckbox = [];
            goodsList.map((v,i)=>{
            ckbox.push(v.idx);
            setCheckList(ckbox);
            })
        }else if(!checked){
            setCheckList([]);
        }
    }

    // 체크 박스 단일 선택
    const oneCheck = (checked,ckidx) =>{
        if(checked){
            setCheckList([...checkList, ckidx]);
        }else if(!checked){
            setCheckList(checkList.filter(idx => idx !== ckidx));
        }
    } 


    const movePage = (page,langCode) => {   
        const params = {      
            searchType,
            search,
            sortColumn,
            sortType
        }
        
        history.push({
            pathname : "/goodsManagement/"+page+'/'+langCode,
            state : params
        });
        setCheckList([]);
        getGoodsList(page,langCode);
    }

    //검색
    const onSearch = (e) => {
        e.preventDefault();
        movePage(1,selectLangCode);

    }
    
    useMemo(() => {
        movePage(page,selectLangCode);        
    },[])


    const test = [
        {
        idx : '1',
        articleNumberKo : '1111',
        titleKo : '테스트입니다1',
        priceKo : '테스트입니다1',
        createAt : '2021-03-16',
        }
        ,
        {
        idx : '2',
        articleNumberKo : '2222',
        titleKo : '테스트입니다2',
        priceKo : '테스트입니다2',
        createAt : '2021-03-16',
        }
        ,
        {
        idx : '3',
        articleNumberKo : '3333',
        titleKo : '테스트입니다3',
        priceKo : '테스트입니다3',
        createAt : '2021-03-16',
        }
        ,
        {
        idx : '4',
        articleNumberKo : '4444',
        titleKo : '테스트입니다4',
        priceKo : '테스트입니다4',
        createAt : '2021-03-16',
        }
        ,
        {
        idx : '5',
        articleNumberKo : '5555',
        titleKo : '테스트입니다5',
        priceKo : '테스트입니다5',
        createAt : '2021-03-16',
        }
        
    ]

    return(
        
            <>
                <Container fluid className="goodsContainer">
                    
                    {/* 검색영역 */}
                         <CustomSearchCardv2 
                            isLanguage = {false}
    
                            isSortColumn={true}
                            onChangeSortColumn ={onChangeSortColumn}
                            sortColumnItems={sortColumnItems}
                            sortColumn={sortColumn}
    
                            isSortType={false}
                            onChangeSortType={onChangeSortType}
                            sortTypeItems={sortTypeItems}
                            sortType={sortType}
    
                            isSearch={true}
                            onChangeSearch={onChangeSearch}
                            onSearchEvent={onSearch}
                            searchItems={searchItems}
                            onChangeSearchType={onChangeSearchType}
                            search={search}   
                            />
                    <Row>
                    <Col>
                    <Tabs defaultActiveKey={langCode} ActiveKey={selectLangCode} transition={false} className="upTabs m-0" onSelect={(e)=>onChangeLangCode(e)} > 
                        {langCodeList.map((item, index)=> {
                            return (
                                <Tab eventKey={item.idx} title={item.title}>
                                    <TabList
                                        searchParams ={searchParams}
                                        title={'제품'}
                                        history={history}
                                        handleShow_delete={handleShow_delete}
                                        goodsList = {goodsList}
                                        movePage={movePage}
                                        page={page}
                                        goodsPage={goodsPage}
                                        langCode={selectLangCode}
                                        allCheck ={allCheck}
                                        oneCheck = {oneCheck}
                                        checkList={checkList}
                                        handleShow_delete = {handleShow_delete}
                                    />
                                </Tab>
                            )
                        })}
                    </Tabs>
                    </Col>
                </Row>
    
                    {/* 모달 (공지사항 삭제) */}
                    <Modal show={show_delete} onHide={()=>handleClose_delete('revoke')} centered>
                        <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                        <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary"  onClick={handleClose_delete}>취소</Button>
                            <Button variant="danger" onClick={goodsDelete}>확인</Button>
                        </Modal.Footer>
                    </Modal>
    
                </Container >
            </>
        );
    
};

export default GoodsManagement;
