import React, { useState,useMemo  } from 'react';
import styled, {css} from 'styled-components';
import { Tabs, Tab } from "react-bootstrap";

const LanguagesTabs = ({onLanSelected, langCode,selectLangCode,idx,i,focus}) => {
/*

*/
    //console.log(selectLangCode);
    // const e = ['en','ko','ja','zh','id','th']
    // const def = () =>{       
    //     for(let j = 0; j<e.length; j++){
    //         if(selectLangCode.includes(e[j])==false){            
    //             return e[j];
    //         }
    //     }        
    // }

    return (
        <>  
            <Tabs activeKey={langCode} transition={false}  className="languageTabs mb-0" onSelect={(e)=>onLanSelected(e,i)}>
                <Tab  disabled={selectLangCode.includes('en')} eventKey="en" title="ENGLISH"></Tab>
                <Tab  disabled={selectLangCode.includes('ko')} eventKey="ko" title="KOREAN"></Tab>
                <Tab  disabled={selectLangCode.includes('ja')} eventKey="ja" title="JAPANESE"></Tab>
                <Tab  disabled={selectLangCode.includes('zh')} eventKey="zh" title="TAIWAN"></Tab>
                <Tab  disabled={selectLangCode.includes('id')} eventKey="id" title="INDONESIAN"></Tab>
                <Tab  disabled={selectLangCode.includes('th')} eventKey="th" title="THAI"></Tab>
            </Tabs>     
        </> 
    )
}

export default LanguagesTabs;
