import axios from "axios"

export const ADMIN_MEDICALSTAFF_FILEUPLOAD ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/common/onlyFileUploads'
export const ADMIN_MEDICALSTAFF = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/medicalStaff'
export const ADMIN_MEDICALSTAFF_BY_IDX = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/medicalStaff/'

export function getMediaclStaff(medicalStaffIdx){
    return axios.get(ADMIN_MEDICALSTAFF_BY_IDX+medicalStaffIdx);
}

export function updateMedicalStaff(medicalStaffIdx,params){
    return axios.put(ADMIN_MEDICALSTAFF_BY_IDX +medicalStaffIdx,params);
}

export function createMedicalStaff(params){
    return axios.post(ADMIN_MEDICALSTAFF,params);
}

export function deleteMedicalStaff(medicalStaffIdx){
    return axios.delete(ADMIN_MEDICALSTAFF_BY_IDX+medicalStaffIdx);
}

export function uplodaMedicalStaffImg(params){
    return axios.post(ADMIN_MEDICALSTAFF_FILEUPLOAD,params,{headers : {'Content-Type' : 'multipart/form-data'}})
}