export default {
    header: {
        self: {},
        items: []
    },
    aside: {
        self: {},
        items: [
            {
                title: "요약",
                root: true,
                icon: "/media/icons/Layers.svg",
                page: "home",
            },
            {
                title: "메인화면관리",
                root: true,
                icon: "/media/icons/Pictures1.svg",
                page: "mainScreenManagement",
            },
            {
                title: "동영상",
                root: true,
                icon: "/media/icons/Video-camera.svg",
                page: "videoManagement",
            },
            {
                title: "E-BOOK",
                root: true,
                icon: "/media/icons/Library.svg",
                page: "magazineManagement",
                submenu: [
                    {
                        title: "E-BOOK",
                        icon: "/media/icons/Selected-file.svg",
                        page: "magazineManagement"
                    },
                    // {
                    //     title: "오디오북",
                    //     icon: "/media/icons/Radio.svg",
                    //     page: "audiobookManagement"
                    // },
                ]
            },
            {
                title: "명예의전당",
                root: true,
                icon: "/media/icons/Smile.svg",
                page: "honoraryManagement",
            },
            {
                title : "PUSH 알림",
                root : true,
                icon : "/media/icons/Chat6.svg",
                page : "pushManagement"

            },
            {
                title: "공지사항",
                root: true,
                icon: "/media/icons/Info-circle.svg",
                page: "noticeManagement",
            },
            {
                title: "카테고리",
                root: true,
                icon: "/media/icons/Layout-4-blocks.svg",
                page: "categoryManagement",
            },
            {
                title: "배너 리스트",
                root: true,
                icon: "/media/icons/Layout-top-panel-6.svg",
                page: "bannerManagement",
            },
             // {
            //     title: "프리미엄회원",
            //     root: true,
            //     icon: "/media/icons/User.svg",
            //     page: "premiumManagement",
            // },
            /* 문진관리 안 씀 */
            // {
            //     title: "문진 관리",
            //     root: true,
            //     icon: "/media/icons/Clipboard-list.svg",
            //     page: "questionManagement",
            //     submenu: [
            //         {
            //             title: "질문 리스트",
            //             icon: "/media/icons/Clipboard-list.svg",
            //             page: "questionManagement"
            //         },
            //         {
            //             title: "의료진 리스트",
            //             icon: "/media/icons/Address-card.svg",
            //             page: "medicalStaffManagement"
            //         },
            //     ]
            // },
            {
                title: "제품 관리",
                root: true,
                icon: "/media/icons/Loader.svg",
                page: "goodsManagement",
            },
            {
                title: "관리자",
                root: true,
                icon: "/media/icons/Settings4.svg",
                page: "adminManagement",
            },
            {
                title: "약관관리",
                root: true,
                icon: "/media/icons/Layout-top-panel-1.svg",
                page: "termsManagement",
            },
            
        ]
    }
};
