import React, { useState, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col, Card, Button, Modal} from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import swal from 'sweetalert'
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';

import LanguageTabs from "../../../common/components/LanguageTabs"
import { getCategoryList, getBannerByIdx, uploadBannerFileUpload, createBanner, updateBanner, deleteBanner } from './crud';
import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import { ContactsOutlined } from '@material-ui/icons';

// Radio Checked Style
const useStyles = makeStyles(theme => ({
    radio: {
        '&$checked': {
          color: '#343a40'
        }
    },
    checked: {}
}));

const UploadBanner = () => {

    const { page, idx } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();

    const titleFocus = useRef(null);
    const urlFocus = useRef(null);

    const [beforeCategory ,setBeforeCategory] = useState((history.location.state && history.location.state.categoryIdx) ||'')
    const [category, setCategory] = useState(beforeCategory);
    const [categoryList,setCategoryList] = useState([{
        idx:[],
        titleKo:[],
        thumbnailPath:[],
        useFlag:[],
        num:[],
        type:[]
    }]);
    const [imgInfo, setImgInfo] = useState([]);
    const [imgName, setImgName] = useState(history.location.state.bannerPicPath && history.location.state.bannerPicPath || '');
    
    // const [bannerIdx, setBannerIdx] =useState('');
    const [status, setStatus] = useState(history.location.state.openFlag ? history.location.state.openFlag : false);
    const [langCode,setLangCode] =useState(history.location.state.langCode);
    const [title,setTitle] =useState(history.location.state.title);
    const [bannerLink, setBannerLink] =useState(history.location.state.bannerLink);


    //onChange
    const onChangeLanguage = (e) => {setLangCode(e)}
    const onChangeCategory=(e) => { setCategory(e.target.value);}
    const onChangeTitle = (e) => {setTitle(e.target.value)}
    const onChangeBannerLink = (e) => {setBannerLink(e.target.value)}
    const onChangeStatus=(e)=> {setStatus(e.target.value);}
    const onChangeImg =(e)=>{
        setImgInfo(e);
        setImgName(e[0].name);
    };

    // 모달 (업로드)
    const [show, setShow_upload] = useState(false);
    const handleClose_upload = () => setShow_upload(false);
    const handleShow_upload = () => setShow_upload(true);

    const [deleteShow, setShow_delete] = useState(false);
    const handleClose_delete = () => setShow_delete(false);
    const handleShow_delete = () => setShow_delete(true);

    const [push, setShow_push] = useState(false);
    const handleClose_push = () => setShow_push(false);
    const handleShow_push = () => setShow_push(true);

    const getBanner = () => {
        const rs = getBannerByIdx(idx);
        rs.then(({ data : {success, message, code, data} }) => {
            if(data != null) {
                
            } else {

            }
        })
    }

    const onClickImageUpload = (e) => {
        window.document.getElementById('picFile').click()
    }

    const imgDelete = () => {
        setImgName('');
        setImgInfo([]);
    }

    const bannerUpload = () => {
        handleClose_upload();
        if(!title) return swal("알림", '배너 제목을 입력하지 않았습니다.')
        
        let imgPath = imgName;
        var formData = new FormData;

        if(imgInfo.length != 0) {
            formData.append('files', imgInfo[0]);
            formData.append('path', 'bannerImgFile');
            
            const rs = uploadBannerFileUpload(formData);
            rs.then(({data}) => {
                let dataArray = new Array();

                for(let key in data){ 
                    let realName = data[key].realName;
                    let filePath = data[key].filePath;
                    let pushData = filePath + realName;
                    dataArray.push(pushData);
                }
                let imgPath = dataArray.join();
                upload(imgPath);
            })
        } else {
            upload(imgPath);
        }
    }

    const upload = (imgPath) => {
        let params = {
            langCode: langCode ? langCode : 'en',
            title: title,
            bannerPicPath: imgPath,
            openFlag: status,
            bannerLink: bannerLink,
            categoryIdx: category
        }
        if(idx != 0) {
            const rs = updateBanner(idx, params);
            rs.then(({ data : { success, message, code, data } }) => {
                if ( data !== null ) {
                    swal({
                        title: "알림",
                        text: "정상적으로 수정 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push(`/bannerManagement/${page}/${category}`);
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            }).catch(({response : data})=>{
                // console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            upload(imgPath);
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        } else {
            const rs = createBanner(params);
            rs.then(({ data: {success, message, code, data } }) => {
                if ( data !== null ) {
                    swal({
                        title: "알림",
                        text: "정상적으로 등록 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push(`/bannerManagement/${page}/${category}`);
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            }).catch(({response : data})=>{
                // console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            upload(imgPath);
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        }
    }

    const bannerDelete = () => {
        handleClose_delete();
        const rs = deleteBanner(idx);
        rs.then(({data : {success,message,code,data} })=>{
            if(success=true && message ==='success') {
                swal({
                    title :"알림",
                    text : "정상적으로 삭제 되었습니다."
                }).then((isConfirm)=>{
                    if(isConfirm){
                        history.push(`/bannerManagement/${page}/${category}`);
                    }
                })
            }else{
                swal({
                    title: "알림",
                    text : message
                }).then((isConfirm)=>{
                    if(isConfirm){
                        return false;
                    }
                })
            }
        }).catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        bannerDelete();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    //카테고리 리스트
    const readCategoryList = ()=>{
        let params = {
            sortType : "ASC",
            sortColumn : "num",
            contentType : 'VIDEO'
        }
      const rs = getCategoryList(params);
        rs.then(({data :{content}})=>{
            setCategoryList(content);
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        readCategoryList();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    useMemo(()=>{
        readCategoryList();
        getBanner();
    },[])

    return (
        <>
        {console.log('qfwf',status)}
            <Container fluid className = "UploadBannerContainer">
                <Row className = "mb-4 no-gutters">
                    <Col className="d-flex justify-content-between align-items-center">
                        <div>
                            <Button variant = "outline-primary mr=2" onClick = {() => history.push(`/bannerManagement/${page}/` + (beforeCategory !== '' ? beforeCategory : category ))}>목록으로</Button>
                        </div>
                        <div>
                            {idx != 0 &&
                            <Button variant = "outline-danger mr-2" onClick = {handleShow_delete}>삭제</Button>
                            }
                            <Button variant = "primary" onClick = {handleShow_upload}>{idx != 0 ? '수정' : '업로드'}</Button>
                        </div>
                    </Col>
                </Row>

                {/* 모달 업로드 */}
                <Modal show = {show} onHide = {handleClose_upload} centered>
                <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>{idx != 0 ? '수정' : '업로드'} 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant = "secondary" onClick = {handleClose_upload}>취소</Button>
                        <Button variant = "primary" id = 'upload' onClick = {bannerUpload}>확인</Button>
                    </Modal.Footer>
                </Modal>

                {/*모달 삭제 */}
                <Modal show={deleteShow} onHide={handleClose_delete} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_delete}>취소</Button>
                        <Button variant="primary" onClick={bannerDelete}>확인</Button>
                    </Modal.Footer>
                </Modal>

                <Row className = "mb-4">
                    <Col>
                        <Card>
                            <Card.Header className = "cardTitle text-primary">배너 정보</Card.Header>
                            <Card.Body className = "p-0">
                                <Table className = "commonTable">
                                    <Tbody>
                                        <Tr>
                                            <Td className = "font-weight-bold text-dark text-left pr-0" style = {{width: 100}}>언어선택</Td>
                                            <Td>
                                                <LanguageTabs
                                                    onLanSelected = {onChangeLanguage}
                                                    langCode = {langCode}
                                                />
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className = "font-weight-bold text-dark text-left pr-0" style = {{width: 100}}>카테고리</Td>
                                            <Td>
                                                <FormControl component = "fieldset" style = {{'display': 'flex'}}>
                                                    <RadioGroup
                                                        aria-label = "Category"
                                                        name = "category"
                                                        value = {Number(category)}
                                                        onChange = {(e) => onChangeCategory(e)}
                                                        row
                                                    >
                                                    {categoryList.map((v, i) => {
                                                        return (
                                                            <FormControlLabel
                                                                control = {
                                                                    <Radio
                                                                        classes = {{
                                                                            root: classes.radio,
                                                                            checked: classes.checked
                                                                        }}
                                                                    />
                                                                }
                                                                value = {v.idx}
                                                                label = {v.titleKo}
                                                            />
                                                        )
                                                    })}
                                                    </RadioGroup>
                                                </FormControl>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td colspan = "2">
                                                <Row className = "no-gutters">
                                                    <Col className = "d-flex justify-content-between align-items-center mr-3 no-gutters">
                                                        <label className = "font-weight-bold text-dark text-left" style={{width:100}}>배너제목</label>
                                                        <Form.Control type="text" placeholder="배너의 제목을 입력해 주세요.(최대 30자)" ref={titleFocus&&titleFocus}  value={title} onChange={(e) => onChangeTitle(e)}/>
                                                    </Col>
                                                    <Col className = "d-flex justify-content-between align-items-center mr-3 no-gutters">
                                                        <label className = "font-weight-bold text-dark text-left" style={{width:100}}>URL 연동</label>
                                                        <Form.Control type="text" placeholder="해당 배너를 통해 이동 가능한 링크(URL)을 입력해 주세요." ref={urlFocus&&urlFocus}  value={bannerLink} onChange={(e) => onChangeBannerLink(e)}/>
                                                    </Col>
                                                </Row>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                        <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>사진등록</Td>
                                        <Td>
                                                <div className="d-flex justify-content-between align-items-center">
                                                <form encType="multipart/form-data">
                                                <input type='file' key='image' accept="image/*" className='inputFiles' id='picFile' hidden onChange = {(e) => onChangeImg(e.target.files)}></input>
                                                </form>
                                                    <Button variant="outline-primary" className="col-2 mr-2" onClick = {(e) => onClickImageUpload(e)}>파일선택</Button>
                                                    {imgName &&
                                                    <Button variant="outline-primary" className="col-2 mr-2" onClick = {() => imgDelete()}>파일삭제</Button>
                                                    }
                                                    <Form.Control type="text" placeholder= {imgName ? imgName : "파일을 선택해주세요."} disabled />
                                                
                                                </div>
                                                <Row className="no-gutters"  style={{marginTop:'10px'}}>
                                                    <Col><span>* 권장 이미지 크기: 375 X 200</span></Col>
                                                </Row>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-4 openCheck">
                    <Col>
                        <Card className="d-flex flex-row align-items-center p-4">
                            <Col className="d-flex col-9 justify-content-start align-items-center">
                                <h5 className="cardTitle text-primary">공개상태</h5>
                                <p className="m-0 ml-4 text-subgray"> 배너 업로드시 공개여부를 선택해주세요.</p>
                            </Col>
                            <Col className="col-3">
                                <FormControl component="fieldset" style={{'display':'flex'}}>
                                    <RadioGroup
                                        aria-label="Status"
                                        name="status"
                                        value={String(status)}
                                        onChange={(e)=>onChangeStatus(e)}
                                        row
                                        className="statusRadio justify-content-end"
                                    >
                                        <FormControlLabel
                                            control={
                                                <Radio 
                                                    classes={{
                                                        root: classes.radio,
                                                        checked: classes.checked
                                                    }}
                                                />
                                            }
                                            value= 'false'
                                            label="비공개"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Radio 
                                                    classes={{
                                                        root: classes.radio,
                                                        checked: classes.checked
                                                    }}
                                                />
                                            }
                                            value='true'
                                            label="공개"
                                            className="ml-2"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Col>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default UploadBanner;