// UploadVideo _crud
import axios from 'axios';

export const ADMIN_CATEGORY ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/common/category';
export const adming_MEDIA_UPLOADIMG = "/api/" + process.env.REACT_APP_API_VERSION + "/admin/common/onlyFileUploads";


export const POST_MEDIA_INSERT_URL = '/api/v1/admin/media';
export const POST_MEDIA_CONVERT_URL = '/api/v1/admin/convertFile';
export const ADMIN_VIDEO ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/media';
export const ADMIN_VIDEO_BY_IDX = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/media/';

export const ADMIN_PUSH = '/api/v1/app/admin/common/requestPush';

export function insertMedia(data) {
    return axios.post(POST_MEDIA_INSERT_URL, data);
}

export function mediaConvert(data) {
    return axios.post(POST_MEDIA_CONVERT_URL, data);
}

export function getCategoryList(params){
    return axios.get(ADMIN_CATEGORY,{params});
}

export function getvideoByIdx(idx){
    return axios.get(ADMIN_VIDEO_BY_IDX+idx);
}

export function UploadVideoimg(params){

    // console.log('파일 업로드 params',params);
    return axios.post(adming_MEDIA_UPLOADIMG,params, {headers : {'Content-Type' : 'multipart/form-data'} })
}

export function createVideo(params) {
    // POST : /api/v1/admin/notice
    return axios.post(ADMIN_VIDEO, params);
}

export function updateVideo(videoIdx, params) {
    // PUT : /api/v1/admin/notice/{noticeIdx}
    // console.log('params',params);
    return axios.put(ADMIN_VIDEO_BY_IDX + videoIdx, params);
}

export function deleteOnvideo(videoIdx) {
    return axios.delete(ADMIN_VIDEO_BY_IDX + videoIdx);
}

export function requestPush(params) {
    return axios.post(ADMIN_PUSH, params);
}