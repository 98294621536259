// MagazineManagement _crud

import axios from "axios";

export const API_ADMIN_EBOOK = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/ebook';
export const API_ADMIN_EBOOK_DELETE = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/ebooks';
export const API_ADMIN_EBOOK_OPENFLAG = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/updateEbookOpenFlag';

export function getEbookList(params){
    return axios.get(API_ADMIN_EBOOK,{params});   
}
export function deleteEbookList(idxs) {
    return axios.delete(API_ADMIN_EBOOK_DELETE,{data :{idxs : idxs}});   
}
export function updateUseFlags(idxs, id) {
    let useFlag = false
    if(id == 'true'){
        useFlag =true
    }
    return axios.put(API_ADMIN_EBOOK_OPENFLAG,{idxs : idxs,useFlag:useFlag})
}