// UploadHonorary _crud

import axios from "axios";

export const ADMIN_PERSON = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/person';
export const ADMIN_PERSON_BY_IDX ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/person/';
export const ADMIN_PERSON_TYPE = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/common/levelTypes';

export const API_ADMIN_PERSON_FILEUPLOAD ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/common/onlyFileUploads'


export function getParsonByIdx(personIdx){
    // console.log('Idx',personIdx)
    return axios.get(ADMIN_PERSON_BY_IDX+personIdx);
}

export function getParsonLevel(){
    return axios.get(ADMIN_PERSON_TYPE);
}

export function uploadParsonFileupload(params){
    // console.log('params',params);
    return axios.post(API_ADMIN_PERSON_FILEUPLOAD,params,{headers : {'Content-Type' : 'multipart/form-data'}})
}

export function updateParson(personIdx, params) {
    //console.log('updateParson',params)
    // PUT : /api/v1/admin/notice/{noticeIdx}
    return axios.put(ADMIN_PERSON_BY_IDX + personIdx, params);
}

export function createParson(params) {
   // console.log('createParson',params)
    return axios.post(ADMIN_PERSON, params);
}

export function deleteParson(personIdx) {
    return axios.delete(ADMIN_PERSON_BY_IDX+personIdx)
}

