
import React, { useState } from 'react';
import styled, {css} from 'styled-components';
import { Form, Row, Col, InputGroup, FormControl, Card, Button } from "react-bootstrap";

const SearchCard = ({ noLanguage, type,onChangesortColumn,onSelectLanguage,onChangeTitleOrContent, onSearchEvent, value, sortValue,typeValue, onSelectType, langValue }) => {
    // TODO :: onKeyPress
  const appKeyPress = (e) => {
    // 엔터 일 때
    if (e.key === 'Enter') {
      // 검색 이벤트 호출
      onSearchEvent(e);
    }
  }
    return (
        <Row className="mb-4 searchCard no-gutters">
            <Card className="p-4 flex-row justify-content-between align-items-center no-gutters w-100">

                {/* 좌측 */}
                <Col className="d-flex mr-4">
                    <Col className="d-flex flex-row justify-content-between align-items-center">
                        <h5 className="font-weight-bold text-primary mr-3">나열순서</h5>
                        <Form.Control className="select-bold" as="select" onChange={onChangesortColumn} value={sortValue || 'createAt'}>
                            {/* <option value="">선택</option> */}
                            <option value="createAt">등록일순</option>
                        </Form.Control>
                    </Col>
                    <Col className="d-flex flex-row justify-content-between align-items-center">
                        { noLanguage ? null :
                        <>
                            { type !== 'honorary' ?
                            <>
                                <h5 className="font-weight-bold text-primary mr-3">언어선택</h5>
                                <Form.Control className="select-bold" as="select" onChange={onSelectLanguage} value={langValue || ''}>
                                    <option value="" selected>선택</option>
                                    {/** 영어 */}
                                    <option value="en">ENGLISH</option>
                                    {/** 한국어 */}
                                    <option value="ko">KOREAN</option>
                                    {/** 일본어 */}
                                    <option value="ja">JAPANESE</option>
                                    {/** 대만어 */}
                                    <option value="zh">TAIWAN</option>
                                    {/** 인도네시아어 */}
                                    <option value="id">INDONESIAN</option>
                                    {/** 태국어 */}
                                    <option value="th">THAI</option>
                                </Form.Control>
                            </>
                            :
                            <>
                                <h5 className="font-weight-bold text-primary mr-3">등급선택</h5>
                                <Form.Control className="select-bold" as="select" onChange={onSelectType} value={typeValue || ''}> 
                                    <option value="">전체</option>
                                </Form.Control>
                            </>
                            }
                        </>
                        }
                    </Col>
                </Col>

                {/* 우측 */}
                <Col className="d-flex ml-4">
                    <Col className="d-flex flex-row justify-content-end col-4">
                        <Form.Control className="select-bold" as="select" >
                            <option value="">제목 + 내용</option>
                        </Form.Control>
                    </Col>
                    <Col className="col-8">
                        <InputGroup>
                            <FormControl onChange={onChangeTitleOrContent}
                                id="SearchBar"
                                aria-label="SearchBar"
                                aria-describedby="SearchBar"
                                onKeyPress={(e) => appKeyPress(e)}
                                value={value || ''}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text htmlFor="SearchBar">
                                    <Button type={'button'} style={{background: 'none', border: 'none', padding: '0'}} onClick={(e) => onSearchEvent(e)}>
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </Button>
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Col>

            </Card>
        </Row>
    )
}

export default SearchCard;


