

import React, { useState ,useRef } from 'react';
import styled, {css} from 'styled-components';
import nullImg from '../../../assets/img/nullimg.png';
import {Form , Col, InputGroup} from "react-bootstrap";
import $ from "jquery";
const UploadImage = ({ title, description, disabled,onChangeEvent,fileUpload,inputImg,testpreview,pre,preview ,removeImageInput,multiple ,styles}) => {

    const UploadImageContainer = styled.div`
        height:160px;
        border: ${ disabled ? '1px solid #ebedf2' : '3px dashed #3699ff' };
        border-radius:10px;
        cursor:pointer;
        
    `;

    const labelRow="3"
    const inputRow="9"
    const onClickImageUpload = (e)=>{
    window.document.getElementById('file').click();
   // $(e.target).parents('.inputParent').find('.inputFiles').()
    //$(e.target).parents('.inputParent').find('.inputFiles')[0].click()
    }
    //<div  class="btn btn-sm"  style={{marginRight: '30px', width: '38px', border:'3px' , backgroundImage : 'url('+(v.name ? v.name+v.path  : v.path )+")", backgroundSize : '100% 100%', backgroundRepeat: 'no-repeat' }} >
    return (
        <UploadImageContainer style={{ zIndex : '3'}} className="w-100 d-flex flex-column justify-content-center align-items-center flex-shrink-0 inputParent" onClick={(e)=>onClickImageUpload(e.target.files)} >
            
            { !disabled ?
            <>
             <div>
                <h5 className="font-weight-bold text-dark mt-2">{title}</h5>
                <p className="mx-2 mt-3 mb-0 text-subgray" style={{lineHeight:'1.6'}} dangerouslySetInnerHTML={{__html:description}}></p>
                </div>
            </>
            :
            
                // preview.map((v,k)=>{
                //     return(
                //         <Col>
                //         <InputGroup style={{ zIndex : '1',display:'inline'}} >
                        
                //         <div  >
                //         {/*<img src= {nullImg} className="h-100 w-100" />*/}
                //         <div >
                //             <img src={v.name? v.name+v.path :v.path } style={styles}></img>
                //             <button onClick={(e)=>removeImageInput(k)}>삭제</button>
                //         </div>
                        
                //         </div>
                //         </InputGroup>
                //         </Col>
                //     )
                //     })
                  <div>없음</div>
                    
            }
             <form encType="multipart/form-data">
                <input type='file' key='image'accept="image/*" className='inputFiles' id='file' multiple={multiple} hidden onChange={(e)=>onChangeEvent(e.target.files)}></input>
                </form>
        </UploadImageContainer>        
    )
    
}
//export default React.memo(CreateUser);
export default React.memo(UploadImage);