import React, { useEffect, useState ,useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col,FormControl, Card, Badge, Button, Modal } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import {getGoodsByIdx,createGoods,updateGoods,deleteGoods} from "./crud"
import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import swal from 'sweetalert'
import LanguageTabs from "../../../common/components/LanguageTabs"

import { label } from '@material-ui/icons';

const UploadGoods = () =>{

    const {page,idx} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    // 내용이 채워졌을 때 업로드 활성화

    // 모달 (업로드)
    const [show, setShow_upload] = useState(false);
    const handleClose_upload = () => setShow_upload(false);
    const handleShow_upload = () => setShow_upload(true);
    // 모달 삭제
    const [deleteShow, setShow_delete] = useState(false);
    const handleClose_delete = () => setShow_delete(false);
    const handleShow_delete = () => setShow_delete(true);
    const [goodsIdx,setGoodsIdx] = useState('');
    
    const [articleNumber,setArticleNumber] =useState('');
    const [title,setTitle] = useState('');
    const [price,setPrice] = useState('');
    const [langCode,setLangCode] = useState((history.location.state && history.location.state.langCode)||'en')

    const titleFocus = useRef(null);
    const articleNumberFocus = useRef(null);
    const priceFocus = useRef(null);
   
    
    const onChangeTitle = (e) => {if(e.target.value.length <=200){setTitle(e.target.value)}}
    const onChangeArticleNumber = (e) => {setArticleNumber(e.target.value)}
    const onChangeLanguage = (e) => {setLangCode(e)}
    const onChangePrice = (e) =>{
        let price = e.target.value;
        if(onlyNum(price) !== false){
            setPrice(onlyNum(price))
        }
    }


    //only num
    const onlyNum=(num)=>{
        let newNum = deleteComma(num);
        const regExp = /^[0-9]+$/
        const regExp2 = /^[,0-9]{0,9}$/
        if(newNum !==''){
            if(newNum.match(regExp)!=null){
                newNum = addComma(newNum);
                if(newNum.match(regExp2)!=null)
                {
                    return newNum;
                } else return false
            } else return false
        } else return '';
    }

    //콤마 추가
    const addComma=(num)=>{
        const regexp = /\B(?=(\d{3})+(?!\d))/g;
        return num.toString().replace(regexp, ',');   
    }
    //콤마 제거
    const deleteComma=(num)=>{
        let newNum = num.toString().replace(/,/g,"")
        return newNum
    }

    const getDetail = () =>{
        const rs =getGoodsByIdx(idx);

        rs.then(({ data : {success, message, code, data} }) => {
            if(data != null) {
                setGoodsIdx(data.idx);
                setTitle(data.title);
                setArticleNumber(data.articleNumber)
                setPrice(data.price !== null ? addComma(data.price) : data.price);
                setLangCode(data.langCode)              
            } else {
                // swal('잘못 된 접근입니다.\r\n다시 해주세요!').then(()=>{history.push(`/categoryManagement/${page}`)});
            }
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != '') {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        getDetail();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    const onSubmit=()=>{
        handleClose_upload();

        let GoodsObj = {
            title : title,
            articleNumber : articleNumber,
            price : deleteComma(price),
            langCode : langCode
        }
        if(goodsIdx !=''){
            const rs = updateGoods(goodsIdx,GoodsObj);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== '') {
                    swal({
                        title: "알림",
                        text: "정상적으로 수정 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push(`/goodsManagement/${page}/${langCode}`);
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != '') {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit();
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        } else{
            const rs = createGoods(GoodsObj);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== '') {
                    swal({
                        title: "알림",
                        text: "정상적으로 등록 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push(`/goodsManagement/1/${langCode}`);
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != '') {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit();
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        }
    }

    const deleteOnGoods=()=>{
        handleClose_delete(false);
        const rs = deleteGoods(goodsIdx);
        rs.then(({data : {success,message,code,data} })=>{
            if(success=true && message ==='success') {
                swal({
                    title :"알림",
                    text : "정상적으로 삭제 되었습니다."
                }).then((isConfirm)=>{
                    if(isConfirm){
                        history.push(`/goodsManagement/${page}/${langCode}`);
                    }
                })
            }else{
                swal({
                    title: "알림",
                    text : message
                }).then((isConfirm)=>{
                    if(isConfirm){
                        return false;
                    }
                })
            }
        }).catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != '') {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        deleteOnGoods();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    const nullCheck =()=>{
        
        let goodsFocus = [articleNumberFocus,titleFocus,priceFocus]        
        let goods = [articleNumber,title,price];        
            for(let i=0;i<goods.length; i++){
                if(goods[i]=='' || goods[i]==null){
                    handleClose_upload();                    
                    return swal('알림','등록 하시는 제품의 품번,제품명,원가 모두 입력 하셔야합니다.').then(() => {goodsFocus[i].current.focus();});
                }
            }    
        onSubmit();
    }

    useMemo(()=>{
        // if(idx !==0)
        getDetail();
    },[])


    return (
        <>
            <Container fluid className="UploadGoodsContainer">
           
                {/* 버튼영역 */}
                <Row className="mb-4 no-gutters">
                    <Col className="d-flex justify-content-between align-items-center">
                        <Button  onClick={()=>history.push(`/goodsManagement/${page}/${langCode}`)} variant="outline-primary">목록으로</Button>
                        {/* 정보입력시 활성화 ↑ */}
                        {/* <Button variant="dark" disabled>업로드</Button> */}
                        <div >
                        {goodsIdx !=='' &&
                        <Button  variant="outline-danger mr-2" onClick={handleShow_delete} >삭제</Button>
                        }
                        <Button  variant="primary" onClick={handleShow_upload} >{goodsIdx !== '' ? '수정' : '제품 등록'}</Button>
                        </div>
                    </Col>
                </Row>

                {/* 모달 (업로드) */}
                <Modal show={show} onHide={handleClose_upload} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>{goodsIdx !== '' ? '수정' : '제품 등록'} 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_upload}>취소</Button>
                        <Button variant="primary" onClick={nullCheck}>확인</Button>
                    </Modal.Footer>
                </Modal>
                 {/*모달 삭제 */}
                 <Modal show={deleteShow} onHide={handleClose_delete} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_delete}>취소</Button>
                        <Button variant="primary" onClick={deleteOnGoods}>확인</Button>
                    </Modal.Footer>
                </Modal>

                {/* 정보 입력영역 */}
                <Row className="mb-4">
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h4 className="cardTitleBig text-primary">제품 정보</h4>
                                {/* <h9> 언어별로 입력한 제품이 각 언어별 제품 리스트에 등록되며 빈칸으로 둘 경우 해당 언어 제품 리스트에는 등록이 되지 않습니다.</h9> */}
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable">
                                    <Tbody>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>언어선택</Td>
                                            <Td>
                                                 <LanguageTabs
                                                    onLanSelected={onChangeLanguage}
                                                    langCode={langCode}
                                                />  
                                            </Td>                                           
                                        </Tr>
                                        <Tr>
                                            <Td colspan="2">
                                                <Row className="no-gutters">
                                                <Col className="d-flex justify-content-between align-items-center mr-3 no-gutters" style={{width : 400,flex :'none'}} >
                                                        <label className="font-weight-bold text-dark text-left" style={{width:140}} >품번</label>
                                                        <input className="form-control" style={{flex : 'none' , width : 260}} type="text" placeholder="품번을 입력해 주세요." onChange={(e)=>onChangeArticleNumber(e)} value={articleNumber} ref={articleNumberFocus}/>
                                                    </Col>
                                                    <Col className="d-flex justify-content-between align-items-center ml-3 no-gutters" >
                                                        <label className="font-weight-bold text-dark text-left" style={{width:140}}>제품명</label>
                                                        <input className="form-control" type="text" placeholder="제품명을 입력해 주세요.(최대 200자)" onChange={(e)=>onChangeTitle(e)} value={title} ref={titleFocus}/>
                                                    </Col>
                                                    <Col className="d-flex justify-content-between align-items-center ml-3 no-gutters" style={{width : 200 ,flex:'none'}}>
                                                        <label className="font-weight-bold text-dark text-left" style={{width:80}}>원가(단가)</label>
                                                        <input className="form-control" type="text" placeholder="원가(단가)" onChange={(e)=>onChangePrice(e)} value={price} ref={priceFocus}/>
                                                    </Col>
                                                </Row>
                                                
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container >
        </>
    );
}

export default UploadGoods;
