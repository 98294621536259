import React ,{useState,useMemo} from 'react'
import { useDispatch } from 'react-redux';
import { Row, Container, Col, Card, Button, Modal } from "react-bootstrap";
import CustomPagination from '../../../common/components/CustomPagination'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import SearchCard from "../../../common/components/SearchCard"
import { useHistory, useParams } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert'
import {getMedicalStaff,deleteMedicalStaff} from './crud'
import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import {makeNotEmptyObj} from '../../../../src/_metronic/utils/utils'
import CustomSearchCardv2 from "../../../common/components/CustomSearchCardv2"

const MedicalStaffManamement= ()=>{

    const history = useHistory();
    const dispatch = useDispatch();
    const { page } = useParams();

    //삭제 모달
    const [show_delete, setShow_delete] = useState(false);
    const handleShow_delete = () => {if(checkList.length<1){swal('알림','선택하신 제품이 없습니다.').then((isConfirm)=>{if(isConfirm)return false});}else{setShow_delete(true)}};
    const handleClose_delete = () => setShow_delete(false);
    
    //의료진List data
    const [medicalStaffList ,setMedicalStaffList] = useState([]);
    const [medicalStaffPage ,setMadicalStaffPage] = useState([]);
    //체크박스
    const [checkList , setCheckList] =useState([]);
    //검색 관련 변수
    const [langCode ,setLangCode] =useState((history.location.state && history.location.state.langCode) || '');
    const [sortColumn,setSortColumn] = useState((history.location.state && history.location.state.sortColumn) || 'createAt');
    const sortColumnItems = [
        {
            text : '등록일순',
            value : 'createAt',
        },
    ];
    const [sortType, setSortType] = useState((history.location.state && history.location.state.sortType) || 'DESC')
    const sortTypeItems = [
        {
            text : '오름차순',
            value : 'ASC',
        },
        {
            text : '내림차순',
            value : 'DESC',
        },
    ];
    const [searchType, setSearchType] = useState((history.location.state && history.location.state.searchType) || 'name');
    const [search, setSearch] = useState((history.location.state && history.location.state.search) || '');
    const searchItems = [
        {
            text : '성명',
            value : 'name',
        },
    ];
    //검색 onchange event
    const onChangeSortColumn = (e)=> setSortColumn(e.target.value);
    const onChangeSortType = (e) => setSortType(e.target.value);
    const onChangeSearchType = (e) => setSearchType(e.target.value);
    const onChangeSearch = (e) => setSearch(e.target.value);
    const onSelectLanguage = (e) =>{
        setLangCode(e.target.value);
    }

    const getMediaclStaffList = (page)=>{
        let params = {
            page : page,
            size : 25,
        }
        const chkParams = {
            langCode :langCode,
            sortColumn : sortColumn,
            sortType : sortType,
        }
        if(searchType =="name") {chkParams.name = search};
        params = makeNotEmptyObj(params, chkParams);
        console.log(params);
        const rs = getMedicalStaff(params);
        rs.then(({data :{content,totalElements,totalPages,last,first,numberOfElements}}) =>{
            setMedicalStaffList(content);
            const pageObj ={
                totalElements : totalElements,
                totalPages : totalPages,
                last : last,
                first : first,
                numberOfElements :numberOfElements
            }
            setMadicalStaffPage(pageObj);
        })
        .catch(({response : data})=>{
            if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;

                        localStorage.setItem('persist:auth', JSON.stringify(auth));

                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        movePage(page);
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                }).catch(({response : data})=>{
                    if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                });
            }
        });;

    }

    const medicalStaffDelete =()=>{
        setShow_delete(false);
        const rs = deleteMedicalStaff(checkList);
        rs.then(({ data : {success, message, code, data} }) => {
            if(success === true && message === 'success') {
                swal({
                    title: "알림",
                    text: "정상적으로 삭제 되었습니다."
                }).then((isConfirm) => {
                    if(isConfirm) {
                        if(medicalStaffPage.numberOfElements === checkList.length&& page>1){
                            movePage(page-1);
                         }else{
                             movePage(page);
                         }
                    }
                })
            } else {
                swal({
                    title: "알림",
                    text: message
                }).then((isConfirm) => {
                    if(isConfirm) {
                        return false;
                    }
                })
            }
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        medicalStaffDelete();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    //체크 박스 전체 선택 유무
    const allCheck = (checked) =>{
        if(checked){
            const ckbox = [];
            medicalStaffList.map((v,i)=>{
            ckbox.push(v.idx);
            setCheckList(ckbox);
            })
        }else if(!checked){
            setCheckList([]);
        }
    }

    // 체크 박스 단일 선택
    const oneCheck = (checked,ckidx) =>{
        if(checked){
            setCheckList([...checkList, ckidx]);
        }else if(!checked){
            setCheckList(checkList.filter(idx => idx !== ckidx));
        }
    } 

    /* 디테일 페이지로 이동 */
    const handleClick = (idx) => {
        const params = {
            langCode,      
            searchType,
            search,
            sortColumn,
            sortType
        }
        
        const url = {
        pathname : `/medicalStaffManagement_upload/${page}/${idx}`,
        state : params,
        }
        history.push(url);
    }

    const movePage = (page) => {   
        const params = {   
            langCode,   
            searchType,
            search,
            sortColumn,
            sortType
        }
        
        history.push({
            pathname : "/medicalStaffManagement/"+page,
            state : params
        });
        setCheckList([]);
        getMediaclStaffList(page);
    }

    //검색
    const onSearch = (e) => {
        e.preventDefault();
        movePage(1);

    }
    
    useMemo(() => {
        movePage(page);        
    },[])

    return (
        <>
        <Container fluid className="medicalStaffManagement">
            
                 <CustomSearchCardv2 
                    isLanguage = {true}
                    onSelectLanguage={onSelectLanguage}
                    langValue = {langCode}

                    isSortColumn={true}
                    onChangeSortColumn ={onChangeSortColumn}
                    sortColumnItems={sortColumnItems}
                    sortColumn={sortColumn}

                    isSortType={false}
                    onChangeSortType={onChangeSortType}
                    sortTypeItems={sortTypeItems}
                    sortType={sortType}

                    isSearch={true}
                    onChangeSearch={onChangeSearch}
                    onSearchEvent={onSearch}
                    searchItems={searchItems}
                    onChangeSearchType={onChangeSearchType}
                    search={search}   
                    />

            {/* 공지사항 리스트 */}
            <Row>
                <Col>
                    <Card>
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <h4 className="cardTitleBig">의료진 &nbsp;&nbsp;<span style={{fontSize : '14px', fontWeight: 400}}>[ 전체 : {medicalStaffPage.totalElements} ]</span></h4>
                            <Button onClick={() => { history.push('/medicalStaffManagement_upload/1/0') }} variant="primary">의료진 등록</Button>
                        </Card.Header>
                        <Card.Body className="d-flex no-gutters p-0">
                            <Table className="commonTable borderTable bb-1 clickable">
                                    <Thead>
                                        <Tr>
                                            <Th style={{width:50}}>
                                            <Checkbox 
                                            checked = {
                                                checkList.length === medicalStaffPage.numberOfElements && medicalStaffPage.numberOfElements>0
                                                ? true
                                                : false
                                            }
                                            onChange={(e) =>allCheck(e.target.checked)}/> 
                                            </Th>
                                            <Th style={{width:80}}>번호</Th>
                                            <Th style={{width:150}}>언어</Th>
                                            <Th>성명</Th>
                                            <Th style={{width:160}}>등록(수정)일</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                         {
                                         medicalStaffList.length > 0 ? medicalStaffList.map((v,i) =>{
                                             return (
                                                 <Tr>
                                                     <Td><Checkbox 
                                                        checked={
                                                            checkList.includes(v.idx) ? true : false
                                                        }
                                                     onChange={(e)=>oneCheck(e.target.checked,v.idx)} />
                                                     </Td>
                                                     <Td onClick={() => {handleClick(v.idx)}}>{v.idx}</Td>
                                                     <Td onClick={() => {handleClick(v.idx)}}>{v.langCodeName}</Td>
                                                     <Td style={{textAlign:'left',padding:'1rem'}} onClick={() => {handleClick(v.idx)}}>{v.name}</Td>
                                                     <Td onClick={() => {handleClick(v.idx)}}>{v.createAt}</Td>
                                                 </Tr>
                                             )
                                             })
                                             :
                                             <Tr>
                                                <Td className="font-weight-bold" colSpan={5} >데이터가 없습니다.</Td>
                                            </Tr>
                                             }
                                    </Tbody>
                                </Table>
                        </Card.Body>
                        <Card.Footer className="d-flex flex-row justify-content-between align-items-center paginationFooter">
                            <div>
                                
                                <CustomPagination
                                    itemsCount={10}
                                    pageData={medicalStaffPage}
                                    page={page}
                                    onClickEvent={movePage}
                                >
                                </CustomPagination>
                            </div>
                            <div>
                                <Button variant="outline-danger ml-2" onClick={handleShow_delete}>의료진 삭제</Button>
                            </div>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>

            {/* 모달 (공지사항 삭제) */}
            <Modal show={show_delete} onHide={()=>handleClose_delete('revoke')} centered>
                <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"  onClick={handleClose_delete}>취소</Button>
                    <Button variant="danger" onClick={medicalStaffDelete}>확인</Button>
                </Modal.Footer>
            </Modal>

        </Container >
    </>
    )
};

export default MedicalStaffManamement;
 