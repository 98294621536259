import React, { useEffect, useState,useMemo,useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col, Button, Tabs, Tab, Modal } from "react-bootstrap";
import TabList from './TabList';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import CustomPagination from '../../../common/components/CustomPagination'
import {getCategoryList,getVideoList,deleteVideo,updataOpenFlag} from "./crud"
import SearchCard from "../../../common/components/SearchCard"
import CustomSearchCardv2 from "../../../common/components/CustomSearchCardv2"


import swal from 'sweetalert'
import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import {makeNotEmptyObj} from '../../../../src/_metronic/utils/utils'
// import Pagination from '../../../common/components/Pagination';
// import { getUsers } from './crud';

const VideoManagement = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { page,categoryIdx } = useParams();

    
   /* // 탭 리스트
    const videoTabs = [
        {
            title: '이글스시스템',
            key: 'eagles'
        },
        {
            title: 'HOW TO',
            key: 'howto'
        },
        {
            title: '마이스토리',
            key: 'mystory'
        },
        {
            title: '사업진행사례',
            key: 'ex'
        },
        {
            title: '뉴 핀 스피치',
            key: 'speech'
        },
        {
            title: '리더쉽',
            key: 'leader'
        },
        {
            title: '헬스 & 뷰티',
            key: 'hnb'
        },
        {
            title: '메가마스터',
            key: 'master'
        },
        {
            title: 'NSP 시너지',
            key: 'nsp'
        }
    ]*/
    // 모달 (비공개 전환)
    const [show_private, setShow_private] = useState(false);
    const handleClose_private = () => setShow_private(false);
    const handleShow_private = () => {if(checkList.length<1){return swal('알림','선택하신 동영상이 없습니다.')}else{setShow_private(true)}};
    // 모달 (공개 전환)
    const [show_public, setShow_public] = useState(false);
    const handleClose_public = () => setShow_public(false);
    const handleShow_public = () => {if(checkList.length<1){return swal('알림','선택하신 동영상이 없습니다.')}else{setShow_public(true)}};

    // 모달 (메세지 삭제)
    const [show_delete, setShow_delete] = useState(false);
    const handleClose_delete = () => setShow_delete(false);
    const handleShow_delete = () => {if(checkList.length<1){return swal('알림','선택하신 동영상이 없습니다.')}else{setShow_delete(true)}};

    //검색영역
    const [langCode ,setLangCode] =useState((history.location.state && history.location.state.langCode) || '');
    //검색 변수
        const [sortColumn,setSortColumn] = useState((history.location.state && history.location.state.sortColumn) || 'createAt');
        const sortColumnItems = [
            {
                text : '등록일순',
                value : 'createAt',
            },
            {
                text : '공개일순',
                value : 'openDateAt',
            },
            {
                text : '순서',
                value : 'num',
            },
        ];
        const [sortType, setSortType] = useState((history.location.state && history.location.state.sortType) || 'DESC')
        const sortTypeItems = [
            {
                text : '오름차순',
                value : 'ASC',
            },
            {
                text : '내림차순',
                value : 'DESC',
            },
        ];
        const [searchType, setSearchType] = useState((history.location.state && history.location.state.searchType) || 'title');
        const [search, setSearch] = useState((history.location.state && history.location.state.search) || '');
        const searchItems = [
            {
                text : '제목',
                value : 'title',
            },
        ];
    
    const [searchParams,setSearchParams] =useState([sortColumn,langCode,searchType,search]);
    const [selectctgidx,setSelectctgidx] =useState(categoryIdx)
    const [Page,setPage] =useState(page);
    const [videoPage,setVideoPage] = useState([]);
    // const [avIdx,setavIdx] = useState();
    // const [firstcategoryIdx,setfirstcategoryIdx] = useState('')
    const [checkList,setCheckList] = useState([]);
    const [categoryList,setCategoryList] = useState([{
        idx:[],
        titleKo:[],
        thumbnailPath:[],
        useFlag:[],
        num:[],
        type:[]
    }]);
    const [videoList,setVideoList] = useState([{
        idx:[],
        langCode:[],
        categoryIdx : [],
        showOrder:[],
        title:[],
        thumbnailPath:[],
        content:[],
        author:[],
        videoPath:[],
        playtime:[],
        uploadComplete:[],
        viewCnt:[],
        openFlag:[],
        mediaType:[],
        num:[],
        createAt:[]
    }])

    //검색 onChange
    const onChangeSortColumn = (e)=> setSortColumn(e.target.value);
    const onChangeSortType = (e) => setSortType(e.target.value);
    const onChangeSearchType = (e) => setSearchType(e.target.value);
    const onChangeSearch = (e) => setSearch(e.target.value);
    const onSelectLanguage = (e) =>{
        setLangCode(e.target.value);
    }


    //검색 클릭 이벤트
    const onSearch = (e) => {
        e.preventDefault();
        movePage(1,selectctgidx);

    }

    
    const onChangecategory=(e)=>{
        const categort = true
        setSelectctgidx(e);
        movePage(1,e,categort);
    }

    //비디오 리스트 
    const readVideoList=(page,selectctgidx)=>{
        let params = {
            page : page,
            size : 10,
            categoryIdx: selectctgidx
        }
        const chkParams = {
            langCode : langCode,      
            sortColumn : sortColumn,
            sortType : sortType,
        }
        if(searchType == 'title') { chkParams.title = search; }
        params = makeNotEmptyObj(params,chkParams);
        const rs = getVideoList(params);
        rs.then(({data : {content,totalElements,totalPages,last,first,numberOfElements}})=>{
            setVideoList(content);
            const pageObj = {
                totalElements : totalElements,
                totalPages : totalPages,
                last : last,
                first : first,
                numberOfElements : numberOfElements
            }
            setVideoPage(pageObj);
        }).catch(({response : data})=>{
            if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;

                        localStorage.setItem('persist:auth', JSON.stringify(auth));

                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        movePage(page,selectctgidx);//수정해야함
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                }).catch(({response : data})=>{
                    if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                });
            }
        });;
    
    }
    const updateOpenFlag =(e)=>{
            const rs =updataOpenFlag(checkList,e);
            rs.then(({ data : {success, message, code, data} }) => {
                if(success === true && message === 'success') {
                    swal({
                        title: "알림",
                        text: "정상적으로 수정 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            movePage(Page,categoryIdx);
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            updateOpenFlag(e);
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
                if(e=='true'){
                    setShow_public(false)
                }else{
                    setShow_private(false)
                }
                setCheckList([]);
            }

    //비디오 삭제
    const videoDelete =()=>{
        setShow_delete(false);
        const rs = deleteVideo(checkList);
        rs.then(({ data : {success, message, code, data} }) => {
            if(success === true && message === 'success') {
                swal({
                    title: "알림",
                    text: "정상적으로 삭제 되었습니다."
                }).then((isConfirm) => {
                    if(isConfirm) {
                        if(videoPage.numberOfElements === checkList.length&& Page>1){
                            // history.push(`/noticeManagement/${page-1}`);
                            movePage(Page-1,categoryIdx);
                         }else{
                             movePage(Page,categoryIdx);
                         }
                    }
                })
            } else {
                swal({
                    title: "알림",
                    text: message
                }).then((isConfirm) => {
                    if(isConfirm) {
                        return false;
                    }
                })
            }
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        videoDelete();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    //카테고리 리스트
    const readCategoryList = ()=>{
        let params = {
            sortType : "ASC",
            sortColumn : "num",
            contentType : 'VIDEO'
        }
      const rs = getCategoryList(params);
        rs.then(({data :{content}})=>{
            setCategoryList(content);
            // console.log('content[0].idx',content[0].idx)
            if(categoryIdx ==='0'){
                movePage(1,content[0].idx)
            }
            //setfirstcategoryIdx(content[0].idx)
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        readCategoryList();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

     //체크 박스 전체 선택 유무
     const allCheck = (checked) =>{
        if(checked){
            const ckbox = [];
            videoList.map((v,i)=>{
            ckbox.push(v.idx);
            setCheckList(ckbox);
            })
        }else if(!checked){
            setCheckList([]);
        }
    }

    // 체크 박스 단일 선택
    const oneCheck = (checked,ckidx) =>{
        if(checked){
            setCheckList([...checkList, ckidx]);
        }else if(!checked){
            setCheckList(checkList.filter(idx => idx !== ckidx));
        }
    }

    const movePage = (page,ctgIdx,categort) => {  
        
        const params = {      
            langCode ,
            searchType,
            search,
            sortColumn,
            sortType,
        }
        const params2 = {

        }
        // console.log('categort',categort)
        history.push({
            pathname : "/videoManagement/"+page+"/"+ctgIdx,
            state : categort? params2 : params
        });
            readVideoList(page,ctgIdx);
            setCheckList([]);
        }
        
         //window.onbeforeunload =()=>{
         //   history.push('/videoManagement/'+Page+'/'+selectctgidx) 
         //}

    useMemo(()=>{
        readCategoryList();
        movePage(page,selectctgidx);
    },[])
    return (
        <>
            <Container fluid className="VideoConatainer">
                    <CustomSearchCardv2 
                        isLanguage = {true}
                        onSelectLanguage={onSelectLanguage}
                        langValue = {langCode}

                        isSortColumn={true}
                        onChangeSortColumn ={onChangeSortColumn}
                        sortColumnItems={sortColumnItems}
                        sortColumn={sortColumn}

                        isSortType={true}
                        onChangeSortType={onChangeSortType}
                        sortTypeItems={sortTypeItems}
                        sortType={sortType}

                        isSearch={true}
                        onChangeSearch={onChangeSearch}
                        onSearchEvent={onSearch}
                        searchItems={searchItems}
                        onChangeSearchType={onChangeSearchType}
                        search={search}   
                        />
                <Row>
                    <Col>
                    <Tabs defaultActiveKey={categoryIdx} ActiveKey={selectctgidx} transition={false} className="upTabs m-0" onSelect={(e)=>onChangecategory(e)} > 
                        {categoryList.map((item, index)=> {
                            return (
                                <Tab eventKey={item.idx} title={item.titleKo}>
                                    <TabList
                                        searchParams ={searchParams}
                                        title={item.titleKo}
                                        history={history}
                                        handleShow_private={handleShow_private}
                                        handleShow_public={handleShow_public}
                                        handleShow_delete={handleShow_delete}
                                        videoPage = {videoPage}
                                        movePage={movePage}
                                        page={Page}
                                        videoList={videoList}
                                        categoryIdx={selectctgidx}
                                        allCheck ={allCheck}
                                        oneCheck = {oneCheck}
                                        checkList={checkList}
                                    />
                                </Tab>
                            )
                        })}
                    </Tabs>
                    </Col>
                    
                </Row>

                {/* 모달 (비공개 전환) */}
                <Modal show={show_private} onHide={handleClose_private} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>비공개로 전환하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_private}>취소</Button>
                        <Button variant="primary" id='false' onClick={(e)=>updateOpenFlag(e.target.id)}>확인</Button>
                    </Modal.Footer>
                </Modal>

                {/* 모달 (공개 전환) */}
                <Modal show={show_public} onHide={handleClose_public} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>공개로 전환하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_public}>취소</Button>
                        <Button variant="success" id='true' onClick={(e)=>updateOpenFlag(e.target.id)}>확인</Button>
                    </Modal.Footer>
                </Modal>
                
                {/* 모달 (동영상 삭제) */}
                <Modal show={show_delete} onHide={handleClose_delete} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_delete}>취소</Button>
                        <Button variant="danger" onClick={videoDelete}>확인</Button>
                    </Modal.Footer>
                </Modal>

            </Container >
        </>
    );
};

export default VideoManagement;
