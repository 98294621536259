// UploadNotice _crud
import axios from "axios";

export const adming_notice_uploadImg = "/api/v1/admin/common/onlyFileUploads";
export const admin_notice = "/api/v1/admin/notice";
export const admin_mod_notice = "/api/v1/admin/notice/"

// new
export const API_ADMIN_NOTICE = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/notice';
export const API_ADMIN_NOTICE_BY_IDX = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/notice/';


export function Uploadnoticeimg(params){

    // console.log('파일 업로드 params',params);
    return axios.post(adming_notice_uploadImg,params, {headers : {'Content-Type' : 'multipart/form-data'} })
}

export function NoticeUpload(params){
    // console.log('공지사항 업로드 params',params);
    return axios.post(admin_notice,params);
}
export function ModifyNotice(noticeIdx ,params){
    // console.log('공지 사항 수정 idx',noticeIdx );
    // console.log('공지 사항 수정 내용',params);
    return axios.put(admin_mod_notice+noticeIdx,params);
}

// new
export function createNotice(params) {
    // POST : /api/v1/admin/notice
    return axios.post(API_ADMIN_NOTICE, params);
}

export function updateNotice(noticeIdx, params) {
    // PUT : /api/v1/admin/notice/{noticeIdx}
    return axios.put(API_ADMIN_NOTICE_BY_IDX + noticeIdx, params);
}

export function getNoticeByIdx(noticeIdx) {
    // GET : /api/v1/admin/notice/{noticeIdx}
    return axios.get(API_ADMIN_NOTICE_BY_IDX + noticeIdx);
}

export function deleteNotice(noticeIdx) {
    return axios.delete(API_ADMIN_NOTICE_BY_IDX + noticeIdx);
}