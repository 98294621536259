import React, { useEffect, useState,useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col, InputGroup, FormControl, Card, Badge, Button, Pagination, Modal } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import CustomPagination from '../../../common/components/CustomPagination';
import Checkbox from '@material-ui/core/Checkbox';
import SearchCard from "../../../common/components/SearchCard"
import { getPersonList, deletePerson } from "./crud";
import swal from 'sweetalert'
import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import {makeNotEmptyObj} from '../../../../src/_metronic/utils/utils'
import CustomSearchCardv2 from "../../../common/components/CustomSearchCardv2"


// import Pagination from '../../../common/components/Pagination';
// import { getUsers } from './crud';

// pagination

const HonoraryManagement = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { page } = useParams();
    // 모달 (메세지 삭제)
    const [show_delete, setShow_delete] = useState(false);
    const handleClose_delete = () => setShow_delete(false);
    const handleShow_delete = () => {if(checkList.length<1){return swal('알림','선택하신 명예의전당이 없습니다.')}else{setShow_delete(true)}}
    //검색 관련
    const [type ,setType] =useState((history.location.state && history.location.state.type) || '');
    const [sortColumn,setSortColumn] = useState((history.location.state && history.location.state.sortColumn) || 'createAt');
    const sortColumnItems = [
        {
            text : '등록일순',
            value : 'createAt',
        },
        {
            text : '수정일순',
            value : 'updateAt',
        },
        {
            text : '순서',
            value : 'level',
        }
    ];
    const [sortType, setSortType] = useState((history.location.state && history.location.state.sortType) || 'DESC')
    const sortTypeItems = [
        {
            text : '내림차순',
            value : 'DESC',
        },
        {
            text : '오름차순',
            value : 'ASC',
        },
    ];
    const [searchType, setSearchType] = useState((history.location.state && history.location.state.searchType) || 'name');
    const [search, setSearch] = useState((history.location.state && history.location.state.search) || '');
    const searchItems = [
        {
            text : '성명',
            value : 'name',
        },
          {
            text : '국가',
            value : 'country',
        },
    ];

    const [honoraryPage,setHonoraryPage] = useState([]);
    const [checkList , setCheckList] =useState([]);
    const [typeList ,setTypeList] = useState([]);
    const [honoraryList , setHonoraryList] = useState([{
        idx: [],
        level : [],
        country : [],
        name : [],
        nameEng : [],
        picPath : [],
        thumPath : [],
        type: [],
        storyList : [[]],
        createAt:[],
        updateAt: [],
    }]);

    //onChange 
    const onChangeSortColumn = (e)=> setSortColumn(e.target.value);
    const onChangeSortType = (e) => setSortType(e.target.value);
    const onChangeSearchType = (e) => setSearchType(e.target.value);
    const onChangeSearch = (e) => setSearch(e.target.value);
    const onSelectPersonType = (e) =>{
        setType(e.target.value);
    }

    
    //체크 박스 전체 선택 유무
    const allCheck = (checked) =>{
        if(checked){
            const ckbox = [];
            honoraryList.map((v,i)=>{
            ckbox.push(v.idx);
            setCheckList(ckbox);
            })
        }else if(!checked){
            setCheckList([]);
        }
    }

    // 체크 박스 단일 선택
    const oneCheck = (checked,ckidx) =>{
        if(checked){
            setCheckList([...checkList, ckidx]);
        }else if(!checked){
            setCheckList(checkList.filter(idx => idx !== ckidx));
        }
    }
    //명예의 전당 리스트
    const readHonoraryList =(page) =>{
        let params = {
            page :page,
            size :10,
        }
        const chkParams = {
            type:type,
            sortColumn : sortColumn,
            sortType : sortType,
        }
        if(searchType == 'name') { chkParams.name = search; }
        if(searchType == 'country') { chkParams.country = search; }

        params = makeNotEmptyObj(params,chkParams);
        const rs = getPersonList(params);
        rs.then(({data :{content,totalElements,totalPages,last,first,numberOfElements}}) =>{
            setHonoraryList(content);
            const pageObj ={
                totalElements : totalElements,
                totalPages : totalPages,
                last : last,
                first : first,
                numberOfElements
            }
            setHonoraryPage(pageObj);
        })
        .catch(({response : data})=>{
            if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;

                        localStorage.setItem('persist:auth', JSON.stringify(auth));

                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        movePage(page);
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                }).catch(({response : data})=>{
                    if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                });
            }
        });;
    }
    //삭제
    const honoraryDelete =()=>{
        setShow_delete(false);
        const rs = deletePerson(checkList);
        rs.then(({ data : {success, message, code, data} }) => {
            if(success === true && message === 'success') {
                swal({
                    title: "알림",
                    text: "정상적으로 삭제 되었습니다."
                }).then((isConfirm) => {
                    if(isConfirm) {
                        movePage(page);
                    }
                })
            } else {
                swal({
                    title: "알림",
                    text: message
                }).then((isConfirm) => {
                    if(isConfirm) {
                        return false;
                    }
                })
            }
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        honoraryDelete();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    //페이지 이동
    const movePage = (page) =>{
        const params = {      
            searchType,
            search,
            sortColumn,
            sortType,
            type,
        }
        
        history.push({
            pathname : "/honoraryManagement/"+page,
            state : params
        });
        setCheckList([]);
        readHonoraryList(page);
    }
    //디테일 페이지이동
    const handleClick= (idx) =>{
        const params ={
            searchType,
            search,
            sortColumn,
            sortType,
            type,
        }
        const url = {
            pathname : `/honoraryManagement_upload/${page}/${idx}`,
            state :params,
        } 
        history.push(url);
    }
    //검색
    const onSearch = (e) => {
        e.preventDefault();
        movePage(1);
    }

    useMemo(()=>{
        movePage(page);
    },[])


    return (
        <>
            <Container fluid className="honoraryContainer">
                
                {/* 검색영역 */}
                {/* <SearchCard type="honorary" 
                onChangesortColumn ={onChangesortColumn}
                onSelectType = {onSelectType}
                onChangeTitleOrContent={onChangeTitleOrContent}
                onSearchEvent={onSearch}
                sortValue={sortColumn}
                typeValue={type}
                value={name}
                /> */}
                   <CustomSearchCardv2 
                    isSortColumn={true}
                    onChangeSortColumn ={onChangeSortColumn}
                    sortColumnItems={sortColumnItems}
                    sortColumn={sortColumn}

                    isSortType={true}
                    onChangeSortType={onChangeSortType}
                    sortTypeItems={sortTypeItems}
                    sortType={sortType}

                    isSearch={true}
                    onChangeSearch={onChangeSearch}
                    onSearchEvent={onSearch}
                    searchItems={searchItems}
                    onChangeSearchType={onChangeSearchType}
                    search={search}

                    isPersonType={true}
                    onSelectPersonType={onSelectPersonType}
                    personTypeValue={type}
                />

                {/* 명예의전당 리스트 */}
                <Row>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h4 className="cardTitleBig">명예의전당 &nbsp;&nbsp;<span style={{fontSize : '14px', fontWeight: 400}}>[ 전체 : {honoraryPage.totalElements} ]</span></h4>                                
                                <Button onClick={() => { history.push('/honoraryManagement_upload') }} variant="primary">명예인 업로드</Button>                                
                            </Card.Header>                                                                                                   
                            <Card.Body className="d-flex justify-content-between align-items-center no-gutters p-0">
                                <Table className="commonTable borderTable bb-1">
                                    <Thead>
                                        <Tr>
                                            <Th style={{width:50}}>
                                                <Checkbox checked = {
                                                checkList.length === honoraryPage.numberOfElements && honoraryPage.numberOfElements>0
                                                ? true
                                                : false
                                            }
                                            onChange={(e) =>allCheck(e.target.checked)}/>
                                            </Th>
                                            <Th style={{width:80}}>번호</Th>
                                            <Th style={{width:150}}>국가</Th>
                                            <Th>성명</Th>
                                            <Th style={{width:240}}>등급</Th>
                                            <Th style={{width:80}}>순서</Th>
                                            <Th style={{width:160}}>등록일</Th>
                                            <Th style={{width:160}}>수정일</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                    
                                    {honoraryList.length >0
                                    ?
                                    honoraryList.map((v,i) =>{
                                                return (
                                                    <Tr>
                                                        <Td><Checkbox 
                                                        checked={
                                                            checkList.includes(v.idx) ? true : false
                                                        }
                                                        onChange={(e)=>oneCheck(e.target.checked,v.idx)} />
                                                        </Td>
                                                        <Td onClick={() => {handleClick(v.idx)}}>{v.idx}</Td>
                                                        <Td onClick={() => {handleClick(v.idx)}}>{v.country}</Td>
                                                        <Td onClick={() => {handleClick(v.idx)}}>{v.name}</Td>
                                                        <Td onClick={() => {handleClick(v.idx)}}>{v.typeDescription}</Td>
                                                        <Td onClick={() => {handleClick(v.idx)}}>{v.level}</Td>
                                                        <Td onClick={() => {handleClick(v.idx)}}>{v.createAt}</Td>
                                                        <Td onClick={() => {handleClick(v.idx)}}>{v.updateAt}</Td>
                                                    </Tr>
                                                    )
                                                    })
                                    :
                                                <Tr>
                                                <Td className="font-weight-bold" colSpan={6} >관련 게시글이 없습니다.</Td>
                                                </Tr>               
                                    }
                                        {/* 
                                        <Tr>
                                            <Td><Checkbox /></Td>
                                            <Td>[번호 DATA]</Td>
                                            <Td className="ellipsis">[국가 C]</Td>
                                            <Td className="text-left fw-500 ellipsis">[성명 DATA]</Td>
                                            <Td className="ellipsis">[등급 DATA]</Td>
                                            <Td>[날짜 시간 DATA]</Td>
                                        </Tr>
                                        */}
                                        {/*
                                        <Tr>
                                            <Td><Checkbox /></Td>
                                            <Td>2,345</Td>
                                            <Td className="ellipsis">INDONESIA</Td>
                                            <Td className="text-left fw-500 ellipsis">Ganesh Wirama Sugebong Putra</Td>
                                            <Td className="ellipsis">에메랄드 이그제큐티브</Td>
                                            <Td>2020.10.24 00:00</Td>
                                        </Tr>
                                        <Tr>
                                            <Td><Checkbox /></Td>
                                            <Td>2,345</Td>
                                            <Td className="ellipsis">INDONESIA</Td>
                                            <Td className="text-left fw-500 ellipsis">Ganesh Wirama Sugebong Putra</Td>
                                            <Td className="ellipsis">에메랄드 이그제큐티브</Td>
                                            <Td>2020.10.24 00:00</Td>
                                        </Tr>
                                        <Tr>
                                            <Td><Checkbox /></Td>
                                            <Td>2,345</Td>
                                            <Td className="ellipsis">INDONESIA</Td>
                                            <Td className="text-left fw-500 ellipsis">Ganesh Wirama Sugebong Putra</Td>
                                            <Td className="ellipsis">에메랄드 이그제큐티브</Td>
                                            <Td>2020.10.24 00:00</Td>
                                        </Tr>*/}
                                    </Tbody>
                                </Table>
                            </Card.Body>
                            <Card.Footer className="d-flex flex-row justify-content-between align-items-center paginationFooter">
                                <div>
                                <CustomPagination
                                        itemsCount={10}
                                        pageData={honoraryPage}
                                        page={page}
                                        onClickEvent={movePage}
                                    >
                                    </CustomPagination>
                                </div>
                                <div>
                                    <Button variant="outline-danger ml-2" onClick={handleShow_delete}>명예인 삭제</Button>
                                </div>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>

                {/* 모달 (명예인 삭제) */}
                <Modal show={show_delete} onHide={handleClose_delete} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_delete}>취소</Button>
                        <Button variant="danger" onClick={honoraryDelete}>확인</Button>
                    </Modal.Footer>
                </Modal>

            </Container >
        </>
    );
};

export default HonoraryManagement;
