// UploadAudiobook _crud

import axios from "axios";

export const API_ADMIN_AUDIO_BY_IDX = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/ebook/';
export const API_ADMIN_AUDIO = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/ebook';
export const API_ADMIN_AUDIO_UPLOADIMG = "/api/" + process.env.REACT_APP_API_VERSION + "/admin/common/onlyFileUploads";
export const API_ADMIN_AUDIO_UPLOADAUDIO = "/api/" + process.env.REACT_APP_API_VERSION + "/admin/common/onlyAudioFileUploads";


export function getAudioBookByIdx(audioIdx){
    return axios.get(API_ADMIN_AUDIO_BY_IDX+audioIdx);
}

export function createAudio(params){
    return axios.post(API_ADMIN_AUDIO,params);
}

export function updateAudio(audioIdx, params) {
    return axios.put(API_ADMIN_AUDIO_BY_IDX + audioIdx, params);
}

export function uploadAudioImg(params){
    return axios.post(API_ADMIN_AUDIO_UPLOADIMG,params, {headers : {'Content-Type' : 'multipart/form-data'} })
}

export function uploadAudioFile(params){
    return axios.post(API_ADMIN_AUDIO_UPLOADAUDIO,params, {headers : {'Content-Type' : 'multipart/form-data'} })
}

export function deleteAudioByIdx(audioIdx){
    return axios.delete(API_ADMIN_AUDIO_BY_IDX+audioIdx);
}