import React, { useEffect, useState,useMemo,useRef,createRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col, InputGroup,Control, Card, Badge, Button, Tabs, Tab, Modal} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import LanguagesTabs from "../../../common/components/LanguageTabs/LanguagesTabs"
import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import swal from 'sweetalert'
import userTableMock from '../../../_metronic/__mocks__/userTableMock';
import { TemporaryCredentials } from 'aws-sdk';
import { ContactSupportOutlined, GradeTwoTone, SignalCellularNull } from '@material-ui/icons';
import {getQuestionByIdx,deleteQuestion,updateQuestion,createQuestion} from "./crud"
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import ka from 'date-fns/locale/ka/index.js';
// RemoveCircleOutline

const useStyles = makeStyles(theme => ({
    radio: {
        '&$checked': {
          color: '#343a40'
        }
    },
    checked: {}
}));

const UploadQuestion=()=>{
    const classes = useStyles();

    const { page, idx,langCode } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [categoryIdx ,setCategoryIdx] = useState((history.location.state && history.location.state.categoryIdx)||'')
    const [selectLangCode ,setSelectLangCode] =useState(langCode);
    // 모달 (업로드)
    const [uploadShow, setShow_upload] = useState(false);
    const handleClose_upload = () => setShow_upload(false);
    const handleShow_upload = () => setShow_upload(true);
    //삭제
    const [deleteShow, setShow_delete] = useState(false);
    const handleClose_delete = () => setShow_delete(false);
    const handleShow_delete = () => setShow_delete(true);
    const [langIdx,setLangIdx] = useState();
    const [questionIdx,setQuestionIdx] =useState('');
    const [questionLangCode,setQuestionLangCode]= useState(['en']); // 선택한 언어 리스트
    const [questionType,setQuestionType] = useState(['SINGLE'])
    const [titleFocusList,setTitleListFocusList] = useState([createRef(null)]);
    const [choiceTitleFocusList,setChoiceTitleFocusList] = useState([[createRef(null)]])
    const [choiceList,setChoiceList] = useState([1])
    const [questionItemList,setQuestionItemList] = useState([{
        langCode : 'en',
        title : '',
        type :'SINGLE',
        useFlag:true,
        questionChoiceList : [{
            num : 1,
            idx : 0,
            title : '',
            score : 1,
            useFlag:true,
        }]
    }]);

    const onChangeQuestionchoiceListTitle=(i,k,e,c)=>{
        let newQuestionItemList = [...questionItemList];
        let numList =newQuestionItemList[i].questionChoiceList.map((v,i) =>{return v.num})
        let changeIdx = numList.indexOf(c.num);
        let choiceItem ={
            num : c.num,
            idx : c.idx,
            title : e.target.value,
            score : c.score,
            useFlag : c.useFlag
        }
        newQuestionItemList[i].questionChoiceList.splice(changeIdx,1,choiceItem);
        setQuestionItemList(newQuestionItemList);
    }

    const onChangeQuestionItemTitle=(e,i)=>{
        let newQuestionItemList = [...questionItemList];
        let questionItem = {
            idx : newQuestionItemList[i].idx,
            langCode : newQuestionItemList[i].langCode,
            title : e.target.value,
            type : newQuestionItemList[i].type,
            useFlag : newQuestionItemList[i].useFlag,
            questionChoiceList : newQuestionItemList[i].questionChoiceList
        };
        newQuestionItemList.splice(i,1,questionItem);
        setQuestionItemList(newQuestionItemList);
    };

    const onChlickPlus=(i,k,c)=>{
        let newQuestionItemList = [...questionItemList];
        let numList =newQuestionItemList[i].questionChoiceList.map((v,i) =>{return v.num})
        let changeIdx = numList.indexOf(c.num);
        let choiceItem = {
            num : c.num,
            idx : c.idx,
            title : c.title,
            score : c.score < 10 ? c.score+1 : c.score,
            useFlag : c.useFlag
        }
        newQuestionItemList[i].questionChoiceList.splice(changeIdx,1,choiceItem);
        setQuestionItemList(newQuestionItemList);
    }

    const onChlickMinus=(i,k,c)=>{
        let newQuestionItemList = [...questionItemList];
        let numList =newQuestionItemList[i].questionChoiceList.map((v,i) =>{return v.num})
        let changeIdx = numList.indexOf(c.num);
        let choiceItem = {
            num : c.num,
            idx : c.idx,
            title : c.title,
            score : c.score > 1 ? c.score-1 : c.score,
            useFlag : c.useFlag
        }
        newQuestionItemList[i].questionChoiceList.splice(changeIdx,1,choiceItem);
        setQuestionItemList(newQuestionItemList);
    }

    const onChangeQuestionType=(e,i)=> {
        questionItemList[i].type = e.target.value;
        let newQuestionType = [...questionType]
        newQuestionType.splice(i,1,e.target.value); 
        setQuestionType(newQuestionType);
    };

    const onChangeQuestionLanguage = (e,i) => {    
        let newQuestionLangCode = [...questionLangCode];
        let langCodeIdx = newQuestionLangCode.indexOf(questionItemList[i].langCode)
        newQuestionLangCode.splice(langCodeIdx,1,e); 
        questionItemList[i].langCode = e;
        setQuestionLangCode(newQuestionLangCode);
    } 

    const addQuestionItemList = () =>{
        const e = ['en','ko','ja','zh','id','th'];
        let newTitleFocusList = [...titleFocusList , createRef(null)];
        var item = {
            langCode : 'en',
            title : '',
            type : 'SINGLE',
            useFlag:true,
            questionChoiceList : [{
                num : 1,
                idx :0,
                title : '',
                score : 1,
                useFlag:true,
            }]
        }

        let tmp = e.filter((item)=>{return questionLangCode.filter(itm => item === itm).length === 0});
        
        if(tmp.length > 0){
            setQuestionLangCode([...questionLangCode, tmp[0]])
            item.langCode = tmp[0];
        }else{
            setQuestionLangCode([...questionLangCode,''])
        }
        setQuestionType([...questionType,'SINGLE'])
        setQuestionItemList([...questionItemList, item ]);
        setChoiceTitleFocusList([...choiceTitleFocusList,[createRef(null)]])
        setTitleListFocusList(newTitleFocusList)
        setChoiceList([...choiceList,1])
    }

    const deleteQuestionItemList = (e,i)=>{
        let tmpArr3 = [...questionLangCode];
        let tmpArr4 = [...questionItemList];
        let langCodeIdx = tmpArr3.indexOf(tmpArr4[i].langCode)
        let questionItem = {
            idx :tmpArr4[i].idx,
            langCode : tmpArr4[i].langCode,
            title : tmpArr4[i].title,
            type : tmpArr4[i].type,
            useFlag:false,
            questionChoiceList : tmpArr4[i].questionChoiceList
        }
        
        tmpArr3.splice(langCodeIdx,1,'')
        tmpArr4.splice(i,1,questionItem)
        tmpArr3.splice(langCodeIdx,1)
        setQuestionLangCode(tmpArr3);
        setQuestionItemList(tmpArr4);
    }

    const addQuestionChoiceList=(i,k)=>{
        let newChoiceList = [...choiceList];
        let newQuestionItemList = [...questionItemList];
        let newNum = newQuestionItemList[i].questionChoiceList.length
        let choiceItem ={
            num : newNum+1,
            idx :0,
            title : '',
            score : 1,
            useFlag:true,
        }
        newChoiceList.splice(i,1,newChoiceList[i]+1)
        let newChoiceTitleFocusList = [...choiceTitleFocusList];
        newQuestionItemList[i].questionChoiceList.push(choiceItem)
        setQuestionItemList(newQuestionItemList);
        setChoiceList(newChoiceList);
    }

    const deleteQuestionChoiceList=(i,k,c)=>{
        let newChoiceList = [...choiceList];
        let newQuestionItemList = [...questionItemList];
        let numList =newQuestionItemList[i].questionChoiceList.map((v,i) =>{return v.num})
        let deleteIdx = numList.indexOf(c.num);
        let choiceItem ={
            num : -1,
            idx :newQuestionItemList[i].questionChoiceList[deleteIdx].idx,
            title : newQuestionItemList[i].questionChoiceList[deleteIdx].title,
            score : newQuestionItemList[i].questionChoiceList[deleteIdx].score,
            useFlag:false,
        }
        newChoiceList.splice(i,1,newChoiceList[i]-1)
        newQuestionItemList[i].questionChoiceList.splice(deleteIdx,1,choiceItem);
        let newChoiceTitleFocusList =[...choiceTitleFocusList];
        setQuestionItemList(newQuestionItemList);
        setChoiceList(newChoiceList);
    }

    const getDetail = () => {
        
        const rs = getQuestionByIdx(idx);
        rs.then(({ data : {success, message, code, data} }) => {
            if(data != null) {
                setQuestionIdx(data.idx);
                let newChoiceList = [];
                for(let i=0;i<data.questionItemList.length;i++){
                    let questionChoiceListLength= data.questionItemList[i].questionChoiceList.map((v,i)=>{if(v.useFlag) return v});
                    newChoiceList.push(questionChoiceListLength.length)
                }
                let langObj=[]
                for(let i=0;i<data.questionItemList.length; i++){
                    if(data.questionItemList[i].useFlag)
                    langObj.push(data.questionItemList[i].langCode)
                }
                let typeObj =[]
                for(let i=0;i<data.questionItemList.length; i++){
                    // if(data.questionItemList[i].useFlag)
                    typeObj.push(data.questionItemList[i].type)
                }
                let newQuestionItemList = data.questionItemList.map((v,i)=>{
                    let newQuestionChoiceList = v.questionChoiceList.map((c,k)=>{
                          let questionChoiceList = {
                            num : k+1,
                            idx : c.idx,
                            title : c.title,
                            score : c.score,
                            useFlag:c.useFlag,
                        }
                        return questionChoiceList;
                    })
                    let newQuestionItemList ={
                        idx : v.idx,
                        langCode : v.langCode,
                        title : v.title,
                        type :v.type,
                        useFlag:v.useFlag,
                        questionChoiceList : newQuestionChoiceList
                    }

                    return newQuestionItemList;
                })
                setQuestionLangCode(langObj);
                setQuestionItemList(newQuestionItemList);
                setChoiceList(newChoiceList)
                setQuestionType(typeObj);
            } else {
                // swal('잘못 된 접근입니다.\r\n다시 해주세요!').then(()=>{history.push(`/mainScreenManagement/${page}/message`)});
            }
        })
        .catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        getDetail();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    const onSubmit = () => {
        handleClose_upload();
        let newQuestionItemList = [];
        questionItemList.map((v,i)=>{
            let newQuestionChoiceList = [];
            v.questionChoiceList.map((c,k)=>{
                if(c.useFlag){
                    newQuestionChoiceList.push(c);
                }
            });
            if(v.useFlag){
                let newQuestionItem = {
                    langCode : v.langCode,
                    title : v.title,
                    type : v.type,
                    useFlag:v.useFlag,
                    questionChoiceList : newQuestionChoiceList
                }
                newQuestionItemList.push(newQuestionItem)
            }
        })
        let newTitle = newQuestionItemList[0].title;
        let createQuestionList = {
            questionCategoryIdx : categoryIdx,
            title : newTitle,
            questionItemList : newQuestionItemList
        }

        for(let i = 0;i<createQuestionList.questionItemList.length;i++){
            if(createQuestionList.questionItemList[i].title === ""){
                return swal('알림','질문 제목을 입력해주세요')
            }
            for(let j = 0; j<createQuestionList.questionItemList[i].questionChoiceList.length; j++){
                if(createQuestionList.questionItemList[i].questionChoiceList[j].title ===""){
                    return swal('알림','답변 제목을 입력해주세요')
                }
            }
        }
        if(questionIdx != '') {
            let newQuestionItemList = [];
            questionItemList.map((v,i)=>{
                let newQuestionChoiceList = [];
                v.questionChoiceList.map((c,k)=>{
                    if(c.idx ==0){
                        if(c.useFlag){
                            newQuestionChoiceList.push(c);
                        }
                    }else{
                        newQuestionChoiceList.push(c);
                    }
                });
                if(v.idx == undefined){
                    if(v.useFlag){
                        let newQuestionItem = {
                            langCode : v.langCode,
                            title : v.title,
                            type : v.type,
                            useFlag:v.useFlag,
                            questionChoiceList : newQuestionChoiceList
                        }
                        newQuestionItemList.push(newQuestionItem)
                    }
                }else{
                    newQuestionItemList.push(v);
                }
            })
            let updateQuestionList = {
                questionCategoryIdx : categoryIdx,
                title : newTitle,
                questionItemList : newQuestionItemList
            }
            const rs = updateQuestion(questionIdx ,updateQuestionList);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== null) {
                    swal({
                        title: "알림",
                        text: "정상적으로 수정 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push(`/questionManagement/${page}/${categoryIdx}/${selectLangCode}`);
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit();
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        } else {
            const rs = createQuestion(createQuestionList);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== null) {
                    swal({
                        title: "알림",
                        text: "정상적으로 등록 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push(`/questionManagement/1/${categoryIdx}/${selectLangCode}`);}
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit();
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        }
    }

    const questionDelete = ()=>{
        handleClose_delete();
        const rs = deleteQuestion(questionIdx);
        rs.then(({data : {success,message,code,data} })=>{
            if(success=true && message ==='success') {
                swal({
                    title :"알림",
                    text : "정상적으로 삭제 되었습니다."
                }).then((isConfirm)=>{
                    if(isConfirm){
                        history.push(`/questionManagement/${page}/${categoryIdx}/${selectLangCode}`);
                    }
                })
            }else{
                swal({
                    title: "알림",
                    text : message
                }).then((isConfirm)=>{
                    if(isConfirm){
                        return false;
                    }
                })
            }
        }).catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        questionDelete();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    useMemo(()=>{
        
        getDetail();
    },[])

    

   return (
       
       <>
            <Container fluid className="UploadQuestionContainer">
                <Row className="mb-4 no-gutters">
                        <Col className="d-flex justify-content-between align-items-center">
                            <div>
                            <Button variant="outline-primary mr-2" onClick={()=>history.push(`/questionManagement/${page}/${categoryIdx}/${selectLangCode}`)}>목록으로</Button>
                            </div>
                            {/* 정보입력시 활성화 ↑ */}
                            <div>
                            {questionIdx !=='' &&
                            <Button variant="outline-danger mr-2" onClick={handleShow_delete}>삭제</Button>
                            }
                            <Button variant="primary" onClick={handleShow_upload}>{questionIdx !== '' ? '수정' : '업로드'}</Button>
                            </div>
                        </Col>
                    </Row>

                 {/* 모달 (업로드) */}
                 <Modal show={uploadShow} onHide={handleClose_upload} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>{questionIdx !== '' ? '수정' : '업로드'} 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_upload}>취소</Button>
                        <Button variant="primary" onClick={onSubmit}>확인</Button>
                    </Modal.Footer>
                </Modal>
                {/*모달 삭제 */}
                <Modal show={deleteShow} onHide={handleClose_delete} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_delete}>취소</Button>
                        <Button variant="primary" onClick={questionDelete}>확인</Button>
                    </Modal.Footer>
                </Modal>

                {Array.isArray(questionItemList) && questionItemList.map((v,i)=>{ 
                    if(v.useFlag)
                    return(
                        <>
                                <Row className="mb-4">
                                    <Col>
                                        <Card>
                                            <Card.Body className="p-0">
                                                <Table className="commonTable">
                                                    <Tbody>
                                                        <Tr>
                                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>언어선택</Td>
                                                            <Td>
                                                
                                                            <LanguagesTabs
                                                                i={i}
                                                                onLanSelected={(e,i)=>{onChangeQuestionLanguage(e,i)}}
                                                                langCode={questionItemList[i].langCode}
                                                                selectLangCode={questionLangCode}
                                                            />
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>문진질문</Td>
                                                            <Td>
                                                                <Row style={{margin : 0}}>
                                                                <Form.Control className="form-control" ref={titleFocusList[i]} type="text" placeholder={"질문을 입력해 주세요."} onChange={(e)=>onChangeQuestionItemTitle(e,i)} value={v.title}/>
                                                                <FormControl component="fieldset" style={{width : 200,justifyContent : 'center'}}>
                                                                    <RadioGroup
                                                                        aria-label="Status"
                                                                        name="status"
                                                                        value={String(questionType[i])}
                                                                        onChange={(e)=>onChangeQuestionType(e,i)}
                                                                        row
                                                                        className="statusRadio"
                                                                    >
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Radio 
                                                                                    classes={{
                                                                                        root: classes.radio,
                                                                                        checked: classes.checked
                                                                                    }}
                                                                                />
                                                                            }
                                                                            value= 'SINGLE'
                                                                            label="단일선택"
                                                                        />
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Radio 
                                                                                    classes={{
                                                                                        root: classes.radio,
                                                                                        checked: classes.checked
                                                                                    }}
                                                                                />
                                                                            }
                                                                            value='MULTIPLE'
                                                                            label="다중선택"
                                                                            className="ml-2"
                                                                        />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                                </Row>
                                                            </Td>
                                                        </Tr>
                                                            {Array.isArray(v.questionChoiceList) && v.questionChoiceList.filter(v=> v.useFlag === true).map((c,k)=>{
                                                                return(
                                                                    <>
                                                                        <Tr>
                                                                            <Td colSpan={2} style={{borderBottom : 'none' , paddingTop : 10 ,paddingBottom : 10}}>
                                                                            <Row style={{margin :0}}>
                                                                            <div style={{display:'flex',flex : 1,alignItems:'center',borderRadius:10,paddingTop:5,paddingLeft:20,paddingRight :20, paddingBottom : 5, backgroundColor : 'rgba(52, 52, 52, 0.1)'}}>
                                                                            <span className="font-weight-bold text-dark text-center pr-0" style={{width:50}}>답변 {k+1}</span>
                                                                                <Form.Control className="form-control" type="text" placeholder="답변을 입력한 후 우측의 답변에 대한 점수를 선택해 주세요." onChange={(e)=>onChangeQuestionchoiceListTitle(i,k,e,c)} value={c.title}/>
                                                                                <Td className='font-weight-bold' style={{borderBottom : 'none'}}>점수</Td>
                                                                                <Row style={{backgroundColor : 'white' ,border : `0.5px solid #3699FF`,borderRadius:5,overflow :'hidden'}}>
                                                                                <AddIcon style={{height:41,width:40,color : 'white',backgroundColor : '#3699FF'}} onClick={()=>onChlickPlus(i,k,c)}></AddIcon>
                                                                                <Td className='font-weight-bold text-center' style={{borderBottom : 'none' , width :50,padding : 10}}>{c.score}</Td>
                                                                                <RemoveIcon style={{height:41,width:40,color : 'white',backgroundColor : '#3699FF'}} onClick={()=>onChlickMinus(i,k,c)}></RemoveIcon>
                                                                                </Row>
                                                                            </div>
                                                                            <div style={{display:'flex',alignItems:'center',marginLeft : 10}}>
                                                                                {!k>0 && choiceList[i] <10
                                                                                ?
                                                                                <ControlPointIcon style={{height : 35,width: 35,color:'#66CC66'}} onClick={()=>addQuestionChoiceList(i,k)} />
                                                                                :
                                                                                <RemoveCircleOutline style={{height : 35,width: 35,color:'#FF3333'}} onClick={()=>deleteQuestionChoiceList(i,k,c)} />
                                                                                }
                                                                            </div>
                                                                            </Row>
                                                                            </Td>
                                                                        </Tr>
                                                                    </>
                                                                    );
                                                                })
                                                            }
                                                    </Tbody>
                                                </Table>
                                            </Card.Body>
                                            <Card.Footer>
                                                {questionLangCode.length >1 &&
                                            <Button variant="primary" onClick={(e)=>deleteQuestionItemList(e,i)}>삭제</Button>
                                                }
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                </Row>
                                
                                </>
                            )
                    })}
                    {questionLangCode.length !== 6 && 
                        <div>
                            <Button variant="primary" onClick={()=>{addQuestionItemList()}}>언어별 내용 추가</Button>
                        </div>
                    }  
            </Container >
        </>                
   ) 
};

export default UploadQuestion;