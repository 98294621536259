import React, { useEffect, useRef, useState, useMemo, createRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col, InputGroup, FormControl, Card, Badge, Button, Tabs, Tab, Modal} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import {getEbookByIdx,createEbook,updateEbook,uploadEbookImg,uploadEbookFile,deleteEbookByIdx} from "./crud";

import UploadImage from "../../../common/components/UploadImage";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CustomDropZoneCom from '../../../common/components/CustomDropZoneCom'
import DeleteIcon from "@material-ui/icons/Close";
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';

import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import swal from 'sweetalert';
import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import LanguageTabs from "../../../common/components/LanguageTabs"

// import swal from 'sweetalert';
// import * as authAction from "../../../app/store/ducks/auth.duck";
// import { reissueToken, currency } from '../../../common/up_utils';
// import { TOKEN_EXPIRED_CODE } from '../../../common/dickey';
// import Pagination from '../../../common/components/Pagination';

// import { getUsers } from './crud';

const UploadMagazine = () => {

    const useStyles = makeStyles(theme => ({
        radio: {
            '&$checked': {
              color: '#343a40'
            }
        },
        checked: {}
    }));

    const { page, idx } = useParams();
    // 내용이 채워졌을 때 업로드 활성화
    let addInitialArray = [];
    let delInitialArray = [];
    let [addImgs, setAddImgs] = useState(addInitialArray);
    let [removeImgs, setRemoveImgs] = useState(delInitialArray);
    const deleteOption = (file) => {
        setRemoveImgs(prev => [...prev, file]);
    }
    const updateOption = (file) => {
        setAddImgs(prev => [...prev, file]);        
    }
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    //포커스
    const titleFocus = useRef(null)
    const contentFocus = useRef(null)
    const fileRef = useRef();
    const numFocus = useRef(null);
    const [chapterPageRefList,setChapterPageRefList] = useState([createRef(null)])
    const [chapterTitleRefList,setChapterTitleRefList] = useState([createRef(null)])
    // 모달 (업로드)
    const [show, setShow_upload] = useState(false);
    const handleClose_upload = () => setShow_upload(false);
    const handleShow_upload = () => setShow_upload(true);
    //모달 (삭제)
    const [deleteShow, setShow_delete] = useState(false);
    const handleClose_delete = () => setShow_delete(false);
    const handleShow_delete = () => setShow_delete(true);
    //오디오북
    
    const [ebookIdx,setEbookIdx] =useState('')
    const [title , setTitle] = useState('');
    const [langCode ,setLangCode] = useState('en');
    const [thumbnailPath,setThumbnailPath] = useState([]);
    const [ebookPath , setEbookPath] = useState('');
    const [content,setContent]  = useState('');
    const [useFlag ,setUseFlag] = useState(false);
    const [type , setType] = useState('EBOOK')
    const [file, setFile] = useState([]);
    const [fileName, setFileName] = useState('');
    const [num, setNum] = useState(0);
    const [fileType,setFileTpye] = useState('');
    //목차
    const [chapterList,setChapterList] = useState([{
        page : '',
        title : ''
    }]);

     //onChange event
     const onChangeTitle = (e) => {setTitle(e.target.value)}
     const onChangeContent = (e) => {setContent(e.target.value)}
     const onChangeLanguage = (e) => {setLangCode(e)}
     const onChangeUseFlag=(e)=> {setUseFlag(e.target.value)}
     const handleChange = (e) => {
         setFile(e.target.files);
         setFileName(e.target.files[0].name)
        //  console.log("e.target.files[0].name :: ",e.target.files[0].name)
        //  console.log("fileType :: ",getExtention(e.target.files[0].name))
         setFileTpye(getExtention(e.target.files[0].name))
     }
     const onChangeNum = (e) =>{setNum(e.target.value)}
     const onChangeCapterPage = (e,i)=>{
         let newPage = e.target.value
        const regExp = /^[0-9]+$/
        let newChapterList = [...chapterList]
        if(newPage.match(regExp)!=null || newPage == ""){
            newChapterList[i].page = newPage
        }
        setChapterList(newChapterList)
     }
     const onChangeCapterTitle = (e,i)=>{
        let newChapterList = [...chapterList]
        newChapterList[i].title = e.target.value
        setChapterList(newChapterList)
     }

    const getExtention = (filename) => {
        //To get the file extension
        return /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : undefined;
      };

    //디테일
    const  getDetail =  () => {
        const rs = getEbookByIdx(idx);
        rs.then(({ data : {success, message, code, data} }) => {
            if(data != null) {
                setEbookIdx(data.idx);
                setTitle(data.title);
                setLangCode(data.langCode);
                setContent(data.content);
                setThumbnailPath(data.thumbnailPath)
                setEbookPath(data.ebookPath)
                setNum(data.num);
                setUseFlag(data.useFlag)
                let newEbookChapterList = [{
                    page : '',
                    title : ''
                }]
                let newEbookChapterPageRefList = []
                let newEbookChapterTitleRefList = []
                setFileTpye(getExtention(data.ebookPath))
                if(Array.isArray(data.ebookChapterList) && data.ebookChapterList.length  > 0 ){
                    data.ebookChapterList.map((v,i)=>{
                        newEbookChapterPageRefList.push(createRef(null))
                        newEbookChapterTitleRefList.push(createRef(null))
                    })
                }
                setChapterPageRefList(newEbookChapterPageRefList)
                setChapterTitleRefList(newEbookChapterTitleRefList)
                setChapterList(data.ebookChapterList.length == 0 ? newEbookChapterList : data.ebookChapterList);
            } else {
                // swal('잘못 된 접근입니다.\r\n다시 해주세요!').then(()=>{history.push(`/noticeManagement/${page}`)});
            }
        })
        .catch(({response : data})=>{
            if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        getDetail();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    //오디오 파일 업로드
    const ebookFileUpload =()=>{
        let ebookFilePath = '';
        var formData = new FormData;
        if(file){
            formData.append('files',file[0]);
            //formData.append('path','systemMessagePicfile');
            const rs = uploadEbookFile(formData);
            //const rs = uploadEbookImg(formData);
            rs.then(({data})=>{
                let dataArray =new Array();
                
                for(let key in data){

                    let realName = data[key].realName;
                    let filePath = data[key].filePath;
                    let pushData = filePath + realName;
                    console.log(pushData == 'epub/20210216/415');
                    if(pushData == 'epub/20210216/415'){
                        swal({
                            title: "알림",
                            text : '파일업로드에 실패했습니다.'
                        }).then((isConfirm)=>{
                            if(isConfirm){
                                return false;
                            }
                        })
                        return false;
                    }
                    dataArray.push(pushData);
                }
                let ebookFilePath = dataArray.join();
                thumbnailImpFillupload(ebookFilePath);
            })
        }else{
            thumbnailImpFillupload(ebookFilePath);
        }
    }

    //오디오 삭제
    const deleteEbook = ()=>{
        handleClose_delete();
        const rs = deleteEbookByIdx(ebookIdx);
        rs.then(({data : {success,message,code,data} })=>{
            if(success=true && message ==='success') {
                swal({
                    title :"알림",
                    text : "정상적으로 삭제 되었습니다."
                }).then((isConfirm)=>{
                    if(isConfirm){
                        history.push(`/magazineManagement/${page}`);
                    }
                })
            }else{
                swal({
                    title: "알림",
                    text : message
                }).then((isConfirm)=>{
                    if(isConfirm){
                        return false;
                    }
                })
            }
        }).catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        deleteEbook();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    //썸네일 이미지 업로드
    const thumbnailImpFillupload = (ebookFilePath) =>{
        let inputImg = addImgs.filter(item => removeImgs.filter(i => item.upload.uuid == i.upload.uuid).length == 0);
        var formData = new FormData;
        // console.log(inputImg.length)
        if(inputImg.length > 0) {
            for(let i = 0; i < inputImg.length; i++) {
                formData.append('files', inputImg[i]);   
            }
        
            formData.append('path', 'ebookthumbnail')
            const rs = uploadEbookImg(formData);
            rs.then(({data}) => {
                let dataArray = new Array();
                for(let key in data) {
                    let realName = data[key].realName;
                    let filePath = data[key].filePath;
                    let pushData = filePath + realName;
                    dataArray.push(pushData)
                }
                let paths = dataArray.join();
                onSubmit(paths, ebookFilePath);
            });
        }else{
            let paths ='';
                onSubmit(paths, ebookFilePath);
        }
    }

    //수정 및 추가
    const onSubmit = (paths,ebookFilePath) => {
        handleClose_upload();
        if(!title) return swal('알림','제목을 입력해주세요.').then(() => {titleFocus.current.focus();});
        let ebookObj = {
            title : title,
            content : content,
            useFlag : useFlag,
            type : type,
            //paths :  picPath.length ? picPath :  ''
            langCode : langCode ? langCode :'en' ,
            thumbnailPath :  paths != '' ? paths : thumbnailPath.length ?thumbnailPath : '',
            ebookPath : ebookFilePath != '' ? ebookFilePath : ebookPath,
            num : num !== '' ? num : 0,
        }
        if(chapterList.length == 1 && chapterList[0].page == "" && chapterList[0].title == ""){
            ebookObj.ebookChapterList = []
        }else{
            for(let i =0;i<chapterList.length; i ++){
                if(chapterList[i].page ==""){
                    return swal('페이지를 입력해주세요.').then(()=>{chapterPageRefList[i].current.focus()});
                }else if(chapterList[i].title == ""){
                    return swal('목차 제목을 입력해주세요.').then(()=>{chapterTitleRefList[i].current.focus()});
                }
            }
            ebookObj.ebookChapterList = chapterList
        }
        // console.log(systemMessageIdx)
        if(ebookIdx != '') {
            const rs = updateEbook(ebookIdx ,ebookObj);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== null) {
                    swal({
                        title: "알림",
                        text: "정상적으로 수정 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push(`/magazineManagement/${page}`);
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                // console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit(paths != '' ? paths : thumbnailPath,ebookFilePath !='' ? ebookFilePath :ebookPath);
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        } else {
            const rs = createEbook(ebookObj);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== null) {
                    swal({
                        title: "알림",
                        text: "정상적으로 등록 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push('/magazineManagement/1');}
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                // console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit(paths,ebookFilePath);
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        }
    }

    const galleryDel = (path) => {
        let arr = thumbnailPath.split(',').filter(v => { return  v !== path });
        setThumbnailPath(arr.join(','))
    }

    const addChapterList = (i) =>{
        let newChapterList = [...chapterList]
        let newChapter = {
            page : '',
            title : '',
        }
        // newChapterList.splice(i+1,0,'')
        
        newChapterList.splice(i+1,0,newChapter)
        setChapterList(newChapterList)
        setChapterPageRefList([...chapterPageRefList,createRef(null)])
        setChapterTitleRefList([...chapterTitleRefList,createRef(null)])
    }

    const removeChapterList = (i) =>{
        
        let newChapterList = [...chapterList]
        let newChapterPageRefList = [...chapterPageRefList]
        let newChapterTitleRefList = [...chapterTitleRefList]
        
        newChapterTitleRefList.splice(i,1,'')
        newChapterTitleRefList.splice(i,1)
        newChapterPageRefList.splice(i,1,'')
        newChapterPageRefList.splice(i,1)
        newChapterList.splice(i,1,'')
        newChapterList.splice(i,1)
        
        if(newChapterList.length == 0){
            let newChapter = {
                page : '',
                title : '',
            }
            newChapterList.push(newChapter)
        }
        
        if(newChapterPageRefList.length == 0){
            newChapterPageRefList.push(createRef(null))
        }
        if(newChapterTitleRefList.length == 0){
            newChapterTitleRefList.push(createRef(null))
        }
        setChapterList(newChapterList)
        setChapterPageRefList(newChapterPageRefList)
        setChapterTitleRefList(newChapterTitleRefList)
    }

    useMemo(()=>{
        getDetail();
        
    },[])

    return (
        <>
            <Container fluid className="UpoladVideoContainer">

                {/* 버튼영역 */}
                <Row className="mb-4 no-gutters">
                    <Col className="d-flex justify-content-between align-items-center">
                    <Button variant="outline-primary" onClick={()=>history.push(`/magazineManagement/${page}`)}>목록으로</Button>
                        {/* 정보입력시 활성화 ↑ */}
                        <div>
                        {/* <Button variant="dark" disabled>업로드</Button> */}
                        {ebookIdx !=='' &&
                        <Button  variant="outline-danger mr-2" onClick={handleShow_delete}>삭제</Button>
                        }
                        <Button variant="primary" onClick={handleShow_upload}>{ebookIdx !='' ? '수정' : '업로드'}</Button>
                        </div>
                    </Col>
                </Row>

                {/* 모달 (업로드) */}
                <Modal show={show} onHide={handleClose_upload} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>{ebookIdx != '' ? '수정' : '업로드'} 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_upload}>취소</Button>
                        <Button variant="primary" onClick={ebookFileUpload}>확인</Button>
                    </Modal.Footer>
                </Modal>

                {/*모달 삭제 */}
                <Modal show={deleteShow} onHide={handleClose_delete} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_delete}>취소</Button>
                        <Button variant="primary" onClick={deleteEbook}>확인</Button>
                    </Modal.Footer>
                </Modal>

                {/* 정보 입력영역 */}
                <Row className="mb-4">
                    <Col>
                        <Card>
                            <Card.Header className="cardTitle text-primary">E-BOOK 정보</Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable">
                                    <Tbody>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>언어선택</Td>
                                            <Td><LanguageTabs
                                            onLanSelected={onChangeLanguage}
                                            langCode={langCode}
                                            /></Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>제목</Td>
                                            <Td>
                                                <Form.Control type="text" placeholder="E-BOOK 제목을 입력하세요." value={title} ref={titleFocus&&titleFocus} onChange={(e)=>onChangeTitle(e)}/>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>순서</Td>
                                            <Td>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <Form.Control type="number" placeholder="순서를 입력하세요." ref={numFocus&&numFocus}  value={num} onChange={(e)=>onChangeNum(e)} />
                                                </div>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>소개글</Td>
                                            <Td>
                                                <Form.Control as="textarea" rows="3" placeholder="E-BOOK 소개글을 입력하세요." value={content} ref={contentFocus&&contentFocus} onChange={(e)=>onChangeContent(e)}/>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>파일선택</Td>
                                            <Td>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <form encType="multipart/form-data">
                                                        <input type='file' key='ebook'accept="ebook/*" className='inputFiles' id='file'  hidden onChange={(e)=>handleChange(e)} ref={fileRef} ></input>
                                                    </form>
                                                    <Button variant="outline-primary" className="col-2 mr-2" onClick={() => window.document.getElementById('file').click()}>파일선택</Button>
                                                    <Form.Control type="text" placeholder="파일을 선택해주세요." disabled value={ebookPath ? ebookPath : fileName}/>
                                                </div>
                                            </Td>
                                        </Tr>
                                        {fileType == "pdf" &&
                                            <>
                                                <Tr>
                                                    <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>목차</Td>
                                                    <Td style={{flex:1}}>
                                                        {Array.isArray(chapterList)&&chapterList.map((v,i)=>{
                                                            return(
                                                            <Row>
                                                                <Td className="font-weight-bold border-0 text-dark text-center">페이지</Td>
                                                                <Form.Control ref={chapterPageRefList[i]&&chapterPageRefList[i]} style={{flex : 'none',width:200}} placeholder="페이지를 입력하세요." value={v.page} onChange={(e)=>{onChangeCapterPage(e,i)}}/>
                                                                <Td className="font-weight-bold border-0 text-dark text-center">목차 이름</Td>
                                                                <Form.Control ref={chapterTitleRefList[i]&&chapterTitleRefList[i]} placeholder="목차 제목을 입력하세요." value={v.title} onChange={(e)=>{onChangeCapterTitle(e,i)}}/>
                                                                <ControlPointIcon style={{height : 35,width: 35,color:'#66CC66'}} onClick={()=>{addChapterList(i)}}></ControlPointIcon>
                                                                <RemoveCircleOutline style={{height : 35,width: 35,color:'#FF3333'}} onClick={()=>{removeChapterList(i)}}></RemoveCircleOutline>
                                                            </Row>
                                                            )
                                                        })}
                                                    </Td>
                                                </Tr>
                                            </>
                                        }
                                        <Tr>
                                            {/* <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>미리보기</Td> */}
                                            <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}}>{ebookIdx !== '' ? '교체이미지' : '사진등록'}</Td>  
                                            <Td>
                                                <CustomDropZoneCom 
                                                    onUpdate={updateOption}
                                                    onDelete={deleteOption}
                                                    acceptedFiles={'image/*'}
                                                    iconFiletypes={['.jpg', '.png', '.gif', '.jpeg']}
                                                    maxFiles={1}
                                                    fileLimit={1}
                                                    title={'SIZE 335 x 508'}
                                                    description={'사진파일을 끌어서 여기에 놓거나<br />여기를 클릭하여 사진파일을 업로드합니다'}
                                                />
                                                {/* <UploadImage
                                                    title="SIZE 335 X 508"
                                                    description="사진파일을 끌어서 여기에 놓거나<br />여기를 클릭하여 사진파일을 업로드합니다."
                                                /> */}
                                            </Td>
                                        </Tr>
                                        {thumbnailPath != '' && thumbnailPath.split(',').length > 0 &&
                                            <Tr>
                                                <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100, fintSize: '13px'}}>기존이미지</Td>
                                                <Td>
                                                    <div class="modifyCustomDropzone modifyImgContainer">
                                                    <div class="gallery-grid">
                                                        {thumbnailPath.split(',').map((v, i) => {
                                                            return(
                                                                <div class="gallery-frame">
                                                                    {/* <img class="gallery-img" alt={v} src={'/fileUpload/' + v} />   */}
                                                                    <img class="gallery-img" alt={v} src={'/fileUpload/' + v} />  
                                                                    <button onClick={() => galleryDel(v)}><DeleteIcon /></button>
                                                                </div>  
                                                            )
                                                        })}
                                                        </div>
                                                    </div>
                                                </Td>
                                            </Tr>
                                        }
                                    </Tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-4 openCheck">
                    <Col>
                        <Card className="d-flex flex-row align-items-center p-4">
                            <Col className="d-flex col-8 justify-content-start align-items-center">
                                <h5 className="cardTitle text-primary">공개여부</h5>
                                <p className="m-0 ml-4 text-subgray">E-BOOK 업로드시 공개여부를 선택해주세요. 공개 선택시 모바일에서 바로 E-BOOK 감상이 가능합니다.</p>
                            </Col>
                            <Col className="col-4">
                                <RadioGroup
                                    aria-label="Status"
                                    name="status"
                                    value={String(useFlag)}
                                    onChange={(e)=>onChangeUseFlag(e)}
                                    row
                                    className="statusRadio justify-content-end"
                                >
                                    <FormControlLabel
                                        control={
                                            <Radio 
                                                classes={{
                                                    root: classes.radio,
                                                    checked: classes.checked
                                                }}
                                            />
                                        }
                                        value= 'false'
                                        label="비공개"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Radio 
                                                classes={{
                                                    root: classes.radio,
                                                    checked: classes.checked
                                                }}
                                            />
                                        }
                                        value='true'
                                        label="공개"
                                        className="ml-2"
                                    />
                                </RadioGroup>
                            </Col>
                        </Card>
                    </Col>
                </Row>

            </Container >
        </>
    );
};

export default UploadMagazine;

