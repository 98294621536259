import React, { useState, useEffect,useMemo,Component, useRef } from 'react';
import { ReactReader, EpubView } from "react-reader";
import { Button } from "react-bootstrap"
import $ from 'jquery';
import BackBtn from '../../assets/img/ic_back.png';
import SizeResetBtn from '../../assets/img/ic_format_size.jpg';
import SizeUpBtn from '../../assets/img/ic_add_circle_outline.jpg';
import SizeDownBtn from '../../assets/img/ic_remove_circle_outline.jpg';

const EpubReader = () => {

    const [epub, setEpub] = useState(null);
    const [title, setTitle] = useState('');
    const [location, setLocation] = useState(0);
    const [fontSize, setFontSize] = useState(100);
    const [rendition, setRendition] = useState(null);
    const [showMenu, setShowMenu] = useState(true);
    const [refresh, setRefresh] = useState(false);

    const epubRef = useRef(null);

    useEffect(()=>{
        let params = window.location.href.split('?data=')[1].split('&title=');
        setEpub(params[0]);
        setTitle(decodeURI(params[1]));
    },[]);

    // useEffect(()=>{
    //     bindClickEvent();        
    // },[showMenu])

    useEffect(()=>{
        if(refresh){
            setRefresh(false);            
        }            
    },[refresh])    

    const bindClickEvent = ()=>{        
        let iframe = $('#root').find('.epub-view').find('iframe')[0];        
        if(!iframe){
            setTimeout(()=>{
                bindClickEvent();
            },500)
            return;
        }
        let iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        $(iframeDocument).find('body').unbind('click').on('click',()=>{
            setShowMenu(!showMenu);
            setRefresh(true);
        })
    }

    const resetFontSize = () => {
        const defaultSize = 100;
        setFontSize(defaultSize);
        rendition.themes.fontSize(defaultSize.toString()+'%');
    }

    const changeFontSize = (size) => {
        const nextFont = fontSize + size
        if(nextFont <= 200 & nextFont >= 50) {
            setFontSize(nextFont);
            rendition.themes.fontSize(nextFont.toString()+'%');
        }
    }

    const gotoBack = ()=>{
        //alert('back')
        window.ReactNativeWebView.postMessage(JSON.stringify({ event: 'goBack' }))
    }

    return (
        <>
            { showMenu &&
                <div id="headerData" style = {styles.headerContainer}>
                    <button style={styles.leftBtn}  onClick={() => gotoBack()}>
                        <img style={styles.leftBtnImg} src={BackBtn}/>
                    </button>
                    <div style = {styles.titleContainer}>{title}</div>
                </div>
            }
            <div style={{ position: "relative", height: "100%"}}>
                {epub && !refresh &&
                <>
                    <ReactReader
                        url={epub}
                        styles={reactReaderStyles}
                        location={location}
                        locationChanged={(epubcifi) => {
                            bindClickEvent();
                            setLocation(epubcifi);                            
                        }}
                        getRendition={rendition => {
                            rendition.themes.fontSize(fontSize.toString()+'%');
                            setRendition(rendition);
                            bindClickEvent();
                        }}
                    />
                </>
                }
            </div>
            
            {epub && showMenu &&
            <div id = 'bottomTab' style={styles.bottomTabContainer}>
                <div style={styles.resetSize} onClick={() => resetFontSize()}> <img src={SizeResetBtn}/> </div>
                <div style={{display:'flex', width: '120px'}}>
                    <div style={styles.upSize} onClick={() => changeFontSize(10)}> <img src={SizeUpBtn}/> </div>
                    <div style={styles.currentSize}> {fontSize+'%'} </div>
                    <div style={styles.downSize} onClick={() => changeFontSize(-10)}><img src={SizeDownBtn}/> </div>
                </div>
            </div>
            }
            
        </>
    );
};

const reactReaderStyles = {
    container: {
        overflow: "hidden",
        height: "100%"
    },
    readerArea: {
        position: "relative",
        zIndex: 1,
        height: "100%",
        width: "100%",
        backgroundColor: "#fff",
        transition: "all .3s ease"
    },
    containerExpanded: {
        transform: "translateX(256px)"
    },
    titleArea: {
        position: "absolute",
        top: 20,
        left: 50,
        right: 50,
        textAlign: "center",
        color: "#999"
    },
    reader: {
        position: "absolute",
        top: 50,
        left: 50,
        bottom: 20,
        right: 50
    },
    swipeWrapper: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 200
    },
    prev: {
        left: 1
    },
    next: {
        right: 1
    },
    arrow: {
        outline: "none",
        border: "none",
        background: "none",
        position: "absolute",
        top: "50%",
        marginTop: -32,
        fontSize: 64,
        padding: "0 10px",
        color: "#E2E2E2",
        fontFamily: "arial, sans-serif",
        cursor: "pointer",
        userSelect: "none",
        appearance: "none",
        fontWeight: "normal"
    },
    arrowHover: {
        color: "#777"
    },
    tocBackground: {
        position: "absolute",
        left: 256,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 1
    },
    tocArea: {
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 0,
        width: '256px',
        overflowY: "auto",
        WebkitOverflowScrolling: "touch",
        background: "#f2f2f2",
        padding: "10px 0"
    },
    tocAreaButton: {
        userSelect: "none",
        appearance: "none",
        background: "none",
        border: "none",
        display: "block",
        fontFamily: "sans-serif",
        width: "100%",
        fontSize: ".9em",
        textAlign: "left",
        padding: ".9em 1em",
        borderBottom: "1px solid #ddd",
        color: "#aaa",
        boxSizing: "border-box",
        outline: "none",
        cursor: "pointer"
    },
    tocButton: {
        background: "none",
        border: "none",
        width: 32,
        height: 32,
        position: "absolute",
        top: 10,
        left: 10,
        borderRadius: 2,
        outline: "none",
        cursor: "pointer"
    },
    tocButtonExpanded: {
        background: "#f2f2f2"
    },
    tocButtonBar: {
        position: "absolute",
        width: "60%",
        background: "#ccc",
        height: 2,
        left: "50%",
        margin: "-1px -30%",
        top: "50%",
        transition: "all .5s ease"
    },
    tocButtonBarTop: {
        top: "35%"
    },
    tocButtonBottom: {
        top: "66%"
    },
    loadingView: {
        position: "absolute",
        top: "50%",
        left: "10%",
        right: "10%",
        color: "#ccc",
        textAlign: "center",
        margintop: "-.5em"
    },
    viewHolder: {
        position: "relative",
        height: "100%",
        width: "100%"
    },
    view: {
        height: "100%"
    }
};

const styles = {
    headerContainer: {
        minHeight: '56px',        
        lineHeight: 1.1,
        display:'flex',
        backgroundColor:'#fff',
        borderBottom:'1px solid #e2e4ec',
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleContainer: {
        height: '56px',
        width: '100%',
        paddingLeft: '45px',
        paddingRight: '45px',
        fontSize: '20px',
        fontWeight: 'bold',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        overflowWrap: 'anywhere',
        color : 'black'
    },
    bottomTabContainer: {
        minHeight: '60px',
        display:'flex',
        padding: '15px 16px 15px 26px',
        justifyContent: 'space-between',
        zIndex: 99999,
        backgroundColor: '#ffffff',
        boxShadow: '0 -3px 6px 0 rgba(0, 0, 0, 0.16)',
    },
    leftBtn: {
        //position: 'absolute',
        backgroundColor:'#fff',
        //left: 0,
        border : 'none',
    },
    leftBtnImg: {        
        height: '15px',
        paddingLeft: '7px',
    },
    resetSize: {
        objectFit: 'contain',
    },
    upSize: {
        //position: 'absolute',
        width: '20px',
        height: '20px',
        margin: '5px 0 5px 0',
        objectFit: 'contain',
        //right: '131px',
    },
    downSize: {
        //position: 'absolute',
        width: '20px',
        height: '20px',
        margin: '5px 0 5px 0',
        objectFit: 'contain',
        //right: '16px',
    },
    currentSize: {
        //position: 'absolute',
        width: '55px',
        height: '29px',
        //right: '56px',
        margin : '0 auto',
        objectFit: 'contain',
        fontFamily: 'NotoScansCJKkr',
        fontSize: '20px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.45,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#3a7bd5'
    }
}

export default EpubReader;
