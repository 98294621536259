import React, { useEffect, useState,useMemo,useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col, InputGroup, Card, Badge, Button, Tabs, Tab, Modal} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import {getTerms,updateTerms,createTerms,deleteTerms,updateOpenFlag} from "./crud";

import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import swal from 'sweetalert'





const UploadTerms=()=>{
    const itemListFocus0 =useRef(null);
    const itemListFocus1 =useRef(null);
    const itemListFocus2 =useRef(null);
    const itemListFocus3 =useRef(null);
    const itemListFocus4 =useRef(null);
    const itemListFocus5 =useRef(null);
    const itemFocus = [itemListFocus0,itemListFocus1,itemListFocus2,itemListFocus3,itemListFocus4,itemListFocus5]
    const versionFocus = useRef(null);
    const langList ={
        langCode : ['ko','en','ja','zh','id','th'],
        langDescription : ['KOREAN','ENGLISH','JAPENESE','TAIWAN','INDONESIAN','THAI']
    }
    const typeList ={
        type : ['SERVICE','PRIVACY_POLICY', 'SEND'],
        typeDescription : ['서비스이용약관','개인정보처리방침', '소식및정보수신']
    }

    const useStyles = makeStyles(theme => ({
        radio: {
            '&$checked': {
              color: '#343a40'
            }
        },
        checked: {}
    }));
    const {page,idx} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();

    // 모달 (업로드,수정)
    const [uploadShow, setShow_upload] = useState(false);
    const handleClose_upload = () => setShow_upload(false);
    const handleShow_upload = () => setShow_upload(true);
    //삭제
    const [deleteShow, setShow_delete] = useState(false);
    const handleClose_delete = () => setShow_delete(false);
    const handleShow_delete = () => setShow_delete(true);
    // 모달 (활성 비활성 전환)
    const [show_public, setShow_public] = useState(false);
    const handleClose_public = () => setShow_public(false);
    const handleShow_public = () => setShow_public(true);

    const [termsidx, setTermsidx] = useState('');
    const [version,setVersion] = useState('');
    const [type,setType] =useState('SERVICE');
    const [typeDescription,setTypeDescription] = useState('서비스이용약관')
    const [openFlag,setOpenFlag] = useState();
    const [itemList,setItemList] = useState([]);

    const onChangetype=(e)=>{
        setType(e.target.value);
        setTypeDescription(e.target.label);
    }
    const onChangeversion =(e)=>{
        setVersion(e.target.value);
    }
    const onChangeContent =(e,i)=>{
        itemList[i].content = e.target.value;
    }
//고지 전화 및 중단
const modOpenFlag=()=>{
    let oldopenFlag =openFlag? false :true
    const rs = updateOpenFlag(termsidx,oldopenFlag);
    rs.then(({data : {success,message,code,data} })=>{
        if(success=true && message ==='success') {
          return  swal({
                title :"알림",
                text : `정상적으로 ${!openFlag ? '활성화' : '비활성화'} 되었습니다`,
            })
            // .then((isConfirm)=>{
            //     if(isConfirm){
            //        return false;
            //     }
            // })
        }else{
          return  swal({
                title: "알림",
                text : message
            })
            // .then((isConfirm)=>{
            //     if(isConfirm){
            //         return false;
            //     }
            // })
        }
    }).catch(({response : data})=>{
        if(data.status == 401 && data.data && data?.data?.code == 1101){
            const auth = JSON.parse(localStorage.getItem('persist:auth'));
            const rsRf= refresh(auth.refreshToken);
            
            rsRf.then(({ data : {success, message, code, data} }) => {
                if(data != null) {
                    let accessToken = data.accessToken;
                    let refreshToken = data.refreshToken;
                    auth.authToken = accessToken;
                    auth.refreshToken = refreshToken;
            
                    localStorage.setItem('persist:auth', JSON.stringify(auth));
    
                    dispatch(authAction.actions.login(accessToken, refreshToken));
                    modOpenFlag();
                } else {
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                }
            })
            .catch(({response : data})=>{
              if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
              }
            });
        }
    });
    setOpenFlag(oldopenFlag);
    handleClose_public();
}

    //디테일
    const getDetail = () => {
        const item = [{
            langCode : 'ko',
            content : ''
        },{
            langCode : 'en',
            content : ''
        },{
            langCode : 'ja',
            content : ''
        },{
            langCode : 'zh',
            content : ''
        },{
            langCode : 'id',
            content : ''
        },{
            langCode : 'th',
            content : ''
        }]
        if(idx=='0'){
            setItemList(item);
        }
        const rs = getTerms(idx);
        rs.then(({ data : {success, message, code, data} }) => {
            if(data != null) {
                setTermsidx(data.idx);
                setTypeDescription(data.typeDescription);
                setOpenFlag(data.openFlag);
                setType(data.type);
                setVersion(data.version);
                setItemList(data.itemList);
            } else {
                // swal('잘못 된 접근입니다.\r\n다시 해주세요!').then(()=>{history.push(`/mainScreenManagement/${page}/message`)});
            }
        })
        .catch(({response : data})=>{
            // console.log('catch :: ', data)
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        getDetail();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }
    //nullck
    const nullCheck=()=>{
        handleClose_upload();
        if(version=='') return swal("알림", '버전명을 작성하지 않았습니다').then(() =>  versionFocus.current.focus())
        for(let i=0; i<itemList.length; i++){
            if(!itemList[i].content) return swal("알림", '작성하지 않은 약관 내용이 있습니다.').then(() =>  itemFocus[i].current.focus())
        } 
        onSubmit();
    }

    //추가 및 수정
    const onSubmit = () => {
        let terms = {
            version : version,
            type : type,
            itemList :  itemList
        } 
        // //console.log(systemMessageIdx)
        if(termsidx != '') {
            const rs = updateTerms(termsidx,terms);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== null) {
                    swal({
                        title: "알림",
                        text: "정상적으로 수정 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push(`/termsManagement/${page}`);
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                // //console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit();
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                    if(data.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                    });
                }
            });
        } else {
            const rs = createTerms(terms);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== null) {
                    swal({
                        title: "알림",
                        text: "정상적으로 등록 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push('/termsManagement/1');
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                // //console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit();
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                    if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                    });
                }
            });
        }
    }
    //삭제
    const termsDelete = ()=>{
        handleClose_delete(false);
        const rs = deleteTerms(termsidx);
        rs.then(({data : {success,message,code,data} })=>{
            if(success=true && message ==='success') {
                swal({
                    title :"알림",
                    text : "정상적으로 삭제 되었습니다."
                }).then((isConfirm)=>{
                    if(isConfirm){
                        history.push(`/termsManagement/${page}`);
                    }
                })
            }else{
                swal({
                    title: "알림",
                    text : message
                })
                .then((isConfirm)=>{
                    if(isConfirm){
                        return false;
                    }
                })
            }
        }).catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        termsDelete();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                }
                });
            }
        });
    }

    useMemo(()=>{
        getDetail();
    },[])
    return(
        <Container fluid className="UploadtermsConatiner">
            {/* 버튼영역 */}
            <Row className="mb-4 no-gutters">
                    <Col className="d-flex justify-content-between align-items-center">
                        <Button variant="outline-primary" onClick={()=>history.push(`/termsManagement/${page}`)}>목록으로</Button>
                        {/* 정보입력시 활성화 ↑ */}
                        {/* <Button variant="dark" disabled>업로드</Button> */}
                        <div>
                      
                         {termsidx !=='' &&
                         <>
                        {
                        openFlag ?
                            <Button variant="outline-gray mr-2" onClick={handleShow_public}>고지중단</Button>
                        :
                            <Button variant="outline-success mr-2" onClick={handleShow_public} >고지전환</Button> 
                        }
                        <Button variant="outline-danger mr-2" onClick={handleShow_delete}>삭제</Button>
                        </>
                        } 
                        <Button variant="primary" onClick={handleShow_upload} >{termsidx !== '' ? '수정' : '업로드'}</Button>
                        </div>
                    </Col>
            </Row>
             {/* 모달 (업로드) */}
             <Modal show={uploadShow} onHide={handleClose_upload} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>{termsidx !== '' ? '수정' : '업로드'} 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_upload}>취소</Button>
                        <Button variant="primary" onClick={nullCheck}>확인</Button>
                    </Modal.Footer>
                </Modal>
                {/*모달 삭제 */}
                <Modal show={deleteShow} onHide={handleClose_delete} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_delete}>취소</Button>
                        <Button variant="primary" onClick={termsDelete}>확인</Button>
                    </Modal.Footer>
                </Modal>
                
            {/*정보 입력영역 */}
            <Row className="mb-4">
                <Col>
                <Card>
                    <Card.Header className="cardTitle text-primary" >
                        약관 정보
                    </Card.Header>
                    <Card.Body  className="p-0">
                        <Table className="commonTable">
                            <Tbody>
                                <Tr>
                                    <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}} >약관종류</Td>
                                    <Td>    
                                        <FormControl component="fieldset" style={{'display':'flex'}}>
                                                <RadioGroup
                                                        aria-label="type"
                                                        name="type"
                                                        value={type}
                                                        onChange={onChangetype}
                                                        row
                                                    >
                                                    {typeList.type.map((v,i)=>{
                                                        return(
                                                        <FormControlLabel
                                                        control={
                                                            <Radio 
                                                                classes={{
                                                                    root: classes.radio,
                                                                    checked: classes.checked
                                                                }}
                                                            />
                                                        }
                                                        value={v}
                                                        label={typeList.typeDescription[i]}
                                                    />
                                                        )
                                                })}
                                            </RadioGroup>
                                        </FormControl>
                                    </Td>
                                </Tr>  
                                <Tr>
                                    <Td className="font-weight-bold text-dark text-left pr-0">버전정보</Td>
                                    <Td>
                                        <input className="form-control" type="text" placeholder="버전을 입력해주세요"  onChange={(e)=>onChangeversion(e)} value={version} ref={versionFocus}/>
                                    </Td>
                                </Tr>
                                {termsidx&&
                                <Tr>
                                    <Td className="font-weight-bold text-dark text-left pr-0">고지상태</Td>
                                    <Td>
                                        <Col style={{textAlign:'left'}}>
                                        {openFlag
                                        ?    
                                            <span className="font-weight-bold text-success">고지</span>
                                        :
                                            <span className="font-weight-bold text-gray">고지중단</span>
                                        }
                                        </Col>
                                    </Td>
                                    
                                </Tr>
                                }
                            </Tbody>
                        </Table>
                    </Card.Body>
                    
                    </Card>
                </Col>
            </Row>
            {itemList.map((v,i)=>{
                return(
                    <Row className="mb-4">
                    <Col>
                        <Card>
                            {/* <Card.Header className="cardTitle text-primary">추가정보(프레지덴셜 등급 이상)</Card.Header> */}
                            <Card.Header className="cardTitle text-primary">{langList.langDescription[i]}</Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable">
                                    <Tbody>
                                        <Tr>
                                        <Td className="font-weight-bold text-dark text-left pr-0" style={{width:100}} >약관내용</Td>
                                        <Td>
                                            <Form.Control as="textarea" rows={8} placeholder="약관 내용을 입력해주세요" multiline style={{minHeight:100, textAlignVertical:'top',}} ref={itemFocus[i]&&itemFocus[i]} defaultValue={v.content} onChange={(e)=>onChangeContent(e,i)}/>
                                        </Td>
                                        </Tr>
                                     </Tbody>
                                </Table>
                                </Card.Body>
                        </Card>
                    </Col>
                </Row>
                )
            })}

            
            {/* 모달 (활성 전환) */}
            <Modal show={show_public} onHide={handleClose_public} centered>
                <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                <Modal.Body>
                    고지상태를 {!openFlag ? '활성화' : '비활성화'}로 전환하시겠습니까?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose_public}>취소</Button>
                    <Button variant="success" onClick={modOpenFlag}>확인</Button>
                </Modal.Footer>
            </Modal>
            
        </Container>            
    )
}
export default UploadTerms;
