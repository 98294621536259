import React, { useState, useEffect,useMemo } from 'react';
import { Form, Row, Container, Col, ListGroup, Card, Button } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import {getallList} from "./crud";

import SummaryCard from "../../../common/components/SummaryCard"
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";

import { refresh } from '../../auth/Login/crud';
import swal from 'sweetalert'
import * as authAction from "../../../app/store/ducks/auth.duck";
// import { getUsers } from './crud';

const Home = () => {
    const history = useHistory();
    const dispatch = useDispatch();


    const [noticeList ,setnoticeList] = useState([]);
    const [mediaList ,setmediaList] = useState([]);
    const [personList ,setpersonList] = useState([]);
    const [systemMessageList ,setsystemMessageList] = useState([]);
    const [ebookList ,setEbookList] = useState([]);
    const [audioBookList,setAudioBookList] = useState([]);
    const [mediaCnt , setmediaCnt] = useState();
    const [personCnt, setpersonCnt] = useState();
    const [audioBookCnt ,setAudioBookCnt] = useState();
    const [systemMessageCnt,setsystemMessageCnt] = useState();
    const [ebookCnt ,setEbookCnt] = useState();

    const allList = ()=>{
        const rs = getallList();
        rs.then(({data :{noticeList,mediaList,personList,systemMessageList,ebookList,audioBookList,mediaCnt,personCnt,systemMessageCnt,audioBookCnt,ebookCnt}}) =>{
            // console.log('mediaCnt',mediaCnt)
            setnoticeList(noticeList)
            setmediaList(mediaList)
            setpersonList(personList)
            setsystemMessageList(systemMessageList)
            setmediaCnt(mediaCnt)
            setpersonCnt(personCnt)
            setsystemMessageCnt(systemMessageCnt)
            setAudioBookCnt(audioBookCnt)
            setEbookCnt(ebookCnt)
            setEbookList(ebookList)
            setAudioBookList(audioBookList)
        }).catch(({response : data})=>{
            if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;

                        localStorage.setItem('persist:auth', JSON.stringify(auth));

                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        allList();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                }).catch(({response : data})=>{
                    if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                });
            }
        });;
    }
    const createAtsplit = (e)=>{
        if(e !== null) {
            let strArray = e.split(' ');
            let strArray2 =strArray[1].split(':');
            let strArray3 = [strArray2[0],strArray2[1]].join(':');
            let createAt = [strArray[0],strArray3].join(' ')
    
            return createAt;
        }
        
       return '';
    }
    const noticeHandleClick = (idx) => {
        // console.log('idx',idx);

        const url = {
          pathname : `/noticeManagement_upload/1/${idx}`
        }
        history.push(url);
      }
      const videoHandleClick = (idx) => {
        // console.log('idx',idx);

        const url = {
          pathname : `/videoManagement_upload/1/${idx}`
        }
        history.push(url);
      }
      const personHandleClick = (idx) => {
        // console.log('idx',idx);

        const url = {
          pathname : `/honoraryManagement_upload/1/${idx}`
        }
        history.push(url);
      }
      const messageHandleClick = (idx) => {
        // console.log('idx',idx);

        const url = {
          pathname : `/mainScreenManagement_uploadMessage/1/${idx}`
        }
        history.push(url);
      }
      const audioBookHandleClick = (idx) => {
        // console.log('idx',idx);

        const url = {
          pathname : `/audiobookManagement_upload/1/${idx}`
        }
        history.push(url);
      }
      const ebookHandleClick = (idx) => {
        // console.log('idx',idx);

        const url = {
          pathname : `/magazineManagement_upload/1/${idx}`
        }
        history.push(url);
      }
useMemo(()=>{
    allList();
},[])



    return (
        <>
            <Container fluid className="HomeConatiner">

                {/* 상단 6개카드 */}
                <Row className="mb-4 summaryCards">
                    <Col className="px-1">
                        <SummaryCard 
                            title="동영상"
                            data={mediaCnt} // [동영상 COUNT DATA]
                            color="#343a40"
                        />
                    </Col>
                    <Col className="px-1">
                        <SummaryCard 
                            title="E-BOOK"
                            data= {ebookCnt}// [E-BOOK COUNT DATA]
                            color="#3699ff"
                        />
                    </Col>
                    {/* <Col className="px-1">
                        <SummaryCard 
                            title="오디오북"
                            data={audioBookCnt} // [오디오북 COUNT DATA]
                            color="#35bfa3"
                        />
                    </Col>  */}
                    <Col className="px-1">
                        <SummaryCard 
                            title="명예의전당"
                            data={personCnt} // [명예의전당 COUNT DATA]
                            color="#DB1E32"
                        />
                    </Col>
                    <Col className="px-1">
                        <SummaryCard 
                            title="시스템메세지"
                            data={systemMessageCnt} // [시스템메세지 COUNT DATA]
                            color="#ffb822"
                        />
                    </Col>
                    {/* <Col className="px-1">
                        <SummaryCard 
                            title="프리미엄회원"
                            data="34" // [프리미엄회원 COUNT DATA]
                            color="var(--indigo)"
                        />
                    </Col> */}
                </Row>

                {/* 공지사항 */}
                <Row className="mb-4">
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h5 className="cardTitle">공지사항</h5>
                                <Button onClick={() => { history.push('/noticeManagement') }} variant="basic" className="p-0"><span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Other-2.svg')}/></span></Button>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable">
                                    <Tbody>
                                        {/* 
                                        <Tr>
                                            <Td className="text-left fw-500 ellipsis">[제목 DATA]</Td>
                                            <Td style={{width:140}} className="text-right">[날짜 DATA]</Td>
                                        </Tr>
                                        */}
                                        {noticeList.length > 0 ? noticeList.map((v,i)=>{
                                            return(
                                             <Tr>
                                             <Td className="text-left fw-500 ellipsis" onClick={(e)=>noticeHandleClick(v.idx)}>{v.title}</Td>
                                             <Td style={{width:200}} className="text-right table-td-date" >{createAtsplit(v.createAt)}</Td>
                                            </Tr>
                                            )
                                        })
                                        :
                                        <Tr>
                                            <Td className="font-weight-bold" colSpan={2} >데이터가 없습니다.</Td>
                                        </Tr>
                                        }
                                        {/* <Tr>
                                            <Td className="text-left fw-500 ellipsis">버전 업데이트 공지 2.3.1 버전 업데이트 공지 2.3.1 버전 업데이트 공지 2.3.1 버전 업데이트 공지 2.3.1 버전 업데이트 공지 2.3.1 버전 업데이트 공지 2.3.1 버전 업데이트 공지 2.3.1 버전 업데이트 공지 2.3.1 버전 업데이트 공지 2.3.1 버전 업데이트 공지 2.3.1 버전 업데이트 공지 2.3.1 버전 업데이트 공지 2.3.1</Td>
                                            <Td style={{width:140}} className="text-right">2020.10.24</Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="text-left fw-500 ellipsis">버전 업데이트 공지 2.3.1</Td>
                                            <Td style={{width:140}} className="text-right">2020.10.24</Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="text-left fw-500 ellipsis">버전 업데이트 공지 2.3.1</Td>
                                            <Td style={{width:140}} className="text-right">2020.10.24</Td>
                                        </Tr> */}
                                    </Tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* 동영상, E-BOOK */}
                <Row className="mb-4">
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h5 className="cardTitle">동영상</h5>
                                <Button onClick={() => { history.push('/videoManagement') }} variant="basic" className="p-0"><span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Other-2.svg')}/></span></Button>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable borderTable">
                                    <Thead>
                                        <Tr>
                                            <Th style={{width:80}}>번호</Th>
                                            <Th>영상제목</Th>
                                            <Th style={{width:90}}>상태</Th>
                                            <Th style={{width:110}}>등록(수정)일</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {/* 
                                        <Tr>
                                            <Td>[번호 DATA]</Td>
                                            <Td className="text-left fw-500 ellipsis">[영상제목 DATA]</Td>
                                            { 상태 == 공개 ? badge-success, 공개
                                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                            : 비공개 badge-gray, 비공개
                                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비공개</div></Td>
                                            }
                                            <Td>[날짜 DATA]</Td>
                                        </Tr>
                                        */}
                                        {mediaList.length > 0 ? mediaList.map((v,i)=>{
                                            return(
                                            <Tr>
                                            <Td>{v.idx}</Td>
                                            <Td className="text-left fw-500 ellipsis" onClick={(e)=>videoHandleClick(v.idx)}>{v.title}</Td>
                                            {v.openFlag  ? 
                                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                            : 
                                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비공개</div></Td>
                                            }
                                            <Td className="table-td-date">{createAtsplit(v.createAt)}</Td>
                                            </Tr>
                                            )
                                        })
                                        :
                                        <Tr>
                                            <Td className="font-weight-bold" colSpan={4} >데이터가 없습니다.</Td>
                                        </Tr>
                                        }
                                    {/*                                         
                                        <Tr>
                                            <Td>100</Td>
                                            <Td className="text-left fw-500 ellipsis">영상 제목은 두 줄로 하고 두 줄이 넘어가면 ellipsis 처리</Td>
                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                            <Td>2020.10.24</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>99</Td>
                                            <Td className="text-left fw-500 ellipsis">영상 제목은 두 줄로 하고 두 줄이 넘어가면 ellipsis 처리</Td>
                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비공개</div></Td>
                                            <Td>2020.10.24</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>98</Td>
                                            <Td className="text-left fw-500 ellipsis">영상 제목은 두 줄로 하고 두 줄이 넘어가면 ellipsis 처리</Td>
                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                            <Td>2020.10.24</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>97</Td>
                                            <Td className="text-left fw-500 ellipsis">영상 제목은 두 줄로 하고 두 줄이 넘어가면 ellipsis 처리</Td>
                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                            <Td>2020.10.24</Td>
                                        </Tr> */}
                                    </Tbody>
                                </Table>
                            </Card.Body>
                        </Card >
                    </Col>
                    
                    
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h5 className="cardTitle">이달의 메세지</h5>
                                <Button onClick={() => { history.push('/mainScreenManagement') }} variant="basic" className="p-0"><span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Other-2.svg')}/></span></Button>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable borderTable">
                                    <Thead>
                                        <Tr>
                                            <Th style={{width:80}}>번호</Th>
                                            <Th>메세지제목</Th>
                                            {/* <Th style={{width:90}}>상태</Th> */}
                                            <Th style={{width:110}}>등록(수정)일</Th>
                                        </Tr>
                                    </Thead>
                                    {}
                                    <Tbody> 
                                        {systemMessageList.length > 0 ? systemMessageList.map((v,i)=>{
                                            return(
                                                <Tr style={{height:'54.44px'}}>
                                                    <Td>{v.idx}</Td>
                                                    <Td className="text-left fw-500 ellipsis" onClick={(e)=>messageHandleClick(v.idx)}>{v.title}</Td>
                                                    {/* <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td> */}
                                                    <Td className="table-td-date">{createAtsplit(v.createAt)}</Td>
                                                </Tr>
                                            )
                                        })
                                        :
                                        <Tr>
                                            <Td className="font-weight-bold" colSpan={3} >데이터가 없습니다.</Td>
                                        </Tr>
                                        }
                                    </Tbody>
                                </Table>
                            </Card.Body>
                        </Card >
                    </Col>
                </Row> 
                
                {/* 오디오북, 프리미엄회원 */}
                {/* <Row className="mb-4">
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h5 className="cardTitle">오디오북</h5>
                                <Button onClick={() => { history.push('/audiobookManagement') }} variant="basic" className="p-0"><span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Other-2.svg')}/></span></Button>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable borderTable">
                                    <Thead>
                                        <Tr>
                                            <Th style={{width:80}}>번호</Th>
                                            <Th>오디오북제목</Th>
                                            <Th style={{width:90}}>상태</Th>
                                            <Th style={{width:110}}>등록(수정)일</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody> */}
                                        {/* 
                                        <Tr>
                                            <Td>[번호 DATA]</Td>
                                            <Td className="text-left fw-500 ellipsis">[오디오북제목 DATA]</Td>
                                            { 상태 == 공개 ? badge-success, 공개
                                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                            : 비공개 badge-gray, 비공개
                                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비공개</div></Td>
                                            }
                                            <Td>[날짜 DATA]</Td>
                                        </Tr>
                                        */}
                                        {/* <Tr>
                                            <Td>100</Td>
                                            <Td className="text-left fw-500 ellipsis">오디오북 제목은 두 줄로 하고 두 줄이 넘어가면 ellipsis 처리</Td>
                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                            <Td>2020.10.24</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>99</Td>
                                            <Td className="text-left fw-500 ellipsis">오디오북 제목은 두 줄로 하고 두 줄이 넘어가면 ellipsis 처리</Td>
                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비공개</div></Td>
                                            <Td>2020.10.24</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>98</Td>
                                            <Td className="text-left fw-500 ellipsis">오디오북 제목은 두 줄로 하고 두 줄이 넘어가면 ellipsis 처리</Td>
                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                            <Td>2020.10.24</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>97</Td>
                                            <Td className="text-left fw-500 ellipsis">오디오북 제목은 두 줄로 하고 두 줄이 넘어가면 ellipsis 처리</Td>
                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                            <Td>2020.10.24</Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Card.Body>
                        </Card >
                    </Col> */}
                    {/* <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h5 className="cardTitle">프리미엄회원</h5>
                                <Button onClick={() => { history.push('/premiumManagement') }} variant="basic" className="p-0"><span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Other-2.svg')}/></span></Button>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable borderTable">
                                    <Thead>
                                        <Tr>
                                            <Th style={{width:80}}>번호</Th>
                                            <Th>계정명</Th>
                                            <Th style={{width:90}}>상태</Th>
                                            <Th style={{width:110}}>등록(수정)일</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody> */}
                                        {/* 
                                        <Tr>
                                            <Td>[번호 DATA]</Td>
                                            <Td className="text-left fw-500 ellipsis">[계정명 DATA]</Td>
                                            { 상태 == 사용중 ? badge-success, 사용중
                                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">사용중</div></Td>
                                            : 기간만료 badge-danger, 기간만료
                                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-danger">기간만료</div></Td>
                                            }
                                            <Td>[날짜 DATA]</Td>
                                        </Tr>
                                        */}
                                        {/* <Tr>
                                            <Td>100</Td>
                                            <Td className="text-left fw-500 ellipsis">selee@upchain.kr</Td>
                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">사용중</div></Td>
                                            <Td>2020.10.24</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>99</Td>
                                            <Td className="text-left fw-500 ellipsis">selee@upchain.kr</Td>
                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-danger">기간만료</div></Td>
                                            <Td>2020.10.24</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>98</Td>
                                            <Td className="text-left fw-500 ellipsis">selee@upchain.kr</Td>
                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">사용중</div></Td>
                                            <Td>2020.10.24</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>97</Td>
                                            <Td className="text-left fw-500 ellipsis">selee@upchain.kr</Td>
                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">사용중</div></Td>
                                            <Td>2020.10.24</Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Card.Body>
                        </Card >
                    </Col>
                </Row> */}
                {/*E-BOOK,AUDIOBOOK */}
                 <Row className="mb-4">
                 <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h5 className="cardTitle">E-BOOK</h5>
                                <Button onClick={() => { history.push('/magazineManagement') }} variant="basic" className="p-0"><span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Other-2.svg')}/></span></Button>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable borderTable">
                                    <Thead>
                                        <Tr>
                                            <Th style={{width:80}}>번호</Th>
                                            <Th>E-BOOK제목</Th>
                                            <Th style={{width:90}}>상태</Th>
                                            <Th style={{width:110}}>등록(수정)일</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {ebookList.length > 0
                                        ?
                                        ebookList.map((v,i)=>{
                                            return(
                                                <Tr>
                                                <Td>{v.idx}</Td>
                                                <Td className="text-left fw-500 ellipsis" onClick={(e)=>ebookHandleClick(v.idx)}>{v.title}</Td>
                                                { v.useFlag
                                                ?
                                                    <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                                : 
                                                    <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비공개</div></Td>
                                                }
                                                <Td className="table-td-date">{createAtsplit(v.createAt)}</Td>
                                            </Tr> 
                                            )
                                        })
                                        :
                                        <Tr>
                                            <Td className="font-weight-bold" colSpan={4} >데이터가 없습니다.</Td>
                                        </Tr>
                                        }
                                        {/* 
                                        <Tr>
                                            <Td>[번호 DATA]</Td>
                                            <Td className="text-left fw-500 ellipsis">[E-BOOK제목 DATA]</Td>
                                            { 상태 == 공개 ? badge-success, 공개
                                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                            : 비공개 badge-gray, 비공개
                                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비공개</div></Td>
                                            }
                                            <Td>[날짜 DATA]</Td>
                                        </Tr>
                                        */}
                                        {/* <Tr>
                                            <Td>100</Td>
                                            <Td className="text-left fw-500 ellipsis">E-BOOK 제목은 두 줄로 하고 두 줄이 넘어가면 ellipsis 처리</Td>
                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                            <Td>2020.10.24</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>99</Td>
                                            <Td className="text-left fw-500 ellipsis">E-BOOK 제목은 두 줄로 하고 두 줄이 넘어가면 ellipsis 처리</Td>
                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비공개</div></Td>
                                            <Td>2020.10.24</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>98</Td>
                                            <Td className="text-left fw-500 ellipsis">E-BOOK 제목은 두 줄로 하고 두 줄이 넘어가면 ellipsis 처리</Td>
                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                            <Td>2020.10.24</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>97</Td>
                                            <Td className="text-left fw-500 ellipsis">E-BOOK 제목은 두 줄로 하고 두 줄이 넘어가면 ellipsis 처리</Td>
                                            <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                            <Td>2020.10.24</Td>
                                        </Tr>*/}
                                    </Tbody>
                                </Table>
                            </Card.Body>
                        </Card >
                    </Col> 

                    {/* <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h5 className="cardTitle">오디오북</h5>
                                <Button onClick={() => { history.push('/audiobookManagement') }} variant="basic" className="p-0"><span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Other-2.svg')}/></span></Button>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable borderTable">
                                    <Thead>
                                        <Tr>
                                            <Th style={{width:80}}>번호</Th>
                                            <Th>오디오북제목</Th>
                                            <Th style={{width:90}}>상태</Th>
                                            <Th style={{width:110}}>등록(수정)일</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody> 
                                        {audioBookList.length > 0
                                        ?
                                        audioBookList.map((v,i)=>{
                                            return(
                                            <Tr>
                                                <Td>{v.idx}</Td>
                                                <Td className="text-left fw-500 ellipsis"onClick={(e)=>audioBookHandleClick(v.idx)} >{v.title}</Td>
                                                {v.useFlag
                                                ?
                                                    <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                                : 
                                                    <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비공개</div></Td>
                                                }
                                                <Td className="table-td-date">{createAtsplit(v.createAt)}</Td>
                                            </Tr>   
                                            )
                                        })
                                        :
                                        <Tr>
                                            <Td className="font-weight-bold" colSpan={4} >데이터가 없습니다.</Td>
                                        </Tr>
                                        }
                                    </Tbody>
                                </Table>
                            </Card.Body>
                        </Card >
                    </Col> */}
                </Row> 

                {/* 명예의 전당 테이블 */}
                 <Row className="mb-4">
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h5 className="cardTitle">명예의전당</h5>
                                <Button onClick={() => { history.push('/honoraryManagement') }} variant="basic" className="p-0"><span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl('/media/icons/Other-2.svg')}/></span></Button>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable borderTable">
                                    <Thead>
                                        <Tr>
                                            <Th style={{width:80}}>번호</Th>
                                            <Th style={{width:150}}>국가</Th>
                                            <Th>성명</Th>
                                            <Th style={{width:240}}>등급</Th>
                                            <Th style={{width:110}}>등록(수정)일</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {personList.length > 0 ? personList.map((v,i)=>{
                                            return(
                                            <Tr>
                                            <Td>{v.idx}</Td>
                                            <Td className="ellipsis" >{v.country}</Td>
                                            <Td className="text-left fw-500 ellipsis" onClick={(e)=>personHandleClick(v.idx)}>{v.name}</Td>
                                            <Td className="ellipsis">{v.typetDescription}</Td>
                                            <Td className="table-td-date">{createAtsplit(v.createAt)}</Td>
                                        </Tr>
                                            )
                                        })
                                        :
                                        <Tr>
                                            <Td className="font-weight-bold" colSpan={5} >데이터가 없습니다.</Td>
                                        </Tr>
                                        }
                                        {/* <Tr>
                                            <Td>2,345</Td>
                                            <Td className="ellipsis">INDONESIA</Td>
                                            <Td className="text-left fw-500 ellipsis">Ganesh Wirama Sugebong Putra</Td>
                                            <Td className="ellipsis">에메랄드 이그제큐티브</Td>
                                            <Td>2020.10.24</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>2,344</Td>
                                            <Td className="ellipsis">SOUTH KOREA</Td>
                                            <Td className="text-left fw-500 ellipsis">공효진</Td>
                                            <Td className="ellipsis">레가시 프레지덴셜 이그제큐티브</Td>
                                            <Td>2020.10.24</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>2,343</Td>
                                            <Td className="ellipsis">JAPAN</Td>
                                            <Td className="text-left fw-500 ellipsis">Ganesh Wirama Sugebong Putra</Td>
                                            <Td className="ellipsis">펄 이그제큐티브</Td>
                                            <Td>2020.10.24</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>2,342</Td>
                                            <Td className="ellipsis">USA</Td>
                                            <Td className="text-left fw-500 ellipsis">John Lennon</Td>
                                            <Td className="ellipsis">트리플 프레지덴셜 이그제큐티브</Td>
                                            <Td>2020.10.24</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>2,341</Td>
                                            <Td className="ellipsis">USA</Td>
                                            <Td className="text-left fw-500 ellipsis">Milky Jessy</Td>
                                            <Td className="ellipsis">에메랄드 이그제큐티브</Td>
                                            <Td>2020.10.24</Td>
                                        </Tr> */}
                                    </Tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row> 

            </Container>
        </>
    );
};

export default Home;
