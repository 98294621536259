// AudiobookManagement _crud

import axios from "axios";


export const API_ADMIN_AUDIO = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/ebook';
export const API_ADMIN_AUDIO_DELETE = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/ebooks';
export const API_ADMIN_AUDIO_OPENFLAG = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/updateEbookOpenFlag';

export function getAudioList(params){
    return axios.get(API_ADMIN_AUDIO,{params});   
}
export function deleteAudioList(idxs) {
    return axios.delete(API_ADMIN_AUDIO_DELETE,{data :{idxs : idxs}});   
}
export function updateUseFlags(idxs,id) {
    let useFlag = false
    // console.log('id',id)
    if(id =='true'){
     useFlag =true
    }
    return axios.put(API_ADMIN_AUDIO_OPENFLAG,{idxs : idxs,useFlag:useFlag});   
}