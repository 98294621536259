import _ from "lodash";

export function removeCSSClass(ele, cls) {
    const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
    ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => pathname;

export function setupAxios(axios, store) {
    axios.interceptors.request.use(
        config => {
            // config.baseURL = process.env.REACT_APP_API_URL+"/api/v1/";
            // config.baseURL = "http://localhost/api/v1/";

            const {
                auth: { authToken }
            } = store.getState();

            if (authToken) {
                // console.log('authToken ', authToken)
                config.headers.Authorization = `Bearer ${authToken}`;
            }

            return config;
        },
        err => Promise.reject(err)
    );
}
export function textSubstring(text, length) {
    let result = text;
    if (result ? result.length > length : null) {
        result = result.substring(0, length) + '...';
    }
    return result;
}

export function makeNotEmptyObj(params, chkParams, exclude){
    if(_.isUndefined(exclude)){
      exclude = [];
    }
  
    _.each(chkParams, (v,k) => {
        // console.log(v != '')
      if(v != '' && v != exclude[k]){
        params[k] = v;
      }
    })
  
    return params;
  }