
import React, { useState  } from 'react';
import styled, {css} from 'styled-components';
import { Tabs, Tab } from "react-bootstrap";

const LanguageTabs = ({onLanSelected, langCode}) => {


    return (
        <>
        {langCode&&
            <Tabs  activeKey={langCode} transition={false} className="languageTabs mb-0" onSelect={(e)=>{onLanSelected(e)}}>
                <Tab eventKey="en" title="ENGLISH"></Tab>
                <Tab eventKey="ko" title="KOREAN"></Tab>
                <Tab eventKey="ja" title="JAPANESE"></Tab>
                <Tab eventKey="zh" title="TAIWAN"></Tab>
                <Tab eventKey="id" title="INDONESIAN"></Tab>
                <Tab eventKey="th" title="THAI"></Tab>
            </Tabs>  
        }
        {!langCode&&
        <Tabs  defaultActiveKey='en' transition={false} className="languageTabs mb-0" onSelect={(e)=>{onLanSelected(e)}}>
            <Tab eventKey="en" title="ENGLISH"></Tab>
            <Tab eventKey="ko" title="KOREAN"></Tab>
            <Tab eventKey="ja" title="JAPANESE"></Tab>
            <Tab eventKey="zh" title="TAIWAN"></Tab>
            <Tab eventKey="id" title="INDONESIAN"></Tab>
            <Tab eventKey="th" title="THAI"></Tab>
        </Tabs>
        }
        </>
    )
}

export default LanguageTabs;


