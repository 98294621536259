import axios from "axios";

export const ADMIN_TERMS = '/api/' + process.env.REACT_APP_API_VERSION +'/admin/terms'
export const ADMIN_TERMS_BY_IDX = '/api/' + process.env.REACT_APP_API_VERSION +'/admin/terms/'
export const ADMIN_TERMS_OPENFLAG = '/api/'+ process.env.REACT_APP_API_VERSION +'/admin/updateTermsOpenFlag/'


export function getTerms(termsIdx){
    return axios.get(ADMIN_TERMS_BY_IDX+termsIdx);
}

export function updateTerms(termsIdx,params){
    return axios.put(ADMIN_TERMS_BY_IDX+termsIdx,params);
}

export function createTerms(params){
    return axios.post(ADMIN_TERMS,params);
}

export function deleteTerms(termsIdx){
    return axios.delete(ADMIN_TERMS_BY_IDX+termsIdx)
}

export function updateOpenFlag(termsidx,openFlag){
    return axios.put(ADMIN_TERMS_OPENFLAG+termsidx + '/?openFlag=' + openFlag);
}