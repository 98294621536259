import React, { useEffect, useState ,useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Container, Col, InputGroup, FormControl, Card, Badge, Button, Modal } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import CustomDropZoneCom from '../../../common/components/CustomDropZoneCom'
import {getDetailCategoryList,updateCategory,createCategory,CategoryFileupload,deleteCategory} from "./crud";

import nullImg from '../../../assets/img/nullimg.png';

import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import swal from 'sweetalert'
// import swal from 'sweetalert';
// import * as authAction from "../../../app/store/ducks/auth.duck";
// import { reissueToken, currency } from '../../../common/up_utils';
// import { TOKEN_EXPIRED_CODE } from '../../../common/dickey';
// import Pagination from '../../../common/components/Pagination';
// import { getUsers } from './crud';

const UploadCategory = () => {



    const {page,idx} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    // 내용이 채워졌을 때 업로드 활성화

    // 모달 (업로드)
    const [show, setShow_upload] = useState(false);
    const handleClose_upload = () => setShow_upload(false);
    const handleShow_upload = () => setShow_upload(true);
    // 모달 삭제
    const [deleteShow, setShow_delete] = useState(false);
    const handleClose_delete = () => setShow_delete(false);
    const handleShow_delete = () => setShow_delete(true);
    /*
     idx:[],
        title:[],
        titleEn:[],
        thumbnailPath:[],
        useFlag:[],
        num:[],
        createAt : [],
        type:[]
    */
    const [categoryidx,setCategoryidx] =useState('');
    const [titleKo,setTitleKo] =useState('');
    const [titleEn,setTitleEn] =useState('');
    const [titleJa,setTitleJa] =useState('');
    const [titleTh,setTitleTh] =useState('');
    const [titleZh,setTitleZh] =useState('');
    const [titleId,setTitleId] =useState('');
    const [thumbnailPath,setThumbnailPath] =useState('');
    const [useFlag,setUseFlag] =useState('');
    const [num,setNum] =useState('');
    const [type,setType] =useState('FREE');
    const [thumbnailName,setThumbnailName] = useState('');
    const [thumbnailImg,setThumbnailImg] = useState([]);
    const [contentType,setContentType] = useState('')

    const onChangeTitleKo = (e) => {setTitleKo(e.target.value)}
    const onChangeTitleEn = (e) => {setTitleEn(e.target.value)}
    const onChangeTitleJa = (e) => {setTitleJa(e.target.value)}
    const onChangeTitleTh = (e) => {setTitleTh(e.target.value)}
    const onChangeTitleZh = (e) => {setTitleZh(e.target.value)}
    const onChangeTitleId = (e) => {setTitleId(e.target.value)}
    const onChangeNum=(e)=>{setNum(e.target.value)}
    const onChangeThumbnailinputimg =(e)=>{setThumbnailImg(e.target.files); setThumbnailName(e.target.files[0].name); };

    const titleKoFocus = useRef(null);
    const titleEnFocus = useRef(null);
    const titleJaFocus = useRef(null);
    const titleThFocus = useRef(null);
    const titleZhFocus = useRef(null);
    const titleIdFocus = useRef(null);
    const numFocus = useRef(null);

    const getthumbnailName =(e)=>{
        // console.log('e::', e)
        if(e){
        let fileName = e;
        let filenameArr = fileName.split('/');
        // console.log('e::',e);
        setThumbnailName(filenameArr[2]);
        }
    }

    const thumbnailImgDelete= ()=>{
        setThumbnailPath();
        setThumbnailName('');
    }
    //파일 업로드 
    const categoryIconFileUpload =()=>{
        let Paths = '';
        var formData = new FormData;
        // console.log('nullImg',nullImg);
        if(thumbnailImg.length>0){
            // console.log('파일',thumbnailImg[0])
            formData.append('files',thumbnailImg[0]);
            formData.append('path','categoryIcon');
            const rs = CategoryFileupload(formData);
            rs.then(({data})=>{
                let dataArray =new Array();
                
                for(let key in data){ 
                    let realName = data[key].realName;
                    let filePath = data[key].filePath;
                    let pushData = filePath + realName;
                    dataArray.push(pushData);
                }
                let Paths = dataArray.join();
                // console.log('Paths',Paths)
                onSubmit(Paths);
            })
        }else{
            onSubmit(Paths);
        }

        }

    //카테고리 디테일
    const getDetail = () => {
        const rs = getDetailCategoryList(idx);
        // console.log('idx',idx);
        rs.then(({ data : {success, message, code, data} }) => {
            if(data != null) {
                // console.log('data ::',data);
                setCategoryidx(data.idx);
                setTitleKo(data.titleKo);
                setTitleJa(data.titleJa);
                setTitleTh(data.titleTh);
                setTitleZh(data.titleZh);
                setTitleId(data.titleId);
                setTitleEn(data.titleEn);
                setThumbnailPath(data.thumbnailPath);
                setUseFlag(data.useFlag);
                setNum(data.num)
                setType(data.type);
                getthumbnailName(data.thumbnailPath);
                setContentType(data.contentType)
               // console.log('pic 파일 이름',data.picPath)
            } else {
                // swal('잘못 된 접근입니다.\r\n다시 해주세요!').then(()=>{history.push(`/categoryManagement/${page}`)});
            }
        })
        .catch(({response : data})=>{
            // console.log('catch :: ', data)
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        getDetail();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }
    //수정 및 추가
    const onSubmit = (paths) => {
        handleClose_upload();
        if(contentType === "SYSTEM_MESSAGE"){
            if(titleKo.split('|').length !== 12) return swal('알림','12개의 시스템메세지명을 입력하세요.').then(() => {titleKoFocus.current.focus();});
            if(titleEn.split('|').length !== 12) return swal('알림','12개의 시스템메세지명을 입력하세요.').then(() => {titleEnFocus.current.focus();});
            if(titleTh.split('|').length !== 12) return swal('알림','12개의 시스템메세지명을 입력하세요.').then(() => {titleThFocus.current.focus();});
            if(titleZh.split('|').length !== 12) return swal('알림','12개의 시스템메세지명을 입력하세요.').then(() => {titleZhFocus.current.focus();});
            if(titleJa.split('|').length !== 12) return swal('알림','12개의 시스템메세지명을 입력하세요.').then(() => {titleJaFocus.current.focus();});
            if(titleId.split('|').length !== 12) return swal('알림','12개의 시스템메세지명을 입력하세요.').then(() => {titleIdFocus.current.focus();});
        }
        if(!titleKo && !titleEn && !titleJa && !titleTh &&!titleZh &&!titleId){return swal('알림','하나 이상의 카테고리명을 입력해주세요').then(() => {titleKoFocus.current.focus();});}
        // if(!titleKo) return swal('알림','카테고리 한국어명을 입력해주세요').then(() => {titleKoFocus.current.focus();});
        // if(!titleEn) return swal('알림','카테고리 영어명을 입력해주세요').then(() => {titleEnFocus.current.focus();});
        // if(!titleJa) return swal('알림','카테고리 일본어명을 입력해주세요').then(() => {titleJaFocus.current.focus();});
        // if(!titleTh) return swal('알림','카테고리 태국어명을 입력해주세요').then(() => {titleThFocus.current.focus();});
        // if(!titleZh) return swal('알림','카테고리 대만어명을 입력해주세요').then(() => {titleZhFocus.current.focus();});
        // if(!titleId) return swal('알림','카테고리 인도네시아어명을 입력해주세요').then(() => {titleIdFocus.current.focus();});
        if(num <= 0) return swal('알림','순서는 0 이상이여야 합니다.').then(() => {numFocus.current.focus();});

        let CategoryObj = {
            titleKo : titleKo,
            titleEn : titleEn,
            titleJa :titleJa,
            titleTh :titleTh,
            titleZh :titleZh,
            titleId :titleId,
            thumbnailPath :paths !='' ? paths : thumbnailPath,
            //useFlag :useFlag,
            num :num,
            type :type,
        } 
        // console.log(systemMessageIdx)
        if(categoryidx != '') {
            const rs = updateCategory(categoryidx ,CategoryObj);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== null) {
                    swal({
                        title: "알림",
                        text: "정상적으로 수정 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push(`/categoryManagement/${page}`);
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                // console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit(paths != '' ? paths : thumbnailPath);
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        } else {
            const rs = createCategory(CategoryObj);
            rs.then(({ data : {success, message, code, data} }) => {
                if(data !== null) {
                    swal({
                        title: "알림",
                        text: "정상적으로 등록 되었습니다."
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            history.push('/categoryManagement/1');
                        }
                    })
                } else {
                    swal({
                        title: "알림",
                        text: message
                    }).then((isConfirm) => {
                        if(isConfirm) {
                            return false;
                        }
                    })
                }
            })
            .catch(({response : data})=>{
                // console.log('catch :: ', data)
                if(data.status == 401 && data.data && data?.data?.code == 1101){
                    const auth = JSON.parse(localStorage.getItem('persist:auth'));
                    const rsRf= refresh(auth.refreshToken);
                    
                    rsRf.then(({ data : {success, message, code, data} }) => {
                        if(data != null) {
                            let accessToken = data.accessToken;
                            let refreshToken = data.refreshToken;
                            auth.authToken = accessToken;
                            auth.refreshToken = refreshToken;
                    
                            localStorage.setItem('persist:auth', JSON.stringify(auth));
            
                            dispatch(authAction.actions.login(accessToken, refreshToken));
                            onSubmit(thumbnailPath);
                        } else {
                            swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                        }
                    })
                    .catch(({response : data})=>{
                      if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                      }
                    });
                }
            });
        }
    }
    //삭제
    const deleteOnCategory = ()=>{
        handleClose_delete(false);
        const rs = deleteCategory(categoryidx);
        rs.then(({data : {success,message,code,data} })=>{
            if(success=true && message ==='success') {
                swal({
                    title :"알림",
                    text : "정상적으로 삭제 되었습니다."
                }).then((isConfirm)=>{
                    if(isConfirm){
                        history.push(`/categoryManagement/${page}`);
                    }
                })
            }else{
                swal({
                    title: "알림",
                    text : message
                }).then((isConfirm)=>{
                    if(isConfirm){
                        return false;
                    }
                })
            }
        }).catch(({response : data})=>{
            if(data.status == 401 && data.data && data?.data?.code == 1101){
                const auth = JSON.parse(localStorage.getItem('persist:auth'));
                const rsRf= refresh(auth.refreshToken);
                
                rsRf.then(({ data : {success, message, code, data} }) => {
                    if(data != null) {
                        let accessToken = data.accessToken;
                        let refreshToken = data.refreshToken;
                        auth.authToken = accessToken;
                        auth.refreshToken = refreshToken;
                
                        localStorage.setItem('persist:auth', JSON.stringify(auth));
        
                        dispatch(authAction.actions.login(accessToken, refreshToken));
                        deleteOnCategory();
                    } else {
                        swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                    }
                })
                .catch(({response : data})=>{
                  if(data?.status == 401 && data?.data && data?.data?.code == 1101){
                    swal('로그인을 다시 해주세요!').then(()=>{history.push('/logout')});
                  }
                });
            }
        });
    }

    const onClickImageUpload = (e)=>{
        window.document.getElementById('picfile').click();
    }


    
    useMemo(()=>{
        getDetail();
    },[])


    return (
        <>
            <Container fluid className="UploadCategoryContainer">
           
                {/* 버튼영역 */}
                <Row className="mb-4 no-gutters">
                    <Col className="d-flex justify-content-between align-items-center">
                        <Button  onClick={()=>history.push(`/categoryManagement/${page}`)} variant="outline-primary">목록으로</Button>
                        {/* 정보입력시 활성화 ↑ */}
                        {/* <Button variant="dark" disabled>업로드</Button> */}
                        <div >
                        {categoryidx !=='' &&
                        <Button  variant="outline-danger mr-2" onClick={handleShow_delete} >삭제</Button>
                        }
                        <Button  variant="primary" onClick={handleShow_upload} >{categoryidx !== '' ? '수정' : '업로드'}</Button>
                        </div>
                    </Col>
                </Row>

                {/* 모달 (업로드) */}
                <Modal show={show} onHide={handleClose_upload} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>{categoryidx !== '' ? '수정' : '업로드'} 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_upload}>취소</Button>
                        <Button variant="primary" onClick={categoryIconFileUpload}>확인</Button>
                    </Modal.Footer>
                </Modal>
                 {/*모달 삭제 */}
                 <Modal show={deleteShow} onHide={handleClose_delete} centered>
                    <Modal.Header closeButton><Modal.Title>알림</Modal.Title></Modal.Header>
                    <Modal.Body>삭제 하시겠습니까?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_delete}>취소</Button>
                        <Button variant="primary" onClick={deleteOnCategory}>확인</Button>
                    </Modal.Footer>
                </Modal>

                {/* 정보 입력영역 */}
                <Row className="mb-4">
                    <Col>
                        <Card>
                            <Card.Header className="cardTitle text-primary">카테고리</Card.Header>
                            <Card.Body className="p-0">
                                <Table className="commonTable">
                                    <Tbody>
                                        <Tr>
                                            <Td colspan="2">
                                                <Row className="no-gutters">
                                                    <Col className="d-flex justify-content-between align-items-center mr-3 no-gutters">
                                                        <label className="font-weight-bold text-dark text-left" style={{width:100}}>KOREAN</label>
                                                        <input className="form-control" type="text" placeholder={contentType === "SYSTEM_MESSAGE" ?"| 를 기준으로 각 월에 입력될 시스템메세지명을 입력하세요."  :"카테고리명을 입력하세요."} onChange={(e)=>onChangeTitleKo(e)} value={titleKo} ref={titleKoFocus}/>
                                                    </Col>
                                                    <Col className="d-flex justify-content-between align-items-center ml-3 no-gutters">
                                                        <label className="font-weight-bold text-dark text-left" style={{width:100}}>ENGLISH</label>
                                                        <input className="form-control" type="text" placeholder={contentType === "SYSTEM_MESSAGE" ?"| Enter the name of the system message to be entered in each month based on."  :"Pleaseenter category name."} onChange={(e)=>onChangeTitleEn(e)} value={titleEn}  ref={titleEnFocus}/>
                                                    </Col>
                                                </Row>
                                                <Row className="no-gutters mt-3">
                                                    <Col className="d-flex justify-content-between align-items-center mr-3 no-gutters">
                                                        <label className="font-weight-bold text-dark text-left" style={{width:100}}>JAPANESE</label>
                                                        <input className="form-control" type="text" placeholder={contentType === "SYSTEM_MESSAGE" ?"| 를 기준으로 각 월에 입력될 시스템메세지명을 입력하세요."  :"카테고리명을 입력하세요."} onChange={(e)=>onChangeTitleJa(e)} value={titleJa} ref={titleJaFocus}/>
                                                    </Col>
                                                    <Col className="d-flex justify-content-between align-items-center ml-3 no-gutters">
                                                        <label className="font-weight-bold text-dark text-left" style={{width:100}}>THAI</label>
                                                        <input className="form-control" type="text" placeholder={contentType === "SYSTEM_MESSAGE" ?"| Enter the name of the system message to be entered in each month based on."  :"Pleaseenter category name."} onChange={(e)=>onChangeTitleTh(e)} value={titleTh} ref={titleThFocus}/>
                                                    </Col>
                                                </Row>
                                                <Row className="no-gutters mt-3">
                                                    <Col className="d-flex justify-content-between align-items-center mr-3 no-gutters">
                                                        <label className="font-weight-bold text-dark text-left" style={{width:100}}>TAIWAN</label>
                                                        <input className="form-control" type="text" placeholder={contentType === "SYSTEM_MESSAGE" ?"| 를 기준으로 각 월에 입력될 시스템메세지명을 입력하세요."  :"카테고리명을 입력하세요."} onChange={(e)=>onChangeTitleZh(e)} value={titleZh} ref={titleZhFocus}/>
                                                    </Col>
                                                    <Col className="d-flex justify-content-between align-items-center ml-3 no-gutters">
                                                        <label className="font-weight-bold text-dark text-left" style={{width:100}}>INDONESIAN</label>
                                                        <input className="form-control" type="text" placeholder={contentType === "SYSTEM_MESSAGE" ?"| Enter the name of the system message to be entered in each month based on."  :"Pleaseenter category name."} onChange={(e)=>onChangeTitleId(e)} value={titleId} ref={titleIdFocus}/>
                                                    </Col>
                                                </Row>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td colspan="2">
                                                <Row className="no-gutters">
                                                    <Col className="d-flex justify-content-between align-items-center no-gutters col-auto">
                                                        <label className="font-weight-bold text-dark text-left" style={{width:100}}>아이콘</label>
                                                    </Col>
                                                    <Col className="d-flex justify-content-between align-items-center" style={{flex:1}}>
                                                        <form encType="multipart/form-data">
                                                            <input type='file' key='image'accept="image/png" className='inputFiles' id='picfile'  hidden onChange={(e)=>onChangeThumbnailinputimg(e) } ></input>
                                                        </form>
                                                        <Button variant="outline-primary" className="col-2 mr-2" onClick={(e)=>onClickImageUpload(e)} >파일선택</Button>
                                                        {thumbnailName&&
                                                        <Button variant="outline-primary" className="col-2 mr-2" onClick={()=>thumbnailImgDelete('')}>파일삭제</Button>
                                                        }
                                                        <Form.Control type="text" placeholder={thumbnailName ?thumbnailName : "50 X 50 크기의 파일을 선택해주세요."} disabled />                                                
                                                    </Col>
                                                </Row>
                                                <Row className="no-gutters"  style={{marginTop:'10px'}}>
                                                    <Col><span style={{color : 'red'}}>* 50 X 50 크기의 배경이 투명한 .png 파일만 등록가능합니다.</span></Col>
                                                </Row>
                                            </Td>
                                        </Tr>
                                    
                                        <Tr>
                                            <Td colspan="2">
                                                <Row className="no-gutters">
                                                    <Col className="d-flex justify-content-between align-items-center no-gutters col-auto">
                                                        <label className="font-weight-bold text-dark text-left" style={{width:100}}>순서</label>
                                                    </Col>
                                                    
                                                    <Col className="d-flex justify-content-between align-items-center" style={{flex:1}}>
                                                        <input className="form-control" style={{width : '30px'}} type="number" placeholder="순서를 입력해 주세요" onChange={(e)=>onChangeNum(e)} defaultValue={num} ref={numFocus}/>
                                                    </Col>
                                                   
                                                </Row>
                                            </Td>
                                        </Tr>
                                        
                                    </Tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container >
        </>
    );
};

export default UploadCategory;
