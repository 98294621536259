import axios from "axios";
export const ADMIN_PRODUCT_URL = "/admin/product";
export const ADMIN_USER_URL = "/admin/user";

export const MAIN_HOME = "/api/"+process.env.REACT_APP_API_VERSION+"/admin/main"

export function getAdminProductList(data) {
    return axios.get(ADMIN_PRODUCT_URL, data);
}


export function getAdminUserList(data) {
    return axios.get(ADMIN_USER_URL, data);
}

export function getallList() {
    return axios.get(MAIN_HOME);
}