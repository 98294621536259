import React, { useEffect, useState,useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Card, Button, Pagination } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useHistory, useParams } from "react-router-dom";
import {getCategoryList,getvideoList} from "./crud"
import Checkbox from '@material-ui/core/Checkbox';
import testImg from '../../../assets/img/testimg.jpg';
import { refresh } from '../../auth/Login/crud';
import * as authAction from "../../../app/store/ducks/auth.duck";
import {makeNotEmptyObj} from '../../../../src/_metronic/utils/utils'
import swal from 'sweetalert'
import CustomPagination from '../../../common/components/CustomPagination'
import {convertSecondsToMinutes} from '../../../common/up_utils'

const TabList = ({title, history,page, handleShow_private,checkList,oneCheck,categoryIdx,allCheck,videoList,handleShow_public,movePage, handleShow_delete,videoPage,searchParams}) => {

   // const { page ,categoryIdx } = useParams();
   
const handleClick = (idx, e) => {
    if(e.target.type === 'checkbox') return false;
    // console.log('categoryIdx',categoryIdx);
    const params = {      
        langCode :searchParams[1],       
        searchType : searchParams[3],   
        sortColumn : searchParams[0],
        search:searchParams[4],
        categoryIdx : categoryIdx
    }
    const url = {
      pathname : `/videoManagement_upload/${page}/${idx}`,
      state : params,
    }
    history.push(url);
  }
  const uploadhandleClick = () => {
    // console.log('categoryIdx',categoryIdx);
    const params = {      
        categoryIdx : categoryIdx
    }
    const url = {
      pathname : `/videoManagement_upload/1/0`,
      state : params,
    }
    history.push(url);
  }
  
    return(
        <Card className="tabBodyCard">
            <Card.Header className="d-flex justify-content-between align-items-center">
                <h4 className="cardTitleBig">{title} &nbsp;&nbsp;<span style={{fontSize : '14px', fontWeight: 400}}>[ 전체 : {videoPage.totalElements} ]</span></h4>
                <Button onClick={uploadhandleClick} variant="primary">동영상 업로드</Button>
            </Card.Header>
            <Card.Body className="d-flex no-gutters p-0">
                <Table className="commonTable borderTable bb-1">
                        <Thead>
                            <Tr>
                                <Th style={{width:50}}>
                                <Checkbox checked = {
                                    checkList.length === videoPage.numberOfElements && videoPage.numberOfElements>0
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>allCheck(e.target.checked)}/>
                                </Th>
                                <Th style={{width:80}}>번호</Th>
                                <Th style={{width:100}}>언어</Th>
                                <Th>제목</Th>
                                <Th style={{width:100}}>조회수</Th>
                                <Th style={{width:80}}>순서</Th>
                                <Th style={{width:120}}>설정상태</Th>
                                <Th style={{width:160}}>공개일</Th>
                                <Th style={{width:160}}>등록(수정)일</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {/*
                            <Tr>
                                <Td><Checkbox /></Td>
                                <Td>[번호 DATA]</Td>
                                <Td className="text-left">
                                    <div class="thumbWrap">
                                        <div className="thumbImgBox">
                                            <img src=[동영상썸네일 URL DATA] className="thumbImg" />
                                            <span className="thumbSpan">[동영상 시간 DATA]</span>
                                        </div>
                                        <h6 className="thumbText">[동영상 제목 DATA]</h6>
                                    </div>
                                </Td>
                                <Td>[동영상 조회수 DATA]</Td>
                                { 설정상태 == 공개 ? badge-success, 공개
                                    <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                : 비공개 badge-gray, 비공개
                                    <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비공개</div></Td>
                                }
                                <Td>[날짜 시간 DATA]</Td>
                            </Tr>
                            */}
                                {/*
                                 <Tr>
                                <Td><Checkbox /></Td>
                                <Td>57</Td>
                                <Td className="text-left">
                                    <div class="thumbWrap">
                                        <div className="thumbImgBox">
                                            <img src={testImg} className="thumbImg" />
                                            <span className="thumbSpan">03:00</span>
                                        </div>
                                        <h6 className="thumbText">동영상 제목은 두줄로 하고 두 줄이 넘어가면 ellipsis, 한줄이면 간격 상관없이 상하 가운데정렬 - 이 내용은 앱 적용 내용입니다. 어드민에서는 내용 전체 노출됩니다. (메인배너, 동영상, E-BOOK)</h6>
                                    </div>
                                </Td>
                                <Td>2,557</Td>
                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                <Td>2020.10.24 00:00</Td>
                            </Tr>
                            <Tr>
                                <Td><Checkbox /></Td>
                                <Td>56</Td>
                                <Td className="text-left">
                                    <div class="thumbWrap">
                                        <div className="thumbImgBox">
                                            <img src={testImg} className="thumbImg" />
                                            <span className="thumbSpan">03:00</span>
                                        </div>
                                        <h6 className="thumbText">{title} 동영상 테이블 더미 테스트</h6>
                                    </div>
                                </Td>
                                <Td>2,557</Td>
                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                <Td>2020.10.24 00:00</Td>
                            </Tr>
                            <Tr>
                                <Td><Checkbox /></Td>
                                <Td>55</Td>
                                <Td className="text-left">
                                    <div class="thumbWrap">
                                        <div className="thumbImgBox">
                                            <img src={testImg} className="thumbImg" />
                                            <span className="thumbSpan">03:00</span>
                                        </div>
                                        <h6 className="thumbText">{title} 동영상 테이블 더미 테스트</h6>
                                    </div>
                                </Td>
                                <Td>2,557</Td>
                                <Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비공개</div></Td>
                                <Td>2020.10.24 00:00</Td>
                                */}
                                {videoList.length > 0 

                                ? 
                                videoList.map((v,i)=>{
                                   
                                    return(
                                        <Tr onClick={(e)=>handleClick(v.idx, e)}>
                                        <Td>
                                            <Checkbox 
                                            checked={
                                                checkList.includes(v.idx) ? true : false
                                            }
                                            onChange={(e)=>oneCheck(e.target.checked,v.idx)} />
                                        </Td>
                                        <Td>{v.idx}</Td>
                                        <Td>{v.langCodeName}</Td>
                                        <Td className="text-left">
                                            <div class="thumbWrap">
                                                {v.thumbnailPath ?
                                                <div className="thumbImgBox">
                                                    <img src={'/fileUpload/'+v.thumbnailPath} className="thumbImg" />
                                                    {
                                                        v.uploadComplete === 'C' ? 
                                                        <span className="thumbSpan">{convertSecondsToMinutes(v.playtime)}</span>
                                                        :
                                                        v.uploadComplete === 'I' ?
                                                        <span className="thumbSpan">{'인코딩중'}</span>
                                                        :
                                                        <span className="thumbSpan">{'실패'}</span>
                                                    }
                                                    
                                                </div>
                                                :
                                                <div className="thumbImgBox">
                                                    <img src={'/media/empty/default-video.jpg'} className="thumbImg" />
                                                </div>
                                                 }
                                                <h6 className="thumbText">{v.title}</h6>
                                            </div>
                                        </Td>
                                        <Td>{v.viewCnt}</Td>
                                        <Td>{v.num}</Td>
                                        {v.openFlag
                                        ?<Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-success">공개</div></Td>
                                        :<Td><div className="d-inline-block w-75 m-0 badge text-wrap font-weight-normal stateBadge badge-gray">비공개</div></Td>
                                        }
                                        <Td>{v.openDateAt}</Td>
                                        <Td>{v.createAt}</Td>
                                        </Tr>
                                    )
                                    
                                })
                               
                                :
                                <Tr >
                                    <Td colSpan="7">관련 게시물이 없습니다</Td>
                                </Tr>
                                }
                        </Tbody>
                    </Table>
            </Card.Body>

            <Card.Footer className="d-flex flex-row justify-content-between align-items-center paginationFooter">
                <div>
                    <CustomPagination
                        itemsCount={10}
                        pageData={videoPage}
                        page={page}
                        selectctgidx={categoryIdx}
                        onClickEvent={movePage}
                    >
                    </CustomPagination>
                </div>
                <div>
                    <Button variant="outline-gray" onClick={handleShow_private}>비공개 전환</Button>
                    <Button variant="outline-success ml-2" onClick={handleShow_public}>공개 전환</Button>
                    <Button variant="outline-danger ml-2" onClick={handleShow_delete}>동영상 삭제</Button>
                </div>
            </Card.Footer>

        </Card>
    )
}

export default TabList;