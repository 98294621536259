
import React, { useState } from 'react';
import styled, {css} from 'styled-components';
import { Form, Row, Col, InputGroup, FormControl, Card, Button } from "react-bootstrap";

const CustomSearchCardv2 = (
    {   
        isLanguage,
        onSelectLanguage,
        langValue,

        isSortColumn,
        onChangeSortColumn,
        sortColumnItems,
        sortColumn,

        isSortType,
        onChangeSortType,
        sortTypeItems,
        sortType,

        isSearch,
        onChangeSearch,
        onSearchEvent,
        searchItems,
        onChangeSearchType,
        search,

        isPersonType,
        onSelectPersonType,
        personTypeValue,
    }) => {
    // TODO :: onKeyPress
  const appKeyPress = (e) => {
    // 엔터 일 때
    if (e.key === 'Enter') {
      // 검색 이벤트 호출
      onSearchEvent(e);
    }
  }
    return (
        <Row className="mb-4 searchCard no-gutters">
            <Card className="p-4 flex-row justify-content-between align-items-center no-gutters w-100">

                {/* 좌측 */}
                <Col className="d-flex mr-4">
                    {isSortColumn && 
                        <Col className="d-flex flex-row justify-content-between align-items-center">
                            <h5 className="font-weight-bold text-primary mr-3">나열순서</h5>
                            <Form.Control className="select-bold" as="select" onChange={onChangeSortColumn} value={sortColumn || 'createAt'}>
                                {
                                    sortColumnItems && sortColumnItems.length > 0 ? sortColumnItems.map((v, i) => {
                                            return (
                                                <option value={v.value}>{v.text}</option>    
                                            )
                                    })
                                    : 
                                    <option value="createAt">등록일순</option>
                                }
                                
                            </Form.Control>
                        </Col>
                    }
                    {!isSortType && !isLanguage && !isPersonType &&
                        <>
                        <Col className="d-flex flex-row justify-content-between align-items-center">
                            
                        </Col>
                        </>  
                    }
                    
                    {isSortType && 
                        <Col className="d-flex flex-row justify-content-between align-items-center">
                            <h5 className="font-weight-bold text-primary mr-3">정렬</h5>
                            <Form.Control className="select-bold" as="select" onChange={onChangeSortType} value={sortType || 'ASC'}>
                                {
                                    sortTypeItems && sortTypeItems.length > 0 ? sortTypeItems.map((v, i) => {
                                            return (
                                                <option value={v.value}>{v.text}</option>    
                                            )
                                    })
                                    : 
                                    <option value="ASC">오름차순</option>
                                }
                                
                            </Form.Control>
                        </Col>
                    }
                    { isLanguage &&
                            <Col className="d-flex flex-row justify-content-between align-items-center">
                            <>
                                <h5 className="font-weight-bold text-primary mr-3">언어선택</h5>
                                <Form.Control className="select-bold" as="select" onChange={onSelectLanguage} value={langValue || ""}>
                                    <option value="" selected>선택</option>
                                    {/** 영어 */}
                                    <option value="en">ENGLISH</option>
                                    {/** 한국어 */}
                                    <option value="ko">KOREAN</option>
                                    {/** 일본어 */}
                                    <option value="ja">JAPANESE</option>
                                    {/** 대만어 */}
                                    <option value="zh">TAIWAN</option>
                                    {/** 인도네시아어 */}
                                    <option value="id">INDONESIAN</option>
                                    {/** 태국어 */}
                                    <option value="th">THAI</option>
                                </Form.Control>
                            </>
                        </Col>
                 }
                 { isPersonType &&
                            <Col className="d-flex flex-row justify-content-between align-items-center">
                            <>
                                <h5 className="font-weight-bold text-primary mr-3">등급선택</h5>
                                <Form.Control className="select-bold" as="select" onChange={onSelectPersonType} value={personTypeValue || ''}>
                                    <option value="" selected>선택</option>
                                    <option value="LEGACY">레가시 프리지덴셜 이그제큐티브</option>
                                    <option value="TRIPLE">트리플 프리지덴셜 이그제큐티브</option>
                                    <option value="DOUBLE">더블 프리지덴셜 이그제큐티브</option>
                                    <option value="PRESIDENTIAL">프레지덴셜 이그제큐티브</option>
                                    <option value="DIAMOND">다이아몬드 이그제큐티브</option>
                                    <option value="EMERALD">에메랄드 이그제큐티브</option>
                                    <option value="PEARL">펄 이그제큐티브</option>
                                </Form.Control>
                            </>
                        </Col>
                 }
                </Col>

                {/* 우측 */}
                {isSearch &&     
                    <Col className="d-flex ml-4">
                        <Col className="d-flex flex-row justify-content-end col-4">
                            <Form.Control className="select-bold" as="select" onChange={onChangeSearchType}>
                                {
                                    searchItems && searchItems.length > 0 ? searchItems.map((v, i) => {
                                            return (
                                                <option value={v.value}>{v.text}</option>    
                                            )
                                    })
                                    : 
                                    <option value="title">제목 + 내용</option>
                                }
                            </Form.Control>
                        </Col>
                        <Col className="col-8">
                            <InputGroup>
                                <FormControl onChange={onChangeSearch}
                                    id="SearchBar"
                                    aria-label="SearchBar"
                                    aria-describedby="SearchBar"
                                    onKeyPress={(e) => appKeyPress(e)}
                                    value={search || ''}
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text htmlFor="SearchBar">
                                        <Button type={'button'} style={{background: 'none', border: 'none', padding: '0'}} onClick={(e) => onSearchEvent(e)}>
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </Button>
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Col>
                }
                 
            </Card>
        </Row>
    )
}

export default CustomSearchCardv2;


