/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Menu from "./common/Menu/index";
import AuthPage from "./app/pages/auth/AuthPage";
import ErrorsPage from "./app/pages/errors/ErrorsPage";
import LogoutPage from "./app/pages/auth/Logout";
import { LayoutContextProvider } from "./_metronic";

// synergy
import Home from "./views/summary/Home";
import MainScreenManagement from "./views/main/MainScreenManagement";
import UploadMessage from "./views/main/UploadMessage";
import VideoManagement from "./views/video/VideoManagement";
import UploadVideo from "./views/video/UploadVideo";
import MagazineManagement from "./views/ebook/MagazineManagement";
import AudiobookManagement from "./views/ebook/AudiobookManagement";
import UploadMagazine from "./views/ebook/UploadMagazine";
import UploadAudiobook from "./views/ebook/UploadAudiobook";
import HonoraryManagement from "./views/honorary/HonoraryManagement";
import UploadHonorary from "./views/honorary/UploadHonorary";
import NoticeManagement from "./views/notice/NoticeManagement";
import UploadNotice from "./views/notice/UploadNotice";
import CategoryManagement from "./views/category/CategoryManagement";
import UploadCategory from "./views/category/UploadCategory";
import PremiumManagement from "./views/premium/PremiumManagement";
import AdminManagement from "./views/admin/AdminManagement";
import AwsUpload from "./views/video/Upload/AwsUpload";
import Players from "./views/video/Player/Players"
import UploadmainTopScreen from "./views/main/MainScreenManagement/UploadMainTopscreen";
import TermsManagement from "./views/terms/termsManagement"
import UploadTerms from "./views/terms/UploadTerms"
import Policy from "./views/others/Policy"
import EpubReader from "./views/epubReader/epubReader"
import Intro from "./views/others/Intro"
import GoodsManagement from "./views/goods/GoodsManagement"
import UploadGoods from "./views/goods/UploadGoods"
import BannerManagement from "./views/banner/BannerManagement"
import UploadBanner from "./views/banner/UploadBanner"
import MedicalStaffManagement from "./views/medicalStaff/MedicalStaffManagement"
import UploadMedicalStaff from "./views/medicalStaff/UploadMedicalStaff"
import QuestionManagement from "./views/question/QuestionManagement"
import UploadQuestion from "./views/question/UploadQuestion"
import PushManagement from "./views/push/PushManagement"
import UploadPush from "./views/push/UploadPush"
export const Routes = withRouter(({ Layout, history }) => {
    const { isAuthorized, menuConfig, userInfo } = useSelector(
        
        ({ auth, builder: { menuConfig } }) => ({
            menuConfig,
            isAuthorized: auth.authToken != null,
        }),
        shallowEqual
    );
    return (
        /* Create `LayoutContext` from current `history` and `menuConfig`. */
        <LayoutContextProvider history={history} menuConfig={menuConfig}>
            <Switch>
                {!isAuthorized ? (
                    /* Render auth page when user at `/auth` and not authorized. */
                    <Route path="/auth/login" component={AuthPage} />
                ) : (
                    /* Otherwise redirect to root page (`/`) */
                    <Redirect from="/auth/login" to="/home" />
                )}

                <Route path="/error" component={ErrorsPage} />
                <Route path="/logout" component={LogoutPage} />
                <Route path="/upload" component={AwsUpload} />
                <Route path="/videoPlayer" component={Players} />
                <Route path="/policy" component={Policy} />
                <Route path="/epubReader" component={EpubReader} />
                <Route path="/intro" component={Intro} />
                {/* <Route path="/videoPlayer/:mediaIdx" component={Players} /> */}
                {/* {
                    <Redirect exact from="/videoPlayer" to="/videoPlayer/1" />
                } */}
                
                
                {/** 디자인 작업을 위해 권한체크하지않음. */}
                {/** Redirect to `/auth` when user is not authorized */}
                {!isAuthorized ? (
                    <Redirect to="/intro" />
                ) : (
                    <Layout menuData={Menu}>
                        <Switch>
                            {
                                <Redirect exact from="/" to="/home" />
                            }
                            <Route path="/home" component={Home} />
                            <Route path="/mainScreenManagement/:page/:tabacv" component={MainScreenManagement} />
                            {
                                <Redirect exact from="/mainScreenManagement" to="/mainScreenManagement/1/message" />
                            }

                            <Route path="/mainScreenManagement_uploadMessage/:page/:idx" component={UploadMessage} />
                            {
                                <Redirect exact from="/mainScreenManagement_uploadMessage" to="/mainScreenManagement_uploadMessage/1/0" />
                            }
                            <Route path="/mainScreenManagement_UploadmainTopScreen/:page/:idx" component={UploadmainTopScreen} />
                            {
                                <Redirect exact from="/mainScreenManagement_UploadmainTopScreen" to="/mainScreenManagement_UploadmainTopScreen/1/0" />
                            }
                            <Route path="/videoManagement/:page/:categoryIdx" component={VideoManagement} />
                            {
                                <Redirect exact from="/videoManagement" to="/videoManagement/1/0" />
                            }
                            <Route path="/videoManagement_upload/:page/:idx" component={UploadVideo} />
                            {
                            <Redirect exact from="/videoManagement_upload" to="/videoManagement_upload/1/0" />  
                            }
                            <Route path="/magazineManagement/:page" component={MagazineManagement} />
                            {
                                <Redirect exact from="/magazineManagement" to="/magazineManagement/1" />
                            }
                            <Route path="/magazineManagement_upload/:page/:idx" component={UploadMagazine} />
                            {
                                <Redirect exact from="/magazineManagement_upload" to="/magazineManagement_upload/1/0" />
                            }
                            <Route path="/audiobookManagement/:page" component={AudiobookManagement} />
                            {
                                <Redirect exact from="/audiobookManagement" to="/audiobookManagement/1" />
                            }
                            <Route path="/audiobookManagement_upload/:page/:idx" component={UploadAudiobook} />
                            {
                                <Redirect exact from="/audiobookManagement_upload" to="/audiobookManagement_upload/1/0" />
                            }
                            <Route path="/honoraryManagement/:page" component={HonoraryManagement} />
                                {
                                    <Redirect exact from="/honoraryManagement" to="/honoraryManagement/1" />
                                }
                            <Route path="/honoraryManagement_upload/:page/:idx" component={UploadHonorary} />
                            {
                                <Redirect exact from="/honoraryManagement_upload" to="/honoraryManagement_upload/1/0" />
                            }
                            {/* <Route path="/noticeManagement" component={NoticeManagement} /> */}
                            {/** 페이지 리스트 라우터 다시 설정하기 */}
                            <Route path="/noticeManagement/:page" component={NoticeManagement} />
                            {
                                <Redirect exact from="/noticeManagement" to="/noticeManagement/1" />
                            }
                            <Route path="/noticeManagement_upload/:page/:idx" component={UploadNotice} />
                            {
                                <Redirect exact from="/noticeManagement_upload" to="/noticeManagement_upload/1/0" />
                            }
                            
                            <Route path="/medicalStaffManagement/:page" component={MedicalStaffManagement} />
                            {
                                <Redirect exact from="/medicalStaffManagement" to="/medicalStaffManagement/1" />
                            }
                            <Route path="/medicalStaffManagement_upload/:page/:idx" component={UploadMedicalStaff} />
                            {
                                <Redirect exact from="/medicalStaffManagement_upload" to="/medicalStaffManagement_upload/1/0" />
                            }

                            <Route path="/pushManagement/:page" component={PushManagement} />
                            {
                                <Redirect exact from="/pushManagement" to="/pushManagement/1" />
                            }
                            <Route path="/pushManagement_upload/:page/:idx" component={UploadPush} />
                            {
                                <Redirect exact from="/pushManagement_upload" to="/pushManagement_upload/1/0" />
                            }


                            <Route path="/questionManagement/:page/:categoryIdx/:langCode" component={QuestionManagement} />
                            {
                                <Redirect exact from="/questionManagement" to="/questionManagement/1/0/ko" />
                            }
                            <Route path="/questionManagement_upload/:page/:idx/:langCode" component={UploadQuestion} />
                            {
                                <Redirect exact from="/questionManagement_upload" to="/questionManagement_upload/1/0/ko" />
                            }

                            <Route path="/goodsManagement/:page/:langCode" component={GoodsManagement} />
                            {
                                <Redirect exact from="/goodsManagement" to="/goodsManagement/1/en" />
                            }
                            <Route path="/goodsManagement_upload/:page/:idx" component={UploadGoods} />
                            {
                                <Redirect exact from="/goodsManagement_upload" to="/goodsManagement_upload/1/0" />
                            }
                            <Route path="/categoryManagement/:page" component={CategoryManagement} />
                            {
                                 <Redirect exact from="/categoryManagement" to="/categoryManagement/1" />
                            }
                            <Route path="/categoryManagement_upload/:page/:idx" component={UploadCategory} />
                            {
                                <Redirect exact from="/categoryManagement_upload" to="/categoryManagement_upload/1/0" />
                            }
                            <Route path="/bannerManagement/:page/:categoryIdx" component={BannerManagement}/>
                            {
                                <Redirect exact from="/bannerManagement" to="/bannerManagement/1/0"/>
                            }
                            <Route path="/bannerManagement_upload/:page/:idx" component={UploadBanner} />
                            {
                                <Redirect exact from="/bannerManagement_upload" to="/bannerManagement_upload/1/0" />  
                            }
                            <Route path="/premiumManagement" component={PremiumManagement} />
                            <Route path="/adminManagement/:page" component={AdminManagement} />
                            {
                                <Redirect exact from="/adminManagement" to="/adminManagement/1" />
                            }
                            <Route path="/termsManagement/:page" component={TermsManagement}/>
                            {
                                <Redirect exact from="/termsManagement" to="/termsManagement/1"/>
                            }
                            <Route path="/termsManagement_upload/:page/:idx" component={UploadTerms} />
                            {
                                <Redirect exact from="/termsManagement_upload" to="/termsManagement_upload/1/0"/>
                            }

                            <Redirect to="/error/error-v1" />
                        </Switch>
                    </Layout>
                )}
            </Switch>
        </LayoutContextProvider>
    );
});
