import React from 'react';
import DropzoneComponent from 'react-dropzone-component';
import 'react-dropzone-component/styles/filepicker.css'
import 'dropzone/dist/min/dropzone.min.css'
import jQuery from 'jquery';

/**
 * react-dropzone-component
 * 파일 드로그 앤 드랍
 * 
 * @param {onUpdate} param0  추가 이벤트 : ex) onUpdate(file)
 * @param {onDelete} param1  삭제 이벤트 : ex) onDelete(file)
 * @param {acceptedFiles} param2  파일 형식 : ex) 'image/*'
 * @param {iconFiletypes} param3  파일 형식 icons : ex) ['.jpg', '.png', '.gif', '.jpeg']
 * @param {maxFiles} param4  최대 멀티 선택 수 : ex) 1 ~ 256
 * @param {fileLimit} param5  최대 멀티 업로드 수 : ex) 1 ~ 256
 * @param {title} param6  타이틀 : ex) SIZE 375 X 584
 * @param {description} param7  내용 : ex) 사진파일을 끌어서 여기에 놓거나<br />여기를 클릭하여 사진파일을 업로드합니다
 */
const CustomDropZoneCom = ({onUpdate, onDelete, acceptedFiles, iconFiletypes, maxFiles, fileLimit, title, description}) => {
    let fileSize = maxFiles || 256;
    let limit = fileLimit || 256;

    var myDropzone;
    var componentConfig = {
        iconFiletypes: iconFiletypes || ['.jpg', '.png', '.gif', '.jpeg'],
        showFiletypeIcon: true,
        postUrl: '/no-url',
        
    };
    var djsConfig = {
        addRemoveLinks: true,
        autoProcessQueue: false,
        acceptedFiles: acceptedFiles || 'image/*',
        maxFiles: fileSize,
        maxFilesize: fileSize,
        // uploadMultiple: true,
        // previewsContainer: false,
     }
    var eventHandlers = {
        // This one receives the dropzone object as the first parameter
        // and can be used to additional work with the dropzone.js
        // object
        init: (dropzone) => initCallback(dropzone),
        // All of these receive the event as first parameter:
        drop: callbackArray,
        // dragstart: null,
        // dragend: null,
        // dragenter: null,
        // dragover: null,
        // dragleave: null,
        // All of these receive the file as first parameter:
        removedfile: (file) => removeCallBack(file),
        // thumbnail: null,
        // error: null,
        // processing: null,
        // uploadprogress: null,
        // sending: null,
        // success: null,
        // complete: null,
        // canceled: null,
        // maxfilesreached: null,
        // maxfilesexceeded: null,
        // All of these receive a list of files as first parameter
        // and are only called if the uploadMultiple option
        // in djsConfig is true:
        // processingmultiple: null,
        // sendingmultiple: null,
        // successmultiple: null,
        // completemultiple: null,
        // canceledmultiple: null,
        // Special Events
        // totaluploadprogress: null,
        // reset: null,
        // queuecomplete: null,
        addedfile: (file) => simpleCallBack(file),
    }

    let callbackArray = [
        function () {
            // console.log('Look Ma, I\'m a callback in an array!');
        },
        function () {
            // console.log('Wooooow!');
        }
    ];

    let simpleCallBack = function (file) {
        if(myDropzone) {
            if(myDropzone.files.length <= limit) {
                onUpdate(file);
            } else {
                myDropzone.removeFile(file);
            }
        }
    };    
    
    let removeCallBack = function (file) {
        onDelete(file);
    };

    let initCallback = function (dropzone) {
        myDropzone = dropzone;
        // console.log(myDropzone)
        let html = `<div>`;
        html += `<h5>${title}</h5>`;
        html += `<p>`;
        html += `${description}`;
        html += `</p>`;
        html += `</div>`;
        if(title || description) {
            jQuery('.dz-default.dz-message').html(html)
        }
    }

    return (
        <DropzoneComponent config={componentConfig}
                       eventHandlers={eventHandlers}
                       djsConfig={djsConfig}
                       className={'customDropzone'} />
    )
}

export default CustomDropZoneCom;
