import axios from "axios";

export const ADMIN_TERMS = '/api/' + process.env.REACT_APP_API_VERSION +'/admin/terms'
export const ADMIN_TERMS_BY_IDX = '/api/' + process.env.REACT_APP_API_VERSION +'/admin/terms/'


export function getTerms(params){
    return axios.get(ADMIN_TERMS,{params});
} 

export function deleteTerms(CheckList){
    return axios.delete(ADMIN_TERMS,{data :{idxs : CheckList}})
}

