import axios from 'axios';

export const ADMIN_GOODS = "/api/"+ process.env.REACT_APP_API_VERSION +"/admin/goods"
export const ADMIN_GOODS_BY_IDX = "/api/"+ process.env.REACT_APP_API_VERSION +"/admin/goods/"

export function getGoods(params){
    return axios.get(ADMIN_GOODS,{params});
}

export function deleteGoods(checkList){
    return axios.delete(ADMIN_GOODS,{data :{idxs : checkList}});
}