import React, { useEffect, useState,useMemo } from 'react';
import { Col, Card, Button, Pagination } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Checkbox from '@material-ui/core/Checkbox';
import CustomPagination from '../../../common/components/CustomPagination'

const TabList = ({title, history,page,checkList,oneCheck,langCode,allCheck,goodsList,movePage,goodsPage,searchParams,handleShow_delete}) => {

    
 const handleClick = (idx, e) => {
     if(e.target.type === 'checkbox') return false;
     const params = {             
         searchType : searchParams[3],   
         sortColumn : searchParams[0],
         search:searchParams[4],
         langCode : langCode
     }
     const url = {
       pathname : `/goodsManagement_upload/${page}/${idx}`,
       state : params,
     }
     history.push(url);
   }
   const uploadhandleClick = () => {
     const params = {      
         langCode : langCode
     }
     const url = {
       pathname : `/goodsManagement_upload/1/0`,
       state : params,
     }
     history.push(url);
   }
    //콤마 추가
    const addComma=(num)=>{
        const regexp = /\B(?=(\d{3})+(?!\d))/g;
        return num.toString().replace(regexp, ',');   
    }
   
     return(
         <Card className="tabBodyCard">
             <Card.Header className="d-flex justify-content-between align-items-center">
                 <h4 className="cardTitleBig">{title} &nbsp;&nbsp;<span style={{fontSize : '14px', fontWeight: 400}}>[ 전체 : {goodsPage.totalElements} ]</span></h4>
                 <Button onClick={uploadhandleClick} variant="primary">제품 등록</Button>
             </Card.Header>
             <Card.Body className="d-flex no-gutters p-0">
                 <Table className="commonTable borderTable bb-1">
                         <Thead>
                             <Tr>
                                 <Th style={{width:50}}>
                                 <Checkbox checked = {
                                     checkList.length === goodsPage.numberOfElements && goodsPage.numberOfElements>0
                                         ? true
                                         : false
                                     }
                                     onChange={(e) =>allCheck(e.target.checked)}/>
                                 </Th>
                                 <Th style={{width:80}}>번호</Th>
                                 <Th style={{width:80}}>품번</Th>
                                 <Th>제풍명</Th>
                                 <Th style={{width:80}}>원가(단가)</Th>
                                 <Th style={{width:160}}>등록(수정)일</Th>
                             </Tr>
                         </Thead>
                         <Tbody>
                                 {goodsList.length > 0 
 
                                 ? 
                                 goodsList.map((v,i)=>{
                                    
                                     return(
                                         <Tr onClick={(e)=>handleClick(v.idx, e)}>
                                         <Td>
                                             <Checkbox 
                                             checked={
                                                 checkList.includes(v.idx) ? true : false
                                             }
                                             onChange={(e)=>oneCheck(e.target.checked,v.idx)} />
                                         </Td>
                                         <Td>{v.idx}</Td>
                                         <Td>{v.articleNumber}</Td>
                                         <Td>{v.title}</Td>
                                         <Td>{addComma(v.price)}</Td>
                                         <Td>{v.createAt}</Td>
                                         </Tr>
                                     )
                                     
                                 })
                                
                                 :
                                 <Tr >
                                     <Td colSpan="6">관련 게시물이 없습니다</Td>
                                 </Tr>
                                 }
                         </Tbody>
                     </Table>
             </Card.Body>
 
             <Card.Footer className="d-flex flex-row justify-content-between align-items-center paginationFooter">
                 <div>
                     <CustomPagination
                         itemsCount={10}
                         pageData={goodsPage}
                         page={page}
                         selectctgidx={langCode}
                         onClickEvent={movePage}
                     >
                     </CustomPagination>
                 </div>
                 <div>
                    <Button variant="outline-danger ml-2" onClick={handleShow_delete}>제품 삭제</Button>
                </div>
             </Card.Footer>
 
         </Card>
     )
 }
 
 export default TabList;