import React, { useState, useEffect,useMemo } from 'react';
import axios from 'axios';

export const MAIN_TOP_SCREEN = "/api/"+ process.env.REACT_APP_API_VERSION + "/admin/mainTop"
const Policy = () => {

    const [policy ,setPolicy] = useState('');
    
    useEffect(()=>{
        const rs = axios.get('/api/v1/app/common/terms?langCode=Ko');
        rs.then(({data})=>{    
            let obj = data?.filter(item=>item.type == 'PRIVACY_POLICY')[0];        
            setPolicy(obj.item.content.replace(/\n/g, '<br/>'));
        });
    },[])

    return (
        <div style={{background : '#fff', width: '100%', height : '100%', color: 'black', paddingTop: '28px'}}>
            <div style={{textAlign : 'center', fontSize : '36px', fontWeight: '700'}}>개인정보 처리방침</div>
            <div style={{textAlign : 'left', fontSize : '16px', padding: '50px'}} dangerouslySetInnerHTML={{__html : policy}}>                    
            </div>
        </div>
    );
};

export default Policy;
