import React, { useRef, useState } from 'react';
import {insertMedia, mediaConvert} from '../UploadVideo/crud'

const AwsUpload = () => {
    var aws = require('aws-sdk'); 
    require('dotenv').config(); // Configure dotenv to load in the .env file
    // Configure aws with your accessKeyId and your secretAccessKey

    const fileRef = useRef();
    const [file, setFile] = useState({});
    const region = 'ap-northeast-2';
    const identityPoolId = 'ap-northeast-2:d5aac547-4440-4cb9-976b-c7edfe9e39f5';
    const bucket = 'kr.synergy.dev.origin.video';

    const handleChange = (e) => {
        setFile(e.target.files[0]);
    }

    // console.log(file)
    
    const handleUpload = (e) => {
        return false;
        var n = new Date().getTime();

        var fileKey = 'mov_'+n;
        // console.log(file.type);
        var params = {
            Key: fileKey,
            ContentType: file.type,
            Body: file,
            ContentDisposition: 'attachment',
        };

        aws.config.update({
            region: region, // Put your aws region here
            credentials: new aws.CognitoIdentityCredentials({
                IdentityPoolId: identityPoolId
            })
        });
        aws.config.httpOptions.timeout = 0;
    
        var s3 = new aws.S3({
            apiVersion: "2006-03-01",
            params: { Bucket: bucket }
        });

        // console.log(s3);

        s3.upload(params).on('httpUploadProgress', function(evt) {
            // 여기서 업로드 진행 상황 알려줌..
            var uploaded = Math.round(evt.loaded / evt.total * 100);
            console.log('File uploaded: '+uploaded+'%');
            // $('#loading_per').text(uploaded+'%');
            // $('.loading .bar2').animate({width : uploaded + '%'},100);
        }).send(function(err, data) {
            if (err){
                // console.log(err, err.stack);
                alert('File upload fail!');
                return false;
            }
            // console.log('data :: ', data)
            // 업로드 완료 되면 호출되는 부분
            // ths.uploadVideoFileSrc = data.Location;
            // ths.uploadVideoKey = fileKey;
            // // fileKey  부분이 was 쪽에 전달이 되어야 한다.
            // console.log(fileKey)
            // console.log('File URL:', ths.uploadVideoFileSrc);
            // ths.uploadVideoFile = null
            var obj = {
                bucket: data.Bucket,
                eTag: data.ETag,
                key: data.Key,
                location: data.Location,
                fileType: file.type,
            }
            saveMedia(obj);
            // alert('File is uploaded successfully!');
        })
    }

    const saveMedia = (obj) => {
        var mediaParams = {
            title : 'test',
            content : 'test',
            langCode : 'ko',
            mediaType : obj.fileType,
            openFlag : true,
            playtime : 0,
            showOrder : '',
            thumbnailPath : '',
            videoPath : obj.key,
            categoryIdx : 2,
            uploadedVideos :  JSON.parse(JSON.stringify(obj)),
        }
        // console.log('mediaParams :: ', mediaParams)

        const insertRs = insertMedia(mediaParams);
        insertRs.then((insertRes) => {
            // console.log('insertRes :: ', insertRes)

            // 에러처리

            // 성공처리
            // 성공시 convertFile
            var convertParams = {
                path : obj.key,
                bucketRegion : region,
                albumBucketName : bucket,
                IdentityPoolId : identityPoolId,
            }

            const convertRs = mediaConvert(convertParams);
            convertRs.then((convertRes) => {
                // console.log('convertRes :: ', convertRes)
            })
        })

    }
  
    
    return (
        <div className="App">
        <center>
            <h1>UPLOAD A FILE</h1>
            
            <input onChange={handleChange} ref={fileRef} type="file"/>
            <br/>
            <button onClick={handleUpload}>UPLOAD</button>
        </center>
        </div>
    );
}
export default AwsUpload;
