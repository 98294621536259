// UploadCategory _crud

import axios from "axios";
 
export const ADMIN_CATEGORY ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/category';
export const ADMIN_CATEGORY_BY_IDX ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/category/';
export const API_ADMIN_CATEGORY_FILEUPLOAD ='/api/' + process.env.REACT_APP_API_VERSION + '/admin/common/onlyFileUploads'
export function getDetailCategoryList(params){
    return axios.get(ADMIN_CATEGORY_BY_IDX+params);
}

export function updateCategory(categoryidx, params) {
    // PUT : /api/v1/admin/notice/{noticeIdx}
    return axios.put(ADMIN_CATEGORY_BY_IDX + categoryidx, params);
}

export function createCategory(params) {
 
    return axios.post(ADMIN_CATEGORY, params);
}

export function CategoryFileupload(params){
    // console.log('파일 업로드 시 params',params)
    return axios.post(API_ADMIN_CATEGORY_FILEUPLOAD,params,{headers : {'Content-Type' : 'multipart/form-data'}})
}

export function deleteCategory(categoryidx) {
    return axios.delete(ADMIN_CATEGORY_BY_IDX+categoryidx)
}