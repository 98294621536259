// UploadNotice _crud

import axios from "axios";


export const admin_notice = "/api/v1/admin/notice";
export const admin_notice_delete = "/api/v1/admin/notices";

// new
export const API_ADMIN_NOTICE = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/notice';
export const API_ADMIN_NOTICES = '/api/' + process.env.REACT_APP_API_VERSION + '/admin/notices';


export function AllnoticeList(params,searchimt){
    let param = {
        page : params.page,
        size : params.size,
        sortColumn : searchimt.sortColumn ? searchimt.sortColumn : '' ,
        langCode : searchimt.langCode ? searchimt.langCode :'',
        titleOrContent : searchimt.titleOrContent ? searchimt.titleOrContent :''
    }
    return axios.get(admin_notice+'?langCode='+param.langCode+'&titleOrContent='+param.titleOrContent+'&sortColumn='+param.sortColumn+'&page='+param.page+'&size='+param.size);
}
export function Deletenotice(CheckList){
    return axios.delete(admin_notice_delete,{data :{idxs : CheckList}});   
}


// new 
export function deleteNotices(idxs) {
    return axios.delete(API_ADMIN_NOTICES,{data :{idxs : idxs}});   
}
export function getNotices(params) {
    return axios.get(API_ADMIN_NOTICE, {params});
}